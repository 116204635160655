import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VitalInspection } from "types/inspection";

/**
 * Currently this redux slice is unused
 */

export type VitalInspectionFormState = VitalInspection & {
  enId?: number;
};

type VitalInspectionArrayState = VitalInspectionFormState[];

const initialState: VitalInspectionArrayState = [];

const vitalInspectionSlice = createSlice({
  name: "vital_inspection_slice",
  initialState,
  reducers: {
    addVitalInspection: (
      state,
      action: PayloadAction<VitalInspectionFormState>,
    ) => {
      state.push(action.payload);
    },
    updateVitalInspection: (
      state,
      action: PayloadAction<VitalInspectionFormState>,
    ) => {
      const { enId, ...data } = action.payload;
      const index = state.findIndex((item) => item.enId === enId);
      if (index !== -1) {
        state[index] = { ...state[index], ...data };
      }
    },
    removeVitalInspection: (state, action: PayloadAction<number>) => {
      const enId = action.payload;
      const index = state.findIndex((item) => item.enId === enId);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    resetArrayState: () => initialState,
  },
});

export default vitalInspectionSlice.reducer;
export const {
  addVitalInspection,
  updateVitalInspection,
  removeVitalInspection,
  resetArrayState,
} = vitalInspectionSlice.actions;
