import styled from "styled-components";
import Select, {
  Props as ReactSelectProps,
  GroupBase,
  components,
  CSSObjectWithLabel,
  StylesConfig,
  DropdownIndicatorProps,
} from "react-select";
import { BiChevronDown } from "react-icons/bi";

import { COLORS } from "assets/theme";
import { GroupLabel, StyledLabel } from "components/InputText/InputText";
import { Label } from "reactstrap";

interface PureInputSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends ReactSelectProps<Option, IsMulti, Group> {
  label?: string;
  labelColor?: string;
  className?: string;
  fullWidth?: boolean;
  icon?: JSX.Element;
}

const StyledInputWrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};

  & [class*="-container"] {
    margin: 0;
    outline: none;
  }

  & [class$="-control"] {
    border-radius: 6px;
    box-shadow: none !important;
    border: 1px solid ${COLORS.neutral_gray_gull};
    &:focus-within {
      outline: 2px solid ${COLORS.green} !important;
      outline-offset: -2px;
    }

    &:hover {
      border-color: ${COLORS.neutral_gray_gull};
    }
  }

  & [class$="-multiValue"] {
    background: ${COLORS.teal};
    color: white;
    box-shadow: none;
  }

  &.disabled {
    & [class$="-multiValue"] > div[class$="-MultiValueRemove"] {
      display: none;
      /* background: ${COLORS.teal};
    color: white; */
    }
  }
`;

const PureInputSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  label,
  labelColor,
  noOptionsMessage,
  styles,
  placeholder,
  className,
  name,
  icon,
  fullWidth,
  ...rest
}: PureInputSelectProps<Option, IsMulti, Group>) => {
  const id = name || `pure-input-select-${Math.random().toString()}`;

  const baseStyle: StylesConfig<Option, IsMulti, Group> = {
    control: (curStyle, { isDisabled }) => {
      const disabledStyle: CSSObjectWithLabel = isDisabled
        ? {
            border: `0.5px solid ${COLORS.neutral_gray_gull}`,
            background: `${COLORS.silver_lining}`,
          }
        : {};
      return {
        ...curStyle,
        borderRadius: 4,
        border: `0.5px solid ${COLORS.neutral_gray_gull}`,
        minHeight: 38,
        ...disabledStyle,
      };
    },
    valueContainer: (curStyle) => ({
      ...curStyle,
      padding: "0 6px",
      margin: 0,
    }),
    placeholder: (curStyle) => ({
      ...curStyle,
      padding: 0,
      margin: 0,
    }),
    groupHeading: (base) => {
      return {
        ...base,
        margin: 0,
      };
    },
    group(base) {
      return {
        ...base,
        background: COLORS.light_grey_1,
        paddingBottom: 0,
      };
    },
    input: (curStyle) => ({
      ...curStyle,
      padding: 0,
      margin: 0,
    }),
    singleValue: (curStyle) => ({
      ...curStyle,
      color: COLORS.dark_blue,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        borderRadius: 6,
        padding: 0,
        color: COLORS.white,
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: COLORS.white,
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        marginTop: 0,
        color: isFocused ? COLORS.text_black : COLORS.dark_blue,
        backgroundColor: isFocused ? COLORS.mint_green : COLORS.white,
      };
    },
    indicatorsContainer: (styles) => ({
      ...styles,
      padding: 0,
      margin: 0,
      cursor: "pointer",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: 0,
      marginLeft: 5,
      marginRight: 5,
    }),
    noOptionsMessage: (styles) => ({
      ...styles,
      color: COLORS.red,
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      marginTop: 5,
      backgroundColor: COLORS.white,
      zIndex: 9999,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
    }),
    multiValueRemove(base, props) {
      return {
        ...base,
        display: props.isDisabled ? "none" : "initial",
        borderRadius: "6px",
      };
    },
    container: (styles) => ({
      ...styles,
      width: "fill-available",
    }),
  };

  const DropdownIndicator = (
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <div style={{ paddingRight: "2px", paddingBottom: "2px" }}>
          {icon || <BiChevronDown size={20} />}
        </div>
      </components.DropdownIndicator>
    );
  };

  return (
    <StyledInputWrapper className={className} fullWidth={fullWidth}>
      {label && (
        <StyledLabel as={Label} htmlFor={id} $labelColor={labelColor}>
          {label}
          {rest.required ? (
            <>
              {" "}
              <span className="required-star">*</span>
            </>
          ) : null}
        </StyledLabel>
      )}
      <Select
        inputId={id}
        name={id}
        placeholder={placeholder ? placeholder : "Pilih..."}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={
          noOptionsMessage
            ? noOptionsMessage
            : ({ inputValue }) => {
                if (inputValue) return `'${inputValue}' tidak ditemukan!`;
                return "Pilihan kosong!";
              }
        }
        styles={{
          ...baseStyle,
          ...styles,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        formatGroupLabel={({ label }) => {
          return <GroupLabel>{label}</GroupLabel>;
        }}
        menuPortalTarget={document.body}
        {...rest}
      />
    </StyledInputWrapper>
  );
};

export default PureInputSelect;
