import { logo } from "assets";
import { BasicHeader } from "components/base";
import {
  BackButton,
  Card,
  CardContent,
} from "Pages/LoginPage/components/Terms/style";

type Props = {
  openTOS: boolean;
  setOpenTOS: React.Dispatch<React.SetStateAction<boolean>>;
};

const Terms = ({ openTOS, setOpenTOS }: Props) => {
  return (
    <>
      {openTOS && (
        <>
          <div className="top-content d-flex justify-content-between py-3 px-5">
            <img src={logo} alt="rekmed" width={150} />
            <BackButton onClick={() => setOpenTOS(false)}>Kembali</BackButton>
          </div>
          <Card>
            <CardContent>
              <BasicHeader className="text-center">Terms of Use</BasicHeader>
              <ol className="main-list">
                <li>Hubungan Anda</li>
                <ol>
                  <li>
                    Penggunaan produk, perangkat lunak, sistem, layanan Rekmed
                    dan situs web ini (selanjutnya disebut secara kolektif
                    sebagai "Layanan" tunduk pada syarat dan ketentuan dalam
                    Ketentuan Perjanjian ini yang mengatur hubungan antara Anda
                    dan PERSEKUTUAN PERDATA Rekmed Indonesia ("Kami"). "Rekmed"
                    adalah sebuah sistem piranti lunak yang Kami ciptakan dan
                    miliki. Kami adalah suatu persekutuan perdata yang didirikan
                    dan dijalankan berdasarkan hukum negara Republik Indonesia.
                  </li>
                  <li>
                    Penggunaan produk, perangkat lunak, sistem, layanan Rekmed
                    dan situs web ini (selanjutnya disebut secara kolektif
                    sebagai "Layanan" tunduk pada syarat dan ketentuan dalam
                    Ketentuan Perjanjian ini yang mengatur hubungan antara Anda
                    dan PERSEKUTUAN PERDATA Rekmed Indonesia ("Kami"). "Rekmed"
                    adalah sebuah sistem piranti lunak yang Kami ciptakan dan
                    miliki. Kami adalah suatu persekutuan perdata yang didirikan
                    dan dijalankan berdasarkan hukum negara Republik Indonesia.
                  </li>
                  <li>
                    Perjanjian Anda dengan Kami juga akan mencakup persyaratan
                    khusus yang disyaratkan dan berlaku pada Layanan tertentu,
                    selain Ketentuan Umum. Semua ini disebut sebagai
                    "Persyaratan Tambahan". Persyaratan Tambahan yang berlaku
                    untuk suatu Layanan dapat diakses oleh Anda. Anda
                    disyaratkan untuk membaca Persyaratan Tambahan baik yang
                    tercantum di dalam atau melalui penggunaan Layanan tersebut.
                  </li>
                  <li>
                    Ketentuan Umum, bersama-sama dengan Persyaratan Tambahan,
                    mengatur hak dan kewajiban Anda dan mengikat Anda secara
                    hukum atas penggunaan Layanan. Penting bagi Anda untuk
                    meluangkan waktu untuk membaca dengan cermat dan seksama
                    Ketentuan Umum dan Persyaratan Tambahan sebelum
                    mempergunakan Layanan Kami. Secara kolektif, Ketentuan Umum
                    dan Persyaratan Tambahan akan disebut sebagai "Persyaratan".
                  </li>
                  <li>
                    Jika terdapat pertentangan antara ketentuan dalam
                    Persyaratan Tambahan dengan ketentuan dalam Ketentuan Umum,
                    ketentuan dalam Persyaratan Tambahan yang akan diutamakan
                    dan berlaku dalam penggunaan Layanan ini.
                  </li>
                </ol>
                <li>Menerima Persyaratan</li>
                <ol>
                  <li>
                    Untuk menggunakan Layanan, Anda wajib terlebih dahulu
                    menyetujui Persyaratan. Anda tidak dapat menggunakan Layanan
                    jika Anda tidak menyetujui dan menerima Persyaratan.
                  </li>
                  <li>
                    Anda dapat menyetujui Persyaratan dengan cara berikut:
                  </li>
                  <ol>
                    <li>
                      Mengklik untuk menerima atau menyetujui Persyaratan,
                      dimana pilihan ini disediakan untuk Anda oleh Kami dalam
                      antarmuka pengguna untuk setiap Layanan; atau
                    </li>
                    <li>
                      Dengan menggunakan Layanan. Dalam hal ini, Anda memahami
                      dan menyetujui bahwa Kami akan menganggap penggunaan
                      Layanan sebagaimana penerimaan Persyaratan dari titik itu
                      dan seterusnya.
                    </li>
                  </ol>
                  <li>
                    Sebelum Anda melanjutkan, Anda disarankan untuk mencetak
                    atau menyimpan salinan lokal Ketentuan Umum untuk keperluan
                    dokumentasi Anda.
                  </li>
                  <li>
                    Anda mungkin tidak dianggap menggunakan Layanan dan dianggap
                    tidak menerima Persyaratan jika:
                  </li>
                  <ol>
                    <li>
                      Anda tidak patut secara hukum untuk membuat suatu
                      kesepakatan atau perjanjian yang mengikat Kami, atau
                    </li>
                    <li>
                      Anda adalah orang yang dilarang menerima Layanan
                      berdasarkan undang-undang negara Republik Indonesia atau
                      negara-negara lain termasuk negara di mana Anda tinggal
                      atau di mana Anda menggunakan Layanan ini.
                    </li>
                  </ol>
                </ol>
                <li>Bahasa</li>
                <ol>
                  <li>
                    Apabila Kami menyediakan terjemahan bahasa asing dari
                    Persyaratan yang aslinya ditulis dalam versi bahasa
                    Indonesia, Anda setuju bahwa terjemahan tersebut disediakan
                    hanya untuk kenyamanan Anda.
                  </li>
                  <li>
                    Jika ada pertentangan antara versi Bahasa Indonesia dan
                    versi bahasa asing dari Persyaratan, maka versi Bahasa
                    Indonesia yang akan berlaku.
                  </li>
                </ol>
                <li>Penyediaan Layanan</li>
                <ol>
                  <li>
                    Dalam menyediakan Layanan, Kami dapat memberikan pelayanan
                    secara langsung maupun dibantu oleh atau melalui anak
                    perusahaan dan badan hukum terafiliasinya (“Anak Perusahaan
                    dan Afiliasi”). Pada umumnya, perusahaan-perusahaan ini akan
                    menyediakan Layanan kepada Anda atas nama Kami untuk dan
                    atas nama Kami sendiri. Anda mengakui dan setuju bahwa Anak
                    Perusahaan dan Afiliasi akan berhak menyediakan Layanan
                    kepada Anda.
                  </li>
                  <li>
                    Dalam menyediakan dan meningkatkan Layanan, Kami juga dapat
                    memberikan pelayanan dengan dibantu oleh pihak ketiga dimana
                    pihak ketiga akan Kami seleksi dengan baik untuk dapat
                    meningkatkan Layanan menjadi lebih baik. Anda mengakui dan
                    menyetujui bahwa pihak ketiga akan berhak untuk menyediakan
                    dan meningkatkan Layanan kepada Anda.
                  </li>
                  <li>
                    Kami akan terus berinovasi untuk memberikan pengalaman
                    terbaik bagi penggunanya. Anda mengakui dan setuju bahwa
                    bentuk dan sifat dari Layanan yang diberikan dapat berubah
                    dari waktu ke waktu tanpa pemberitahuan sebelumnya kepada
                    Anda.
                  </li>
                  <li>
                    Anda dapat menghentikan penggunaan Layanan setiap saat.
                    Penghentian penggunaan Layanan akan dianggap berlaku sejak
                    Kami menerima informasi Anda perihal permohonan penghentian
                    Layanan. Sampai Anda menginformasikan Kami perihal
                    permohonan penghentian Layanan, Anda akan terus ditagih
                    sebesar jumlah bulanan Anda yang telah disetujui sebagaimana
                    dijelaskan dalam “Persyaratan Tambahan”.
                  </li>
                  <li>
                    Jika Kami menonaktifkan akun Anda, seluruh akses Anda ke
                    dalam Layanan akan ditutup termasuk akses atas akun Anda,
                    rincian akun Anda atau setiap file atau konten lain yang
                    terkandung atau disimpan dalam akun Anda.
                  </li>
                </ol>
                <li>Penggunaan Layanan</li>
                <ol>
                  <li>
                    Untuk mengakses Layanan tertentu, Anda akan diminta untuk
                    memberikan informasi tentang diri Anda (seperti identifikasi
                    atau rincian kontak) sebagai bagian dari proses pendaftaran
                    untuk Layanan tertentu tersebut, atau sebagai bagian dari
                    otorisasi untuk dapat terus menggunakan Layanan. Anda setuju
                    bahwa informasi pendaftaran yang Anda berikan untuk sistem
                    Rekmed akan selalu akurat, benar dan yang terbaru.
                  </li>
                  <li>
                    Anda setuju untuk menggunakan Layanan hanya untuk tujuan
                    yang diizinkan oleh (a) Persyaratan, (b) hukum yang berlaku,
                    dan (c) peraturan atau praktek yang berlaku umum atau
                    pedoman di wilayah hukum terkait (termasuk hukum mengenai
                    ekspor data atau perangkat lunak untuk dan dari Indonesia
                    atau negara lain yang relevan).
                  </li>
                  <li>
                    Anda setuju untuk tidak mengakses (atau berupaya mengakses)
                    salah satu Layanan dengan cara apapun selain melalui
                    antarmuka yang disediakan oleh Kami, kecuali Anda secara
                    khusus diijinkan melakukannya dalam perjanjian terpisah
                    dengan Kami. Anda setuju untuk tidak mengakses (atau
                    berupaya mengakses) salah satu Layanan melalui sarana
                    otomatis (termasuk penggunaan script atau crawler web) dan
                    harus memastikan bahwa Anda mematuhi petunjuk yang
                    ditetapkan dalam setiap file robots.txt pada Layanan.
                  </li>
                  <li>
                    Anda setuju bahwa Anda tidak akan terlibat dalam aktivitas
                    apapun yang mempengaruhi atau mengganggu Layanan (atau
                    server dan jaringan yang terhubung ke Layanan).
                  </li>
                  <li>
                    Kecuali Anda secara khusus diperbolehkan melakukannya dalam
                    perjanjian terpisah dengan Kami, Anda setuju bahwa Anda
                    tidak akan mereproduksi, menggandakan, menyalin, menjual,
                    memperdagangkan atau menjual kembali Layanan untuk tujuan
                    apapun.
                  </li>
                  <li>
                    Anda setuju bahwa Anda bertanggung jawab penuh (dan Kami
                    tidak bertanggung jawab kepada Anda atau pihak ketiga mana
                    pun) atas pelanggaran kewajiban Anda berdasarkan Persyaratan
                    dan akibat (termasuk kerugian atau kerusakan yang mungkin
                    Kami alami) dari setiap pelanggaran tersebut.
                  </li>
                </ol>
                <li>Kata Sandi (password) dan Keamanan Akun</li>
                <ol>
                  <li>
                    Anda setuju dan memahami bahwa Anda bertanggung jawab untuk
                    menjaga kerahasiaan kata sandi yang Anda pergunakan terkait
                    dengan akun yang Anda gunakan untuk mengakses Layanan.
                  </li>
                  <li>
                    Anda setuju bahwa Anda akan bertanggung jawab atas semua
                    aktivitas yang terjadi dalam akun Anda.
                  </li>
                  <li>
                    Jika Anda menyadari ada suatu penggunaan Layanan atas nama
                    Anda yang terjadi tanpa izin penggunaan sandi atau akun
                    Anda, Anda setuju untuk memberitahukan Kami segera.
                  </li>
                </ol>
                <li>Privasi dan Informasi Pribadi Anda</li>
                <ol>
                  <li>
                    Berkaitan dengan Layanan, Kami dapat mengumpulkan informasi
                    tentang layanan yang Anda gunakan dan bagaimana Anda
                    menggunakannya, seperti saat Anda mengunjungi situs web yang
                    menggunakan layanan Kami atau Anda melihat dan berinteraksi
                    dengan iklan dan konten Kami.
                  </li>
                  <li>
                    Anda setuju dengan ketentuan penggunaan Kami atas informasi
                    data Anda sesuai dengan Kebijakan Privasi yang tercantum
                    dalam situs Kami.
                  </li>
                </ol>
                <li>Isi dalam Layanan</li>
                <ol>
                  <li>
                    Anda memahami bahwa seluruh informasi (seperti file data,
                    teks tertulis, perangkat lunak komputer, musik, file audio
                    atau suara lainnya, foto, video atau gambar lain) yang dapat
                    Anda akses sebagai bagian dari, atau melalui penggunaan
                    Layanan, merupakan tanggung jawab dari pembuat konten
                    tersebut. Semua informasi tersebut di atas disebut sebagai
                    “Konten”.
                  </li>
                  <li>
                    Anda harus menyadari bahwa Konten yang disajikan kepada Anda
                    sebagai bagian dari Layanan, termasuk namun tidak terbatas
                    pada, iklan dalam Layanan dan Isi yang disponsori di dalam
                    Layanan dapat dilindungi oleh hak kekayaan intelektual yang
                    dimiliki oleh sponsor atau pemasang iklan yang menyediakan
                    Konten tersebut untuk Kami (atau oleh orang lain atau
                    perusahaan atas nama mereka). Anda dilarang mengubah,
                    menyewakan, meminjamkan, menjual, mendistribusikan, atau
                    membuat karya turunan berdasarkan Konten tersebut (baik
                    secara keseluruhan atau sebagian) kecuali Anda secara khusus
                    telah mendapatkan persetujuan atau diizinkan untuk
                    melakukannya oleh pemilik Konten tersebut.
                  </li>
                  <li>
                    Kami berhak (tetapi tidak berkewajiban) untuk pra-layar,
                    mengkaji, memberikan tanda, filter, mengubah, menolak atau
                    menghapus sebagian atau seluruh Konten yang bertentangan
                    dengan norma-norma, ketertiban umum, moral dan undang-undang
                    yang berlaku di Indonesia tanpa persetujuan Anda.
                  </li>
                  <li>
                    Anda setuju bahwa Anda bertanggung jawab penuh (dan Kami
                    tidak bertanggung jawab kepada Anda atau kepada pihak ketiga
                    untuk) terhadap setiap Konten yang Anda buat, kirim atau
                    tampilkan saat menggunakan Layanan dan atas konsekuensi dari
                    tindakan Anda (termasuk kerugian atau kerusakan yang mungkin
                    Kami alami) dengan melakukannya.
                  </li>
                </ol>
                <li>Hak Kepemilikan</li>
                <ol>
                  <li>
                    Anda mengakui dan setuju bahwa Kami (atau pemberi lisensi
                    Kami) memiliki semua hak hukum, kuasa, dan kepentingan dalam
                    dan terhadap Layanan, termasuk hak kekayaan intelektual yang
                    terdapat dalam Layanan (baik hak tersebut didaftarkan atau
                    tidak, dan dimanapun di dunia hak tersebut ada). Anda lebih
                    lanjut mengakui bahwa Layanan dapat memuat informasi yang
                    ditetapkan bersifat rahasia oleh Kami dan bahwa Anda tidak
                    boleh mengungkapkan informasi tersebut tanpa persetujuan
                    tertulis sebelumnya dari Kami.
                  </li>
                  <li>
                    Kecuali jika disetujui sebaliknya secara tertulis oleh Kami,
                    tidak ada dalam Persyaratan yang memberikan Anda hak untuk
                    menggunakan nama dagang Kami termasuk namun tidak terbatas
                    pada “Rekmed”, merek dagang, merek layanan, logo, nama
                    domain, dan fitur merek khusus lainnya.
                  </li>
                  <li>
                    Jika Anda telah diberi hak secara tegas untuk menggunakan
                    salah satu fitur merek tersebut dalam perjanjian tertulis
                    terpisah dengan Kami, maka Anda setuju bahwa penggunaan
                    fitur tersebut harus sesuai dengan perjanjian tersebut,
                    setiap ketentuan yang berlaku pada Persyaratan yang dapat
                    dan akan diperbarui dari waktu ke waktu.
                  </li>
                  <li>
                    Selain lisensi terbatas yang ditetapkan secara khusus dalam
                    Persyaratan ini, Kami mengakui dan setuju bahwa Kami tidak
                    mendapatkan hak, kuasa, atau kepentingan dari Anda (atau
                    pemberi lisensi Anda) berdasarkan Persyaratan ini dalam atau
                    terhadap Konten apapun yang Anda kirimkan, posting,
                    mengirimkan atau ditampilkan pada , atau melalui Layanan,
                    termasuk hak kekayaan intelektual yang terdapat dalam Konten
                    (baik hak tersebut didaftarkan atau tidak, dan dimanapun di
                    dunia hak tersebut ada). Kecuali jika disetujui sebaliknya
                    secara tertulis dengan Kami, Anda setuju bahwa Anda
                    bertanggung jawab untuk melindungi dan menegakkan hak-hak
                    mereka dan bahwa Kami tidak berkewajiban untuk melakukannya
                    atas nama Anda.
                  </li>
                  <li>
                    Anda setuju bahwa Anda tidak akan menghapus, mengaburkan,
                    atau mengubah pemberitahuan hak milik (termasuk hak cipta
                    dan pemberitahuan merek dagang) yang mungkin melekat atau
                    terdapat pada Layanan.
                  </li>
                  <li>
                    Kecuali Anda secara tegas diizinkan untuk melakukannya
                    secara tertulis oleh Kami, Anda setuju bahwa dalam
                    menggunakan Layanan, Anda tidak akan menggunakan merek
                    dagang, merek layanan, nama dagang, logo perusahaan atau
                    organisasi dengan cara yang mungkin atau dimaksudkan untuk
                    menyebabkan kebingungan tentang pemilik atau pengguna resmi
                    dari merek, nama atau logo Rekmed.
                  </li>
                </ol>
                <li>Lisensi</li>
                <ol>
                  <li>
                    Kami memberi Anda lisensi yang tidak dapat dialihkan dan
                    non-eksklusif untuk menggunakan perangkat lunak yang
                    disediakan kepada Anda sebagai bagian dari Layanan yang
                    diberikan kepada Anda oleh Kami (disebut sebagai “Perangkat
                    Lunak”) berdasarkan ketentuan yang tercantum dalam Ketentuan
                    Tambahan. Lisensi ini adalah bertujuan semata-mata untuk
                    memungkinkan Anda untuk menggunakan dan menikmati manfaat
                    Layanan Kami, dengan cara yang diizinkan berdasarkan
                    Persyaratan.
                  </li>
                  <li>
                    Anda dilarang (dan Anda dilarang mengizinkan orang lain
                    untuk) menyalin, memodifikasi, membuat karya turunan dari,
                    merekayasa balik, mendekompilasi, atau berupaya mengambil
                    kode sumber Perangkat Lunak atau bagian daripadanya, kecuali
                    diizinkan secara tersurat atau diwajibkan oleh hukum, atau
                    kecuali Anda secara khusus dan tertulis diizinkan oleh Kami
                    untuk melakukannya.
                  </li>
                  <li>
                    Kecuali apabila Kami telah memberikan izin tertulis untuk
                    Anda melakukannya, Anda dilarang menetapkan pengalihan (atau
                    memberikan sub-lisensi) hak Anda untuk menggunakan Piranti
                    Lunak, memberikan hak tanggungan atas atau terhadap hak Anda
                    untuk menggunakan Piranti Lunak, atau mengalihkan setiap
                    bagian dari hak Anda untuk menggunakan Piranti Lunak kepada
                    pihak manapun.
                  </li>
                </ol>
                <li>Lisensi Konten dari Anda</li>
                <ol>
                  <li>
                    Anda setuju bahwa lisensi ini mencakup hak untuk Kami untuk
                    membuat Konten tersedia untuk perusahaan, organisasi atau
                    individu dengan siapa Kami memiliki hubungan untuk
                    penyediaan layanan sindikasi, dan menggunakan Konten
                    tersebut dalam hubungannya dengan penyediaan layanan
                    tersebut.
                  </li>
                  <li>
                    Anda menegaskan dan menjamin untuk Kami bahwa Anda memiliki
                    semua hak, kekuasaan dan wewenang yang diperlukan untuk
                    memberikan lisensi di atas.
                  </li>
                  <li>
                    Anda menegaskan dan menjamin bahwa Anda telah memahami dan
                    menyetujui pengumpulan informasi Anda sebagaimana dijelaskan
                    didalam Kebijakan Privasi.
                  </li>
                  <li>
                    Bersedia Konten dari Anda digunakan untuk kegiatan
                    penelitian dan pengembangan (R & D) Layanan dari Kami.
                  </li>
                </ol>
                <li>Pembaruan Perangkat Lunak</li>
                <ol>
                  <li>
                    Perangkat Lunak yang Anda gunakan dapat diperbaharui oleh
                    Kami secara berkala . Pembaruan ini dirancang untuk
                    meningkatkan dan mengembangkan Layanan lebih lanjut dan
                    dapat berupa perbaikan bug, penyempurnaan fungsi, modul
                    perangkat lunak baru dan versi yang terbaru. Anda setuju
                    untuk menerima pembaruan (update) tersebut (dan mengizinkan
                    Kami untuk memberikan kepada Anda) sebagai bagian dari
                    penggunaan Layanan oleh Anda.
                  </li>
                </ol>
                <li>Pengakhiran Layanan</li>
                <ol>
                  <li>
                    Persyaratan akan terus berlaku hingga hubungan Anda dengan
                    Kami diakhiri sebagaimana disebutkan di bawah.
                  </li>
                  <li>
                    Jika Anda ingin mengakhiri Layanan, Anda dapat melakukannya
                    dengan (a) memberitahukan Kami pada setiap saat dan (b)
                    menutup akun Anda untuk seluruh Layanan yang Anda gunakan,
                    di mana Rekmed telah membuat pilihan ini tersedia untuk
                    Anda. Pemberitahuan Anda wajib dikirim secara tertulis, dan
                    ditujukan langsung kepada Kami.
                  </li>
                  <li>
                    Kami dapat setiap saat mengakhiri Layanan dan perjanjian
                    hukum dengan Anda jika:
                  </li>
                  <li>
                    Anda telah melanggar ketentuan Persyaratan (atau telah
                    bertindak dengan cara yang jelas menunjukkan bahwa Anda
                    tidak bermaksud atau tidak dapat mematuhi ketentuan
                    Persyaratan); atau
                  </li>
                  <li>
                    Kami diminta oleh hukum atau putusan pengadilan/arbitrase
                    atau pihak yang berwenang di negara Republik Indonesia
                    (misalnya, di mana penyediaan Layanan kepada Anda adalah,
                    atau akan merupakan pelanggaran hukum) untuk mengakhirinya;
                    atau
                  </li>
                  <li>
                    Mitra dengan siapa Kami menawarkan Layanan kepada Anda telah
                    mengakhiri hubungannya dengan Kami atau tidak lagi
                    menawarkan Layanan kepada Anda; atau
                  </li>
                  <li>
                    Kami sedang dalam proses peralihan untuk tidak lagi
                    menyediakan Layanan kepada pengguna di negara di mana Anda
                    tinggal atau dari mana Anda menggunakan layanan; atau
                  </li>
                  <li>
                    Penyediaan Layanan kepada Anda, menurut pendapat Kami, tidak
                    dipergunakan lagi oleh Anda secara aktif dalam waktu.
                  </li>
                  <li>
                    Bila Persyaratan ini berakhir, semua hak-hak hukum dan
                    kewajiban yang Anda dan Kami telah peroleh manfaat dari,
                    telah tunduk pada (atau yang telah diperoleh dari waktu ke
                    waktu sementara Syarat telah berlaku) atau yang telah
                    disajikan untuk melanjutkan tanpa batas waktu, akan
                    terpengaruh oleh penghentian ini. Ketentuan-ketentuan yang
                    dinyatakan secara jelas akan terus berlaku untuk hak,
                    kewajiban dan kewajiban tanpa batas dan akan terus berlaku.
                  </li>
                  <li>
                    Konten apapun yang berkaitan dengan akun Anda akan dihapus
                    secara tetap dari sistem Rekmed setelah 30 hari dari
                    pengakhiran hubungan Anda. Hal ini Anda bertanggung jawab
                    untuk mendapatkan dan menjaga Konten sebelum Layanan Anda
                    berakhir.
                  </li>
                </ol>
                <li>Pengecualian Jaminan</li>
                <ol>
                  <li>
                    Anda memahami dan setuju bahwa penggunaan layanan adalah
                    risiko anda sendiri dan bahwa layanan disediakan
                    "sebagaimana adanya" dan "sebagaimana tersedia."
                  </li>
                  <li>
                    Secara khusus, kami, anak perusahaan dan afiliasi kami, dan
                    pemegang lisensi tidak menyatakan atau menjamin kepada anda
                    bahwa:
                  </li>
                  <li>Penggunaan layanan akan memenuhi persyaratan anda,</li>
                  <li>
                    Penggunaan layanan tidak akan pernah terganggu, tepat waktu,
                    aman atau bebas dari kesalahan,
                  </li>
                  <li>
                    Setiap informasi yang anda peroleh sebagai hasil dari
                    penggunaan layanan akan akurat atau terpercaya, dan
                  </li>
                  <li>
                    Kesalahan dalam operasi atau fungsi perangkat lunak yang
                    disediakan sebagai bagian dari layanan akan maupun dapat
                    diperbaiki.
                  </li>
                  <li>
                    Setiap konten yang anda unduh (download) atau peroleh
                    melalui penggunaan layanan dilakukan atas dan risiko anda
                    sendiri dan anda yang akan bertanggung jawab atas kerusakan
                    pada sistem komputer atau perangkat atau hilangnya data dan
                    informasi lain yang terjadi akibat konten yang diunduh
                    tersebut.
                  </li>
                </ol>
                <li>Pembatasan Tanggung Jawab</li>
                <p>
                  Anda memahami dan setuju bahwa kami, direksi, komisaris,
                  karyawan, agen, anak perusahaan dan afiliasi kami, serta
                  pemegang lisensi tidak bertanggung jawab atas:
                </p>
                <ol>
                  <li>
                    Kerusakan dan kerugian yang terjadi baik langsung, tidak
                    langsung, insidentil, akibat khusus atau kerugian yang
                    menimpa anda maupun pihak lain, termasuk, namun tidak
                    terbatas pada, hilangnya keuntungan (baik yang ditanggung
                    langsung maupun tidak langsung), kehilangan reputasi,
                    hilangnya data, biaya pengadaan barang atau layanan
                    pengganti, atau kerugian tidak berwujud lainnya;
                  </li>
                  <li>
                    Kerugian atau kerusakan yang menimpa anda maupun pihak
                    lainnya, termasuk namun tidak terbatas pada kerugian atau
                    kerusakan akibat:
                  </li>
                  <li>
                    Keyakinan anda terhadap kelengkapan, akurasi atau keberadaan
                    pengiklan/advertising, atau akibat hubungan atau transaksi
                    antara anda dan pengiklan atau sponsor yang muncul di
                    layanan;
                  </li>
                  <li>
                    Perubahan apa pun yang mungkin kami buat terhadap layanan,
                    atau penghentian permanen atau sementara dalam penyediaan
                    layanan (atau fitur dalam layanan);
                  </li>
                  <li>
                    Penghapusan, kerusakan, atau kegagalan menyimpan konten
                    apapun dan data komunikasi lainnya yang dikelola atau
                    dikirim oleh atau melalui penggunaan layanan;
                  </li>
                  <li>
                    Kegagalan anda untuk memberikan kami suatu informasi yang
                    akurat;
                  </li>
                  <li>
                    Kegagalan anda untuk menjaga keamanan dan kerahasiaan
                    password atau akun anda.
                  </li>
                </ol>
                <li>Iklan di Layanan Gratis</li>
                <ol>
                  <li>
                    Beberapa Layanan didukung oleh pendapatan iklan dan dapat
                    menampilkan iklan dan promosi. Iklan ini mungkin ditargetkan
                    untuk konten informasi yang disimpan di layanan, pertanyaan
                    yang disampaikan melalui layanan atau informasi lain.
                  </li>
                  <li>
                    Cara, mode, dan tingkat iklan pada Layanan dapat berubah
                    tanpa pemberitahuan khusus kepada Anda.
                  </li>
                  <li>
                    Dalam pertimbangan untuk pemberian akses dan penggunaan
                    Layanan, Anda setuju bahwa Kami dapat menempatkan iklan
                    tersebut pada Layanan.
                  </li>
                </ol>
                <li>Konten Lainnya</li>
                <ol>
                  <li>
                    Layanan dapat mencakup hyperlink ke situs web lain atau
                    konten atau sumber daya. Kami mungkin tidak memiliki kontrol
                    atas situs web atau sumber daya yang disediakan oleh
                    perusahaan atau orang selain Kami.
                  </li>
                  <li>
                    Anda mengakui dan setuju bahwa Kami tidak bertanggung jawab
                    atas ketersediaan situs eksternal atau sumber daya, dan
                    tidak mengesahkan iklan, produk atau materi lain pada atau
                    yang tersedia dari situs web atau sumber daya.
                  </li>
                  <li>
                    Anda mengakui dan setuju bahwa Kami tidak bertanggung jawab
                    atas kerugian atau kerusakan yang mungkin menimpa Anda
                    sebagai akibat dari ketersediaan situs eksternal atau sumber
                    daya, atau sebagai akibat dari ketergantungan Anda pada
                    kelengkapan, keakuratan atau keberadaan iklan, produk atau
                    materi lain pada atau yang tersedia dari, situs web atau
                    sumber daya.
                  </li>
                </ol>
                <li>Perubahan Persyaratan</li>
                <ol>
                  <li>
                    Kami mungkin akan membuat perubahan Ketentuan Umum atau
                    Persyaratan Tambahan dari waktu ke waktu. Ketika perubahan
                    ini dilakukan, Kami akan membuat salinan baru Ketentuan Umum
                    dan Persyaratan Tambahan baru akan dibuat tersedia untuk
                    Anda baik secara langsung, dari dalam, atau melalui Layanan
                    yang tersedia untuk Anda.
                  </li>
                  <li>
                    Anda memahami dan menyetujui bahwa jika Anda menggunakan
                    Layanan setelah tanggal Ketentuan Umum atau Persyaratan
                    Tambahan diubah, Kami akan menganggap Anda menyetujui
                    Ketentuan Umum atau Persyaratan Tambahan yang telah
                    diperbaharui.
                  </li>
                </ol>
                <li>Persyaratan Hukum Umum</li>
                <ol>
                  <li>
                    Terkadang ketika Anda menggunakan Layanan, Anda mungkin
                    (sebagai akibat dari, atau melalui penggunaan Layanan)
                    menggunakan layanan atau mengunduh atau mengundah konten,
                    piranti lunak, atau membeli barang yang disediakan oleh
                    orang atau perusahaan lain. Penggunaan Anda atas layanan
                    lain, perangkat lunak atau barang untuk keperluan tersebut
                    merupakan tanggung jawab Anda.
                  </li>
                  <li>
                    Anda setuju bahwa Kami dapat menyediakan Anda dengan
                    pemberitahuan, termasuk tentang perubahan Persyaratan,
                    melalui email, surat biasa, atau posting pada Layanan.
                  </li>
                  <li>
                    Anda setuju bahwa jika Kami tidak atau belum menjalankan
                    atau menerapkan hak hukum atau upaya hukum yang dimiliki
                    berdasarkan Persyaratan, hal tersebut tidak akan dianggap
                    sebagai suatu pengesampingan formal dari hak Kami untuk
                    meminta, menjalankan dan menerapkan hak tersebut dan bahwa
                    hak atau upaya hukum Kami masih dan akan tetap tersedia.
                  </li>
                  <li>
                    Jika persidangan, yang memiliki yurisdiksi untuk memutuskan
                    masalah ini, menetapkan bahwa ketentuan apapun dari
                    Persyaratan ini tidak berlaku, maka ketentuan tersebut akan
                    dihapus dari Persyaratan tanpa mempengaruhi sisa ketentuan
                    dalam Persyaratan. Ketentuan sisa Persyaratan akan terus
                    menjadi sah dan berlaku dan mengikat Anda.
                  </li>
                  <li>
                    Anda mengakui dan setuju bahwa setiap anggota kelompok Kami
                    akan menjadi penerima manfaat pihak ketiga dengan Syarat dan
                    bahwa perusahaan lain tersebut berhak untuk secara langsung
                    memberlakukan dan bergantung pada, ketentuan Persyaratan
                    yang memberikan manfaat (atau hak yang mendukung) mereka.
                  </li>
                  <li>
                    Persyaratan ini dibuat dan penggunaan Layanan diatur oleh
                    hukum Negara Indonesia. Anda dan Kami setuju untuk tunduk
                    pada yurisdiksi eksklusif pengadilan yang berlokasi di
                    Indonesia untuk menyelesaikan setiap masalah hukum yang
                    timbul dari Persyaratan.
                  </li>
                </ol>
              </ol>
              <BackButton
                className="m-auto mt-4"
                onClick={() => setOpenTOS(false)}
              >
                Kembali
              </BackButton>
            </CardContent>
          </Card>
          {/* <span className="m-5"></span> */}
        </>
      )}
    </>
  );
};

export default Terms;
