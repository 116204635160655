import styled from "styled-components";
import { Link } from "react-router-dom";
import { BasicActionBtn } from "components/base";
import { COLORS } from "assets/theme";

export const ActionButton = styled(BasicActionBtn)`
  margin-top: 16px;
  padding: 12px 16px;
  white-space: nowrap;
  width: max-content;
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & div {
    margin: 0 !important;
  }

  ${ActionButton} {
    align-self: center;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const ActionLink = styled(Link)`
  color: ${COLORS.green_btn};
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
`;
