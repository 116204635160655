import React, { useState } from "react";
import styled from "styled-components";
import RippleWrapper from "components/Button/RippleWrapper";
import { COLORS } from "assets/theme";
import { MdChevronLeft } from "react-icons/md";
import { CustomDropdownItemProps } from "types/components";

type DropdownDirection = "left" | "center" | "right";

type DropdownProps = {
  name: string;
  items: CustomDropdownItemProps[];
  direction?: DropdownDirection;
  /**
   * @in pixels
   */
  width?: number;
  backgroundColor?: string;
  contentColor?: string;

  className?: string;

  children?: (
    isOpen: boolean,
    toggle: (isOpen: boolean) => void,
  ) => React.ReactNode;
};

const DropdownContainer = styled.div`
  position: relative;
`;

type C = {
  /**
   * @ 1 or 0 in {String}}
   */
  isopen: "1" | "0";
};
const StyledDropdownChevron = styled(MdChevronLeft)<C>`
  transform: rotate(${({ isopen }) => (isopen === "1" ? -270 : -90)}deg);
  transition: transform 0.2s ease-in-out;
  margin-top: 2px;
`;

type O = Pick<DropdownProps, "backgroundColor" | "contentColor" | "width">;
const DropdownButton = styled.div<O>`
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : COLORS.asphalt};
  color: ${({ contentColor }) => (contentColor ? contentColor : COLORS.white)};
  cursor: pointer;
  padding: 8px;
  min-width: ${({ width }) => (width ? width : 64)}px;
  ${StyledDropdownChevron} {
    color: ${({ contentColor }) =>
      contentColor ? contentColor : COLORS.white};
  }
`;

type D = {
  width: number;
  direction?: DropdownDirection;
};
const DropdownItemWrapper = styled.li<D>`
  padding: 8px 12px;
  overflow: hidden;
  cursor: pointer;
  .no-action {
    /* pointer-events: none; */
    cursor: not-allowed;
  }
  :hover {
    background: ${COLORS.blue_1};
    color: ${COLORS.white};
  }
  ${({ direction }) => {
    return direction
      ? direction === "center"
        ? {
            fontSize: 64 / 8,
          }
        : {
            whiteSpace: "pre",
          }
      : {
          whiteSpace: "pre",
        };
  }};

  /* &:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  &:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  } */
`;

type DE = D & {
  isOpen: boolean;
};
const DropdownContent = styled.ul<DE>`
  position: absolute;
  ${(props) =>
    props.direction
      ? props.direction === "right"
        ? {
            right: 0,
          }
        : props.direction === "center"
        ? {
            left: 0,
            width: "100%",
          }
        : {
            left: 0,
          }
      : {
          left: 0,
        }}
  /* width: 100%; */
  /* padding: 8px; */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  list-style: none;
  background: ${COLORS.white};
  padding: 0;
  z-index: 1000;
  transform: translateY(${({ isOpen }) => (isOpen ? "-8px" : "16px")});
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
`;

const CustomDropdown: React.FC<DropdownProps> = ({
  name,
  items,
  direction,
  width = 64,
  backgroundColor,
  contentColor,
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleClickDropdownItem = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    toggleDropdown();
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <DropdownContainer className={className} ref={dropdownRef}>
      <RippleWrapper>
        {children ? (
          children(isOpen, (v) => setIsOpen(v))
        ) : (
          <>
            <DropdownButton
              width={width}
              backgroundColor={backgroundColor}
              contentColor={contentColor}
              onClick={toggleDropdown}
            >
              {name}
              <StyledDropdownChevron isopen={isOpen ? "1" : "0"} size={16} />
            </DropdownButton>
          </>
        )}
      </RippleWrapper>
      <DropdownContent
        className="content"
        width={width}
        isOpen={isOpen}
        direction={direction}
      >
        {items.map((item, index) => (
          <DropdownItemWrapper
            className={["item", item.hasNoAction ? "no-action" : ""]
              .filter((v) => !!v)
              .join(" ")}
            key={`${item.name}-${index}`}
            aria-disabled={item.hasNoAction}
            onClick={(e) => {
              if (item.hasNoAction) return;
              handleClickDropdownItem(e);
              item.onClick && item.onClick(e);
            }}
            direction={direction}
            width={width}
          >
            {item.name}
          </DropdownItemWrapper>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default CustomDropdown;
