import React from "react";

const useToggle = (initValue: boolean) => {
  const [value, setValue] = React.useState(initValue);
  return {
    value,
    setValue,
    toggle: (v?: boolean) => {
      if (typeof v === "boolean") {
        setValue(v);
      } else setValue((v) => !v);
    },
  };
};

export default useToggle;
