// import React from 'react'
import { getPractitionerName } from "assets/usefulFunctions/practitioner";
import CookieMonster from "config/CookieMonster";
import { Role } from "types/roles";
import { usePractitionerList } from "hooks/practitioner/usePractitionerList";

const useMyProperName = () => {
  const { roles: r, username: u } = CookieMonster.loadCookies([
    "roles",
    "username",
  ]);
  const roles = r as Role[] | undefined;
  const username = u as string | undefined;

  const { list: l, isLoading } = usePractitionerList();

  if (roles?.includes("practitioner")) {
    const dr = l?.find((p) => {
      return p.username === username;
    });
    const str_no = dr?.userProfile?.str_no;
    const name = dr?.userProfile
      ? getPractitionerName(dr?.userProfile)
      : username ?? "-";
    return {
      name,
      str_no,
      isLoading,
    };
  }

  return { name: username ?? "-", str_no: undefined, isLoading };
};

export default useMyProperName;
