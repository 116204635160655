/**
 * @param amount value to be formatted
 * @returns string representation of the rupiah currency
 */

const useFormatRupiah = () => {
  const formatRupiah = (amount: number) => {
    const formatter = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  };

  return { formatRupiah };
};

export default useFormatRupiah;
