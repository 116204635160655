import React from "react";
import { useNavigate } from "react-router-dom";

import { combinedRoutes } from "config/nav";
import { QueueCategory } from "types/queue";
import { RecipeStatusEnum } from "types/api/transactions";
import { AnEncounterWithId, QueueStatusEnum } from "types/api/encounter";

import QueueItem from "Pages/Queue/components/QueueItem/QueueItem";

type Props = {
  queueCategory: QueueCategory;
  patientList: AnEncounterWithId[];
  setSelectedPatient: React.Dispatch<
    React.SetStateAction<AnEncounterWithId | null>
  >;
  toggleDeleteModal: (v?: boolean | undefined) => void;
};

const routes = combinedRoutes;

const PatientQueue = ({
  queueCategory,
  patientList,
  setSelectedPatient,
  toggleDeleteModal,
}: Props) => {
  const navigate = useNavigate();

  const getQueueRoute = () => {
    let route = "";
    switch (queueCategory) {
      case "pasien":
        route = routes.pemeriksaan;
        return route;
      case "obat":
        route = routes.antrian_farmasi;
        return route;
      case "kasir":
        route = routes.antrian_kasir;
        return route;
      default:
        return null;
    }
  };

  const getActionTextAndTitle = (
    val: AnEncounterWithId,
    initialInspectionDone: boolean,
  ) => {
    let actionText = "";
    let actionTitle = "";
    let appendSearchParams = "";
    let replaceRoute = "";
    if (!!val.medicalRecord) {
      if (queueCategory === "pasien") {
        actionText = "Lihat";
        actionTitle = "Lihat Rekam Medis";
        appendSearchParams = "tab=SOAP";
      } else {
        switch (val.medicalRecord.medrecTrx.recipe_status) {
          case RecipeStatusEnum.DONE:
            if (queueCategory === "obat") {
              actionText = "Lihat";
              actionTitle = "Lihat Detail Resep Obat";
            } else {
              actionText = "Lihat";
              actionTitle = "Lihat Detail Invoice";
            }
            break;
          case RecipeStatusEnum.WIP:
            actionText = "Serahkan Obat";
            actionTitle = "Ke Penyerahan Obat";
            replaceRoute = routes.antrian_farmasi;
            break;
          case RecipeStatusEnum.QUEUE:
            if (queueCategory === "obat") {
              actionText = "Edit Resep";
              actionTitle = "Edit Resep Obat";
            } else {
              actionText = "Lakukan Pembayaran";
              actionTitle = "Ke Pembayaran di Kasir";
            }
            break;
          case null:
            actionText = "Proses Resep";
            actionTitle = "Proses Resep Obat";
            break;
          default:
            actionText = "Lihat";
            actionTitle = "Lihat Data";
            break;
        }
      }
    } else {
      actionText = initialInspectionDone ? "Periksa SOAP" : "Periksa";
      actionTitle = `Periksa ${val.patient.full_name}`;
      appendSearchParams = initialInspectionDone
        ? "tab=SOAP"
        : "tab=pemeriksaan";
    }
    return { actionText, actionTitle, appendSearchParams, replaceRoute };
  };

  const renderPatientList = () => {
    return patientList.map((patient) => {
      const initialInspectionDone = Boolean(
        patient.initialInspection || patient.antenatal,
      );
      const { actionText, actionTitle, appendSearchParams, replaceRoute } =
        getActionTextAndTitle(patient, initialInspectionDone);
      let route = getQueueRoute();

      if (replaceRoute !== "") {
        route = replaceRoute;
      }

      return (
        <QueueItem
          key={patient.id}
          onClick={() => {
            navigate({
              pathname: `${route}/${patient.id}`,
              search: `${appendSearchParams}`,
            });
          }}
          showCallBtn={true}
          actionText={actionText}
          actionTitle={actionTitle}
          id={patient.id}
          queue={patient}
          actions={
            patient.status === QueueStatusEnum.FINISH
              ? undefined
              : [
                  {
                    name: "Hapus Antrian",
                    onClick: () => {
                      setSelectedPatient(patient);
                      toggleDeleteModal(true);
                    },
                  },
                ]
          }
        />
      );
    });
  };

  return <>{renderPatientList()}</>;
};

export default PatientQueue;
