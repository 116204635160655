import { COLORS } from "assets/theme";
import styled from "styled-components";
import CustomDropdown from "components/CustomDropdown";

export const Container = styled.div`
  display: flex;
  padding: 8px;

  border-top: 1px solid #eef1f8;
  border-bottom: 1px solid #eef1f8;
  background: transparent;
  transition: all 0.2s ease-in;
  :hover {
    background: ${COLORS.mint_green};
  }
`;

export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

export const InfoTextContainer = styled.span`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  align-items: center;
  justify-items: center;
  gap: 0 10px;
`;

export const Chip = styled.span`
  background: ${COLORS.teal};
  font-weight: 600;
  align-self: flex-start;
  color: white;
  padding: 3px 12px;
  border-radius: 15px;
  font-size: 0.8rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const ActionButton = styled.button`
  background: ${COLORS.green_linear_gradient};
  box-sizing: border-box;
  border-radius: 2px;
  padding: 7px 10px;
  border: 1px solid ${COLORS.green};
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 8px;
  &.inverse {
    background: transparent;
    color: ${COLORS.green};
  }
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const ActionDropdown = styled(CustomDropdown)`
  .three-dots-btn {
    display: flex;
    align-items: center;
    padding: 0 5px;
    background: transparent;
    svg {
      color: ${COLORS.text_gray};
      width: 20px;
      height: 20px;
    }
  }

  ul {
    width: fit-content;
  }

  li {
    white-space: nowrap;
    font-size: 0.75rem;
  }
  .no-action {
    color: ${COLORS.text_gray};

    &:hover {
      background: #efefef;
    }
  }

  & .content {
    border-radius: 6px;
  }
  & .item {
    padding: 0;
    :first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    :last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    > * {
      display: block;
      padding: 8px 12px;
      width: 100%;
    }

    :hover {
      color: black;
      background: ${COLORS.mint_green};
    }
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
`;
