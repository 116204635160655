import styled from "styled-components";
import { COLORS } from "assets/theme";

export const CustomPaginationWrapper = styled.div`
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
  font-size: 14px;
`;

export const StyledPaginationSelect = styled.select`
  border: 0.5px solid ${COLORS.neutral_chromium};
  background: transparent;
  padding: 0 10px;
  margin: 0 10px;
  width: 100px;
  border-radius: 6px;
  height: 35px;
  color: ${COLORS.black_1};
  option {
    color: ${COLORS.black_1};
  }
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px top 55%;
  background-size: 1em;

  &:focus {
    outline: 2px solid ${COLORS.green};
    outline-offset: -2px;
  }
`;

export const StyledPageDescription = styled.span`
  color: ${COLORS.text_gray};
`;

export const StyledButtonNextAndPreviousWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: fit-content;
`;

export const StyledPaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
  border: 0.5px solid ${COLORS.neutral_chromium};
  background: transparent;
  color: ${COLORS.black_1};
  :disabled {
    background: ${COLORS.lightGray_2};
    border: 0.5px solid ${COLORS.neutral_chromium};
  }

  &:hover:not(:disabled) {
    background: ${COLORS.mint_green};
  }

  .hide {
    display: none;
  }
`;

export const StyledPaginationInput = styled.input`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 2px solid ${COLORS.green};
  text-align: center;
  font-weight: 600;
  background: transparent;
  color: ${COLORS.green};
  &:disabled {
    background: ${COLORS.lightGray_2};
  }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &:focus {
    outline: 2px solid ${COLORS.green};
    border: none;
    outline-offset: -2px;
  }
`;
