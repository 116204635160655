import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { OrderPackage } from "types/api/payment";

export const productStoreApi = createApi({
  reducerPath: "productStoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/product`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => {
    return {
      getProducts: builder.query<OrderPackage[], void>({
        query: () => {
          return {
            url: "",
            method: "GET",
          };
        },
        providesTags: ["Product"],
      }),
    };
  },
});

export const {
  useGetProductsQuery,
  util: { resetApiState: resetProductStoreApi },
} = productStoreApi;
