import { UseFormReturn } from "react-hook-form";

import { APatientBase } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";
import { QueueTypeEnum } from "types/queue";

import { BaseRadioGroupInput } from "components/base/Input";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  bpjsConfigured: boolean;
};

const JenisAntrianChanger = ({
  formData,
  value,
  onChange,
  bpjsConfigured,
}: Props) => {
  const jenisAntrianOptions = [
    { label: "Umum", value: QueueTypeEnum.UMUM },
    ...(bpjsConfigured ? [{ label: "BPJS", value: QueueTypeEnum.BPJS }] : []),
  ];

  return (
    <BaseRadioGroupInput
      id="jenis-antrian-radio"
      legend="Jenis Antrian"
      required
      disabled={!bpjsConfigured}
      options={jenisAntrianOptions}
      value={value}
      onChange={onChange}
      orientation="row"
      spacingOptions="24px"
    />
  );
};

export default JenisAntrianChanger;
