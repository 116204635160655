import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { UseFormReturn } from "react-hook-form";

import { APatientBase, APatientMinWithId } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";
import { QueuePemeriksaanTypeEnum } from "types/queue";
import { useGetPatientByNameAndNikQuery } from "store/patientStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import { BaseSelectInput } from "components/base/Input";
import { format, parseISO } from "date-fns";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
  selectedPatient: APatientMinWithId | undefined;
  setSelectedPatient: React.Dispatch<
    React.SetStateAction<APatientMinWithId | undefined>
  >;
};

const defaultPatientData: any = {
  email: "",
  phone_number: "",
  full_name: "",
  birth_date: "",
  birth_place: "",
  sex: "",
  address: "",
  additional_address: "",
  rt: "",
  rw: "",
  postal_code: "",
  nik: "",
  bpjs_no: "",
  is_opt_in: false,
  encounter: "",
  type: "WNI",
  mothers_nik: "",
  passport_no: "",
  reference_no: "",
  reference_name: "",
  address_match_with_ktp: false,
};

const SearchPatientInput = ({
  formData,
  selectedPatient,
  setSelectedPatient,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  const validToSearch = searchValue.length > 0;

  const {
    currentData: searchedPatientList,
    isLoading: loadingSearchedPatient,
    isFetching: fetchingSearchedPatient,
  } = useGetPatientByNameAndNikQuery(
    {
      all: debouncedSearch,
    },
    {
      skip: !validToSearch,
    },
  );

  const isLoadingSearchedPatient =
    loadingSearchedPatient || fetchingSearchedPatient;

  const options = searchedPatientList?.data.map((patient) => ({
    value: patient?.id,
    label: `${patient?.full_name} ${
      patient?.nik ? ` - (${patient?.nik})` : ""
    }`,
  }));

  const tipePemeriksaan = formData.getValues("pemeriksaan_type");
  const tipeKunjunganKehamilan = formData.getValues("kunjungan_type");

  useEffect(() => {
    if (selectedPatient) {
      let formattedBirthDate;
      if (selectedPatient.birth_date) {
        const parsedDate = parseISO(selectedPatient.birth_date);
        formattedBirthDate = format(parsedDate, "yyyy-MM-dd");
      }

      const { is_opt_in, ...nonNullPreviousPatientData } = Object.fromEntries(
        Object.entries(selectedPatient)?.filter(
          ([key, value]) => value !== null,
        ),
      );

      formData.reset({
        ...nonNullPreviousPatientData,
        sex:
          tipePemeriksaan === QueuePemeriksaanTypeEnum.KEHAMILAN
            ? "female"
            : selectedPatient.sex,
        birth_date: formattedBirthDate,
        pemeriksaan_type: tipePemeriksaan,
        kunjungan_type: tipeKunjunganKehamilan,
      });
    }
  }, [selectedPatient, formData, tipePemeriksaan, tipeKunjunganKehamilan]);

  return (
    <BaseSelectInput<any>
      className="w-full"
      label="Cari Pasien Lama"
      placeholder="Cari berdasarkan Nama atau NIK pasien"
      name="search"
      options={options}
      isClearable
      isSearchable
      onInputChange={(value) => setSearchValue(value)}
      onChange={(selectedOption) => {
        if (selectedOption) {
          const patient = searchedPatientList?.data.find(
            (patient) => patient.id === selectedOption.value,
          );
          setSelectedPatient(patient as APatientMinWithId);
        } else {
          setSelectedPatient(undefined);
          formData.reset(defaultPatientData);
        }
      }}
      isLoading={isLoadingSearchedPatient}
      loadingMessage={() => "Mencari pasien..."}
      noOptionsMessage={
        validToSearch
          ? () => "Tidak ada pasien dengan nama atau NIK tersebut"
          : () => "Masukkan nama atau NIK pasien"
      }
      menuPlacement="auto"
      icon={<BiSearch />}
    />
  );
};

export default SearchPatientInput;
