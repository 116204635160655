import { FormInput } from "Pages/Management/components";
import WilayahJSON from "./wilayah.json";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "hooks/utils/useDebounce";

type TWilayah = {
  Level: number;
  Code: number;
  Name: string;
  "Kode Lama": number;
  "Kode BPS": number;
  Parent: string;
  State: string;
};

type TWilayahSanitized = {
  Level4Name: string;
  Level4Code: string;
  Level3Name: string;
  Level3Code: string;
  Level2Name: string;
  Level2Code: string;
  Level1Name: string;
  Level1Code: string;
};

const ResearchAddress = () => {
  const [keyword, setKeyword] = useState("");
  const keywordDebounce = useDebounce(keyword, 500);
  const [result, setResult] = useState<TWilayahSanitized[]>([]);

  const Wilayah = useMemo(() => {
    const data = JSON.parse(JSON.stringify(WilayahJSON));
    return data as TWilayah[];
  }, []);

  useEffect(() => {
    const handleSearch = (keyword: string) => {
      const arrayKeyword = keyword.trim().split(" ");

      let result = [] as TWilayah[];

      if (arrayKeyword.length === 1) {
        // Search by Level 4
        const tempResult = Wilayah.filter(
          (item) =>
            item.Level === 4 && item.Name.toLowerCase().includes(keyword),
        );

        if (tempResult.length > 0) {
          result = tempResult;
        } else {
          // If no result, search by Level 3
          result = Wilayah.filter(
            (item) =>
              item.Level === 4 && item.Parent.toLowerCase().includes(keyword),
          );
        }
      }

      if (arrayKeyword.length >= 2) {
        console.log(2);
        // Search by Level 4 + Level 3
        const tempResult = Wilayah.filter(
          (item) =>
            item.Level === 4 &&
            item.Name.toLowerCase().includes(arrayKeyword[0]) &&
            item.Parent.toLowerCase().includes(arrayKeyword[1]),
        );

        if (tempResult.length > 0) {
          console.log(3);
          result = tempResult;
        } else {
          console.log(4);
          // If no result, search by Level 3 + 4
          result = Wilayah.filter(
            (item) =>
              item.Level === 4 &&
              item.Name.toLowerCase().includes(arrayKeyword[1]) &&
              item.Parent.toLowerCase().includes(arrayKeyword[0]),
          );
        }
      }

      const sanitizedResult = result.map((item) => {
        const Level4Code = item.Code.toString();
        const Level4Name = item.Name;

        const Level3Code = item.Code.toString().slice(0, 6);
        const Level3Name = Wilayah.find(
          (item) => item.Code.toString() === Level3Code,
        )?.Name;

        const Level2Code = item.Code.toString().slice(0, 4);
        const Level2Name = Wilayah.find(
          (item) => item.Code.toString() === Level2Code,
        )?.Name;

        const Level1Code = item.Code.toString().slice(0, 2);
        const Level1Name = Wilayah.find(
          (item) => item.Code.toString() === Level1Code,
        )?.Name;

        return {
          Level4Name: Level4Name,
          Level4Code: Level4Code,
          Level3Name: Level3Name,
          Level3Code: Level3Code,
          Level2Name: Level2Name,
          Level2Code: Level2Code,
          Level1Name: Level1Name,
          Level1Code: Level1Code,
        };
      }) as TWilayahSanitized[];

      setResult(sanitizedResult);
    };

    if (keywordDebounce !== "") {
      handleSearch(keywordDebounce);
    }
  }, [keywordDebounce, Wilayah]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  return (
    <div>
      <FormInput
        title="Masukan Nama Wilayah"
        placeholder="Masukan Nama Wilayah"
        value={keyword}
        onChange={handleChange}
      />

      <p>Keyword : {keyword}</p>
      {result.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              border: "1px solid black",
            }}
          >
            <p>Level 4 Name : {item.Level4Name}</p>
            <p>Level 4 Code : {item.Level4Code}</p>
            <p>Level 3 Name : {item.Level3Name}</p>
            <p>Level 3 Code : {item.Level3Code}</p>
            <p>Level 2 Name : {item.Level2Name}</p>
            <p>Level 2 Code : {item.Level2Code}</p>
            <p>Level 1 Name : {item.Level1Name}</p>
            <p>Level 1 Code : {item.Level1Code}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ResearchAddress;
