import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { useGetTransactionByPolyReportQuery } from "store/reportStoreAPI";
import { useGetPolyclinicQuery } from "store/clinicStoreAPI";
import useDebounce from "hooks/utils/useDebounce";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import RangedDatePicker from "components/RangedDatepicker/RangedDatepicker";
import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableTransaksiByPoli/TableTemplate";

const TableTransaksiByPoli = () => {
  const today = new Date().toISOString();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [takePolyclinic, setTakePolyclinic] = useState<number>(50);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [polyId, setPolyId] = useState<string>("");
  const { formatRupiah } = useFormatRupiah();

  const { data: polyclinicData } = useGetPolyclinicQuery({
    page: 1,
    take: takePolyclinic,
  });

  const polyOpt = [
    ...(polyclinicData?.data?.map((v) => {
      return {
        value: v.id.toString(),
        label: `${v.polyclinic_name}`,
      };
    }) || []),
  ];

  const { data: reportData } = useGetTransactionByPolyReportQuery(
    {
      page: page,
      take: take,
      query: debouncedSearchValue || undefined,
      startDate: startDate?.toISOString() || today,
      endDate: endDate?.toISOString() || today,
      polyclinicId: polyId || "1",
    },
    {
      skip: !startDate || !endDate || !polyId,
    },
  );

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
        <PureInputSelect
          label=""
          placeholder="Pilih Poli"
          options={polyOpt}
          value={polyOpt.find((option) => option.value === polyId)}
          onChange={(e) => {
            if (e) {
              const newValue = e.value as string;
              setPolyId(newValue);
            }
          }}
          onMenuScrollToBottom={() => {
            if (polyOpt && polyOpt.length >= takePolyclinic) {
              setTakePolyclinic(takePolyclinic + 50);
            }
          }}
        />
        <RangedDatePicker label="" fullWidth onDateChange={handleDateChange} />
      </div>
      {startDate && endDate && polyId && (
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Total Transaksi{" "}
                <span>{formatRupiah(data?.total_earning || 0)}</span>
              </RecapItem>
            </RecapContainer>
            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              {_.isEmpty(data?.entities) ? null : (
                <DownloadReport
                  startDate={startDate}
                  endDate={endDate}
                  polyclinicId={polyId}
                />
              )}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      )}
    </>
  );
};

export default TableTransaksiByPoli;
