import { COLORS, small, xSmall } from "assets/theme";
import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px 30px;
`;

export const CardContent = styled.div`
  background-color: ${COLORS.white};
  border-radius: 6px;
  padding: 30px 65px 30px 40px;

  ${small} {
    padding: 30px 45px 30px 20px;
  }

  ${xSmall} {
    padding: 30px 35px 30px 10px;
  }

  .main-list > li {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .main-list > ol {
    margin-top: 0;
    margin-bottom: 15px;
    padding-left: 15px;
    font-size: 14px;
  }

  .main-list > ol > ol {
    margin-top: 0;
    padding-left: 15px;
  }

  .main-list p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export const BackButton = styled.span`
  background: ${COLORS.green_linear_gradient};
  font-weight: 600;
  color: ${COLORS.white};
  text-decoration: none;
  width: 130px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;
