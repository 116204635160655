import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { FormInput, ActionWrapper } from "Pages/Settings/components";
import { BasicActionBtn } from "components/base";
import CustomToast from "components/CustomToast";

import { AClinicBase, AClinicWithLogo } from "types/api/clinic";

import { COLORS } from "assets/theme";

import {
  useCreateClinicLogoMutation,
  useUpdateClinicMutation,
} from "store/clinicStoreAPI";

import useClinicData from "hooks/clinic/useClinicData";

import EditClinicLogo from "Pages/Settings/components/EditClinicLogo";
import { ClinicDataWrapper } from "Pages/Settings/components";

type Props = {
  activeState: () => void;
  clinicData: AClinicWithLogo;
};

const SettingClinicDataForm = ({ activeState, clinicData }: Props) => {
  const [file, setFile] = useState<File>();
  const [createLogo, { isLoading: loadingLogo, isError: errorLogo }] =
    useCreateClinicLogoMutation();
  const [updateClinic, { isLoading: loadingClinic, isError: errorCLinic }] =
    useUpdateClinicMutation();
  const { triggerGetLogo } = useClinicData();
  const { control, handleSubmit } = useForm<AClinicBase>({
    defaultValues: {
      clinic_name: clinicData?.clinic_name,
      email: clinicData?.email,
      phone_number: clinicData?.phone_number,
      address: clinicData?.address,
    },
  });

  const isLoading = loadingClinic || loadingLogo;

  const checkPreviousData = (data: Partial<AClinicBase>) => {
    const previousData = {
      clinic_name: clinicData?.clinic_name,
      email: clinicData?.email,
      phone_number: clinicData?.phone_number,
      address: clinicData?.address,
    };

    return (
      data.clinic_name === previousData.clinic_name &&
      data.email === previousData.email &&
      data.phone_number === previousData.phone_number &&
      data.address === previousData.address
    );
  };

  const onSubmit = async (data: Partial<AClinicBase>) => {
    // POST logo
    if (file) {
      const arrLogo = new FormData();
      arrLogo.append("files", file);
      await createLogo(arrLogo).unwrap();
      if (!errorLogo) {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Berhasil!"
            description="Berhasil Unggah Logo Klinik"
          />
        ));
        triggerGetLogo();
      } else {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal!"
            description="Gagal Unggah Logo Klinik"
          />
        ));
      }
    }

    // POST clinic data
    if (data) {
      const sameWithPrev = checkPreviousData(data);
      if (!sameWithPrev) {
        await updateClinic(data).unwrap();
        if (!errorCLinic) {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil!"
              description="Berhasil Mengubah Data Klinik"
            />
          ));
        } else {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal!"
              description="Gagal Mengubah Data Klinik"
            />
          ));
        }
      }

      activeState();
    }
  };

  return (
    <ClinicDataWrapper>
      <EditClinicLogo
        currentImage={clinicData?.logo}
        file={file}
        setFile={setFile}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="clinic_name"
          control={control}
          defaultValue={clinicData?.clinic_name}
          render={({ field }) => (
            <FormInput
              placeholder="Masukan Nama Klinik"
              label="Nama Klinik"
              value={field.value}
              onChange={field.onChange}
              maxLength={100}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue={clinicData?.email}
          render={({ field }) => (
            <FormInput
              label="Email"
              placeholder="Masukan Email Klinik"
              value={field.value}
              onChange={field.onChange}
              type="email"
              required
            />
          )}
        />
        <Controller
          name="phone_number"
          control={control}
          defaultValue={clinicData?.phone_number}
          render={({ field }) => (
            <FormInput
              value={field.value}
              onChange={field.onChange}
              label="No. Telepon"
              placeholder="Masukan Nomor Telepon Klinik"
              maxLength={20}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          defaultValue={clinicData?.address}
          render={({ field }) => (
            <FormInput
              value={field.value}
              onChange={field.onChange}
              label="Alamat"
              placeholder="Masukan Alamat Klinik"
              maxLength={150}
            />
          )}
        />
        <ActionWrapper>
          <BasicActionBtn
            type="button"
            onClick={activeState}
            style={{
              background: COLORS.neutral_gray_gull,
              color: COLORS.black_1,
            }}
          >
            Batal
          </BasicActionBtn>

          <BasicActionBtn type="submit" disabled={isLoading}>
            {isLoading ? "Menyimpan..." : "Simpan"}
          </BasicActionBtn>
        </ActionWrapper>
      </form>
    </ClinicDataWrapper>
  );
};

export default SettingClinicDataForm;
