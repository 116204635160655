import { CSSProperties, Fragment, MouseEvent, ReactNode } from "react";
import {
  CellProps,
  Column,
  Renderer,
  Row,
  TableState,
  useTable,
  useSortBy,
  useExpanded,
  useRowSelect,
} from "react-table";
import {
  CardTable,
  Table,
  TableContainer,
  StyledTableTh,
  StyledTableTr,
  StyledTd,
} from "components/BasicTable/BasicTable.style";
// import { COLORS } from "../../assets/theme";
import { IdType } from "react-table";
import { MdUnfoldMore } from "react-icons/md";
import { CustomPaginationProps } from "types/table/pagination";
import DefaultPaginationTable from "components/BasicTable/DefaultPaginationTable/DefaultPaginationTable";

export interface BasicTableProps<D extends object> {
  data: readonly D[];
  columns: Column<D>[];
  title?: string | ReactNode;
  getThPropsStyle?: (
    args: Column<D>,
    headersLength?: number,
    index?: number,
  ) => CSSProperties;
  customPagination?: React.ReactElement<CustomPaginationProps>;
  getTrPropsStyle?: (args: Row<D>) => CSSProperties;
  headerStyle?: CSSProperties;
  renderExpansion?: (row: Row<D>) => JSX.Element | undefined;
  hideCols?: IdType<D>[];
  isHideNumbering?: boolean;
  showPagination?: boolean;
  /**
   * @description "in pixels" font size & icon svg sort header
   */
  fontSize?: number;
  onClickRow?: (e: React.MouseEvent, row: Row<D>) => void;

  className?: string;

  noDataText?: string;

  isLoading?: boolean;
}

const BasicTable = <D extends object>(props: BasicTableProps<D>) => {
  const {
    data,
    columns,
    getThPropsStyle,
    getTrPropsStyle,
    headerStyle,
    renderExpansion,
    hideCols,
    isHideNumbering,
    fontSize,
    onClickRow,
    showPagination: isPagination = true,
    customPagination,
    className,
    noDataText = "No Data",
    isLoading,
  } = props;
  const generateInitialState = ({
    expandable,

    numbering,
  }: {
    expandable: boolean;
    numbering: boolean;
    hideCols?: IdType<D>[];
  }): Partial<TableState<D>> => {
    let hiddenColumns: IdType<D>[] = [];
    if (!expandable) hiddenColumns.push("expander");
    if (!numbering) hiddenColumns.push("no");
    if (hideCols) hiddenColumns.push(...hideCols);
    return {
      hiddenColumns,
    };
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page: p,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // selectedFlatRows,
  } = useTable(
    {
      data,
      columns,
      initialState: generateInitialState({
        expandable: !!renderExpansion,
        numbering: !isHideNumbering,
        hideCols,
      }),
      manualPagination: !!customPagination,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const CellNoRenderer: Renderer<CellProps<D, any>> = ({ row }) => {
          return <Fragment>{row.index + 1}</Fragment>;
        };
        return [
          {
            id: "no",
            Header: "No",
            Cell: CellNoRenderer,
          },
          ...columns,
        ];
      });
    },
  );

  const page = isPagination ? p : rows;
  return (
    <CardTable className={className}>
      {isLoading && (
        <div style={{ textAlign: "center", padding: "20px" }}>
          Fetching data...
        </div>
      )}
      {!isLoading && (
        <TableContainer className="table-container">
          <Table
            {...getTableProps()}
            style={{ tableLayout: "fixed", width: "100%" }}
            fontSize={fontSize}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <StyledTableTr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, idx) => {
                    const thPropsStyle = getThPropsStyle
                      ? getThPropsStyle(column, headerGroup.headers.length, idx)
                      : headerStyle;
                    let actionHeaderThStyle: CSSProperties =
                      column.id === "action"
                        ? {
                            textAlign: "center",
                          }
                        : {};
                    switch (column.id) {
                      case "action":
                        actionHeaderThStyle = {
                          textAlign: "center",
                        };
                        break;
                      case "no":
                        actionHeaderThStyle = {
                          maxWidth: "40px",
                        };
                        break;
                      default:
                        actionHeaderThStyle = {};
                        break;
                    }
                    return (
                      <StyledTableTh
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          width: column.width,
                          ...actionHeaderThStyle,
                          ...thPropsStyle,
                        }}
                      >
                        <div>
                          {column.render("Header")}
                          {column.isSorted ? (
                            <span>
                              <MdUnfoldMore />
                            </span>
                          ) : null}
                        </div>
                      </StyledTableTh>
                    );
                  })}
                </StyledTableTr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page && page.length ? (
                page.map((row) => {
                  prepareRow(row);
                  const isRowExpansionExist =
                    renderExpansion && renderExpansion(row);
                  let { key, style, ...rest } = row.getRowProps();
                  const trPropsStyle = getTrPropsStyle
                    ? getTrPropsStyle(row)
                    : style;
                  const handleClickRow = (
                    e: MouseEvent<HTMLTableRowElement>,
                  ) => {
                    if (isRowExpansionExist) {
                      /**
                       * @description toggleRowExpanded is to make the row expand just by clicking the row table
                       */
                      row.toggleRowExpanded();
                    } else {
                      onClickRow && onClickRow(e, row);
                    }
                  };
                  return (
                    <Fragment key={key}>
                      <StyledTableTr
                        {...rest}
                        onClick={handleClickRow}
                        style={{
                          ...trPropsStyle,
                        }}
                      >
                        {row.cells.map((cell) => {
                          const isActionCol = cell.column.id === "action";
                          const actionTdStyle: CSSProperties = isActionCol
                            ? {
                                display: "flex",
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }
                            : {};
                          return (
                            <StyledTd
                              {...cell.getCellProps()}
                              style={{ ...actionTdStyle }}
                            >
                              {cell.render("Cell")}
                            </StyledTd>
                          );
                        })}
                      </StyledTableTr>
                      {row.isExpanded && isRowExpansionExist ? (
                        <tr>
                          <td colSpan={visibleColumns.length}>
                            {renderExpansion(row)}
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })
              ) : (
                <tr style={{ backgroundColor: "white" }}>
                  <td
                    style={{ textAlign: "center", padding: "8px 0" }}
                    colSpan={headerGroups.reduce((acc, curr) => {
                      // return the longest
                      return Math.max(acc, curr.headers.length);
                    }, 0)}
                  >
                    {noDataText}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableContainer>
      )}
      {isPagination ? (
        customPagination ? (
          customPagination
        ) : data ? (
          <DefaultPaginationTable
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageOptions={pageOptions}
            pageIndex={pageIndex}
            previousPage={previousPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
          />
        ) : null
      ) : null}
    </CardTable>
  );
};

export default BasicTable;
