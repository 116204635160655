import {
  useGetAnalyticsPatientQuery,
  useGetAnalyticsRevenueQuery,
} from "store/analyticsStoreAPI";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import {
  Container,
  PatientCount,
  Pricing,
  StatsTitle,
} from "Pages/Dashboard/components/BasicStatsCard/style";

type Stats = "clinic" | "pharmacy" | "procedure" | "patient";

type Props = {
  stats: Stats;
};

const BasicStatsCard = ({ stats }: Props) => {
  const { formatRupiah } = useFormatRupiah();

  const { data: revenueData } = useGetAnalyticsRevenueQuery();
  const { data: patientData } = useGetAnalyticsPatientQuery();

  const title = () => {
    switch (stats) {
      case "clinic":
        return "Pendapatan Klinik";
      case "pharmacy":
        return "Pendapatan Farmasi";
      case "procedure":
        return "Pendapatan Tindakan";
      case "patient":
        return "Total Pasien Hari ini";
    }
  };

  return (
    <Container>
      <>
        <StatsTitle>{title()}</StatsTitle>
        {stats === "clinic" || stats === "pharmacy" || stats === "procedure" ? (
          <StatsTitle className="secondary">(bulan ini)</StatsTitle>
        ) : null}
      </>

      {stats === "clinic" && (
        <Pricing>
          {formatRupiah(parseFloat(revenueData?.pendapatan_total || "0"))}
        </Pricing>
      )}
      {stats === "pharmacy" && (
        <Pricing>
          {formatRupiah(parseFloat(revenueData?.pendapatan_farmasi || "0"))}
        </Pricing>
      )}
      {stats === "procedure" && (
        <Pricing>
          {formatRupiah(parseFloat(revenueData?.pendapatan_tindakan || "0"))}
        </Pricing>
      )}
      {stats === "patient" && (
        <PatientCount>{patientData?.total_patient || 0} Orang</PatientCount>
      )}
    </Container>
  );
};

export default BasicStatsCard;
