import { PatientType } from "types/report";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";

type Props = {
  state: PatientType;
  onChange: (patientType: PatientType) => void;
};

const PatientTypeOpt = [
  {
    value: "all",
    label: "Semua",
  },
  {
    value: "umum",
    label: "Umum",
  },
  {
    value: "bpjs",
    label: "BPJS",
  },
];

const ReportPatientTypeSelect = ({ state, onChange }: Props) => {
  return (
    <PureInputSelect
      label=""
      placeholder="Tipe Pasien"
      options={PatientTypeOpt}
      value={PatientTypeOpt.find((option) => option.value === state)}
      onChange={(e) => {
        if (e) {
          const newPatientType = e.value as PatientType;
          onChange(newPatientType);
        }
      }}
    />
  );
};

export default ReportPatientTypeSelect;
