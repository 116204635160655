import styled, { css } from "styled-components";
import { COLORS } from "assets/theme";

interface BaseInfoTextProps {
  className?: string;
  styleType?: "regular" | "danger" | "info" | "alt";
  styleSize?: "10" | "12" | "14" | "16";
}

const styleTypes = {
  regular: css`
    color: ${COLORS.green};
  `,
  danger: css`
    color: ${COLORS.magenta};
  `,
  info: css`
    color: ${COLORS.text_hint};
  `,
  alt: css`
    color: ${COLORS.baltic};
  `,
};

const styleSizes = {
  10: css`
    font-size: 10px;
  `,
  12: css`
    font-size: 12px;
  `,
  14: css`
    font-size: 14px;
  `,
  16: css`
    font-size: 16px;
  `,
};

export const BaseInfoText = styled.p<BaseInfoTextProps>`
  margin: 0;
  color: ${COLORS.green};
  font-size: 16px;

  ${({ styleType = "regular" }) => styleTypes[styleType]}
  ${({ styleSize = "16" }) => styleSizes[styleSize]}
`;
