import { AProfileWithId } from "../../../types/api/practitioner";

/**
 *
 * @param profile a profile of practitioner
 * @param short get short or long. defaults to false
 * @returns proper practitioner name
 */
export const getPractitionerName = (
  profile?: AProfileWithId,
  short?: boolean,
): string => {
  if (profile) {
    const dr = profile;
    const prefix = dr.prefix ?? dr.specialist;
    return short
      ? `${prefix ? prefix + " " : ""} ${dr.first_name}`
      : `${prefix ? prefix + " " : ""}${dr.first_name}${
          dr.middle_name ? " " + dr.middle_name : ""
        }${dr.last_name ? " " + dr.last_name : ""}${
          dr.suffix ? ", " + dr.suffix : ""
        }`;
  }
  return "";
};
