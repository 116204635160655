import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import toast from "react-hot-toast";

import { TKelompokBersamaBase } from "types/api/bpjs";
import {
  useDeleteKelompokKegiatanMutation,
  useGetKelompokKegiatanQuery,
} from "store/bpjsStoreAPI/bpjsKelompokAPI";

import { BaseButton } from "components/base/Button";
import { BaseHeaderLayout, BaseLayout } from "components/base/Container";
import { BaseHeaderText, BaseText } from "components/base/Text";
import { BaseTextInput } from "components/base/Input";
import { useModal } from "components/ModalRefined/ModalRefined";
import { BaseModalRefined } from "components/base/Modal";
import CustomToast from "components/CustomToast";
import TableDaftarPeserta from "./components/TableDaftarPeserta/TableDaftarPeserta";
import KelompokDetails from "./components/KelompokDetails/KelompokDetails";
import AddPesertaForm from "./components/TableDaftarPeserta/components/AddPesertaForm/AddPesertaForm";

const DetailKelompokBersama = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const id = params?.id;

  const { data: kelompokKegiatan, isLoading: isLoadingKelompokKegiatan } =
    useGetKelompokKegiatanQuery({
      month: format(new Date().setDate(1), "dd-MM-yyyy"),
    });

  const kelompokData: TKelompokBersamaBase | undefined = kelompokKegiatan?.find(
    (item: any) => item?.eduId === id,
  );

  const [deleteKelompok] = useDeleteKelompokKegiatanMutation();

  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal } =
    useModal();
  const { isOpen: isAddPesertaModalOpen, toggleModal: toggleAddPesertaModal } =
    useModal();

  const handleDelete = () => {
    deleteKelompok({
      eduId: id ?? "",
    })
      .unwrap()
      .then((res) => {
        navigate(-1);
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Berhasil"
            description="Kelompok berhasil dihapus"
          />
        ));
      })
      .catch((err) => {
        console.error(err);
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description="Kelompok gagal dihapus"
          />
        ));
      });
  };

  return (
    <>
      <BaseModalRefined
        isOpen={isDeleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        header="Konfirmasi"
        size="sm"
      >
        <BaseText>
          Apakah Anda yakin ingin menghapus kegiatan kelompok ini?
        </BaseText>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          <BaseButton styleType="info" onClick={() => toggleDeleteModal(false)}>
            Batalkan
          </BaseButton>
          <BaseButton styleType="danger" onClick={handleDelete}>
            Hapus
          </BaseButton>
        </div>
      </BaseModalRefined>

      <BaseModalRefined
        isOpen={isAddPesertaModalOpen}
        onClose={() => toggleAddPesertaModal(false)}
        header="Tambah Data Peserta"
        size="xl"
      >
        <AddPesertaForm eduId={id} toggleModal={toggleAddPesertaModal} />
      </BaseModalRefined>

      <BaseLayout
        height="auto"
        style={{
          marginBottom: "16px",
        }}
      >
        <BaseHeaderLayout
          style={{
            flexDirection: "column",
          }}
        >
          <BaseHeaderText styleSize="24">Kelompok Bersama</BaseHeaderText>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <BaseButton
              styleType="info"
              style={{ padding: "8px 16px" }}
              onClick={() => navigate(-1)}
            >
              <BiArrowBack /> Kembali
            </BaseButton>
            <BaseButton
              styleType="danger"
              style={{ padding: "8px 16px" }}
              onClick={() => toggleDeleteModal(true)}
            >
              Hapus Kegiatan
            </BaseButton>
          </div>
        </BaseHeaderLayout>

        <KelompokDetails kelompokData={kelompokData} />
      </BaseLayout>

      <BaseLayout height="auto">
        <BaseHeaderLayout
          style={{
            flexDirection: "column",
          }}
        >
          <BaseHeaderText styleSize="24">Daftar Peserta Hadir</BaseHeaderText>

          <div
            style={{
              display: "flex",
              gap: "16px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BaseTextInput
              placeholder="Cari..."
              elementAddonRight={<BiSearch />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <BaseButton
              styleType="dark"
              style={{ padding: "8px 16px" }}
              onClick={() => toggleAddPesertaModal(true)}
            >
              + Peserta
            </BaseButton>
          </div>
        </BaseHeaderLayout>
        <TableDaftarPeserta eduId={id} search={search} />
      </BaseLayout>
    </>
  );
};

export default DetailKelompokBersama;
