import { UseFormReturn } from "react-hook-form";

import { BasicSelectOpt } from "types";
import { PatientTypeEnum, QueuePemeriksaanTypeEnum } from "types/queue";
import { APatientBase } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";

import { BaseSelectInput } from "components/base/Input";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
  value?: string;
  onChange: (selectedOption: BasicSelectOpt) => void;
};

const TipePemeriksaanSelect = ({ formData, value, onChange }: Props) => {
  const tipePemeriksaanOptions = [
    { label: "Umum", value: QueuePemeriksaanTypeEnum.UMUM },
    { label: "Kehamilan", value: QueuePemeriksaanTypeEnum.KEHAMILAN },
  ];

  const jenisAntrian = formData.watch("is_bpjs");
  const tipePasien = formData.watch("type");
  const disabled = jenisAntrian === true || tipePasien === PatientTypeEnum.BABY;

  return (
    <BaseSelectInput<any>
      required
      isDisabled={disabled}
      label="Pemeriksaan"
      placeholder="Pemeriksaan"
      options={tipePemeriksaanOptions}
      onChange={(selectedOption: BasicSelectOpt) => {
        onChange(selectedOption.value);
        if (selectedOption.value === QueuePemeriksaanTypeEnum.KEHAMILAN) {
          formData.setValue("sex", "female");
        }
      }}
      value={tipePemeriksaanOptions.find((option) => option.value === value)}
    />
  );
};

export default TipePemeriksaanSelect;
