import React, { ChangeEventHandler, InputHTMLAttributes } from "react";
import styled from "styled-components";

import { COLORS } from "assets/theme";
import {
  BaseRadioGroup,
  BaseRadioInput,
  BaseRadioLabel,
} from "components/base/Input";

const StyledLegend = styled.legend<{
  $labelColor?: string;
  labelFontSize?: string;
  spacingLabel?: string;
}>`
  margin-bottom: ${(props) => props.spacingLabel || "7px"};
  font-size: ${(props) => (props.labelFontSize ? props.labelFontSize : "1rem")};
  color: ${(props) =>
    props.$labelColor ? props.$labelColor : COLORS.text_black};

  .required-star {
    padding-left: 4px;
    color: ${COLORS.red};
  }
`;

const StyledContainer = styled.div<{
  orientation?: "row" | "column";
  spacingOptions?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.orientation || "row"};
  flex-wrap: wrap;
  gap: ${(props) => props.spacingOptions || "4px"};
`;

const StyledRadioSpacer = styled.div<{
  spacing?: string;
}>`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.spacing || "4px"};
  cursor: pointer;
`;

type RadioOption = {
  label: string;
  value: string | number | boolean | readonly string[] | any | undefined;
};

type RadioGroupProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  legend: string;
  required?: boolean;
  options: RadioOption[];
  value?: string | number | boolean | readonly string[] | any | undefined;
  orientation?: "row" | "column";
  spacingOptions?: string;
  legendFontSize?: string;
  spacing?: string;
  spacingLabel?: string;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  legend,
  required,
  options,
  value,
  onChange,
  orientation,
  spacingOptions,
  spacing,
  legendFontSize,
  spacingLabel,
  ...props
}) => {
  return (
    <BaseRadioGroup id={id}>
      <StyledLegend labelFontSize={legendFontSize} spacingLabel={spacingLabel}>
        {legend}
        {required && <span className="required-star">*</span>}
      </StyledLegend>
      <StyledContainer
        orientation={orientation}
        spacingOptions={spacingOptions}
      >
        {options.map((option, index) => {
          const radioId = option.label
            ? `${option.label.replace(/ /g, "-").toLowerCase()}-${Math.random()
                .toString(36)
                .slice(2, 11)}`
            : `radio-input-${Math.random().toString(36).slice(2, 11)}`;

          return (
            <StyledRadioSpacer
              key={index}
              spacing={spacing}
              onClick={() => document.getElementById(radioId)?.click()}
            >
              <BaseRadioInput
                id={radioId}
                type="radio"
                name={id}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                required={required}
                {...props}
              />
              <BaseRadioLabel htmlFor={radioId}>{option.label}</BaseRadioLabel>
            </StyledRadioSpacer>
          );
        })}
      </StyledContainer>
    </BaseRadioGroup>
  );
};

export default RadioGroup;
