import React, {
  useState,
  InputHTMLAttributes,
  ChangeEventHandler,
} from "react";
import styled from "styled-components";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  valueLabel?: string;
  color?: string;
};

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledCheckbox = styled.input`
  cursor: pointer;
  width: 14px;
  height: 14px;
`;

const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  checked,
  valueLabel,
  color,
  ...props
}) => {
  return (
    <Container>
      <StyledCheckbox
        type="checkbox"
        checked={checked}
        onChange={onChange}
        style={{
          accentColor: color,
        }}
        {...props}
      />
      {valueLabel && <span>{valueLabel}</span>}
    </Container>
  );
};

export default Checkbox;
