export type QueueType = "all" | "umum" | "bpjs";

export const queueTypeOpt = [
  {
    value: "all",
    label: "Semua Antrian",
  },
  {
    value: "umum",
    label: "Antrian Umum",
  },
  {
    value: "bpjs",
    label: "Antrian BPJS",
  },
];

export type tabKey = "berjalan" | "selesai";

export type QueueCategory = "pasien" | "obat" | "kasir";

export enum PatientTypeEnum {
  WNI = "WNI",
  WNA = "WNA",
  BABY = "BABY",
  WITHOUT_NIK = "WITHOUT_NIK",
}

export enum BpjsTypeEnum {
  BARU = "BARU",
  KUNJUNGAN = "KUNJUNGAN",
}

export enum QueuePemeriksaanTypeEnum {
  UMUM = "UMUM",
  KEHAMILAN = "KEHAMILAN",
}

export enum QueueTypeEnum {
  BPJS = "BPJS",
  UMUM = "UMUM",
}

export enum PemeriksaanKehamilanEnum {
  K1_AKSES = "K1_AKSES",
  K1_MURNI = "K1_MURNI",
  LANJUTAN = "LANJUTAN",
}
