import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useGetAnalyticsDrugsQuery } from "store/analyticsStoreAPI";

import { COLORS } from "assets/theme";
import { StatsHeader, StatsTitle } from "Pages/Dashboard/components";
import {
  Circle,
  Container,
  ScrollableContainer,
  StatsLabel,
} from "Pages/Dashboard/components/DrugStockChart/style";

interface CustomBarProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

const CustomBar = (props: CustomBarProps) => {
  const { fill, x, y, width, height } = props;
  return (
    <rect
      x={x}
      y={y}
      rx={2}
      ry={2}
      width={width}
      height={height}
      style={{ fill }}
    />
  );
};

const DrugStockChart = () => {
  const { data: drugsData, isLoading: isLoadingDrugs } =
    useGetAnalyticsDrugsQuery();

  const cappedDrugsData = drugsData ? drugsData?.slice(0, 10) : [];

  return (
    <>
      <Container>
        <StatsHeader>
          <StatsTitle>{"Stok Obat < 30"}</StatsTitle>
          <StatsLabel>
            <Circle />
            {"Obat"}
          </StatsLabel>
        </StatsHeader>
        {drugsData && drugsData?.length > 0 && !isLoadingDrugs && (
          <ScrollableContainer>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                width={Math.max(500, cappedDrugsData?.length * 50)}
                height={200}
                data={cappedDrugsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="0%"
                      stopColor={COLORS.gradient_blue_chart_1}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={COLORS.gradient_blue_chart_2}
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  strokeDasharray="0"
                  strokeWidth={0.5}
                  horizontal={true}
                  vertical={false}
                />
                <XAxis
                  dataKey={"drug_merk"}
                  axisLine={false}
                  tickLine={false}
                  interval={0}
                  tick={(props) => {
                    const { x, y, payload } = props;
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={12}
                          y={0}
                          dy={10}
                          textAnchor="end"
                          fill={COLORS.text_gray}
                          transform="rotate(-0)"
                          fontSize={10}
                        >
                          {payload.value.substring(0, 10)}
                        </text>
                      </g>
                    );
                  }}
                />
                <YAxis
                  dataKey="drug_stock"
                  width={10}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip />
                <Bar
                  dataKey="drug_stock"
                  fill="url(#colorUv)"
                  shape={(props: any) => <CustomBar {...props} />}
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
          </ScrollableContainer>
        )}

        {!drugsData && <div>No data</div>}
      </Container>
    </>
  );
};

export default DrugStockChart;
