import React from "react";
import { Navigate, useLocation } from "react-router";
import { toast } from "react-hot-toast";

import { combinedRoutes } from "config/nav";
import CookieMonster from "config/CookieMonster";
import { Role } from "types/roles";

import CustomToast from "components/CustomToast";

type Props = {
  allowedRoles?: Role[];
  children?: React.ReactNode;
};

const AuthHOC = ({ allowedRoles, children }: Props) => {
  const location = useLocation();
  const jwt = CookieMonster.loadCookie("access_token");
  const roles: Role[] | undefined = CookieMonster.loadCookie("roles");
  localStorage.removeItem("temporary_access_token");

  const noJWT = !jwt || !jwt.length;
  const rolesFound = allowedRoles?.length
    ? roles?.find((r) => allowedRoles.includes(r))
    : true;

  if (noJWT) {
    return (
      <Navigate to={combinedRoutes.login_page} state={{ from: location }} />
    );
  }

  if (!rolesFound) {
    toast.custom(
      (t) => (
        <CustomToast
          t={t}
          headerText="Tidak diizinkan!"
          description="Tidak ada akses"
          status="error"
        />
      ),
      { id: "not_allowed" },
    );
    return <Navigate to={combinedRoutes.antrian_pasien} replace />;
  }

  return <>{children}</>;
};

export default AuthHOC;
