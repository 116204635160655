import styled from "styled-components";
import { COLORS } from "assets/theme";

export interface ATableStyleProps {
  /**
   * font size and icon svg sort header size in "pixels"
   */
  fontSize?: number;
}

export const StyledTableRow = styled.tr`
  transition: all 0.2s;
  font-weight: normal;
  color: ${COLORS.black_1};
  text-transform: uppercase;
  &:hover {
    background: transparent;
  }
  &.expanded {
    background: ${COLORS.asphalt};
  }
  /* background: ${COLORS.blue_1}; */
`;

export const StyledPageDropdownButton = styled.select`
  border: 0.5px solid ${COLORS.neutral_gray_gull};
  background-color: transparent;
  padding: 0.6rem 0.7rem;
  border-radius: 6px;
  color: ${COLORS.white};
`;

export const StyledPaginationWrapper = styled.button`
  padding: 0%.6rem 0.7rem;
  border-radius: 6px;
  border: 1px solid ${COLORS.text_hint};
  background: transparent;
  color: ${COLORS.white};
  &:disabled {
    background: ${COLORS.yale_blue};
  }
`;

export const CardTable = styled.div`
  /* padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.2); */
`;

export const StyledTableTr = styled.tr``;

export const StyledTableTh = styled.th`
  font-weight: 600;
  padding: 0.75rem;
  text-align: left;
`;

export const TableContainer = styled.div`
  flex: 1;
  /* min-height: 100vh; */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
  }
`;

export const Table = styled.table<ATableStyleProps>`
  width: 100%;
  border-collapse: collapse;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 12)}px;
  border-spacing: 0;
  tr {
    border-bottom: 0.5px solid ${COLORS.neutral_gray_gull};
    th {
      cursor: pointer;
    }
    th > div {
      display: flex;
      align-items: center;
    }
    th > div > span > svg {
      height: ${(props) => (props.fontSize ? props.fontSize : 12)}px;
      width: ${(props) => (props.fontSize ? props.fontSize : 12)}px;
      margin-left: 4px;
    }
    td {
    }
    :last-child {
      td {
        /* border-bottom: none; */
      }
    }
  }
`;

export const StyledTd = styled.td`
  padding: 0.75rem;
`;
