import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { UsedAPI } from "api";

import CookieMonster from "config/CookieMonster";

import {
  BackendDataPageShape,
  BackendDataShape,
  BackendMetaPagination,
  PaginationQueryParams,
} from "types";
import { Accounts, ChangePwdAccountRequest } from "types/api/account";
import { APractitioner, AnAccountWithProfile } from "types/api/practitioner";

import { clinicStoreAPI } from "store/clinicStoreAPI";
import { AnAccount } from "types/auth";

export const accountStoreAPI = createApi({
  reducerPath: "accountStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/user-accounts`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["users"],
  endpoints: (builder) => {
    return {
      getAccounts: builder.query<
        {
          data: (AnAccount & { no: number })[];
          meta: BackendMetaPagination;
        },
        {
          isDoctor?: boolean;
          isActive?: boolean;
        } & PaginationQueryParams
      >({
        query: (args) => {
          const { page = 1, take = 10, ...rest } = args;

          return {
            url: "/",
            method: "GET",
            params: {
              ...rest,
              page,
              take,
              is_doctor: args.isDoctor,
            },
          };
        },
        providesTags: ["users"],
        transformResponse: (res: BackendDataPageShape<AnAccount[]>) => {
          const { offset } = res.data.meta;
          return {
            data: res.data.entities.map((account, idx) => ({
              ...account,
              no: offset + idx + 1,
            })),
            meta: res.data.meta,
          };
        },
      }),

      getAccountById: builder.query<Accounts, number>({
        query: (id) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
        providesTags: ["users"],
        transformResponse: (res: BackendDataShape<Accounts>) => {
          return res.data;
        },
      }),

      createAccountDoctor: builder.mutation<
        BackendDataShape<{ id: number }>,
        APractitioner
      >({
        query: (obj) => {
          return {
            url: `/create`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["users"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled.then(() =>
            dispatch(clinicStoreAPI.util.invalidateTags(["practitioner"])),
          );
        },
      }),

      createAccount: builder.mutation<
        BackendDataShape<{ id: number }>,
        Accounts
      >({
        query: (obj) => {
          return {
            url: `/create`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["users"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled.then(() =>
            dispatch(clinicStoreAPI.util.invalidateTags(["practitioner"])),
          );
        },
      }),

      changePassword: builder.mutation<
        BackendDataShape<null>,
        ChangePwdAccountRequest
      >({
        query: (obj) => {
          return {
            url: "change/password",
            method: "PATCH",
            body: obj,
          };
        },
      }),

      resetPassword: builder.mutation<
        unknown,
        { id: number; password: string }
      >({
        query: ({ id, password }) => {
          return {
            url: `/${id}/reset/password`,
            method: "PATCH",
            body: {
              password: password,
            },
          };
        },
      }),

      editAccountDoctor: builder.mutation<
        unknown,
        APractitioner & { id: number }
      >({
        query: ({ id, ...body }) => {
          return {
            url: `/${id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["users"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled.then(() =>
            dispatch(clinicStoreAPI.util.invalidateTags(["practitioner"])),
          );
        },
      }),

      editAccount: builder.mutation<unknown, Accounts>({
        query: ({ id, ...body }) => {
          return {
            url: `/${id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["users"],
      }),

      deleteAccount: builder.mutation<unknown, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["users"],
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          await queryFulfilled.then(() =>
            dispatch(clinicStoreAPI.util.invalidateTags(["practitioner"])),
          );
        },
      }),

      editAccountNonId: builder.mutation<unknown, Accounts>({
        query: ({ ...body }) => {
          return {
            url: "",
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["users"],
      }),
    };
  },
});

export const {
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useCreateAccountDoctorMutation,
  useCreateAccountMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useEditAccountDoctorMutation,
  useEditAccountMutation,
  useDeleteAccountMutation,
  useEditAccountNonIdMutation,
  util: { resetApiState: resetAccountStoreAPI },
} = accountStoreAPI;
