import { ReportType } from "types/report";
import { useAppDispatch, useAppSelector } from "hooks";
import { setReportType } from "store/reportSlice";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";

const ReportOptions = [
  {
    label: "Kunjungan Pasien",
    value: "report-kunjungan",
  },
  {
    label: "Transaksi Pasien",
    value: "report-transaksi",
  },
  {
    label: "Rekap Stok Obat",
    value: "report-obat",
  },
  {
    label: "Daftar Pasien",
    value: "report-pasien-list",
  },
  {
    label: "Daftar Dokter",
    value: "report-dokter-list",
  },
  {
    label: "Daftar Poli",
    value: "report-poli-list",
  },
  {
    label: "Daftar Tindakan",
    value: "report-tindakan-list",
  },
  {
    label: "Transaksi Farmasi",
    value: "report-transaksi-farmasi",
  },
  {
    label: "Transaksi berdasarkan Poli",
    value: "report-transaksi-by-poli",
  },
  {
    label: "Transaksi berdasarkan Dokter",
    value: "report-transaksi-by-dokter",
  },
  {
    label: "Detail Transaksi per Pasien",
    value: "report-transaksi-by-pasien",
  },
];

const ReportTypeSelect = () => {
  const dispatch = useAppDispatch();
  const { reportType } = useAppSelector((state) => state.reportSlice);

  return (
    <PureInputSelect
      label=""
      placeholder="Pilih Jenis Laporan"
      options={ReportOptions}
      value={ReportOptions.find((option) => option.value === reportType)}
      onChange={(e) =>
        dispatch(setReportType(e ? (e.value as ReportType) : undefined))
      }
      isClearable={true}
      maxMenuHeight={200}
    />
  );
};

export default ReportTypeSelect;
