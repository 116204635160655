import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { AProcedureBase, AProcedureWithId } from "types/api/procedures";
import { BackendDataShape } from "types";

export const procedureStoreAPI = createApi({
  reducerPath: "procedureStoreAPI",
  baseQuery: fetchBaseQuery({
    /**
     * @TODO adjust this base url later
     */
    baseUrl: `${UsedAPI}/procedures`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["procedures"],
  endpoints: (builder) => {
    return {
      getProcedures: builder.query<(AProcedureWithId & { no: number })[], {}>({
        query: () => {
          return {
            url: "",
            method: "GET",
          };
        },
        providesTags: ["procedures"],
        transformResponse: (data: BackendDataShape<AProcedureWithId[]>, _) => {
          const f = data.data.slice();
          f.sort((a, b) => {
            return a.namespace.localeCompare(b.namespace);
          });

          return f.map((d, idx) => ({ ...d, no: idx + 1 }));
        },
      }),

      createProcedure: builder.mutation<unknown, AProcedureBase>({
        query: (body) => {
          return {
            url: "",
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["procedures"],
      }),

      updateProcedure: builder.mutation<
        unknown,
        AProcedureBase & { id: number }
      >({
        query: ({ id, ...body }) => {
          return {
            url: `/${id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["procedures"],
      }),

      deleteProcedure: builder.mutation<unknown, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["procedures"],
      }),

      // getPractitionerInPoly: builder.query<
      //   (APolyclinicWithPractitioner & { no: number })[],
      //   {}
      // >({
      //   query: () => {
      //     return {
      //       url: "/polyclinic/practitioners",
      //       method: "GET",
      //     };
      //   },
      //   providesTags: ["practitioner"],
      //   transformResponse: (data: APolyclinicWithPractitioner[]) => {
      //     const f = data;
      //     return f.map((d, idx) => {
      //       // filter unwanted practitioners
      //       const practitioners = d.userAccounts.filter((p) => !!p.id);

      //       return { ...d, practitioners, no: idx + 1 };
      //     });
      //   },
      // }),
    };
  },
});

export const {
  useGetProceduresQuery,
  useCreateProcedureMutation,
  useUpdateProcedureMutation,
  useDeleteProcedureMutation,
  util: { resetApiState: resetProcedureStoreAPI },
} = procedureStoreAPI;
