import { useState } from "react";
import { LuInfo } from "react-icons/lu";
import { BiX } from "react-icons/bi";

import useIntegrationConfig from "hooks/utils/useIntegrationConfig";

import { IconContainer, InfoContainer } from "components/base/Container";

const IntegrationInfo = () => {
  const [close, setClose] = useState(false);

  const { bpjsConfigured, satusehatConfigured, clinicConfigured } =
    useIntegrationConfig();

  if (clinicConfigured || close) return null;

  const handleClose = () => {
    setClose(true);
  };

  const renderMissingConfigMessage = () => {
    let message = "";
    if (!bpjsConfigured && satusehatConfigured) return (message = "BPJS");
    if (!satusehatConfigured && bpjsConfigured) return (message = "Satu Sehat");
    if (!clinicConfigured) return (message = "BPJS dan Satu Sehat");

    return message;
  };

  return (
    <InfoContainer
      style={{
        marginBottom: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <IconContainer>
            <LuInfo size={24} />
          </IconContainer>
          <p>
            Klinik anda belum melakukan integrasi dengan{" "}
            {renderMissingConfigMessage()}
          </p>
        </div>
        <BiX
          style={{
            cursor: "pointer",
            fontSize: "24px",
          }}
          onClick={handleClose}
        />
      </div>
    </InfoContainer>
  );
};

export default IntegrationInfo;
