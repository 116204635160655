import styled from "styled-components";
import { medium, small } from "assets/theme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${medium} {
    grid-template-columns: 1fr;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;

  .BaseText:nth-child(2) {
    font-weight: bold;
  }

  ${small} {
    flex-direction: column;
  }
`;

export const TitleContainer = styled.div`
  min-width: 200px;

  ${medium} {
    min-width: 180px;
  }

  ${small} {
    min-width: auto;
  }
`;
