import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { APatientBase } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";

import { BaseRadioGroupInput } from "components/base/Input";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const BpjsJenisPoliSakitChanger = ({ formData, value, onChange }: Props) => {
  const jenisKunjunganPoliOptions = [
    { label: "Kunjungan Sakit", value: "sakit" },
    { label: "Kunjungan Sehat", value: "sehat" },
  ];

  useEffect(() => {
    if (value === "sehat") {
      formData.setValue("kunj_sakit", true);
    } else {
      formData.setValue("kunj_sakit", false);
    }
  }, [formData, value]);

  return (
    <BaseRadioGroupInput
      id="jenis-bpjs-kunjungan-radio"
      legend="Jenis Kunjungan"
      required
      options={jenisKunjunganPoliOptions}
      value={value}
      onChange={onChange}
      orientation="row"
      spacingOptions="24px"
    />
  );
};

export default BpjsJenisPoliSakitChanger;
