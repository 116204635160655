import { BaseRadioGroupInput } from "components/base/Input";
import { BpjsTypeEnum } from "types/queue";

type Props = {
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const BpjsJenisAntrianChanger = ({ value, onChange }: Props) => {
  const jenisAntrianBpjsOptions = [
    { label: "Baru", value: BpjsTypeEnum.BARU },
    // { label: "Rujukan", value: BpjsTypeEnum.KUNJUNGAN },
  ];

  return (
    <BaseRadioGroupInput
      id="jenis-antrian-bpjs-radio"
      legend="Antrian BPJS"
      required
      options={jenisAntrianBpjsOptions}
      value={value}
      onChange={onChange}
      orientation="row"
      spacingOptions="24px"
    />
  );
};

export default BpjsJenisAntrianChanger;
