import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  z-index: 9999999;
`;

const Load = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #02ac8e 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #02ac8e);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 0.5s infinite linear;
  @keyframes s3 {
    100% {
      transform: rotate(1turn);
    }
  }
`;

const CustomLoader: React.FC = () => {
  return (
    <Wrapper className="d-flex align-items-center justify-content-center">
      <Load />
    </Wrapper>
  );
};

export default CustomLoader;
