import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import sidebarStore from "store/sidebarStore";
import activeEncounterOptStore from "store/activeEncounterOptStore";
import { patientStoreAPI } from "store/patientStoreAPI";
import { encounterStoreAPI } from "store/encounterStoreAPI";
import { clinicStoreAPI } from "store/clinicStoreAPI";
import vitalFormStore from "store/form/vitalFormStore";
import soapFormStore from "store/form/soapFormStore";
import { diagnosisAPI } from "store/diagnosisAPI";
import { accountStoreAPI } from "store/accountsStoreAPI";
import { obatStoreAPI } from "store/obatStoreAPI";
import { userProfileStoreAPI } from "store/userProfileStoreAPI";
import { pharmacyStoreAPI } from "store/pharmacyStoreAPI";
import { procedureStoreAPI } from "store/procedureStoreAPI";
import { signupStoreAPI } from "store/signUpStoreAPI";
import { odontogramStoreAPI } from "store/odontogramStoreAPI";
import odontogramFormStore from "store/form/odontogramFormStore";
import { bpjsStoreApi } from "store/bpjsAPI";
import { satuSehatStoreAPI } from "store/satuSehatStoreAPI";
import vitalInspectionSlice from "store/inspectionSlice/vitalInspectionSlice";
import soapInspectionSlice from "store/inspectionSlice/soapInspectionSlice";
import { paymentStoreApi } from "store/paymentStoreAPI";
import { productStoreApi } from "store/productStoreAPI";
import { voucherStoreApi } from "store/voucherStoreAPI";
import { baseApi } from "store/baseStoreAPI";
import queueSlice from "store/queueSlice/queueSlice";
import reportSlice from "store/reportSlice";

export const store = configureStore({
  reducer: {
    [accountStoreAPI.reducerPath]: accountStoreAPI.reducer,
    activeEncounterOptStore,
    [clinicStoreAPI.reducerPath]: clinicStoreAPI.reducer,
    [diagnosisAPI.reducerPath]: diagnosisAPI.reducer,
    [encounterStoreAPI.reducerPath]: encounterStoreAPI.reducer,
    [obatStoreAPI.reducerPath]: obatStoreAPI.reducer,
    [patientStoreAPI.reducerPath]: patientStoreAPI.reducer,
    [pharmacyStoreAPI.reducerPath]: pharmacyStoreAPI.reducer,
    [procedureStoreAPI.reducerPath]: procedureStoreAPI.reducer,
    sidebarStore,
    [signupStoreAPI.reducerPath]: signupStoreAPI.reducer,
    soapFormStore,
    [userProfileStoreAPI.reducerPath]: userProfileStoreAPI.reducer,
    vitalFormStore,
    [odontogramStoreAPI.reducerPath]: odontogramStoreAPI.reducer,
    odontogramFormStore,
    [bpjsStoreApi.reducerPath]: bpjsStoreApi.reducer,
    [satuSehatStoreAPI.reducerPath]: satuSehatStoreAPI.reducer,
    vitalInspectionSlice,
    soapInspectionSlice,
    [paymentStoreApi.reducerPath]: paymentStoreApi.reducer,
    [productStoreApi.reducerPath]: productStoreApi.reducer,
    [voucherStoreApi.reducerPath]: voucherStoreApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    queueSlice,
    reportSlice,
  },

  middleware: (getMiddleWares) => {
    let middlewares = getMiddleWares().concat([
      accountStoreAPI.middleware,
      clinicStoreAPI.middleware,
      diagnosisAPI.middleware,
      encounterStoreAPI.middleware,
      obatStoreAPI.middleware,
      patientStoreAPI.middleware,
      pharmacyStoreAPI.middleware,
      procedureStoreAPI.middleware,
      signupStoreAPI.middleware,
      userProfileStoreAPI.middleware,
      odontogramStoreAPI.middleware,
      bpjsStoreApi.middleware,
      satuSehatStoreAPI.middleware,
      paymentStoreApi.middleware,
      productStoreApi.middleware,
      voucherStoreApi.middleware,
      baseApi.middleware,
    ]);

    return middlewares;
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
