import cookie from "react-cookies";
import { CookieSerializeOptions } from "cookie";

export type UserCredCookies =
  | "username"
  | "access_token"
  | "roles"
  | "id"
  | "is_bpjs_configured"
  | "is_satu_sehat_configured";
export type CookieName = UserCredCookies;
export type CookieDataType = string | number | object;
export type CookieObject = Partial<Record<CookieName, CookieDataType>>;
export type CookieDataArray = [CookieName, CookieDataType];

export default class CookieMonster {
  /**
   * Load a specific cookie by name
   * @param cookieName Name of the cookie
   * @returns Cookie value or undefined if not found
   */
  static loadCookie(cookieName: CookieName) {
    return cookie.load(cookieName);
  }

  /**
   *
   * @param cookieNames cookie names to load (in Array)
   * @returns `CookieObject`
   */
  static loadCookies(cookieNames: CookieName[]) {
    let data: CookieObject = {};
    cookieNames.forEach((name) => {
      const cookieValue = cookie.load(name);
      if (cookieValue !== undefined) {
        data[name] = cookieValue;
      }
    });
    return data;
  }

  /**
   * Load all available cookies in 1 object
   * @returns `CookieObject` containing all available cookies
   */
  static loadCookieObj(): CookieObject {
    const allCookies: CookieObject = {};
    const cookieNames = cookie.loadAll(true);
    for (const [name, value] of Object.entries(cookieNames)) {
      allCookies[name as CookieName] = value;
    }
    return allCookies;
  }

  /**
   * Save a cookie with optional options
   * @param cookieName Name of the cookie
   * @param data Data to be stored in the cookie
   * @param options Optional options for the cookie
   */
  static saveCookie(
    cookieName: CookieName,
    data: CookieDataType,
    options?: CookieSerializeOptions,
  ) {
    cookie.save(cookieName, data, {
      path: "/",
      // secure: true,
      sameSite: true,
      ...options,
    });
  }

  /**
   * Save all provided cookie names with option `{path:'/'}`
   * @param cookieDatas Object containing cookie names and their values
   * @param options Optional options for the cookies
   * @example [ ['menu','simelekete'], ['division_id', 2]]
   */
  static saveCookies(
    cookieDatas: {
      [key in UserCredCookies]?: CookieDataType;
    },
    options?: CookieSerializeOptions,
  ) {
    for (let key in cookieDatas) {
      let k = key as UserCredCookies;
      let data = cookieDatas[k];
      if (data) {
        cookie.save(key, data, { path: "/", sameSite: true, ...options });
      }
    }
  }

  /**
   * Remove a specific cookie by name
   * @param cookieName Name of the cookie to be removed
   */
  static removeCookie(cookieName: CookieName) {
    cookie.remove(cookieName, {
      path: "/",
      // secure: true,
      sameSite: true,
    });
  }

  /**
   * Remove multiple cookies by names
   * @param cookieNames Array of cookie names to be removed
   * @example `['menu','permission']`
   */
  static removeCookies(cookieNames: CookieName[]) {
    cookieNames.forEach((name) => {
      this.removeCookie(name);
    });
  }
}
