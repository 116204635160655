import { baseApi } from "store/baseStoreAPI";
import {
  ResponseDrugs,
  ResponsePatients,
  ResponsePatientsAmounts,
  ResponsePatientsTrend,
  ResponseRevenue,
  ResponseRevenueTrend,
} from "types/api/dashboard";

const analyticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalyticsRevenue: builder.query<ResponseRevenue, void>({
      query: () => ({
        url: "/analytics/revenue",
        method: "GET",
      }),
      transformResponse: (res: { data: ResponseRevenue }) => res.data,
      providesTags: ["Analytics"],
    }),

    getAnalyticsPatient: builder.query<ResponsePatients, void>({
      query: () => ({
        url: "/analytics/patients",
        method: "GET",
      }),
      transformResponse: (res: { data: ResponsePatients }) => res.data,
      providesTags: ["Analytics"],
    }),

    getAnalyticsDrugs: builder.query<ResponseDrugs, void>({
      query: () => ({
        url: "/analytics/drugs",
        method: "GET",
      }),
      transformResponse: (res: { data: ResponseDrugs }) => res.data,
      providesTags: ["Analytics"],
    }),

    getAnalyticsRevenueTrend: builder.query<
      ResponseRevenueTrend,
      { monthRange: string }
    >({
      query: ({ monthRange }) => ({
        url: `/analytics/revenue/trend/${monthRange}`,
        method: "GET",
      }),
      transformResponse: (res: { data: ResponseRevenueTrend }) => res.data,
      providesTags: ["Analytics"],
    }),

    getAnalyticsPatientStats: builder.query<
      ResponsePatientsAmounts,
      { monthRange: string }
    >({
      query: ({ monthRange }) => ({
        url: `/analytics/patients/${monthRange}`,
        method: "GET",
      }),
      transformResponse: (res: { data: ResponsePatientsAmounts }) => res.data,
      providesTags: ["Analytics"],
    }),

    getAnalyticsPatientTrend: builder.query<
      ResponsePatientsTrend,
      { monthRange: string }
    >({
      query: ({ monthRange }) => ({
        url: `/analytics/patients/trend/${monthRange}`,
        method: "GET",
      }),
      transformResponse: (res: { data: ResponsePatientsTrend }) => res.data,
      providesTags: ["Analytics"],
    }),
  }),
});

export const {
  useGetAnalyticsRevenueQuery,
  useGetAnalyticsPatientQuery,
  useGetAnalyticsDrugsQuery,
  useGetAnalyticsRevenueTrendQuery,
  useGetAnalyticsPatientStatsQuery,
  useGetAnalyticsPatientTrendQuery,
  util: { resetApiState: resetAnalyticsAPI },
} = analyticsApi;
