import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "types";
import { VitalSignFormKeys } from "types/periksa";

export type VitalFormState = {
  /**
   * Vital form id
   */
  id?: Nullable<number>;
} & {
  [key in VitalSignFormKeys]?: Nullable<string>;
};

const initialState: VitalFormState = {};

const vitalFormSlice = createSlice({
  name: "vitalform_slice",
  initialState,
  reducers: {
    setVitalFormState: (
      state,
      action: PayloadAction<{
        id: number;
        name: VitalSignFormKeys;
        value: string;
      }>,
    ) => {
      const p = action.payload;
      state.id = p.id;
      state[p.name] = p.value;
    },
    // @ts-ignore
    setVitalAll: (state, action: PayloadAction<VitalFormState>) => {
      const { id, ...p } = action.payload;
      state.id = id;
      for (const [k, val] of Object.entries(p)) {
        const key = k as VitalSignFormKeys;
        if (val || val === null) {
          state[key] = val;
        }
      }
      // console.log({ p: action.payload });
      // state = { ...state, ...action.payload };
    },
    // @ts-ignore
    resetVital: (state) => {
      return initialState;
    },
  },
});

export default vitalFormSlice.reducer;
export const { setVitalFormState, setVitalAll, resetVital } =
  vitalFormSlice.actions;
