import { CookieSerializeOptions } from "cookie";
import CookieMonster, {
  UserCredCookies,
  CookieDataType,
} from "config/CookieMonster";

export type ParsedCookieObject = {
  username?: string;
  access_token?: string;
  roles?: string[];
  id?: number;
  is_bpjs_configured?: boolean;
  is_satu_sehat_configured?: boolean;
};

type CookieAccessMethods = {
  loadCookie: (cookieName: UserCredCookies) => ParsedCookieObject | undefined;
  loadCookies: (
    cookieNames: UserCredCookies[],
  ) => Partial<Record<UserCredCookies, ParsedCookieObject>>;
  loadCookieObj: () => ParsedCookieObject;
  saveCookie: (
    cookieName: UserCredCookies,
    data: ParsedCookieObject,
    options?: CookieSerializeOptions,
  ) => void;
  saveCookies: (
    cookieDatas: Record<UserCredCookies, ParsedCookieObject>,
    options?: CookieSerializeOptions,
  ) => void;
  removeCookie: (cookieName: UserCredCookies) => void;
  removeCookies: (cookieNames: UserCredCookies[]) => void;
};

/**
 * A hook that returns an object containing various methods for accessing and manipulating cookies.
 * Any loaded cookies will be parsed based on ParsedCookieObject.
 *
 * @returns {CookieAccessMethods} An object with the following methods:
 *   - `loadCookie(cookieName: UserCredCookies): ParsedCookieObject | undefined`: Loads a specific cookie by name.
 *   - `loadCookies(cookieNames: UserCredCookies[]): Partial<Record<UserCredCookies, ParsedCookieObject>>`: Loads multiple cookies by name.
 *   - `loadCookieObj(): CookieObject`: Loads all available cookies.
 *   - `saveCookie(cookieName: UserCredCookies, data: CookieDataType, options?: CookieSerializeOptions): void`: Saves a cookie with the given name, data, and options.
 *   - `saveCookies(cookieDatas: Record<UserCredCookies, CookieDataType>, options?: CookieSerializeOptions): void`: Saves multiple cookies with the given data and options.
 *   - `removeCookie(cookieName: UserCredCookies): void`: Removes a specific cookie by name.
 *   - `removeCookies(cookieNames: UserCredCookies[]): void`: Removes multiple cookies by name.
 */
const useCookieAccess = (): CookieAccessMethods => {
  const parseCookieObj = (rawCookies?: any): ParsedCookieObject => {
    if (!rawCookies) {
      return {};
    }

    return {
      username: rawCookies.username as string,
      access_token: rawCookies.access_token as string,
      roles: Array.isArray(rawCookies.roles)
        ? (rawCookies.roles as string[])
        : rawCookies.roles
        ? (JSON.parse(rawCookies.roles as string) as string[])
        : [],
      id: rawCookies.id ? parseInt(rawCookies.id as string, 10) : undefined,
      is_bpjs_configured: rawCookies.is_bpjs_configured === "true",
      is_satu_sehat_configured: rawCookies.is_satu_sehat_configured === "true",
    };
  };

  const loadCookie = (
    cookieName: UserCredCookies,
  ): ParsedCookieObject | undefined => {
    const rawCookie = CookieMonster.loadCookie(cookieName);
    return rawCookie !== undefined
      ? parseCookieObj({ [cookieName]: rawCookie })
      : undefined;
  };

  const loadCookies = (
    cookieNames: UserCredCookies[],
  ): Partial<Record<UserCredCookies, ParsedCookieObject>> => {
    const rawCookies = CookieMonster.loadCookies(cookieNames);
    const parsedCookies: Partial<Record<UserCredCookies, ParsedCookieObject>> =
      {};

    for (const cookieName of cookieNames) {
      const rawCookie = rawCookies[cookieName];
      parsedCookies[cookieName] =
        rawCookie !== undefined
          ? parseCookieObj({ [cookieName]: rawCookie })
          : undefined;
    }

    return parsedCookies;
  };

  const loadCookieObj = (): ParsedCookieObject => {
    const rawCookies = CookieMonster.loadCookieObj();
    return parseCookieObj(rawCookies);
  };

  const saveCookie = (
    cookieName: UserCredCookies,
    data: CookieDataType,
    options?: CookieSerializeOptions,
  ): void => {
    CookieMonster.saveCookie(cookieName, data, options);
  };

  const saveCookies = (
    cookieDatas: Record<UserCredCookies, CookieDataType>,
    options?: CookieSerializeOptions,
  ): void => {
    CookieMonster.saveCookies(cookieDatas, options);
  };

  const removeCookie = (cookieName: UserCredCookies): void => {
    CookieMonster.removeCookie(cookieName);
  };

  const removeCookies = (cookieNames: UserCredCookies[]): void => {
    CookieMonster.removeCookies(cookieNames);
  };

  return {
    loadCookie,
    loadCookies,
    loadCookieObj,
    saveCookie,
    saveCookies,
    removeCookie,
    removeCookies,
  };
};

export default useCookieAccess;
