import { baseApi } from "store/baseStoreAPI";
import { BackendDataShape } from "types";
import { ResponseTemplate, TTemplate, TTemplateWithId } from "types/template";

const subEndpoint = "/template-soap";

export const templateSoapApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateSoap: builder.query<
      ResponseTemplate,
      {
        page?: number;
        take?: number;
        query?: string;
      }
    >({
      query: ({ page = 1, take = 10, ...args }) => {
        return {
          url: `${subEndpoint}`,
          method: "GET",
          params: {
            page,
            take,
            ...args,
          },
        };
      },
      providesTags: ["Template"],
      transformResponse: (res: BackendDataShape<ResponseTemplate>) => {
        const { entities, meta } = res.data;

        return {
          entities: entities.map((item: TTemplateWithId) => ({
            id: item.id,
            name: item.name,
            subjective: item.subjective,
            objective: item.objective,
            assessment: item.assessment,
            plan: item.plan,
            clinic_id: item.clinic_id,
          })),
          meta: meta,
        };
      },
    }),

    getAllTemplateSoap: builder.query<
      TTemplateWithId[],
      {
        getAll: boolean;
      }
    >({
      query: ({ getAll = true }) => {
        return {
          url: `${subEndpoint}`,
          method: "GET",
          params: {
            getAll,
          },
        };
      },
      providesTags: ["Template"],
      transformResponse: (res: BackendDataShape<TTemplateWithId[]>) => {
        return res.data.map((item: TTemplateWithId) => ({
          id: item.id,
          name: item.name,
          subjective: item.subjective,
          objective: item.objective,
          assessment: item.assessment,
          plan: item.plan,
          clinic_id: item.clinic_id,
        }));
      },
    }),

    getTemplateSoapById: builder.query<
      TTemplate,
      {
        id: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `${subEndpoint}/${id}`,
          method: "GET",
        };
      },
    }),

    createTemplateSoap: builder.mutation<BackendDataShape<any>, TTemplate>({
      query: (args) => {
        return {
          url: `${subEndpoint}`,
          method: "POST",
          body: args,
        };
      },
      invalidatesTags: ["Template"],
    }),

    updateTemplateSoap: builder.mutation<
      BackendDataShape<any>,
      {
        id: string;
      } & TTemplate
    >({
      query: ({ id, ...args }) => {
        return {
          url: `${subEndpoint}/${id}`,
          method: "PATCH",
          body: args,
        };
      },
      invalidatesTags: ["Template"],
    }),

    deleteTemplateSoap: builder.mutation<BackendDataShape<any>, { id: string }>(
      {
        query: ({ id }) => {
          return {
            url: `${subEndpoint}/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["Template"],
      },
    ),
  }),
  overrideExisting: false,
});

export const {
  useGetTemplateSoapQuery,
  useGetAllTemplateSoapQuery,
  useGetTemplateSoapByIdQuery,
  useCreateTemplateSoapMutation,
  useUpdateTemplateSoapMutation,
  useDeleteTemplateSoapMutation,
  util: { resetApiState: resetTemplateSoapApi },
  endpoints: {
    getTemplateSoap,
    getAllTemplateSoap,
    getTemplateSoapById,
    createTemplateSoap,
    updateTemplateSoap,
    deleteTemplateSoap,
  },
} = templateSoapApi;
