import { add } from "date-fns";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import CookieMonster from "config/CookieMonster";
import { BaseRoute } from "config/nav";
import { letUserLogin } from "api/auth";
import { LoginObj } from "types/auth";
import { useAppDispatch } from "hooks";
import { resetPatientStoreAPI } from "store/patientStoreAPI";
import { resetEncounterStoreAPI } from "store/encounterStoreAPI";
import { resetClinicStoreAPI } from "store/clinicStoreAPI";
import { resetActiveEncounter } from "store/activeEncounterOptStore";
import { resetSOAP } from "store/form/soapFormStore";
import { resetVital } from "store/form/vitalFormStore";
import { resetAccountStoreAPI } from "store/accountsStoreAPI";
import { resetObatStoreAPI } from "store/obatStoreAPI";
import { resetUserProfileStore } from "store/userProfileStoreAPI";
import { resetPharmacyStoreAPI } from "store/pharmacyStoreAPI";
import { resetBpjsStoreAPI } from "store/bpjsAPI";
import { resetSatuSehatStoreAPI } from "store/satuSehatStoreAPI";
import { resetQueue } from "store/queueSlice/queueSlice";
import { resetAnalyticsAPI } from "store/analyticsStoreAPI";
import { resetPaymentStoreApi } from "store/paymentStoreAPI";
import { resetReportApi } from "store/reportStoreAPI";
import { resetTemplateSoapApi } from "store/templateStoreAPI";
import { resetReport } from "store/reportSlice";

import CustomToast from "components/CustomToast";

export const useClearAllCache = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(resetAccountStoreAPI());
    dispatch(resetActiveEncounter());
    dispatch(resetClinicStoreAPI());
    // let it be for diagnoses
    // dispatch(resetDiagnosisAPI());
    dispatch(resetEncounterStoreAPI());
    dispatch(resetObatStoreAPI());
    dispatch(resetPatientStoreAPI());
    dispatch(resetPharmacyStoreAPI());
    dispatch(resetUserProfileStore());
    dispatch(resetBpjsStoreAPI());
    dispatch(resetSatuSehatStoreAPI());
    dispatch(resetSOAP());
    dispatch(resetVital());
    dispatch(resetQueue());
    dispatch(resetAnalyticsAPI());
    dispatch(resetPaymentStoreApi());
    dispatch(resetReportApi());
    dispatch(resetTemplateSoapApi());
    dispatch(resetReport());
  };
};

/**
 * TODO Login:
 * - remember_me ?
 * - login by jwt to refresh token ?
 * - exp time ?
 */
export const useLogin = () => {
  return async (obj: LoginObj) => {
    try {
      const retObj = await letUserLogin(obj);
      const {
        data: {
          data: {
            username,
            roles,
            id,
            is_bpjs_configured,
            is_satu_sehat_configured,
          },
          access_token,
        },
      } = retObj;
      // const { exp, token } = user;

      CookieMonster.saveCookies(
        {
          access_token,
          username,
          roles,
          id,
          is_bpjs_configured,
          is_satu_sehat_configured,
        },
        { expires: add(new Date(), { days: 1 }) },
      );
      // return user;
      return retObj;
      // return data;
    } catch (e) {
      const isAxiosError = (e: any): e is AxiosError => {
        return !!(e as AxiosError)?.isAxiosError;
      };
      let cause = "unknown error";
      if (isAxiosError(e)) {
        const { response } = e;
        if (response?.status === 401) {
          cause = "Password salah";
        } else if (response?.status === 404) {
          cause = "Username tidak ditemukan";
        } else {
          cause = response?.statusText ?? cause;
        }
        // console.log({ response });
      } else {
        console.error(cause);
      }
      throw new Error(`Gagal Login: ${cause}`);
    }
  };
};

export const useLogout = (shouldNavigate = true) => {
  const clearAllCache = useClearAllCache();
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // all routes should be based on /
  const ROUTES = new BaseRoute("");
  return () => {
    toast.dismiss("ask_for_doctor");
    // Samakan cooke yg diremove dengan cookie yg diSave
    CookieMonster.removeCookies(
      [
        "access_token",
        "username",
        "roles",
        "id",
        "is_bpjs_configured",
        "is_satu_sehat_configured",
      ],
      // { expires: new Date(exp * 1000) },
    );
    clearAllCache();
    shouldNavigate && navigate(ROUTES.login_page);
    toast.custom(
      (t) => (
        <CustomToast
          t={t}
          headerText="Berhasil!"
          description={"Berhasil keluar. Sampai jumpa lagi."}
          status="success"
        />
      ),
      {
        id: "logout",
        position: "bottom-right",
      },
    );
    localStorage.clear();
  };
};
