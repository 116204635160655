import { useAppSelector } from "hooks";
import TableKunjungan from "Pages/Report/components/ReportTables/TableKunjungan";
import TableTransaksi from "Pages/Report/components/ReportTables/TableTransaksi";
import TableObat from "Pages/Report/components/ReportTables/TableObat";
import TablePasien from "Pages/Report/components/ReportTables/TablePasien";
import TableDokter from "Pages/Report/components/ReportTables/TableDokter";
import TablePoliklinik from "Pages/Report/components/ReportTables/TablePoliklinik";
import TableTindakan from "Pages/Report/components/ReportTables/TableTindakan";
import TableTransaksiFarmasi from "Pages/Report/components/ReportTables/TableTransaksiFarmasi";
import TableTransaksiByPoli from "Pages/Report/components/ReportTables/TableTransaksiByPoli";
import TableTransaksiByDokter from "Pages/Report/components/ReportTables/TableTransaksiByDokter";
import TableTransaksiByPasien from "Pages/Report/components/ReportTables/TableTransaksiByPasien";

const ReportTable = () => {
  const { reportType } = useAppSelector((state) => state.reportSlice);

  switch (reportType) {
    case "report-kunjungan":
      return <TableKunjungan />;
    case "report-transaksi":
      return <TableTransaksi />;
    case "report-obat":
      return <TableObat />;
    case "report-pasien-list":
      return <TablePasien />;
    case "report-dokter-list":
      return <TableDokter />;
    case "report-poli-list":
      return <TablePoliklinik />;
    case "report-tindakan-list":
      return <TableTindakan />;
    case "report-transaksi-farmasi":
      return <TableTransaksiFarmasi />;
    case "report-transaksi-by-poli":
      return <TableTransaksiByPoli />;
    case "report-transaksi-by-dokter":
      return <TableTransaksiByDokter />;
    case "report-transaksi-by-pasien":
      return <TableTransaksiByPasien />;
    default:
      return <div>Please select report type</div>;
  }
};

export default ReportTable;
