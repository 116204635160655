import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UsedAPI } from "api";
import {
  SignupRequest,
  SignupRequestDoctor,
  SignupResponse,
} from "types/api/signup";
import CookieMonster from "config/CookieMonster";

export const signupStoreAPI = createApi({
  reducerPath: "signupAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/signup`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => {
    return {
      signup: builder.mutation<SignupResponse, SignupRequest>({
        query: (body) => {
          return {
            url: "",
            method: "POST",
            body,
          };
        },
      }),
      signupDoctor: builder.mutation<unknown, SignupRequestDoctor>({
        query: (body) => {
          return {
            url: "/profile",
            method: "POST",
            body,
          };
        },
      }),
    };
  },
});

export const { useSignupMutation, useSignupDoctorMutation } = signupStoreAPI;
