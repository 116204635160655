import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BasicSelectOpt, Nullable } from "types";
import { SOAPFormKeys } from "types/periksa";
import { MultiValue } from "react-select";
import { AProcedureOpt } from "types/api/procedures";

export type SOAPFormState = {
  /**
   * Vital form id
   */
  id?: Nullable<number>;
} & {
  [key in Exclude<SOAPFormKeys, "diagnosis_multi">]?: Nullable<string>;
} & {
  diagnosis_multi: MultiValue<BasicSelectOpt>;
  tagihan_multi: MultiValue<AProcedureOpt>;
};

const initialState: SOAPFormState = {
  diagnosis_multi: [],
  tagihan_multi: [],
};

const SOAPFormSlice = createSlice({
  name: "soapform_slice",
  initialState,
  reducers: {
    setSOAPFormState: (
      state,
      action: PayloadAction<{
        id: number;
        name: Exclude<SOAPFormKeys, "diagnosis_multi">;
        value: string;
      }>,
    ) => {
      const p = action.payload;
      state.id = p.id;
      state[p.name] = p.value;
    },

    setDiagnosisMulti: (
      state,
      action: PayloadAction<{
        id: number;
        name: "diagnosis_multi";
        value: MultiValue<BasicSelectOpt | null>;
      }>,
    ) => {
      const { payload: p } = action;
      // @ts-ignore
      state[p.name] = p.value;
    },

    setTagihanMulti: (
      state,
      action: PayloadAction<{
        id: number;
        name: "tagihan_multi";
        value: MultiValue<AProcedureOpt | null>;
      }>,
    ) => {
      const { payload: p } = action;
      // @ts-ignore
      state[p.name] = p.value;
    },

    // @ts-ignore
    setSOAPAll: (state, action: PayloadAction<SOAPFormState>) => {
      const { id, ...p } = action.payload;
      state.id = id;
      for (const [k, v] of Object.entries(p)) {
        const key = k as SOAPFormKeys | "diagnosis_multi";
        switch (key) {
          case "diagnosis_multi": {
            const val = v as MultiValue<BasicSelectOpt | null>;
            // @ts-ignore
            state[key] = val;
            break;
          }
          default:
            {
              const val = v as string | null;
              if (val || val === null) {
                state[key] = val;
              }
            }
            break;
        }
      }
      // console.log({ p: action.payload });
      // state = { ...state, ...action.payload };
    },
    // @ts-ignore
    resetSOAP: (state) => {
      return initialState;
    },
  },
});

export default SOAPFormSlice.reducer;
export const {
  setSOAPFormState,
  setDiagnosisMulti,
  setTagihanMulti,
  setSOAPAll,
  resetSOAP,
} = SOAPFormSlice.actions;
