import styled from "styled-components";
import { COLORS, extraLarge, medium, small } from "assets/theme";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  ${extraLarge} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${medium} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${small} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 14px;
    margin: 0;
    color: ${COLORS.dark_grey_1};
  }
`;

export const CardIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.yellow {
    background: ${COLORS.subtle_yellow};
  }

  &.pink {
    background: ${COLORS.subtle_pink};
  }

  &.blue {
    background: ${COLORS.subtle_blue};
  }

  svg {
    width: 24px;
    height: 24px;
  }

  ${extraLarge} {
    width: 30px;
    height: 30px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const PatientCount = styled.span`
  font-size: 40px;
  font-weight: 600;

  ${extraLarge} {
    font-size: 30px;
  }

  ${small} {
    font-size: 24px;
  }
`;

export const ChangesCount = styled.span`
  font-size: 14px;

  p {
    margin: 0;
  }

  span {
    padding-right: 4px;
  }

  .minus,
  .plus {
    align-items: center;
    color: ${COLORS.red};
  }

  .plus {
    color: ${COLORS.green};
  }

  svg {
    margin-bottom: 5px;
  }
`;
