import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  AClinicBase,
  AClinicWIthId,
  APolyclinicForm,
  APolyclinicWithId,
  APolyclinicWithPractitioner,
} from "types/api/clinic";
import {
  BackendDataPageShape,
  BackendDataShape,
  BackendMetaPagination,
  PaginationQueryParams,
} from "types";
import {
  ABpjsCredentialBase,
  ABpjsCredentialWithId,
  ABpjsResponse,
} from "types/api/bpjs_credential";
import {
  ASatusehatRequest,
  ASatusehatResponse,
  Locations,
} from "types/api/satusehat_credential";

import { convertToEncodedURL } from "assets/usefulFunctions";

import { UsedAPI } from "api";

import CookieMonster from "config/CookieMonster";

export const clinicStoreAPI = createApi({
  reducerPath: "clinicStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/organizations`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "practitioner",
    "polyclinic",
    "bpjscredential",
    "clinic",
    "satusehatcredential",
  ],
  endpoints: (builder) => {
    return {
      getPolyclinic: builder.query<
        {
          data: (APolyclinicWithId & { no: number })[];
          meta: BackendMetaPagination;
        },
        PaginationQueryParams
      >({
        query: (args) => {
          const { page = 1, take = 10, ...rest } = args;

          return {
            url: "/polyclinic",
            method: "GET",
            params: {
              ...rest,
              page,
              take,
            },
          };
        },
        providesTags: ["polyclinic"],
        /**
         * @desc /polyclinic can't accept query by id, filtering is removed
         */
        transformResponse: (res: BackendDataPageShape<APolyclinicWithId[]>) => {
          return {
            data: res.data.entities.map((polyclinic, idx) => ({
              ...polyclinic,
              no: idx + 1,
            })),
            meta: res.data.meta,
          };
        },
      }),

      getPractitionerInPoly: builder.query<
        (APolyclinicWithPractitioner & { no: number })[],
        {
          isBpjs?: boolean;
          isActive?: boolean;
        }
      >({
        query: ({ isBpjs, isActive }) => {
          return {
            url: "/polyclinic/practitioners",
            method: "GET",
            params: {
              isBpjs,
              isActive,
            },
          };
        },
        providesTags: ["practitioner"],
        transformResponse: (
          res: BackendDataShape<APolyclinicWithPractitioner[]>,
        ) => {
          const filteredRes = res.data;
          return filteredRes.map((practitioner, idx) => {
            const practitioners = practitioner.userAccounts.filter(
              (v) => !!v.id,
            );
            return { ...practitioner, practitioners, no: idx + 1 };
          });
        },
      }),

      getBpjsCredential: builder.query<
        BackendDataShape<ABpjsCredentialWithId>,
        void
      >({
        query: () => {
          return {
            url: `/clinic/bpjs`,
            method: "GET",
          };
        },
        providesTags: ["bpjscredential"],
      }),

      createPolyclinic: builder.mutation<
        BackendDataShape<Pick<APolyclinicWithId, "id">>,
        APolyclinicForm
      >({
        query: (obj) => {
          return {
            url: `/polyclinic`,
            method: "POST",
            body: obj,
          };
        },
        /**
         * @todo refactor this soon
         */
        invalidatesTags: ["polyclinic", "practitioner"],
      }),

      updatePolyclinic: builder.mutation<
        unknown,
        APolyclinicForm & {
          id: number;
        }
      >({
        query: ({ id, ...obj }) => {
          return {
            url: `/polyclinic/${id}`,
            method: "PATCH",
            body: {
              ...obj,
            },
          };
        },
        invalidatesTags: ["polyclinic", "practitioner"],
      }),

      deletePolyclinic: builder.mutation<unknown, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/polyclinic/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["polyclinic", "practitioner"],
      }),

      createBpjsCredential: builder.mutation<
        BackendDataShape<ABpjsResponse>,
        ABpjsCredentialBase
      >({
        query: (obj) => {
          return {
            url: `/clinic/bpjs`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["bpjscredential"],
      }),

      updateBpjsCredential: builder.mutation<
        BackendDataShape<null>,
        ABpjsCredentialBase
      >({
        query: (obj) => {
          return {
            url: `/clinic/bpjs`,
            method: "PATCH",
            body: obj,
          };
        },
        invalidatesTags: ["bpjscredential"],
      }),

      getSatusehatCredential: builder.query<
        BackendDataShape<ASatusehatResponse>,
        void
      >({
        query: () => {
          return {
            url: `/clinic/satu-sehat`,
            method: "GET",
          };
        },
        providesTags: ["satusehatcredential"],
      }),

      createSatusehatCredential: builder.mutation<
        BackendDataShape<{ id: number }>,
        ASatusehatRequest
      >({
        query: (obj) => {
          return {
            url: `/clinic/satu-sehat`,
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["satusehatcredential"],
      }),

      updateSatusehatCredential: builder.mutation<
        BackendDataShape<null>,
        ASatusehatRequest
      >({
        query: (obj) => {
          return {
            url: `/clinic/satu-sehat`,
            method: "PATCH",
            body: obj,
          };
        },
        invalidatesTags: ["satusehatcredential"],
      }),

      testSatusehatConnection: builder.mutation<
        BackendDataShape<null>,
        Omit<ASatusehatRequest, "location_id">
      >({
        query: (obj) => {
          return {
            url: `/clinic/satu-sehat/test-connection`,
            method: "POST",
            body: obj,
          };
        },
      }),

      getSatusehatLocationId: builder.mutation<
        BackendDataShape<Locations>,
        Omit<ASatusehatRequest, "location_id"> | void
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: `/clinic/satu-sehat/locations${params}`,
            method: "GET",
          };
        },
      }),

      getClinicLogo: builder.query<string, void>({
        query: () => {
          return {
            url: `/clinic/logo`,
            method: "GET",
            responseHandler: async (response) => {
              if (response.ok) {
                const blob = await response.blob();
                const objectUrl = URL.createObjectURL(blob);
                return objectUrl;
              } else {
                throw new Error("Network response was not ok");
              }
            },
            cache: "no-cache",
          };
        },
      }),

      createClinicLogo: builder.mutation<BackendDataShape<null>, FormData>({
        query: (file) => {
          return {
            url: `/clinic/logo`,
            method: "POST",
            body: file,
          };
        },
      }),

      getClinic: builder.query<AClinicWIthId, void>({
        query: () => {
          return {
            url: "/clinic",
            method: "GET",
          };
        },
        providesTags: ["clinic"],
        transformResponse: (res: BackendDataShape<AClinicWIthId>) => {
          return res?.data;
        },
      }),

      updateClinic: builder.mutation<
        BackendDataShape<null>,
        Partial<AClinicBase>
      >({
        query: (obj) => {
          return {
            url: `/clinic`,
            method: "PATCH",
            body: obj,
          };
        },
        invalidatesTags: ["clinic"],
      }),
    };
  },
});

export const {
  useGetPractitionerInPolyQuery,
  useGetPolyclinicQuery,
  useGetBpjsCredentialQuery,
  useCreateBpjsCredentialMutation,
  useCreatePolyclinicMutation,
  useUpdatePolyclinicMutation,
  useDeletePolyclinicMutation,
  useUpdateBpjsCredentialMutation,
  useGetClinicQuery,
  useGetSatusehatCredentialQuery,
  useCreateSatusehatCredentialMutation,
  useUpdateSatusehatCredentialMutation,
  useGetSatusehatLocationIdMutation,
  useTestSatusehatConnectionMutation,
  useGetClinicLogoQuery,
  useCreateClinicLogoMutation,
  useUpdateClinicMutation,
  util: { resetApiState: resetClinicStoreAPI },
} = clinicStoreAPI;
