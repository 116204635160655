import React, {
  CSSProperties,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  MutableRefObject,
  ReactNode,
} from "react";
import styled from "styled-components";
import { COLORS } from "assets/theme";

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: CSSProperties;
  label?: string;
  labelColor?: string;
  labelFontSize?: string;
  className?: string;
  elementAddonRight?: ReactNode;
  elementAddonLeft?: ReactNode;
  elementAddonHelper?: ReactNode;
}

export const StyledLabel = styled.label<{
  $labelColor?: string;
  labelFontSize?: string;
}>`
  font-size: ${(props) =>
    props.labelFontSize ? props.labelFontSize : "inherit"};
  color: ${(props) => (props.$labelColor ? props.$labelColor : COLORS.black_1)};

  .required-star {
    color: ${COLORS.red};
  }
`;
export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupLabel = styled.label`
  /* margin-top: 0; */
  /* background-color: wheat;
  margin: 0; */
  font-weight: 600;
  font-size: 1rem;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  /* margin-bottom: 16px; */
  & > input:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & > input:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  // &:focus {
  //   outline: 1px solid ${COLORS.green};
  //   outline-offset: -1px;
  // }
`;

const StyledLeftAddonElement = styled.div`
  /* position: absolute;
  left: 0;
  top: 0;
  bottom: 0; */
  display: flex;
  align-items: center;
  padding: 0 8px;
  z-index: 1;
  /* box-sizing: content-box; */
`;

const StyledInput = styled.input`
  min-height: 36px;
  border-radius: 6px;
  border: 1px solid ${COLORS.text_gray};
  // &:focus {
  //   border: 1px solid ${COLORS.blue_1};
  //   box-shadow: 0 0 0 1px ${COLORS.blue_1};
  // }
  padding: 0 8px;
  color: ${COLORS.black_1};
  background: ${COLORS.white};
  width: 100%;
  ::placeholder {
    color: ${COLORS.text_gray};
  }
  box-sizing: border-box; /* Include padding and border in the width calculation */
`;
const StyledRightAddonElement = styled.div`
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0; */
  display: flex;
  align-items: center;
  padding: 0 8px 0 8px;
  z-index: 1;
  /* box-sizing: content-box; */
`;

const InputForm: ForwardRefRenderFunction<
  HTMLInputElement | null,
  InputTextProps
> = (props, ref) => {
  const {
    containerStyle,
    label,
    labelColor,
    elementAddonLeft,
    labelFontSize,
    elementAddonRight,
    className,
    name,
    elementAddonHelper,
    ...rest
  } = props;

  const id =
    name || `input-text-${Math.random().toString(36).substring(2, 15)}`;

  return (
    <StyledInputWrapper className={className} style={containerStyle}>
      {label && (
        <StyledLabel
          htmlFor={id}
          $labelColor={labelColor}
          labelFontSize={labelFontSize}
        >
          {label}
          {rest.required ? (
            <>
              {" "}
              <span className="required-star">*</span>
            </>
          ) : null}
        </StyledLabel>
      )}
      <InputWrapper
        className={["wrapper", props.disabled ? "disabled" : ""]
          .filter((v) => !!v)
          .join(" ")}
      >
        {elementAddonLeft && (
          <StyledLeftAddonElement className="left-addon">
            {elementAddonLeft}
          </StyledLeftAddonElement>
        )}
        <StyledInput id={id} ref={ref} name={name} {...rest} />
        {elementAddonRight && (
          <StyledRightAddonElement className="right-addon">
            {elementAddonRight}
          </StyledRightAddonElement>
        )}
      </InputWrapper>
      {elementAddonHelper}
    </StyledInputWrapper>
  );
};

const InputText = React.forwardRef(InputForm) as (
  p: InputTextProps & {
    ref?: MutableRefObject<HTMLInputElement | null>;
  },
) => React.ReactElement;

export default InputText;
