import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { OrderDetailRequest, OrderDetailResponse } from "types/api/payment";

export const paymentStoreApi = createApi({
  reducerPath: "paymentStoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/payment-gateway`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["payment"],
  endpoints: (builder) => {
    return {
      postPaymentToken: builder.mutation<
        OrderDetailResponse,
        OrderDetailRequest
      >({
        query: (obj) => {
          return {
            url: "",
            method: "POST",
            body: {
              ...obj,
            },
          };
        },
        invalidatesTags: ["payment"],
      }),
    };
  },
});

export const {
  usePostPaymentTokenMutation,
  util: { resetApiState: resetPaymentStoreApi },
} = paymentStoreApi;
