import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { startOfDay, endOfDay } from "date-fns";
import { BiSearch } from "react-icons/bi";

import CookieMonster from "config/CookieMonster";
import { combinedRoutes } from "config/nav";
import { Role } from "types/roles";
import { QueueCategory, QueueType, queueTypeOpt, tabKey } from "types/queue";
import { AnEncounterWithId } from "types/api/encounter";
import { RecipeStatusEnum } from "types/api/transactions";
import {
  setEndDate,
  setJenisAntrian,
  setStartDate,
} from "store/queueSlice/queueSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import useEncounterList from "hooks/encounter/useEncounterList";
import usePolyclinicOptions from "hooks/polyclinic/usePolyclinicOptions";
import usePractitionerOptions from "hooks/practitioner/usePractitionerOptions";

import { BasicHeader } from "components/base";
import { BaseModalRefined } from "components/base/Modal";
import { useModal } from "components/ModalRefined/ModalRefined";
import RangedDatePicker from "components/RangedDatepicker/RangedDatepicker";
import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import QueueUpdateHOC from "components/HOC/QueueUpdateHOC";
// import AddAntrianForm from "Pages/Admin/components/AntrianForms/AddAntrianForm";
import {
  ActionButton,
  ActionContainer,
  // AddEncounterModal,
  CounterTag,
  EmptyQueueText,
  HeaderContainer,
  Main,
  PatientContainer,
  SearchInput,
  TabBtn,
  TabContainer,
} from "Pages/Queue/components";
import PatientQueue from "Pages/Queue/components/PatientQueue";
import SubsReminder from "./components/SubsReminder/SubsReminder";
import AddQueueForm from "./components/AddQueueForm/AddQueueForm";
import DeleteQueueForm from "./components/DeleteQueueForm/DeleteQueueForm";

const routes = combinedRoutes;

type Props = {
  queueCategory: QueueCategory;
};

const QueuePage = ({ queueCategory }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    startDate: rStartDate,
    endDate: rEndDate,
    jenisAntrian: rJenisAntrian,
  } = useAppSelector((state) => state.queueSlice);

  const globalStartDate = useMemo(() => {
    return rStartDate ? new Date(rStartDate) : null;
  }, [rStartDate]);

  const globalEndDate = useMemo(() => {
    return rEndDate ? new Date(rEndDate) : null;
  }, [rEndDate]);

  const searchRef = useRef<HTMLInputElement | null>(null);
  const [param, setParam] = useSearchParams();
  const [search, setSearch] = useState(param.get("q") ?? "");
  const [filterPolyclinic, setFilterPolyclinic] = useState<number>(-1);
  const [filterPractitioner, setFilterPractitioner] = useState<number>(-1);
  const [selectedPatient, setSelectedPatient] =
    useState<AnEncounterWithId | null>(null);

  // const { value: showAddModalOld, toggle: toggleAddModalOld } =
  //   useToggle(false);
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal } =
    useModal();
  const { isOpen: isAddModalOpen, toggleModal: toggleAddModal } = useModal();

  const tabs: tabKey[] = ["berjalan", "selesai"];
  const tab = param.get("tab");

  const { list: polyclinicOpt, isLoading: isLoadingPolyclinic } =
    usePolyclinicOptions({ page: 1, take: 100, skip: false, allowAll: true });
  const { list: practitionerOpt, isLoading: isLoadingPractitioner } =
    usePractitionerOptions({ allowAll: true });

  const { list: queues, isLoading: isEncounterLoading } = useEncounterList(
    false,
    {
      start: globalStartDate,
      end: globalEndDate,
    },
  );

  const { roles, username } = CookieMonster.loadCookies(["roles", "username"]);
  const currentLoggedUser = username as string;
  const rolesArray = roles as Role[];
  const isRolePractitionerOnly =
    rolesArray?.length < 2 && rolesArray[0] === "practitioner";
  // const isRolePractitionerOnly = (roles as Role[])?.includes("practitioner");

  const { pendingAntrian, finishedAntrian } = useMemo(() => {
    // Split queues into pending and finished
    const [pendingAntrian, finishedAntrian] = queues.reduce(
      (
        acc: [AnEncounterWithId[], AnEncounterWithId[]],
        queue: AnEncounterWithId,
      ) => {
        // // Filter based on the associated practitioner
        if (
          isRolePractitionerOnly &&
          queue.practitioner.username.localeCompare(currentLoggedUser) !== 0
        ) {
          return acc;
        }

        if (
          filterPolyclinic !== -1 &&
          queue.polyclinic?.id !== filterPolyclinic
        ) {
          return acc;
        }

        if (
          filterPractitioner !== -1 &&
          queue.doctor_id !== filterPractitioner
        ) {
          return acc;
        }

        switch (queueCategory) {
          case "obat":
            if (queue.medicalRecord && queue.medicalRecord.medrecTrx) {
              switch (queue.medicalRecord.medrecTrx.recipe_status) {
                case RecipeStatusEnum.QUEUE:
                case RecipeStatusEnum.WIP:
                case null:
                  acc[0].push(queue);
                  break;
                case RecipeStatusEnum.DONE:
                default:
                  acc[1].push(queue);
                  break;
              }
            }
            return acc;

          case "kasir":
            if (queue.medicalRecord && queue.medicalRecord.medrecTrx) {
              switch (queue.medicalRecord.medrecTrx.recipe_status) {
                case RecipeStatusEnum.QUEUE:
                  acc[0].push(queue);
                  break;
                case RecipeStatusEnum.WIP:
                case RecipeStatusEnum.DONE:
                default:
                  acc[1].push(queue);
                  break;
                case null:
                  break;
              }
            }
            return acc;

          case "pasien":
          default:
            if (
              !queue.medicalRecord ||
              (!queue.initialInspection && !queue.antenatal)
            ) {
              acc[0].push(queue);
            } else {
              acc[1].push(queue);
            }
            return acc;
        }
      },
      [[], []],
    ) || [[], []];

    return {
      pendingAntrian,
      finishedAntrian,
    };
  }, [
    queues,
    queueCategory,
    filterPolyclinic,
    filterPractitioner,
    isRolePractitionerOnly,
    currentLoggedUser,
  ]);

  const filterQueues = useCallback(
    (queues: AnEncounterWithId[]) => {
      const s = search.toLowerCase();

      return queues?.filter((queue: AnEncounterWithId) => {
        const patient = queue.patient;
        const practitioner = queue.practitioner;
        const polyclinic = queue.polyclinic;
        const enId = queue.id?.toString();
        const queueId = queue.queue?.toString();
        let includeBasedOnJenisAntrian = false;

        switch (rJenisAntrian) {
          case "all":
            includeBasedOnJenisAntrian = true;
            break;
          case "umum":
            includeBasedOnJenisAntrian = !queue.is_bpjs;
            break;
          case "bpjs":
            includeBasedOnJenisAntrian = queue.is_bpjs;
            break;
          default:
            break;
        }

        if (!includeBasedOnJenisAntrian) return false;
        if (!s.length) return true;
        if (enId?.includes(s)) return true;
        if (queueId?.includes(s)) return true;
        if (practitioner?.userProfile?.first_name?.toLowerCase().includes(s))
          return true;
        if (polyclinic?.polyclinic_name?.toLowerCase().includes(s)) return true;
        if (patient?.full_name?.toLowerCase().includes(s)) return true;
        if (patient?.nik?.split("-").join("").includes(s)) return true;
        if (patient?.bpjs_no?.includes(s)) return true;
        if (patient?.encounter?.toLowerCase().includes(s)) return true;
        return false;
      });
    },
    [search, rJenisAntrian],
  );

  const filteredFinishedAntrianLength =
    filterQueues(finishedAntrian)?.length || 0;

  const filteredPendingAntrianLength =
    filterQueues(pendingAntrian)?.length || 0;

  const combinedAntrianLength =
    filteredFinishedAntrianLength + filteredPendingAntrianLength;

  const patientList = useMemo(() => {
    const patientQueues = tab === "selesai" ? finishedAntrian : pendingAntrian;
    return filterQueues(patientQueues);
  }, [tab, pendingAntrian, finishedAntrian, filterQueues]);

  const onPressTab = (tab: tabKey) => {
    const newParam = new URLSearchParams(param.toString());
    newParam.set("tab", tab);
    setParam(newParam);
  };

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    // setStartDate(startDate);
    // setEndDate(endDate);
    let convertedStartDate = startDate?.toISOString()
      ? startDate.toISOString()
      : startOfDay(new Date()).toISOString();
    let convertedEndDate = endDate
      ? endDate.toISOString()
      : endOfDay(new Date()).toISOString();

    dispatch(setStartDate(convertedStartDate));
    dispatch(setEndDate(convertedEndDate));
  };

  useEffect(() => searchRef.current?.focus(), [location]);

  useEffect(() => {
    if (!param.get("tab")) {
      const newParam = new URLSearchParams(param.toString());
      newParam.set("tab", tabs[0]);
      setParam(newParam, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param, setParam]);

  const renderTitle = () => {
    switch (queueCategory) {
      case "pasien":
        return "Pasien";
      case "obat":
        return "Farmasi";
      case "kasir":
        return "Pembayaran";
      default:
        return null;
    }
  };

  const renderQueueHOC = () => {
    switch (queueCategory) {
      case "pasien":
        return <QueueUpdateHOC activeOnRoute={combinedRoutes.antrian_pasien} />;
      case "obat":
        return (
          <QueueUpdateHOC activeOnRoute={combinedRoutes.antrian_farmasi} />
        );
      case "kasir":
        return <QueueUpdateHOC activeOnRoute={combinedRoutes.antrian_kasir} />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderQueueHOC()}

      <SubsReminder />

      <BaseModalRefined
        isOpen={isDeleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        header="Konfirmasi"
        size="sm"
      >
        <DeleteQueueForm
          toggle={toggleDeleteModal}
          selectedPatient={selectedPatient}
        />
      </BaseModalRefined>

      <BaseModalRefined
        isOpen={isAddModalOpen}
        onClose={() => toggleAddModal(false)}
        header="Tambah Antrian"
        headerDivider={true}
        size="xl"
      >
        <AddQueueForm toggle={toggleAddModal} />
      </BaseModalRefined>

      {/* <AddEncounterModal
        headerText="Tambah Antrian"
        isShown={showAddModalOld}
        toggle={toggleAddModalOld}
      >
        <AddAntrianForm toggle={toggleAddModalOld} />
      </AddEncounterModal> */}

      <Main>
        <HeaderContainer>
          <BasicHeader>Antrian {renderTitle()}</BasicHeader>
        </HeaderContainer>

        <ActionContainer>
          <SearchInput
            ref={searchRef}
            placeholder="Cari pasien dalam antrian..."
            elementAddonRight={<BiSearch />}
            value={search}
            onChange={(e) => {
              const v = e.target.value;
              if (v.length) {
                param.set("q", v);
              } else {
                param.delete("q");
              }
              setParam(param, { replace: true });
              setSearch(v);
            }}
          />
          {queueCategory === "pasien" && (
            <>
              <ActionButton onClick={() => navigate(routes.management_patient)}>
                Daftar Pasien
              </ActionButton>
              <ActionButton
                className="add-antrian"
                onClick={() => toggleAddModal(true)}
              >
                + Antrian
              </ActionButton>
              {/* <ActionButton
                className="add-antrian"
                onClick={() => toggleAddModalOld(true)}
              >
                + Antrian
              </ActionButton> */}
            </>
          )}
        </ActionContainer>

        <ActionContainer className="filter-section">
          <RangedDatePicker
            label=""
            fullWidth
            onDateChange={handleDateChange}
            startDate={globalStartDate}
            endDate={globalEndDate}
          />
          <PureInputSelect
            label=""
            options={queueTypeOpt}
            defaultValue={queueTypeOpt.find((v) => v.value === rJenisAntrian)}
            onChange={(e) =>
              e && dispatch(setJenisAntrian(e.value as QueueType))
            }
          />
          <PureInputSelect
            label=""
            placeholder="Pilih Poliklinik"
            options={polyclinicOpt}
            isLoading={isLoadingPolyclinic}
            isDisabled={isRolePractitionerOnly}
            defaultValue={polyclinicOpt.find(
              (v) => v.value === filterPolyclinic,
            )}
            onChange={(e) =>
              e ? setFilterPolyclinic(e.value) : setFilterPolyclinic(-1)
            }
          />
          <PureInputSelect
            label=""
            placeholder="Pilih Dokter"
            options={practitionerOpt}
            isLoading={isLoadingPractitioner}
            isDisabled={isRolePractitionerOnly}
            defaultValue={practitionerOpt.find(
              (v) => v.value === filterPractitioner,
            )}
            onChange={(e) =>
              e ? setFilterPractitioner(e.value) : setFilterPractitioner(-1)
            }
          />
        </ActionContainer>

        <TabContainer>
          <TabBtn
            name="berjalan"
            className={`tab-berjalan ${tab === tabs[0] ? "active" : ""}`}
            onClick={() => onPressTab("berjalan")}
          >
            Antrian Berjalan{" "}
            <CounterTag>
              {filteredPendingAntrianLength + " / " + combinedAntrianLength}
            </CounterTag>
          </TabBtn>
          <TabBtn
            name="selesai"
            className={`tab-selesai ${tab === tabs[1] ? "active" : ""}`}
            onClick={() => onPressTab("selesai")}
          >
            Antrian Selesai{" "}
            <CounterTag>
              {filteredFinishedAntrianLength + " / " + combinedAntrianLength}
            </CounterTag>
          </TabBtn>
        </TabContainer>

        <PatientContainer>
          {isEncounterLoading ? (
            <EmptyQueueText>"Fetching antrian..."</EmptyQueueText>
          ) : patientList.length ? (
            <PatientQueue
              queueCategory={queueCategory}
              patientList={patientList}
              setSelectedPatient={setSelectedPatient}
              toggleDeleteModal={toggleDeleteModal}
            />
          ) : (
            <EmptyQueueText>
              {search.length
                ? `Tidak ada antrian dengan suku kata "${search}"`
                : "Tidak ada antrian"}
            </EmptyQueueText>
          )}
        </PatientContainer>
      </Main>
    </>
  );
};

export default QueuePage;
