import { BasicHeader } from "components/base";
import {
  HeaderContainer,
  ItemContainer,
  Layout,
  LayoutItem,
  Main,
} from "Pages/Dashboard/components";
import BasicStatsCard from "Pages/Dashboard/components/BasicStatsCard/BasicStatsCard";
import DrugStockChart from "Pages/Dashboard/components/DrugStockChart/DrugStockChart";
import EarningsChart from "Pages/Dashboard/components/EarningsChart/EarningsChart";
import PatientAmountTracker from "Pages/Dashboard/components/PatientAmountTracker/PatientAmountTracker";
import PatientAmountChart from "Pages/Dashboard/components/PatientAmountChart/PatientAmountChart";

const Dashboard = () => {
  return (
    <Main>
      <HeaderContainer>
        <BasicHeader>Dashboard Analytic</BasicHeader>
      </HeaderContainer>
      <Layout>
        <LayoutItem className="section-1">
          <ItemContainer>
            <BasicStatsCard stats="clinic" />
          </ItemContainer>
          <ItemContainer>
            <BasicStatsCard stats="pharmacy" />
          </ItemContainer>
          <ItemContainer>
            <BasicStatsCard stats="procedure" />
          </ItemContainer>
          <ItemContainer>
            <BasicStatsCard stats="patient" />
          </ItemContainer>
        </LayoutItem>

        <LayoutItem className="section-2">
          <ItemContainer>
            <DrugStockChart />
          </ItemContainer>
          <ItemContainer>
            <EarningsChart />
          </ItemContainer>
        </LayoutItem>

        <LayoutItem className="section-3">
          <ItemContainer>
            <PatientAmountTracker />
          </ItemContainer>
          <ItemContainer>
            <PatientAmountChart />
          </ItemContainer>
        </LayoutItem>
      </Layout>
    </Main>
  );
};

export default Dashboard;
