import { endOfDay, startOfDay } from "date-fns";
import { useGetQueueQuery } from "store/encounterStoreAPI";

/**
 * Custom hook to fetch a list of encounters (antrian pasien).
 *
 * @param {boolean} [todayOnly=true] - If true, only fetch encounters for today.
 * @param {DateRange} [dateRange] - Optional date range.
 * @returns {Object} An object containing the list of encounters and a loading state.
 *
 */

interface DateRange {
  start?: Date | null;
  end?: Date | null;
}

const useEncounterList = (todayOnly = true, dateRange?: DateRange) => {
  const timezoneOffset = 7 * 60 * 60 * 1000; // UTC+7 Indonesian timezone

  const adjustDateForTimezone = (date: Date) => {
    return new Date(date.getTime() + timezoneOffset);
  };

  const getStartTime = () => {
    if (todayOnly || !dateRange?.start)
      return adjustDateForTimezone(startOfDay(new Date())).toISOString();
    return adjustDateForTimezone(startOfDay(dateRange.start)).toISOString();
  };

  const getEndTime = () => {
    if (todayOnly || !dateRange?.end)
      return adjustDateForTimezone(endOfDay(new Date())).toISOString();
    return adjustDateForTimezone(endOfDay(dateRange.end)).toISOString();
  };

  const { data, isFetching, isLoading } = useGetQueueQuery({
    startTime: getStartTime(),
    endTime: getEndTime(),
  });

  return {
    list: data ?? [],
    isLoading: isLoading || isFetching,
  };
};

export default useEncounterList;
