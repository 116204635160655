import useCookieAccess from "hooks/cookie";

/**
 * `useIntegrationConfig` checks if BPJS and SatuSehat integrations are configured.
 *
 * @returns {Object} An object containing:
 * - `bpjsConfigured`: Boolean, `true` if BPJS integration is configured, `false` otherwise.
 * - `satusehatConfigured`: Boolean, `true` if SatuSehat integration is configured, `false` otherwise.
 * - `clinicConfigured`: Boolean, `true` if both BPJS and SatuSehat integrations are configured, `false` otherwise.
 */

interface IntegrationConfig {
  bpjsConfigured: boolean;
  satusehatConfigured: boolean;
  clinicConfigured: boolean;
}

const useIntegrationConfig = (): IntegrationConfig => {
  const { loadCookie } = useCookieAccess();
  const bpjsConfigured = Boolean(loadCookie("is_bpjs_configured"));
  const satusehatConfigured = Boolean(loadCookie("is_satu_sehat_configured"));
  const clinicConfigured = bpjsConfigured && satusehatConfigured;

  return {
    bpjsConfigured,
    satusehatConfigured,
    clinicConfigured,
  };
};

export default useIntegrationConfig;
