import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { combinedRoutes } from "config/nav";
import CookieMonster from "config/CookieMonster";
import useProgressiveImg from "hooks/utils/useProgressiveImg";

import { logo } from "assets";
import { background_min_png, background_png } from "assets/background";
import {
  LGBackground,
  LGContainerAligner,
  LGContainer,
  LGInputTextLabel,
  LGLogoContainer,
  LGHeaderText,
} from "Pages/LoginPage/components";
import Terms from "Pages/LoginPage/components/Terms/Terms";
import RegisterForm from "Pages/LoginPage/components/RegisterForm";

const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openTOS, setOpenTOS] = useState(false);

  const [img] = useProgressiveImg({
    placeholderSrc: background_min_png,
    src: background_png,
  });

  const from = location.state?.from?.pathname ?? combinedRoutes.antrian_pasien;

  useEffect(() => {
    const c = CookieMonster.loadCookies(["access_token", "roles"]);

    if (c.access_token) {
      navigate(from, { replace: true });
    }
  }, [from, navigate]);

  return (
    <LGBackground
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      {openTOS && <Terms openTOS={openTOS} setOpenTOS={setOpenTOS} />}
      <LGContainerAligner style={{ display: openTOS ? "none" : "" }}>
        <LGContainer>
          <LGLogoContainer>
            <img
              width={150}
              style={{ margin: "0 auto" }}
              alt="Rekmed"
              src={logo}
            />
          </LGLogoContainer>
          <div>
            <LGHeaderText>Daftar Sekarang</LGHeaderText>
            <LGInputTextLabel>Buat akun dan gunakan Rekmed</LGInputTextLabel>
          </div>
          <RegisterForm setOpenTOS={setOpenTOS} />
        </LGContainer>
      </LGContainerAligner>
    </LGBackground>
  );
};

export default RegisterPage;
