import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueueType } from "types/queue";
import { startOfDay, endOfDay } from "date-fns";

interface AntrianState {
  startDate: string;
  endDate: string;
  jenisAntrian: QueueType;
}

let startDate = startOfDay(new Date());
let endDate = endOfDay(new Date());

const initialState: AntrianState = {
  startDate: startDate.toISOString(),
  endDate: endDate.toISOString(),
  jenisAntrian: "all",
};

const queueSlice = createSlice({
  name: "antrian",
  initialState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload !== null) {
        const startDate = startOfDay(new Date(action.payload));
        state.startDate = startDate.toISOString();
      }
    },
    setEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload !== null) {
        const endDate = endOfDay(new Date(action.payload));
        state.endDate = endDate.toISOString();
      }
    },
    setJenisAntrian: (state, action: PayloadAction<QueueType>) => {
      state.jenisAntrian = action.payload;
    },
    resetQueue: () => initialState,
  },
});

export const { setStartDate, setEndDate, setJenisAntrian, resetQueue } =
  queueSlice.actions;

export default queueSlice.reducer;
