import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { AProfileWithId } from "types/api/practitioner";
// import { APolyclinicWithId } from "types/api/clinic";
// import { APractitionerWithId } from "types/api/practitioner";

export const userProfileStoreAPI = createApi({
  reducerPath: "userProfileAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/user-profiles`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["usersprofiles"],
  endpoints: (builder) => {
    return {
      getProfiles: builder.query<Array<AProfileWithId & { no: number }>, {}>({
        query: () => {
          return {
            url: "",
            method: "GET",
          };
        },
        providesTags: ["usersprofiles"],
        transformResponse: (data: AProfileWithId[]) => {
          return data.map((d, idx) => ({ ...d, no: idx + 1 }));
        },
      }),

      getProfileById: builder.query<AProfileWithId | null, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
        providesTags: (_, __, { id }) => [{ type: "usersprofiles", id }],
        transformResponse: (data: unknown) => {
          if (typeof data === "string") return null;
          return data as AProfileWithId;
          // return data.map((d, idx) => ({ ...d, no: idx + 1 }));
        },
      }),
    };
  },
});

export const {
  useGetProfileByIdQuery,
  util: { resetApiState: resetUserProfileStore },
} = userProfileStoreAPI;
