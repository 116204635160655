import toast from "react-hot-toast";

import { AnEncounterWithId, QueueStatusEnum } from "types/api/encounter";
import { useDeleteQueueMutation } from "store/encounterStoreAPI";

import CustomToast from "components/CustomToast";
import { ActionButton } from "Pages/Queue/components";
import { BaseButton } from "components/base/Button";

type Props = {
  toggle: (value: boolean) => void;
  selectedPatient: AnEncounterWithId | null;
};

const DeleteQueueForm = ({ toggle, selectedPatient }: Props) => {
  const [deleteQueue] = useDeleteQueueMutation({});

  const handleDeleteAntrian = () => {
    if (
      selectedPatient?.status === QueueStatusEnum.QUEUEING ||
      selectedPatient?.status === QueueStatusEnum.INSPECT
    ) {
      deleteQueue({ id: selectedPatient.id })
        .unwrap()
        .then((res) => {
          toggle(false);
          if (parseInt(res.statusCode) === 200) {
            toast.custom((t) => (
              <CustomToast
                t={t}
                status="success"
                headerText="Berhasil!"
                description="Berhasil menghapus antrian."
              />
            ));
          }
        })
        .catch((e) => {
          let message = e?.data?.message || "Terjadi kesalahan!";
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal!"
              description={message}
            />
          ));
          toggle(false);
        });
    }

    // if (selectedPatient?.status === QueueStatusEnum.INSPECT) {
    //   toast.custom((t) => (
    //     <CustomToast
    //       t={t}
    //       status="error"
    //       headerText="Gagal!"
    //       description="Pasien sedang dalam proses pemeriksaan."
    //     />
    //   ));
    // }
  };

  return (
    <>
      <p>
        Apakah anda yakin ingin hapus menghapus antrian{" "}
        <span className="fw-bold">{selectedPatient?.patient.full_name}</span> ?
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BaseButton
          type="button"
          styleType="info"
          onClick={() => toggle(false)}
        >
          Batalkan
        </BaseButton>
        <BaseButton
          type="button"
          styleType="danger"
          onClick={handleDeleteAntrian}
        >
          Hapus
        </BaseButton>
      </div>
    </>
  );
};

export default DeleteQueueForm;
