import { COLORS, small } from "assets/theme";
import styled from "styled-components";

export const InfoContainer = styled.div`
  background: #e3f1ff;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 2px solid #cee6ff;
  border-radius: 6px;

  p,
  svg {
    margin: 0;
    color: ${COLORS.yale_blue};
  }

  ${small} {
    padding: 8px;
    font-size: 12px;
  }
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${small} {
    width: 16px;
    height: 16px;
  }
`;
