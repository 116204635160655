import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { APatientBase } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";

import { BaseTextInput } from "components/base/Input";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
};

const NikBayiInput = ({ formData }: Props) => {
  const [jenisNik, setJenisNik] = useState<"nik_ibu" | "nik_bayi">("nik_ibu");
  const [inputValue, setInputValue] = useState("");

  const handleChangeTipeNik = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^[0-9]*$/.test(newValue)) {
      setInputValue(newValue);
      if (jenisNik === "nik_ibu") {
        formData.setValue("mothers_nik", newValue);
      } else {
        formData.setValue("nik", newValue);
      }
    }
  };

  return (
    <BaseTextInput
      required
      label="NIK Bayi / NIK Ibu"
      placeholder="NIK Bayi / NIK Ibu"
      type="text"
      value={inputValue}
      onChange={handleChangeTipeNik}
      maxLength={16}
      pattern="^[0-9]{16}$"
      title="NIK harus 16 digit angka"
      elementAddonRight={
        <>
          <label style={{ display: "flex", gap: "5px" }}>
            <span></span>
            <select
              name="option"
              onChange={(e) => {
                const newValue = e.target.value as "nik_ibu" | "nik_bayi";
                setInputValue("");
                if (jenisNik === "nik_ibu") {
                  formData.setValue("mothers_nik", "");
                } else {
                  formData.setValue("nik", "");
                }
                setJenisNik(newValue);
              }}
              value={jenisNik}
            >
              <option value="nik_ibu">NIK Ibu</option>
              <option value="nik_bayi">NIK Bayi</option>
            </select>
          </label>
        </>
      }
    />
  );
};

export default NikBayiInput;
