import styled from "styled-components";
import BasicTable from "components/BasicTable/BasicTable";
import { COLORS } from "assets/theme";

export const BaseTable = styled(BasicTable)`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 0.5px solid ${COLORS.neutral_gray_gull};
  border-radius: 6px;

  & thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${COLORS.white};
  }

  & tbody tr {
    :nth-child(odd) {
      background-color: ${COLORS.light_blue_1};
    }
  }
` as typeof BasicTable;
