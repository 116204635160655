import { Controller, UseFormReturn } from "react-hook-form";

import { bpjsStoreApi } from "store/bpjsAPI";

import { BaseButton } from "components/base/Button";
import { BaseTextInput } from "components/base/Input";
import { BaseInfoText } from "components/base/Text";

type Props = {
  formData: UseFormReturn<any>;
  setPatientBpjsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setPatientBpjsData?: React.Dispatch<React.SetStateAction<any>>;
};

const BpjsCheckInput = ({
  formData,
  setPatientBpjsActive,
  setPatientBpjsData,
}: Props) => {
  const bpjs_no = formData.watch("bpjs_no");

  const [
    triggerCheckBpjs,
    {
      data: bpjsStatus,
      isLoading: isBpjsStatusLoading,
      isFetching: isBpjsStatusFetching,
    },
  ] = bpjsStoreApi.endpoints.getPatientBpjsStatus.useLazyQuery();

  const isLoading = isBpjsStatusLoading || isBpjsStatusFetching;

  const handleCheckBpjsStatus = () => {
    triggerCheckBpjs({
      bpjs_no: formData.getValues("bpjs_no"),
    }).then((res) => {
      const bpjsStatus = res.data;
      setPatientBpjsData?.(bpjsStatus);
      if (bpjsStatus?.aktif) setPatientBpjsActive(bpjsStatus.aktif);
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
        }}
      >
        <Controller
          name="bpjs_no"
          control={formData.control}
          render={({ field: { value = "", onChange } }) => (
            <BaseTextInput
              className="w-full"
              required
              label="No. BPJS"
              placeholder="No. BPJS"
              type="text"
              value={value}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  onChange(e.target.value);
                }
              }}
              maxLength={13}
              pattern="^[0-9]{13}$"
              title="No. BPJS harus 13 digit angka"
            />
          )}
        />
        <BaseButton
          style={{ padding: "8px 10px" }}
          type="button"
          styleSize="regularSlim"
          onClick={handleCheckBpjsStatus}
          disabled={isLoading || !bpjs_no || bpjs_no?.length < 13}
        >
          Cek Status
        </BaseButton>
      </div>
      {bpjsStatus !== undefined ? (
        bpjsStatus?.aktif ? (
          <BaseInfoText>BPJS Aktif</BaseInfoText>
        ) : (
          <BaseInfoText styleType="danger">BPJS Tidak Aktif</BaseInfoText>
        )
      ) : null}
    </>
  );
};

export default BpjsCheckInput;
