import { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import {
  useCreateSatusehatCredentialMutation,
  useGetSatusehatCredentialQuery,
  useGetSatusehatLocationIdMutation,
  useTestSatusehatConnectionMutation,
  useUpdateSatusehatCredentialMutation,
} from "store/clinicStoreAPI";
import useCookieAccess from "hooks/cookie";

import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import CustomToast from "components/CustomToast";
import {
  ActionWrapper,
  Form,
  FormInput,
  InfoText,
} from "Pages/Settings/components";
import { PRRekamMedisSelect } from "Pages/Admin/components/index";
import { LGEyeBtn } from "Pages/LoginPage/components";
import { Col } from "reactstrap";

type Props = {
  activeState: () => void;
};

type Options = {
  label: string;
  value: string;
}[];

type SatusehatFormData = {
  client_id: string;
  client_secret: string;
  organization_id: string;
  location_id: { label: string; value: string };
};

const SettingSatusehatForm = ({ activeState }: Props) => {
  const [showValue, setShowValue] = useState(false);
  const { saveCookie } = useCookieAccess();

  const {
    currentData: satusehatCredential,
    isLoading: isQuerySatusehatLoading,
    isSuccess: isQuerySatusehatSuccess,
  } = useGetSatusehatCredentialQuery();

  const { client_id, client_secret, organization_id, location_id } =
    satusehatCredential?.data || {};

  const isSatusehatIntegrationExist = satusehatCredential?.data;

  const [getSatusehatLocationId, { isLoading: isLocationLoading }] =
    useGetSatusehatLocationIdMutation();

  const [createSatusehatCredential, { isLoading: isCreateLoading }] =
    useCreateSatusehatCredentialMutation();
  const [updateSatusehatCredential, { isLoading: isUpdateLoading }] =
    useUpdateSatusehatCredentialMutation();
  const [testSatusehatConnection, { isLoading: isTestConnectionLoading }] =
    useTestSatusehatConnectionMutation();

  const [locationOptions, setLocationOptions] = useState<Options>();

  const satusehatForm = useForm<SatusehatFormData>({
    defaultValues: {
      client_id: client_id || "",
      client_secret: client_secret || "",
      organization_id: organization_id || "",
      location_id: { label: location_id || "", value: location_id || "" },
    },
  });

  const isFormIncomplete =
    !satusehatForm.watch("client_id") ||
    !satusehatForm.watch("client_secret") ||
    !satusehatForm.watch("organization_id");

  const handleSetPreviousForm = useCallback(() => {
    setLocationOptions([
      {
        label: location_id ?? "",
        value: location_id ?? "",
      },
    ]);
    satusehatForm.reset({
      client_id,
      client_secret,
      organization_id,
      location_id: { label: location_id ?? "", value: location_id ?? "" },
    });
  }, [satusehatForm, client_id, client_secret, organization_id, location_id]);

  const handleGetLocationId = useCallback(() => {
    if (isFormIncomplete)
      return toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Gagal"
          description="Data Client ID, Client Secret, dan Organization ID tidak boleh kosong"
          status="error"
        />
      ));

    getSatusehatLocationId({
      client_id: satusehatForm.getValues("client_id"),
      client_secret: satusehatForm.getValues("client_secret"),
      organization_id: satusehatForm.getValues("organization_id"),
    })
      .unwrap()
      .then((res) => {
        const options = res.data.map((location) => ({
          label: `${location.resource?.id} - ${location.resource?.name}`,
          value: location.resource?.id,
        }));

        setLocationOptions(options);

        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Berhasil"
            description="Berhasil mendapatkan Data Location ID"
            status="success"
          />
        ));
      })
      .catch(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description="Gagal mendapatkan Data Location ID"
            status="error"
          />
        ));
      });
  }, [getSatusehatLocationId, satusehatForm, isFormIncomplete]);

  useEffect(() => {
    if (isQuerySatusehatSuccess) {
      handleSetPreviousForm();
    }
  }, [isQuerySatusehatSuccess, handleSetPreviousForm]);

  const handleSubmit = (formData: SatusehatFormData) => {
    if (isSatusehatIntegrationExist) {
      updateSatusehatCredential({
        client_id: formData.client_id,
        client_secret: formData.client_secret,
        organization_id: formData.organization_id,
        location_id: formData.location_id.value,
      })
        .unwrap()
        .then(() => {
          saveCookie("is_satu_sehat_configured", {
            is_satu_sehat_configured: true,
          });
          toast.custom((t) => (
            <CustomToast
              t={t}
              headerText="Berhasil"
              description="Berhasil memperbarui Integrasi SatuSehat"
              status="success"
            />
          ));
        })
        .catch(() => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              headerText="Gagal"
              description="Gagal memperbarui Integrasi SatuSehat"
              status="error"
            />
          ));
        });
    } else {
      createSatusehatCredential({
        client_id: formData.client_id,
        client_secret: formData.client_secret,
        organization_id: formData.organization_id,
        location_id: formData.location_id.value,
      })
        .unwrap()
        .then(() => {
          saveCookie("is_satu_sehat_configured", {
            is_satu_sehat_configured: true,
          });
          toast.custom((t) => (
            <CustomToast
              t={t}
              headerText="Berhasil"
              description="Berhasil Integrasi SatuSehat"
              status="success"
            />
          ));
        })
        .catch(() => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              headerText="Gagal"
              description="Gagal Integrasi SatuSehat"
              status="error"
            />
          ));
        });
    }
  };

  const handleCheckConnection = () => {
    if (isFormIncomplete)
      return toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Gagal"
          description="Data Client ID, Client Secret, dan Organization ID tidak boleh kosong"
          status="error"
        />
      ));

    testSatusehatConnection({
      client_id: client_id || satusehatForm.getValues("client_id") || "",
      client_secret:
        client_secret || satusehatForm.getValues("client_secret") || "",
      organization_id:
        organization_id || satusehatForm.getValues("organization_id") || "",
    })
      .unwrap()
      .then(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Berhasil"
            description="Tes Koneksi SatuSehat berhasil"
            status="success"
          />
        ));
      })
      .catch(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description="Tes Koneksi SatuSehat gagal"
            status="error"
          />
        ));
      });
  };

  return (
    <Form onSubmit={satusehatForm.handleSubmit(handleSubmit)}>
      {!isQuerySatusehatLoading ? (
        <>
          <Col>
            <div className="input w-100">
              <Controller
                name="client_id"
                control={satusehatForm.control}
                render={({ field }) => (
                  <FormInput
                    name="satusehat-client-id"
                    id="satusehat-client-id"
                    autoComplete="new-password"
                    type="text"
                    label="Client ID"
                    placeholder="Client ID"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    required
                  />
                )}
              />
              <Controller
                name="client_secret"
                control={satusehatForm.control}
                render={({ field }) => (
                  <FormInput
                    name="satusehat-client-secret"
                    id="satusehat-client-secret"
                    autoComplete="new-password"
                    type={showValue ? "text" : "password"}
                    label="Client Secret"
                    placeholder="Client Secret"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    required
                    elementAddonRight={
                      <LGEyeBtn
                        onMouseDown={() => {
                          setShowValue(true);
                        }}
                        onMouseUp={() => setShowValue(false)}
                      >
                        {showValue ? (
                          <BsEye color={COLORS.text_hint} />
                        ) : (
                          <BsEyeSlash color={COLORS.text_hint} />
                        )}
                      </LGEyeBtn>
                    }
                  />
                )}
              />
              <Controller
                name="organization_id"
                control={satusehatForm.control}
                render={({ field }) => (
                  <FormInput
                    name="satusehat-organization-id"
                    id="satusehat-organization-id"
                    autoComplete="new-password"
                    type="text"
                    label="Organization ID"
                    placeholder="Organization ID"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    required
                  />
                )}
              />

              <InfoText>
                Silahkan test koneksi terlebih dahulu untuk memastikan data
                Client ID, Client Secret, dan Organization ID benar. Setelah itu
                klik tombol Get Location ID untuk mendapatkan Location ID dan
                melanjutkan integrasi SatuSehat.
              </InfoText>

              <ActionWrapper
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <BasicActionBtn
                  onClick={handleGetLocationId}
                  type="submit"
                  disabled={isLocationLoading}
                  style={{
                    background: "white",
                    color: `${COLORS.green_4}`,
                    outline: `2px solid ${COLORS.green_4}`,
                    outlineOffset: "-2px",
                    marginLeft: "auto",
                  }}
                >
                  Get Location ID
                </BasicActionBtn>
              </ActionWrapper>

              <Controller
                name="location_id"
                control={satusehatForm.control}
                render={({ field }) => (
                  <PRRekamMedisSelect
                    label="Location ID"
                    placeholder="Location ID"
                    options={locationOptions}
                    defaultValue={
                      locationOptions?.find(
                        (option) => option.value === field.value.value,
                      ) || null
                    }
                    defaultInputValue={field.value.label}
                    onChange={field.onChange}
                    isDisabled={!locationOptions}
                    required
                  />
                )}
              />
            </div>
          </Col>

          <ActionWrapper>
            <BasicActionBtn
              onClick={handleCheckConnection}
              type="button"
              disabled={isTestConnectionLoading}
              style={{
                background: "white",
                color: `${COLORS.green_4}`,
                outline: `2px solid ${COLORS.green_4}`,
                outlineOffset: "-2px",
                marginRight: "auto",
              }}
            >
              Test Koneksi
            </BasicActionBtn>
            <BasicActionBtn
              onClick={activeState}
              type="button"
              style={{
                background: COLORS.neutral_gray_gull,
                color: COLORS.black_1,
              }}
            >
              Batal
            </BasicActionBtn>
            <BasicActionBtn
              disabled={isCreateLoading || isUpdateLoading}
              type="submit"
            >
              {isCreateLoading || isUpdateLoading ? "Menyimpan..." : "Simpan"}
            </BasicActionBtn>
          </ActionWrapper>

          {isSatusehatIntegrationExist && location_id && (
            <InfoText
              style={{
                paddingTop: "10px",
              }}
            >
              *Klinik telah terintegrasi SatuSehat dengan Location ID:{" "}
              {location_id}
            </InfoText>
          )}
        </>
      ) : (
        <p
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          Checking Integration...
        </p>
      )}
    </Form>
  );
};

export default SettingSatusehatForm;
