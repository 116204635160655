import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { BackendDataShape, Nullable } from "types";
import { TPesertaBpjsPureBase } from "types/api/bpjs";
import {
  APolyclinicBpjsArrayListOpt,
  APolyclinicOptBpjs,
} from "types/api/clinic";
import { TListObatDpho } from "types/api/obat";
import {
  ADoctorBpjsArrayListOpt,
  ADoctorBpjsOpt,
} from "types/api/practitioner";
import { TProcedureBpjsListResponse } from "types/api/procedures";
import {
  TListResponse,
  TSarana,
  TTACC,
  TKhusus,
  TStatusPulang,
  TSpesialis,
  TSubspesialisKhusus,
  TSubspesialisSpesial,
  TKesadaran,
  TFaskes,
  TPrognosa,
  TAlergi,
} from "types/inspection";

export const bpjsStoreApi = createApi({
  reducerPath: "bpjsStoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/bpjs`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "BpjsDoctorList",
    "BpjsPolyclinicList",
    "BpjsPatientStatus",
    "BpjsSpesialis",
    "BpjsSubspesialisKhusus",
    "BpjsSubspesialisSpesial",
    "BpjsKhusus",
    "BpjsSarana",
    "BpjsKesadaran",
    "BpjsStatusPulang",
    "BpjsTacc",
    "BpjsFaksesSpesialis",
    "BpjsFaskesKhususSubs",
    "BpjsFaskesKhusus",
    "BpjsFaskes",
    "BpjsPrognosa",
    "BpjsAlergi",
  ],
  endpoints: (builder) => {
    return {
      getBpjsDoctorLov: builder.query<
        ADoctorBpjsOpt[],
        { start: number; limit: number }
      >({
        query: ({ start, limit }) => {
          return {
            url: `/dokter/${start}/${limit}`,
            method: "GET",
          };
        },
        providesTags: ["BpjsDoctorList"],
        transformResponse: (
          response: BackendDataShape<ADoctorBpjsArrayListOpt>,
        ) => response.data.list,
      }),

      getPolyclinicBpjsLov: builder.query<
        APolyclinicOptBpjs[],
        { start: number; limit: number }
      >({
        query: ({ start, limit }) => {
          return {
            url: `/polyclinic/${start}/${limit}`,
            method: "GET",
          };
        },
        providesTags: ["BpjsPolyclinicList"],
        transformResponse: (
          response: BackendDataShape<APolyclinicBpjsArrayListOpt>,
        ) => response.data.list,
      }),

      getBpjsPatientStatus: builder.mutation<
        BackendDataShape<Nullable<object>>,
        string
      >({
        query: (obj) => {
          return {
            url: "/patient-status?noKartu=" + obj,
            method: "GET",
          };
        },
      }),

      getPatientBpjsStatus: builder.query<
        TPesertaBpjsPureBase,
        {
          bpjs_no?: string;
        }
      >({
        query: ({ bpjs_no }) => {
          return {
            url: "/patient-status",
            method: "GET",
            params: {
              noKartu: bpjs_no,
            },
          };
        },
        transformResponse: (res: BackendDataShape<TPesertaBpjsPureBase>) => {
          return res?.data;
        },
      }),

      getBpjsSpesialis: builder.query<TListResponse<TSpesialis>, {}>({
        query: () => {
          return {
            url: "/spesialis",
            method: "GET",
          };
        },
        providesTags: ["BpjsSpesialis"],
        transformResponse: (
          res: BackendDataShape<TListResponse<TSpesialis>>,
        ) => {
          return res?.data;
        },
      }),

      getBpjsSubspesialisKhusus: builder.query<
        TListResponse<TSubspesialisKhusus>,
        {}
      >({
        query: () => {
          return {
            url: "/subspesialis/khusus",
            method: "GET",
          };
        },
        providesTags: ["BpjsSubspesialisKhusus"],
        transformResponse: (
          res: BackendDataShape<TListResponse<TSubspesialisKhusus>>,
        ) => {
          return res?.data;
        },
      }),

      getBpjsSubspesialisSpesial: builder.query<
        TListResponse<TSubspesialisSpesial>,
        {
          kdSpesialis: string;
        }
      >({
        query: ({ kdSpesialis }) => {
          return {
            url: `/subspesialis/${kdSpesialis}`,
            method: "GET",
          };
        },
        providesTags: ["BpjsSubspesialisSpesial"],
        transformResponse: (
          res: BackendDataShape<TListResponse<TSubspesialisSpesial>>,
        ) => {
          return res?.data;
        },
      }),

      getBpjsKhusus: builder.query<TListResponse<TKhusus>, {}>({
        query: () => {
          return {
            url: "/khusus",
            method: "GET",
          };
        },
        providesTags: ["BpjsKhusus"],
        transformResponse: (res: BackendDataShape<TListResponse<TKhusus>>) => {
          return res?.data;
        },
      }),

      getBpjsSarana: builder.query<TListResponse<TSarana>, {}>({
        query: () => {
          return {
            url: "/sarana",
            method: "GET",
          };
        },
        providesTags: ["BpjsSarana"],
        transformResponse: (res: BackendDataShape<TListResponse<TSarana>>) => {
          return res?.data;
        },
      }),

      getBpjsKesadaran: builder.query<TListResponse<TKesadaran>, {}>({
        query: () => {
          return {
            url: "/kesadaran",
            method: "GET",
          };
        },
        providesTags: ["BpjsKesadaran"],
        transformResponse: (
          res: BackendDataShape<TListResponse<TKesadaran>>,
        ) => {
          return res?.data;
        },
      }),

      getBpjsStatusPulang: builder.query<TListResponse<TStatusPulang>, {}>({
        query: () => {
          return {
            url: "/status-pulang",
            method: "GET",
          };
        },
        providesTags: ["BpjsStatusPulang"],
        transformResponse: (
          res: BackendDataShape<TListResponse<TStatusPulang>>,
        ) => {
          return res?.data;
        },
      }),

      getBpjsTacc: builder.query<
        TListResponse<TTACC>,
        {
          kdDiagnosa: string;
        }
      >({
        query: ({ kdDiagnosa }) => {
          return {
            url: `/tacc/${kdDiagnosa}`,
            method: "GET",
          };
        },
        providesTags: ["BpjsTacc"],
        transformResponse: (res: BackendDataShape<TListResponse<TTACC>>) => {
          return res?.data;
        },
      }),

      getBpjsFaskes: builder.query<
        TListResponse<TFaskes>,
        {
          type: "spesialis" | "khusus";
          kdSubSpesialis?: string;
          kdKhusus?: string;
          kdSarana?: string;
          noKartu?: string;
          tglEstRujuk: string;
        }
      >({
        query: (args) => {
          const {
            type,
            kdSubSpesialis,
            kdKhusus,
            kdSarana = "0",
            noKartu,
            tglEstRujuk,
          } = args;
          let url = `/faskes/${type}/`;

          switch (type) {
            case "spesialis":
              url += `${kdSubSpesialis}/${kdSarana}/${tglEstRujuk}`;
              break;
            case "khusus":
              if (kdSubSpesialis) {
                url += `${kdSubSpesialis}/${kdKhusus}/${noKartu}/${tglEstRujuk}`;
              } else {
                url += `${kdKhusus}/${noKartu}/${tglEstRujuk}`;
              }
              break;
            default:
              throw new Error(`Invalid type: ${type}`);
          }

          return {
            url,
            method: "GET",
          };
        },
        providesTags: ["BpjsFaskes"],
        transformResponse: (res: BackendDataShape<TListResponse<TFaskes>>) => {
          return res?.data;
        },
      }),

      getBpjsTindakan: builder.query<
        TProcedureBpjsListResponse,
        {
          kdTkp: string;
          start: number;
          limit: number;
        }
      >({
        query: ({ kdTkp, start, limit }) => {
          return {
            url: `/tindakan/${kdTkp}/${start}/${limit}`,
            method: "GET",
          };
        },
      }),

      getBpjsObatDpho: builder.query<
        BackendDataShape<TListObatDpho>,
        { name: string; start: number; limit: number }
      >({
        query: ({ name, start, limit }) => {
          return {
            url: `/obat-dpho/${name}/${start}/${limit}`,
            method: "GET",
          };
        },
      }),

      getBpjsPrognosa: builder.query<TListResponse<TPrognosa>, void>({
        query: () => ({
          url: "/prognosa",
          method: "GET",
        }),

        transformResponse: (
          res: BackendDataShape<TListResponse<TPrognosa>>,
        ) => {
          return res?.data;
        },
        providesTags: ["BpjsPrognosa"],
      }),

      getBpjsAlergi: builder.query<
        TListResponse<TAlergi>,
        "food" | "drug" | "air"
      >({
        query: (type) => {
          let params = "";

          switch (type) {
            case "food":
              params = "01";
              break;
            case "air":
              params = "02";
              break;
            case "drug":
              params = "03";
              break;
          }

          return {
            url: `/alergi/${params}`,
            method: "GET",
          };
        },
        transformResponse: (res: BackendDataShape<TListResponse<TAlergi>>) => {
          return res?.data;
        },
        providesTags: (_, __, type) => [
          {
            type: "BpjsAlergi",
            id: type,
          },
        ],
      }),
    };
  },
});

export const {
  useGetBpjsPatientStatusMutation,
  useGetBpjsDoctorLovQuery,
  useGetPolyclinicBpjsLovQuery,
  useGetPatientBpjsStatusQuery,
  useGetBpjsSpesialisQuery,
  useGetBpjsSubspesialisKhususQuery,
  useGetBpjsSubspesialisSpesialQuery,
  useGetBpjsKesadaranQuery,
  useGetBpjsStatusPulangQuery,
  useGetBpjsTaccQuery,
  useGetBpjsSaranaQuery,
  useGetBpjsKhususQuery,
  useGetBpjsFaskesQuery,
  useGetBpjsTindakanQuery,
  useLazyGetBpjsObatDphoQuery,
  useGetBpjsPrognosaQuery,
  useGetBpjsAlergiQuery,
  util: { resetApiState: resetBpjsStoreAPI },
} = bpjsStoreApi;
