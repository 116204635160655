import { useState } from "react";
import { LuInfo } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import useIntegrationConfig from "hooks/utils/useIntegrationConfig";

import { IconContainer, InfoContainer } from "Pages/Report/components";

type Props = {
  infoType: "bpjs" | "satusehat";
};

export default function InfoPrompt({ infoType }: Props) {
  const [close, setClose] = useState(false);

  const { bpjsConfigured, satusehatConfigured } = useIntegrationConfig();

  if (close) return null;
  if (infoType === "bpjs" && bpjsConfigured) return null;
  if (infoType === "satusehat" && satusehatConfigured) return null;

  const handleClose = () => {
    setClose(true);
  };

  const renderMissingConfigMessage = () => {
    let message = "";
    if (!bpjsConfigured && infoType === "bpjs") return (message = "BPJS");
    if (!satusehatConfigured && infoType === "satusehat")
      return (message = "Satu Sehat");

    return message;
  };

  return (
    <InfoContainer
      style={{
        marginBottom: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <IconContainer>
            <LuInfo size={24} />
          </IconContainer>
          <p>
            Klinik anda belum melakukan integrasi dengan{" "}
            {renderMissingConfigMessage()}
          </p>
        </div>
        <IoMdClose
          style={{
            cursor: "pointer",
            fontSize: "24px",
          }}
          onClick={handleClose}
        />
      </div>
    </InfoContainer>
  );
}
