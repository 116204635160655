import styled from "styled-components";
import { COLORS, large, medium, small } from "assets/theme";
import BasicTable from "components/BasicTable/BasicTable";
import InputText from "components/InputText/InputText";
import { BasicActionBtn } from "components/base";
import Modal from "components/Modal/Modal";

export const Main = styled.main`
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: -60px;
  gap: 20px;

  border-radius: 6px;
  border-color: ${COLORS.neutral_gray_gull};

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${medium} {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 27px 50px;
  box-shadow: 0px 5px 20px rgba(85, 85, 85, 0.15);
  height: fit-content;
  overflow: auto;

  &:first-child {
    grid-row: span 1;
    grid-column: span 3;
  }

  &:nth-child(2) {
    grid-column: span 2;
  }

  ${medium} {
    padding: 20px;
    &:first-child {
      grid-row: span 1;
      grid-column: span 1;
    }
    &:nth-child(2) {
      grid-column: span 1;
    }
  }

  ${medium} {
    padding: 35px;
  }

  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.gap-20 {
    gap: 20px;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  gap: 30px;

  ${small} {
    flex-wrap: wrap;
    justify-content: center;
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 6px;
  }

  &.hidden {
    display: none;
  }
`;

export const Form = styled.form`
  &.hidden {
    display: none;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  p:first-child {
    margin-bottom: 0;
    color: ${COLORS.text_black};
    min-width: 200px;
  }

  ${small} {
    p:first-child {
      min-width: 150px;
    }
  }

  p:last-child {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

export const FormInput = styled(InputText)`
  flex-basis: 100%;
  margin-bottom: 10px;
  &.s,
  &.m {
    flex-basis: 48%;
  }
  ${large} {
    &.m {
      flex-basis: 100%;
    }
  }
  ${medium} {
    &.s {
      flex-basis: 100%;
    }
  }

  &.alone {
    max-width: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & .wrapper {
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    border-radius: 6px;
  }
  & input {
    border: none;
    ::placeholder {
      font-size: 1rem;
    }
    :focus-visible {
      outline: 2px solid ${COLORS.green};
    }
    :disabled {
      background: ${COLORS.silver_lining};
    }
  }

  .right-addon {
    backgroundcolor: ${COLORS.silver_lining};

    label {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    select {
      border: none;
      height: 36px;

      &:focus {
        outline: none;
      }
    }
  }

  &.compact {
    width: 100px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;

  ${small} {
    justify-content: center;

    button {
      width: 100%;
    }
  }
`;

export const Chip = styled.span`
  background: ${COLORS.teal};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 6px 12px;
  border-radius: 32px;
`;

export const StyledChip = styled(Chip)`
  &.success {
    background: ${COLORS.secondaryBlue};
  }
  &.progress {
    background: ${COLORS.yellowHoney};
  }
  &.failed {
    background: ${COLORS.red};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;

  ${small} {
    flex-direction: column;
    gap: 20px;
  }
`;

export const SubsInfoContainer = styled.div`
  display: flex;
  flexdirection: row;
  gap: 12px;
`;

export const SubsDescription = styled.p`
  margin: 0;

  &.bold {
    font-weight: bold;
  }

  &.span {
    grid-column: span 2;
  }

  &.color-green {
    color: ${COLORS.green};
  }

  &.color-red {
    color: ${COLORS.red};
  }

  &.s-32 {
    font-size: 32px;
  }
`;

export const SubsFormContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  ${small} {
    grid-template-columns: 1fr;
  }
`;

export const SubsChildContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row {
    flex-direction: row;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.counter-wrapper {
    border-radius: 4px;
    border: 1px solid ${COLORS.neutral_gray_gull};
  }

  &.p-8 {
    padding: 8px;
  }

  &.max-w-200 {
    max-width: 200px;
  }

  p {
    margin: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 12px;
  border-bottom: 1px solid ${COLORS.neutral_gray_gull};
`;

export const Header = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  color: ${COLORS.text_black};

  ${small} {
    font-size: 20px;
  }
`;

export const BtnCounter = styled.button`
  width: 30px;
  height: 30px;
  background: ${COLORS.light_grey_1};
  border-radius: 2px;
  border: 0.5px solid var(--neutral-gray-gull, #c4cde0);
`;

export const SubsPricing = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  &.total-pricing {
    padding: 12px;
    background: ${COLORS.mint_green};
  }
`;

export const StyledTable = styled(BasicTable)`
  height: 100%;
  width: 100%;
  overflow: auto;
  border: 0.5px solid ${COLORS.neutral_gray_gull};
  border-radius: 6px;

  & thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${COLORS.white};
  }

  & tbody tr {
    :hover {
      background-color: ${COLORS.light_blue_1};
    }
  }

  & tbody td {
    padding: 2px 12px;
  }
` as typeof BasicTable;

export const Layout = styled.div`
  &.table-action-container {
    display: grid;
    grid-template-columns: 20% 80%;

    & > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.table-username-container {
    display: flex;
    align-items: center;
    gap: 2px;

    .info-icon {
      cursor: pointer;

      :hover {
        opacity: 0.7;
      }
    }
  }
`;

export const ActionBtn = styled(BasicActionBtn)`
  padding: 10px 20px;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ghost {
    background: white;
    color: ${COLORS.green_4};
    outline: 2px solid ${COLORS.green_4};
    outlineoffset: -2px;

    :hover,
    :focus {
      background: ${COLORS.green_4};
      color: white;
      opacity: 0.8;
    }
  }

  &.link {
    background: transparent;
    color: ${COLORS.blue_1};
    outline: none;
    padding: 0;
    margin: 0;
    width: max-content;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const ModalContainer = styled(Modal)`
  & .children-container {
    min-width: 600px;
    position: relative;
  }
  ${small} {
    & .children-container {
      min-width: 80vw;
    }
  }
  max-width: 100%;
  /* max-height: 90vh; */
`;

export const InputHelper = styled.p`
  margin: 0;
  color: ${COLORS.green};
  &.lama {
    color: ${COLORS.text_gray};
  }
  &.danger {
    color: ${COLORS.magenta};
  }
`;

export const GreenText = styled.span`
  color: ${COLORS.green};
`;

export const RedText = styled.span`
  color: ${COLORS.red};
`;

export const ElipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
