import { Container, Item, SubContainer, TitleContainer } from "./style";
import { BaseText } from "components/base/Text";
import { TKelompokBersamaBase } from "types/api/bpjs";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

type Props = {
  kelompokData: TKelompokBersamaBase | undefined;
};

const KelompokDetails = ({ kelompokData }: Props) => {
  const { formatRupiah } = useFormatRupiah();
  return (
    <>
      <Container>
        <SubContainer>
          <Item>
            <TitleContainer>
              <BaseText>Tanggal Pelaksanaan</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.tglPelayanan ? kelompokData?.tglPelayanan : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Kelompok</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.kelompok
                ? `${kelompokData?.kelompok?.kode} - ${kelompokData?.kelompok?.nama}`
                : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Club Prolanis</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.clubProl
                ? `${kelompokData?.clubProl?.clubId} - ${kelompokData?.clubProl?.nama}`
                : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Jenis Kegiatan</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.kegiatan
                ? `${kelompokData?.kegiatan?.kode} - ${kelompokData?.kegiatan?.nama}`
                : "-"}
            </BaseText>
          </Item>
        </SubContainer>
        <SubContainer>
          <Item>
            <TitleContainer>
              <BaseText>Materi</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.materi ? kelompokData?.materi : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Pembicara</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.pembicara ? kelompokData?.pembicara : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Lokasi</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.lokasi ? kelompokData?.lokasi : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Biaya</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.biaya ? formatRupiah(kelompokData?.biaya) : "-"}
            </BaseText>
          </Item>
          <Item>
            <TitleContainer>
              <BaseText>Keterangan</BaseText>
            </TitleContainer>
            <BaseText bold>
              {kelompokData?.keterangan ? kelompokData?.keterangan : "-"}
            </BaseText>
          </Item>
        </SubContainer>
      </Container>
    </>
  );
};

export default KelompokDetails;
