import React, { PropsWithChildren } from "react";
import { NavbarItemProps } from "types/navbar";
import Navbar from "components/Navbar/Navbar";
import styled from "styled-components";

type Props = {
  navitems?: NavbarItemProps[];
};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 130px);
`;

const BasicLayout: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  navitems = [],
}) => {
  return (
    <div>
      <Navbar navitems={navitems} />
      <Container>{children}</Container>
    </div>
  );
};

export default BasicLayout;
