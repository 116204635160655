import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EncounterOpt } from "types/api/encounter";

interface EncounterState {
  [queue_id: number]: EncounterOpt | null;
}

const initialState: EncounterState = {};

const activeEncounterOptStore = createSlice({
  name: "active_encounter_store",
  initialState,
  reducers: {
    setActiveEncounter: (
      state,
      action: PayloadAction<{
        queue_id: number;
        opt: EncounterOpt | null;
      }>,
    ) => {
      state[action.payload.queue_id] = action.payload.opt;
    },
    resetActiveEncounter: () => initialState,
  },
});

export default activeEncounterOptStore.reducer;
export const { setActiveEncounter, resetActiveEncounter } =
  activeEncounterOptStore.actions;
