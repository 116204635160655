import styled from "styled-components";
import { medium, small, xSmall } from "assets/theme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  height: 100%;
  align-items: start;
`;

export const SubContainer = styled.div`
  display: grid;
  width: 100%;
  align-items: start;
  gap: 10px;

  &.hidden {
    display: none;
  }

  &.gap-none {
    gap: 0;
  }

  &.col-2 {
    grid-column: span 2;
    ${medium} {
      grid-column: span 2;
    }
    ${small} {
      grid-column: span 3;
    }
    ${xSmall} {
      grid-column: span 6;
    }
  }

  &.col-3 {
    grid-column: span 3;
    ${medium} {
      grid-column: span 3;
    }
    ${small} {
      grid-column: span 6;
    }
    ${xSmall} {
      grid-column: span 6;
    }
  }

  &.col-6 {
    grid-column: span 6;
    ${small} {
      grid-column: span 6;
    }
  }
`;

// export const Item = styled.div`
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   width: 100%;
//   align-items: start;

//   &.col-2 {
//     grid-column: span 2;
//     ${medium} {
//       grid-column: span 2;
//     }
//     ${small} {
//       grid-column: span 3;
//     }
//     ${xSmall} {
//       grid-column: span 6;
//     }
//   }

//   &.col-3 {
//     grid-column: span 3;
//     ${medium} {
//       grid-column: span 3;
//     }
//     ${small} {
//       grid-column: span 6;
//     }
//     ${xSmall} {
//       grid-column: span 6;
//     }
//   }

//   &.col-6 {
//     grid-column: span 6;
//     ${small} {
//       grid-column: span 6;
//     }
//   }
// `;

export const Item = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: space-between;

  & > * {
    flex-shrink: 0;
  }

  &.flex-row {
    flex-direction: row;
  }

  &.flex-column {
    flex-direction: column;
  }
  &.flex-nowarp {
    flex-wrap: nowrap;
  }

  &.col-3 {
    grid-column: span 3;
  }
`;
