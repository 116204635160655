import React from "react";

type Props = {
  placeholderSrc: any;
  src: any;
};

const useProgressiveImg = ({ placeholderSrc, src }: Props) => {
  const [img, setImg] = React.useState(placeholderSrc);
  const [loading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const img = new Image();
    setIsLoading(true);
    img.src = src;

    img.onload = function () {
      setIsLoading(false);
      setImg(img.src);
    };

    return () => {
      img.onload = null;
    };
  }, [src]);

  return [img, loading];
};

export default useProgressiveImg;
