import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LGBackground,
  LGContainerAligner,
  LGContainer,
  LGLogoContainer,
  InfoContainer,
  InfoContainerInner,
  InfoTitleBar,
  InfoTitle,
  InfoDescription,
} from "Pages/LoginPage/components";
import { logo } from "assets";
import { combinedRoutes } from "config/nav";
import CookieMonster from "config/CookieMonster";
import useProgressiveImg from "hooks/utils/useProgressiveImg";
import { background_min_png, background_png } from "assets/background";
import LoginForm from "Pages/LoginPage/components/LoginForm";

/**
 * TODO:
 * - cantumkan link:
 *   - lupa password
 *   - pendaftaran
 * @returns
 */

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const [img] = useProgressiveImg({
    placeholderSrc: background_min_png,
    src: background_png,
  });

  let from = location.state?.from?.pathname ?? combinedRoutes.antrian_pasien;

  useEffect(() => {
    const c = new AbortController();
    return () => {
      c.abort();
    };
  }, []);

  useEffect(() => {
    const c = CookieMonster.loadCookies(["access_token", "roles"]);

    if (c.access_token) {
      navigate(from, { replace: true });
    }
    setIsLoading(false);
  }, [isLoading]);

  return (
    <LGBackground
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <LGContainerAligner>
        <LGContainer>
          <LGLogoContainer>
            <img
              width={150}
              style={{ margin: "0 auto" }}
              alt="Rekmed"
              src={logo}
            />
          </LGLogoContainer>
          <LoginForm />
        </LGContainer>
        <LGContainer>
          <InfoContainer>
            <InfoTitle>
              <InfoTitleBar />
              <InfoDescription>
                Informasi Terbaru mengenai REKMED
              </InfoDescription>
            </InfoTitle>
            <InfoContainerInner>
              <span>Kontak CS</span>
              <span className="accent">
                <a
                  className="accent"
                  href="https://wa.me/6281933508635"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +62 819-3350-8635
                </a>
              </span>
              <span>Tutorial Penggunaan Rekmed</span>
              <span>
                <a
                  className="accent"
                  href="https://bit.ly/tutorialpenggunaanrekmed"
                >
                  bit.ly/tutorialpenggunaanrekmed
                </a>
              </span>
            </InfoContainerInner>
          </InfoContainer>
        </LGContainer>
      </LGContainerAligner>
    </LGBackground>
  );
};

export default LoginPage;
