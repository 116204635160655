import { useMemo, useState } from "react";
import toast from "react-hot-toast";

import CookieMonster from "config/CookieMonster";
import { useGetAccountByIdQuery } from "store/accountsStoreAPI";
import { userAccountApi } from "store/userAccountStoreAPI";

import { BasicActionBtn, BasicHeader } from "components/base";
import {
  Main,
  Card,
  HeaderContainer,
  Item,
  AccountDisplayWrapper,
  EmailDisplayWrapper,
} from "Pages/Settings/components";
import SettingAccountForm from "Pages/Settings/components/SettingAccountForm";
import SettingPasswordForm from "Pages/Settings/components/SettingPasswordForm";
import CustomToast from "components/CustomToast";

enum ActiveCard {
  profile = "profile",
  password = "password",
}

const SettingsAkun = () => {
  const [isCardActive, setIsCardActive] = useState<ActiveCard | null>(null);
  const id = CookieMonster.loadCookie("id");
  const { data: d, isLoading } = useGetAccountByIdQuery(id);
  const [triggerSendVerification] =
    userAccountApi.endpoints.getVerifyEmail.useLazyQuery();

  const memoizedData = useMemo(() => d, [d]);
  const { username, email, phone_number, roles, is_verified } =
    memoizedData || {};
  const cardState = (card: ActiveCard) => {
    return isCardActive === card ? "active" : "";
  };

  const handleSendVerification = () => {
    triggerSendVerification({})
      .unwrap()
      .then(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Verifikasi Email"
            description="Cek email Anda untuk Verifikasi Akun"
            status="success"
          />
        ));
      })
      .catch(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description="Gagal mengirimkan email verifikasi"
            status="error"
          />
        ));
      });
  };

  const akunDisplay = () => {
    return (
      <AccountDisplayWrapper>
        <Item>
          <p>Username</p>
          <p>{isLoading ? "Loading..." : username}</p>
        </Item>
        <Item>
          <p>Role</p>
          <p>
            {isLoading
              ? "Loading..."
              : roles?.map((role) => role?.user_type).join(", ")}
          </p>
        </Item>
        <Item>
          <p>Email</p>
          <span>
            {isLoading ? (
              "Loading..."
            ) : (
              <EmailDisplayWrapper>
                {email ? (
                  <>
                    {email}
                    {is_verified ? (
                      " ✅"
                    ) : (
                      <BasicActionBtn
                        className="compact"
                        onClick={handleSendVerification}
                      >
                        Kirim ulang Link Verifikasi
                      </BasicActionBtn>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </EmailDisplayWrapper>
            )}
          </span>
        </Item>
        <Item>
          <p>No. Telepon</p>
          <p>{isLoading ? "Loading..." : phone_number || "-"}</p>
        </Item>
      </AccountDisplayWrapper>
    );
  };

  const passwordDisplay = () => {
    return (
      <Item>
        <p>Password</p>
        <p>**********</p>
      </Item>
    );
  };

  return (
    <Main>
      <Card>
        <HeaderContainer className={cardState(ActiveCard.profile)}>
          <BasicHeader>Pengaturan Akun</BasicHeader>
          <BasicActionBtn onClick={() => setIsCardActive(ActiveCard.profile)}>
            Ubah Data Akun
          </BasicActionBtn>
        </HeaderContainer>

        {cardState(ActiveCard.profile) ? (
          <SettingAccountForm
            data={memoizedData}
            closeActiveCard={() => setIsCardActive(null)}
          />
        ) : (
          akunDisplay()
        )}
      </Card>

      <Card>
        <HeaderContainer className={cardState(ActiveCard.password)}>
          <BasicHeader>Password</BasicHeader>
          <BasicActionBtn onClick={() => setIsCardActive(ActiveCard.password)}>
            Ubah Password
          </BasicActionBtn>
        </HeaderContainer>

        {cardState(ActiveCard.password) ? (
          <SettingPasswordForm closeActiveCard={() => setIsCardActive(null)} />
        ) : (
          passwordDisplay()
        )}
      </Card>
    </Main>
  );
};

export default SettingsAkun;
