import { useEffect, useMemo, useState } from "react";
import { SingleValue } from "react-select";

import { APractitionerOpt } from "types/api/practitioner";
import { QueueTypeEnum } from "types/queue";
import useGroupedPractitionerList from "hooks/practitioner/usePractitionerList";

import { BaseSelectInput } from "components/base/Input";

type Props = {
  jenisAntrian: QueueTypeEnum;
  jenisPoliSakit: "sakit" | "sehat";
  bpjsConfigured: boolean;
  selectedPolyclinic: APractitionerOpt | null;
  setSelectedPolyclinic: (v: APractitionerOpt | null) => void;
};

const PoliklinikSelect = ({
  jenisAntrian,
  jenisPoliSakit,
  bpjsConfigured,
  selectedPolyclinic,
  setSelectedPolyclinic,
}: Props) => {
  const [filterPractitioner, setFilterPractitioner] = useState<
    "all" | "bpjs" | "non-bpjs"
  >("all");
  const [filterPolyclinic, setFilterPolyclinic] = useState<
    "all" | "sakit" | "sehat"
  >("all");

  const { list: practitioners } = useGroupedPractitionerList(
    filterPractitioner, //filterPractitioner (dokter bpjs / non-bpjs / all)
    filterPolyclinic, //filterPolyclinic (poli kunjungan sakit / sehat / all)
    undefined, //isBpjs (only dokter bpjs)
    true, //isActive (only dokter with subscription)
  );

  const practitionersList = useMemo(() => {
    return practitioners?.flatMap((p: any) => p.options) ?? [];
  }, [practitioners]);

  const handleSelectPolyclinic = (v: SingleValue<APractitionerOpt>) => {
    setSelectedPolyclinic(v);
  };

  useEffect(() => {
    if (!bpjsConfigured || jenisAntrian === QueueTypeEnum.UMUM) {
      setFilterPolyclinic("all");
    }

    if (bpjsConfigured && jenisAntrian === QueueTypeEnum.BPJS) {
      setFilterPolyclinic(jenisPoliSakit);
      setFilterPractitioner("bpjs");
    } else {
      setFilterPractitioner("all");
    }
  }, [bpjsConfigured, jenisAntrian, jenisPoliSakit]);

  return (
    <BaseSelectInput<APractitionerOpt>
      name="practitioner-polyclinic-select"
      menuPlacement="auto"
      label="Poli / Dokter"
      placeholder="Pilih Poli / Dokter"
      options={practitionersList}
      onChange={handleSelectPolyclinic}
      isClearable
      required
      maxMenuHeight={160}
    />
  );
};

export default PoliklinikSelect;
