import styled from "styled-components";
import { medium, xSmall } from "assets/theme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  ${medium} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${xSmall} {
    grid-template-columns: 1fr;
  }

  .span-full {
    grid-column: 1 / -1;
  }
`;
