import styled from "styled-components";
import { COLORS } from "assets/theme";

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.grey_1};
  border-radius: 14px;
  padding: 2px;
  gap: 2px;
`;

export const Btn = styled.button`
  background-color: ${COLORS.grey_1};
  border: none;
  border-radius: 16px;
  padding: 2px 8px;
  cursor: pointer;
  font-size: 10px;
  color: ${COLORS.text_gray};
  white-space: nowrap;

  &.active {
    color: white;
    background: linear-gradient(
      120deg,
      ${COLORS.gradient_green_chart_1} 0%,
      ${COLORS.gradient_green_chart_2} 100%
    );
  }
`;
