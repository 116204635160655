import { useState } from "react";

import { Btn, BtnGroup } from "Pages/Dashboard/components/MonthBtnGroup/style";

type Props = {
  onButtonClick: (value: number) => void;
};

const MonthBtnGroup = (props: Props) => {
  const [activeButton, setActiveButton] = useState<number>(1); // Set initial state to 1

  const handleClick = (value: number) => {
    setActiveButton(value);
    props.onButtonClick(value);
  };

  return (
    <BtnGroup>
      <Btn
        onClick={() => handleClick(1)}
        className={activeButton === 1 ? "active" : ""}
      >
        Bulan Ini
      </Btn>
      <Btn
        onClick={() => handleClick(3)}
        className={activeButton === 3 ? "active" : ""}
      >
        3 Bulan Terakhir
      </Btn>
      <Btn
        onClick={() => handleClick(6)}
        className={activeButton === 6 ? "active" : ""}
      >
        6 Bulan Terakhir
      </Btn>
    </BtnGroup>
  );
};

export default MonthBtnGroup;
