import styled from "styled-components";
import { COLORS } from "assets/theme";

export const BaseRadioGroup = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 4px 0;

  &.hide {
    display: none;
  }

  &.hide-opacity {
    opacity: 0;
    height: 0;
  }

  // accent-color: ${COLORS.green};
`;

export const BaseRadioSpacer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  cursor: pointer;

  &.gap-sm {
    gap: 16px;
  }
`;

export const BaseRadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
`;

export const BaseRadioInput = styled.input`
  cursor: pointer;
`;
