import { Column } from "react-table";

import { TClubBase } from "types/api/bpjs";
import { useGetKelompokClubQuery } from "store/bpjsStoreAPI/bpjsKelompokAPI";

import { BaseTable } from "components/base/Table";

type Props = {
  search?: string;
};

const columns: Column<TClubBase>[] = [
  {
    Header: "No.",
    accessor: (_, i) => i + 1,
    width: 40,
  },
  {
    accessor: "nama",
    Header: "Nama Club",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "jnsKelompok",
    Header: "Program",
    Cell: ({ value }) => {
      const val = value ? value.nmProgram : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "alamat",
    Header: "Alamat Club",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "ketua_nama",
    Header: "Nama Ketua",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "ketua_noHP",
    Header: "No. HP Ketua",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "tglMulai",
    Header: "Aktif Sejak",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
];

const TableClub = ({ search }: Props) => {
  const { data: kelompokClub01 } = useGetKelompokClubQuery({
    KelompokCode: "01", // Kelompok Diabetes Melitus
  });
  const { data: kelompokClub02 } = useGetKelompokClubQuery({
    KelompokCode: "02", // Kelompok Hipertensi
  });

  const clubData: TClubBase[] = [
    ...(kelompokClub01 || []),
    ...(kelompokClub02 || []),
  ];

  const filteredClub =
    clubData?.filter((club) => {
      if (!search) return true;
      return (
        club.nama?.toLowerCase().includes(search.toLowerCase()) ||
        club.jnsKelompok?.nmProgram
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        club.alamat?.toLowerCase().includes(search.toLowerCase()) ||
        club.ketua_nama?.toLowerCase().includes(search.toLowerCase()) ||
        club.ketua_noHP?.toLowerCase().includes(search.toLowerCase())
      );
    }) || [];

  return (
    <BaseTable
      columns={columns}
      data={filteredClub}
      isHideNumbering
      showPagination={false}
      isLoading={false}
    />
  );
};

export default TableClub;
