import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  FormInput,
  InfoDescription,
  InfoTitle,
  InfoTitleBar,
  LGActionText,
} from "Pages/LoginPage/components";
import {
  Container,
  ActionButton,
  ActionLink,
  FormContainer,
} from "Pages/LoginPage/components/ForgotPasswordForm/style";
import { useRequestForgotPasswordOtpMutation } from "store/forgotPasswordStoreAPI";
import toast from "react-hot-toast";
import CustomToast from "components/CustomToast";

type RequestForgotPassword = {
  username: string;
};

const ResetPasswordForm = () => {
  const [sent, setSent] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [requestForgotPassword, { isLoading }] =
    useRequestForgotPasswordOtpMutation();

  const resetForm = useForm<RequestForgotPassword>({
    defaultValues: {
      username: "",
    },
  });
  const { control } = resetForm;

  const handleSubmit = (formData: RequestForgotPassword) => {
    requestForgotPassword(formData)
      .unwrap()
      .then(() => {
        setSent(true);
        setCountdown(30);
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Berhasil"
            description="Kode OTP telah dikirim ke email Anda."
            status="success"
          />
        ));
      })
      .catch((e) => {
        setSent(false);
        let message = e?.data?.message || "Gagal mengirim kode OTP";
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description={message}
            status="error"
          />
        ));
      });
  };

  useEffect(() => {
    let timer: NodeJS.Timer | null = null;
    if (sent) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [sent]);

  useEffect(() => {
    if (countdown === 0) {
      setSent(false);
    }
  }, [countdown]);

  const disableSubmit = sent || resetForm.formState.isSubmitting || isLoading;

  return (
    <Container>
      <InfoTitle>
        <InfoTitleBar />
        <InfoDescription>
          {sent
            ? "Silakan cek email Anda untuk menemukan link pemulihan kata sandi. Terima kasih!"
            : "Silahkan masukkan Username Anda untuk mereset kata sandi"}
        </InfoDescription>
      </InfoTitle>

      <FormContainer onSubmit={resetForm.handleSubmit(handleSubmit)}>
        <Controller
          name="username"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormInput
              type="text"
              label="Username"
              placeholder="Username"
              value={value}
              onChange={onChange}
              required
            />
          )}
        />

        <ActionButton disabled={disableSubmit} type="submit">
          {sent ? `Kirim ulang dalam 00:${countdown}` : "Reset kata sandi"}
        </ActionButton>
      </FormContainer>

      <LGActionText>
        Kembali ke halaman <ActionLink to={"/login"}>Login</ActionLink>
      </LGActionText>
    </Container>
  );
};

export default ResetPasswordForm;
