import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useGetAnalyticsRevenueTrendQuery } from "store/analyticsStoreAPI";

import { COLORS } from "assets/theme";
import { StatsHeader, StatsTitle } from "Pages/Dashboard/components";
import MonthBtnGroup from "Pages/Dashboard/components/MonthBtnGroup/MonthBtnGroup";
import {
  Container,
  StatsFilter,
} from "Pages/Dashboard/components/EarningsChart/style";

interface CustomBarProps {
  fill: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

const CustomBar = (props: CustomBarProps) => {
  const { fill, x, y, width, height } = props;
  return (
    <rect
      x={x}
      y={y}
      rx={2}
      ry={2}
      width={width}
      height={height}
      style={{ fill }}
    />
  );
};

const EarningsChart = () => {
  const [filterMonth, setFilterMonth] = useState(1);

  const { data: revenueTrendData, isLoading: isLoadingRevenueTrend } =
    useGetAnalyticsRevenueTrendQuery({
      monthRange: filterMonth.toString(),
    });

  // Convert pendapatan_total to numbers and find the max value
  const maxValue = Math.max(
    ...(revenueTrendData?.map((item) => Number(item.pendapatan_total)) || [0]),
  );

  // Calculate the tick interval and generate an array of tick values
  const ticks = Array.from({ length: 11 }, (_, i) => i * (maxValue / 10));

  const processedData = revenueTrendData?.map((item) => ({
    ...item,
    total_patient: Number(item.pendapatan_total),
  }));

  const handleButtonClick = (value: number) => {
    setFilterMonth(value);
  };

  return (
    <>
      <Container>
        <StatsHeader>
          <StatsTitle>Tren Pendapatan</StatsTitle>
          <StatsFilter>
            <MonthBtnGroup onButtonClick={handleButtonClick} />
          </StatsFilter>
        </StatsHeader>
        {revenueTrendData &&
          revenueTrendData?.length > 0 &&
          !isLoadingRevenueTrend && (
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                key={filterMonth}
                height={200}
                data={processedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="0%"
                      stopColor={COLORS.gradient_green_chart_1}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={COLORS.gradient_green_chart_2}
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  strokeDasharray="0"
                  strokeWidth={0.5}
                  horizontal={true}
                  vertical={false}
                />
                <XAxis
                  dataKey="month"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                />
                <YAxis
                  dataKey="pendapatan_total"
                  width={30}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  tickFormatter={(value) => (value / 1000000).toFixed(1)}
                  ticks={ticks}
                  label={{
                    value: "Dalam satuan Juta",
                    fontSize: 12,
                    angle: -90,
                    position: "insideLeft",
                    dy: 50,
                    dx: -18,
                  }}
                />
                <Tooltip />
                <Bar
                  dataKey="pendapatan_total"
                  fill="url(#colorUv2)"
                  shape={(props: any) => <CustomBar {...props} />}
                  barSize={26}
                />
              </BarChart>
            </ResponsiveContainer>
          )}

        {!revenueTrendData && <div>No data</div>}
      </Container>
    </>
  );
};

export default EarningsChart;
