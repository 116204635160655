import styled from "styled-components";
import { small } from "assets/theme";

export const CouponModalLayout = styled.div`
  display: grid;
  gap: 20px;
  padding-top: 10px;
  min-width: 400px;

  ${small} {
    min-width: 100%;
  }
`;
