import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";

import { useModal } from "components/ModalRefined/ModalRefined";

import { BaseHeaderLayout, BaseLayout } from "components/base/Container";
import { BaseHeaderText } from "components/base/Text";
import { BaseTextInput } from "components/base/Input";
import { BaseButton } from "components/base/Button";
import { TabButton } from "./components";
import TableClub from "./components/TableClub/TableClub";
import TablePeserta from "./components/TablePeserta/TablePeserta";
import AddPesertaProlanisForm from "./components/AddPesertaProlanisForm/AddPesertaProlanisForm";
import { BaseModalRefined } from "components/base/Modal";

const Prolanis = () => {
  const [searchClub, setSearchClub] = useState("");
  const [searchPeserta, setSearchPeserta] = useState("");
  const [activeTab, setActiveTab] = useState<"club" | "peserta">("club");

  const { isOpen: isAddModalOpen, toggleModal: toggleAddModal } = useModal();

  return (
    <>
      <BaseModalRefined
        isOpen={isAddModalOpen}
        onClose={toggleAddModal}
        header="Tambah Data Peserta"
        size="xl"
      >
        <AddPesertaProlanisForm toggleModal={toggleAddModal} />
      </BaseModalRefined>

      <BaseLayout>
        <BaseHeaderLayout
          style={{
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <BaseHeaderText styleSize="24">Prolanis</BaseHeaderText>
            <TabButton
              className={activeTab === "club" ? "active" : ""}
              onClick={() => setActiveTab("club")}
            >
              Club
            </TabButton>
            <TabButton
              className={activeTab === "peserta" ? "active" : ""}
              onClick={() => setActiveTab("peserta")}
            >
              Peserta
            </TabButton>
          </div>

          {activeTab === "club" ? (
            <BaseTextInput
              placeholder="Cari..."
              elementAddonRight={<BiSearch />}
              value={searchClub}
              onChange={(e) => setSearchClub(e.target.value)}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <BaseTextInput
                placeholder="Cari..."
                elementAddonRight={<BiSearch />}
                value={searchPeserta}
                onChange={(e) => setSearchPeserta(e.target.value)}
              />
              <BaseButton
                styleType="dark"
                style={{ padding: "8px 16px" }}
                onClick={() => toggleAddModal(true)}
              >
                + Peserta Prolanis
              </BaseButton>
            </div>
          )}
        </BaseHeaderLayout>

        {activeTab === "club" ? (
          <TableClub search={searchClub} />
        ) : (
          <TablePeserta search={searchPeserta} />
        )}
      </BaseLayout>
    </>
  );
};

export default Prolanis;
