import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { ADrugBase, ADrugWithId } from "types/api/obat";
import {
  BackendDataPageShape,
  BackendMetaPagination,
  PaginationQueryParams,
} from "types";
import { convertToEncodedURL } from "assets/usefulFunctions";
// import { APolyclinicWithId } from "types/api/clinic";
// import { APractitionerWithId } from "types/api/practitioner";

export const pharmacyStoreAPI = createApi({
  reducerPath: "pharmacyStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/drugs`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["drugs"],
  endpoints: (builder) => {
    return {
      getDrugs: builder.query<
        {
          data: Array<ADrugWithId & { no: number }>;
          meta: BackendMetaPagination;
        },
        { merk?: string } & PaginationQueryParams
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }
          return {
            url: params,
            method: "GET",
          };
        },
        providesTags: (_, __, { merk: id }) => ["drugs", { type: "drugs", id }],
        transformResponse: (res: BackendDataPageShape<ADrugWithId[]>) => {
          const { offset } = res.data.meta;

          return {
            data: res.data.entities.map((d, idx) => ({
              ...d,
              no: offset + idx + 1,
            })),
            meta: res.data.meta,
          };
          // return data.data.map((d, idx) => ({ ...d, no: idx + 1 }));
        },
      }),

      createDrugs: builder.mutation<unknown, ADrugBase>({
        query: (body) => {
          return {
            url: "",
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["drugs"],
      }),

      updateDrugs: builder.mutation<unknown, ADrugBase & { id: number }>({
        query: ({ id, ...body }) => {
          return {
            url: `/${id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["drugs"],
      }),

      deleteDrugs: builder.mutation<unknown, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["drugs"],
      }),

      updateDrugStock: builder.mutation<
        unknown,
        { id: number; transaction_type: "add" | "subtract"; amount: number }
      >({
        query: ({ id, ...body }) => {
          return {
            url: `/update/stock/${id}`,
            method: "PATCH",
            body,
          };
        },
        invalidatesTags: ["drugs"],
      }),
    };
  },
});

export const {
  useGetDrugsQuery,
  useCreateDrugsMutation,
  useUpdateDrugsMutation,
  useDeleteDrugsMutation,
  useUpdateDrugStockMutation,
  util: { resetApiState: resetPharmacyStoreAPI },
} = pharmacyStoreAPI;
