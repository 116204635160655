import { DataMeta } from "types/api";
import { Nullable } from "types";
import { AMedrecWithId } from "types/api/medrec";
import { AMedrecProcedureWithId } from "types/api/procedures";
import { AMedrecRecipeWithId } from "types/api/recipes";

/**
 * enum resep obat.
 * - `null`: resep obat belum diproses sama sekali
 *
 */
export enum RecipeStatusEnum {
  /**
   * resep obat telah disimpan
   */
  QUEUE = "QUEUE",
  /**
   * Obat dan tindakan telah dibayarkan
   */
  WIP = "WIP",
  /**
   * Obat telah diserahkan
   */
  DONE = "DONE",
}

export type AMedrecTransactionBase = {
  /**
   *  @example "INV-6661966457191552",
   */
  invoice_number: string;
  /**
   * ini jadinya total tanpa discount
   * @example 30000
   */
  total_price: number;
  /**
   * @example 0
   */
  discount: number;
  /**
   * ini adalah diskon final
   * @example 0
   */
  discount_rate: number;

  /**
   * ini nilai yang dibayarkan pasien
   */
  total_paid: number;
  /**
   *  @example ""
   */
  recipe_status: Nullable<RecipeStatusEnum>;
  /**
   *  @example ""
   */
  notes?: Nullable<string>;
};

export type AMedrecTransactionWithId = AMedrecTransactionBase & {
  id: number;
} & DataMeta & {
    /**
     * = ini adalah total diskon obat + tindakan
     */
    subdiscount_total_rate: Nullable<number>;
    /**
     * ini total diskon obat
     */
    subdiscount_drug_rate: Nullable<number>;
    /**
     * ini total diskon tindakan
     */
    subdiscount_procedure_rate: Nullable<number>;
  } & {
    medicalRecord: AMedrecWithId;
  } & {
    recipeTrx: AMedrecRecipeWithId[];
  } & {
    procedureTrx: AMedrecProcedureWithId[];
  };
