import React, {
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
  ReactNode,
} from "react";
import styled from "styled-components";
import { COLORS } from "assets/theme";

export interface InputTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  labelColor?: string;
  labelFontSize?: string;
  className?: string;
  elementAddonRight?: ReactNode;
  elementAddonLeft?: ReactNode;
}

const StyledLeftAddonElement = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  z-index: 1;
  /* box-sizing: content-box; */
`;
const StyledRightAddonElement = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  z-index: 1;
  /* box-sizing: content-box; */
`;

const StyledInput = styled.textarea`
  min-height: 36px;
  border-radius: 6px;
  border: 1px solid ${COLORS.text_gray};
  padding: 0 8px;
  color: ${COLORS.black_1};
  background: ${COLORS.white};
  width: 100%;
  ::placeholder {
    color: ${COLORS.text_gray};
  }
  box-sizing: border-box; /* Include padding and border in the width calculation */
`;

type L = {
  labelColor?: string;
  labelFontSize?: string;
};
export const StyledLabel = styled.label<L>`
  font-size: ${(props) =>
    props.labelFontSize ? props.labelFontSize : "inherit"};
  color: ${(props) => (props.labelColor ? props.labelColor : COLORS.black_1)};

  .required-star {
    color: ${COLORS.red};
  }
`;
export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  /* margin-bottom: 16px; */
`;

const InputForm: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  InputTextAreaProps
> = (props, ref) => {
  const {
    label,
    labelColor,
    elementAddonLeft,
    labelFontSize,
    elementAddonRight,
    className,
    name,
    ...rest
  } = props;

  const id =
    name || `input-textarea-id-${Math.random().toString(36).substring(2, 9)}`;

  return (
    <StyledInputWrapper className={className}>
      <StyledLabel
        htmlFor={id}
        labelColor={labelColor}
        labelFontSize={labelFontSize}
      >
        {label}
        {rest.required ? <span className="required-star">*</span> : null}
      </StyledLabel>
      <InputWrapper className="wrapper">
        {elementAddonLeft && (
          <StyledLeftAddonElement>{elementAddonLeft}</StyledLeftAddonElement>
        )}
        <StyledInput id={id} ref={ref} name={id} {...rest} />
        {elementAddonRight && (
          <StyledRightAddonElement>{elementAddonRight}</StyledRightAddonElement>
        )}
      </InputWrapper>
    </StyledInputWrapper>
  );
};

const InputTextArea = React.forwardRef(InputForm) as (
  p: InputTextAreaProps & {
    ref?: HTMLTextAreaElement;
  },
) => React.ReactElement;

export default InputTextArea;
