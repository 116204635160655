import React, { useMemo } from "react";
import { Column } from "react-table";

import { ReportProcedureItem, ReportProcedureList } from "types/api/report";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportProcedureList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const { formatRupiah } = useFormatRupiah();
  const tableData = data?.entities ?? [];

  const columns: Column<ReportProcedureItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Kategori",
        accessor: (row) => row.namespace || "-",
      },
      {
        Header: "Nama Tindakan",
        accessor: (row) => row.procedure_name || "-",
      },
      {
        Header: "Tarif",
        accessor: (row) => formatRupiah(row.rate) || "-",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, take]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData?.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta?.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta?.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
