import { useState } from "react";
import { Col, Row } from "reactstrap";

import { BasicActionBtn, BasicHeader } from "components/base";

import {
  Main,
  Card,
  HeaderContainer,
  Item,
  ClinicDataWrapper,
  WideItem,
} from "Pages/Settings/components";
import SettingBpjsForm from "Pages/Settings/components/SettingBpjsForm";
import SettingSatusehatForm from "Pages/Settings/components/SettingSatusehatForm";
import InfoPrompt from "Pages/Settings/components/InfoPrompt";
import SettingClinicData from "Pages/Settings/components/SettingClinicDataForm";

import useIntegrationConfig from "hooks/utils/useIntegrationConfig";
import useClinicData from "hooks/clinic/useClinicData";

import { clinic_placeholder } from "assets";

enum ActiveCard {
  clinic = "clinic",
  bpjs = "bpjs",
  satusehat = "satusehat",
}

const SettingsKlinik = () => {
  const [isCardActive, setIsCardActive] = useState<ActiveCard | null>(null);
  const cardState = (card: ActiveCard) => {
    return isCardActive === card ? "active" : "";
  };

  const { bpjsConfigured, satusehatConfigured } = useIntegrationConfig();
  const { data: clinicData, loadingLogo } = useClinicData();

  const asterisk = "*******************";

  const getBpjsDisplayValue = () => {
    return bpjsConfigured ? asterisk : "-";
  };
  const getSatuSehatDisplayValue = () => {
    return satusehatConfigured ? asterisk : "-";
  };

  const getClinicDataDisplayValue = () => {
    return {
      logo: loadingLogo ? clinic_placeholder : clinicData?.logo,
      clinic_name: clinicData?.clinic_name || "-",
      email: clinicData?.email || "-",
      phone: clinicData?.phone_number || "-",
      address: clinicData?.address || "-",
    };
  };

  const bpjsDisplay = () => {
    return (
      <>
        <Item>
          <p>Cons ID</p>
          <p>{getBpjsDisplayValue()}</p>
        </Item>
        <Item>
          <p>Cons Secret</p>
          <p>{getBpjsDisplayValue()}</p>
        </Item>
        <Item>
          <p>Authorization Header</p>
          <p>{getBpjsDisplayValue()}</p>
        </Item>
      </>
    );
  };

  const satusehatDisplay = () => {
    return (
      <>
        <Item>
          <p>Client ID</p>
          <p>{getSatuSehatDisplayValue()}</p>
        </Item>
        <Item>
          <p>Cons Secret</p>
          <p>{getSatuSehatDisplayValue()}</p>
        </Item>
        <Item>
          <p>Organization ID</p>
          <p>{getSatuSehatDisplayValue()}</p>
        </Item>
      </>
    );
  };

  const clinicDataDisplay = () => {
    return (
      <ClinicDataWrapper>
        <img
          src={getClinicDataDisplayValue().logo ?? clinic_placeholder}
          alt="logo klinik"
        />
        <div>
          <WideItem>
            <p>Nama Klinik</p>
            <p>{getClinicDataDisplayValue().clinic_name}</p>
          </WideItem>
          <WideItem>
            <p>Email Klinik</p>
            <p>{getClinicDataDisplayValue().email}</p>
          </WideItem>
          <WideItem>
            <p>No. Telepon</p>
            <p>{getClinicDataDisplayValue().phone}</p>
          </WideItem>
          <WideItem>
            <p>Alamat Klinik</p>
            <p>{getClinicDataDisplayValue().address}</p>
          </WideItem>
        </div>
      </ClinicDataWrapper>
    );
  };

  return (
    <Main>
      <Row>
        <Col>
          <Card>
            <HeaderContainer className={`wide ${cardState(ActiveCard.clinic)}`}>
              <span></span>
              <BasicHeader>Pengaturan Klinik</BasicHeader>
              <BasicActionBtn
                onClick={() => setIsCardActive(ActiveCard.clinic)}
              >
                Ubah Data Klinik
              </BasicActionBtn>
            </HeaderContainer>

            {cardState(ActiveCard.clinic) ? (
              <SettingClinicData
                activeState={() => setIsCardActive(null)}
                clinicData={clinicData}
              />
            ) : (
              clinicDataDisplay()
            )}
          </Card>
        </Col>
      </Row>
      <Row
        style={{
          gap: "1rem",
        }}
      >
        <Col md={12} lg={6}>
          <Card>
            <InfoPrompt infoType="bpjs" />
            <HeaderContainer className={cardState(ActiveCard.bpjs)}>
              <BasicHeader>Integrasi BPJS</BasicHeader>
              <BasicActionBtn onClick={() => setIsCardActive(ActiveCard.bpjs)}>
                Ubah Data BPJS
              </BasicActionBtn>
            </HeaderContainer>

            {cardState(ActiveCard.bpjs) ? (
              <SettingBpjsForm activeState={() => setIsCardActive(null)} />
            ) : (
              bpjsDisplay()
            )}
          </Card>
        </Col>

        <Col>
          <Card>
            <InfoPrompt infoType="satusehat" />
            <HeaderContainer className={cardState(ActiveCard.satusehat)}>
              <BasicHeader>Integrasi SatuSehat</BasicHeader>
              <BasicActionBtn
                onClick={() => setIsCardActive(ActiveCard.satusehat)}
              >
                Ubah Data SatuSehat
              </BasicActionBtn>
            </HeaderContainer>

            {cardState(ActiveCard.satusehat) ? (
              <SettingSatusehatForm activeState={() => setIsCardActive(null)} />
            ) : (
              satusehatDisplay()
            )}
          </Card>
        </Col>
      </Row>
    </Main>
  );
};

export default SettingsKlinik;
