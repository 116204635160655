/**
 * @description Defined BPJS endpoints with the sub endpoint /icare.
 */

import { baseApi } from "store/baseStoreAPI";

const subEndpoint = "/bpjs/icare";

export const bpjsIcareApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    postIcare: builder.mutation<
      any,
      {
        noKartu: string;
      }
    >({
      query: ({ noKartu }) => {
        return {
          url: `${subEndpoint}`,
          method: "POST",
          body: {
            noKartu,
          },
        };
      },
    }),
  }),
});

export const { usePostIcareMutation } = bpjsIcareApi;
