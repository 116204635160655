import { Column } from "react-table";
import { parse, differenceInYears } from "date-fns";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";

import { TPesertaBpjsBase } from "types/api/bpjs";
import {
  useDeleteKelompokPesertaMutation,
  useGetKelompokPesertaQuery,
} from "store/bpjsStoreAPI/bpjsKelompokAPI";

import { COLORS } from "assets/theme";
import { BaseTable } from "components/base/Table";
import { useModal } from "components/ModalRefined/ModalRefined";
import CustomToast from "components/CustomToast";
import { BaseModalRefined } from "components/base/Modal";
import { BaseActionButton, BaseButton } from "components/base/Button";

type Props = {
  eduId?: string;
  search?: string;
};

const columns: Column<TPesertaBpjsBase>[] = [
  {
    Header: "No.",
    accessor: (_, i) => i + 1,
    width: 40,
  },
  {
    Header: "No. Kartu",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const noKartu = data?.peserta?.noKartu;
      const val = noKartu ? noKartu : "-";
      return <span>{val}</span>;
    },
  },
  {
    Header: "Nama Peserta",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const nama = data?.peserta?.nama;
      const val = nama ? nama : "-";
      return <span>{val}</span>;
    },
  },
  {
    Header: "Jenis Kelamin",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const sex = data?.peserta?.sex;
      const val = sex ? sex : "-";
      return <span>{val}</span>;
    },
  },
  {
    Header: "Jenis Peserta",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const jnsPeserta = data?.peserta?.jnsPeserta;
      const val = jnsPeserta
        ? `${jnsPeserta?.kode} - ${jnsPeserta?.nama}`
        : "-";
      return <span>{val}</span>;
    },
  },
  {
    Header: "Tanggal Lahir",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const tglLahir = data?.peserta?.tglLahir;
      const val = tglLahir ? tglLahir : "-";
      return <span>{val}</span>;
    },
  },
  {
    Header: "Usia",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const birthDate = data?.peserta?.tglLahir
        ? parse(data.peserta.tglLahir, "dd-MM-yyyy", new Date())
        : null;
      const age = birthDate ? differenceInYears(new Date(), birthDate) : "-";
      return <span>{age}</span>;
    },
  },
  {
    Header: "Aksi",
    Cell: ({
      row: { original: data },
    }: {
      row: { original: TPesertaBpjsBase };
    }) => {
      const { isOpen, toggleModal } = useModal();
      const noKartu = data?.peserta?.noKartu;
      const eduId = data?.eduId;

      const [deletePesertaKelompok] = useDeleteKelompokPesertaMutation();

      const handleDelete = () => {
        if (eduId && noKartu) {
          deletePesertaKelompok({
            eduId: eduId,
            noKartu: noKartu,
          })
            .unwrap()
            .then((res) => {
              toast.custom((t) => (
                <CustomToast
                  t={t}
                  status="success"
                  headerText="Berhasil"
                  description="Peserta berhasil dihapus dari kelompok"
                />
              ));
            })
            .catch((err) => {
              console.log(err);
              toast.custom((t) => (
                <CustomToast
                  t={t}
                  status="error"
                  headerText="Gagal"
                  description="Peserta gagal dihapus dari kelompok"
                />
              ));
            });
        }
      };

      return (
        <>
          <BaseModalRefined
            isOpen={isOpen}
            onClose={toggleModal}
            header="Konfirmasi"
            headerColor="alt"
            size="sm"
          >
            <div>
              <p
                style={{
                  margin: "12px 0",
                  textAlign: "center",
                }}
              >
                Apakah anda yakin ingin menghapus data peserta{" "}
                {data?.peserta?.nama}?
              </p>
              <div
                style={{
                  paddingTop: "24px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <BaseButton
                  type="button"
                  styleType="info"
                  onClick={() => toggleModal(false)}
                >
                  Batalkan
                </BaseButton>

                <BaseButton
                  type="button"
                  styleType="danger"
                  onClick={handleDelete}
                >
                  Hapus
                </BaseButton>
              </div>
            </div>
          </BaseModalRefined>

          <div className="flex justify-center">
            <BaseActionButton
              title="Hapus Kelompok"
              onClick={() => {
                toggleModal(true);
              }}
            >
              <MdDeleteForever size={18} color={COLORS.magenta} />
            </BaseActionButton>
          </div>
        </>
      );
    },
  },
];

const TableDaftarPeserta = ({ eduId, search }: Props) => {
  const { data: kelompokPeserta, isLoading: isLoadingKelompokPeserta } =
    useGetKelompokPesertaQuery(
      {
        eduId: eduId || "",
      },
      {
        skip: !eduId,
      },
    );

  const filteredKelompokPeserta =
    kelompokPeserta?.filter((peserta) => {
      if (!search) return true;
      return (
        peserta.peserta?.noKartu
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        peserta.peserta?.nama?.toLowerCase().includes(search.toLowerCase()) ||
        peserta.peserta?.jnsPeserta?.nama
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    }) || [];

  return (
    <>
      <BaseTable
        columns={columns}
        data={filteredKelompokPeserta}
        isHideNumbering
        showPagination={false}
        isLoading={isLoadingKelompokPeserta}
      />
    </>
  );
};

export default TableDaftarPeserta;
