import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { useGetProcedureReportQuery } from "store/reportStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableTindakan/TableTemplate";

const TableTindakan = () => {
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { data: reportData } = useGetProcedureReportQuery({
    page: page,
    take: take,
    query: debouncedSearchValue || undefined,
  });

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
      </div>
      {
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Jumlah Tindakan <span>{data?.meta?.itemCount ?? "-"}</span>
              </RecapItem>
            </RecapContainer>
            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              {_.isEmpty(data?.entities) ? null : <DownloadReport />}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      }
    </>
  );
};

export default TableTindakan;
