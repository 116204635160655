import { getPractitionerName } from "assets/usefulFunctions/practitioner";
import { useGetAccountsQuery } from "store/accountsStoreAPI";
import { useGetPractitionerInPolyQuery } from "store/clinicStoreAPI";
import { BackendMetaPagination } from "types";
import { APolyclinicWithId } from "types/api/clinic";
import { APractitionerOpt, AnAccountWithProfile } from "types/api/practitioner";

export type FilterOptions = "all" | "bpjs" | "non-bpjs";
export type FilterKunjungan = "all" | "sakit" | "sehat";

const useGroupedPractitionerList = (
  filterPractitioner: FilterOptions = "all",
  filterPolyclinic: FilterKunjungan = "all",
  isBpjs?: boolean,
  isActive?: boolean,
) => {
  const { data, isLoading, isFetching } = useGetPractitionerInPolyQuery({
    isBpjs,
    isActive,
  });

  const list = (data ?? []).flatMap(
    ({
      polyclinic_name,
      id,
      clinic_id,
      poli_sakit,
      userAccounts: practitioner,
      is_default,
    }) => {
      const options: APractitionerOpt[] = practitioner
        ?.filter((p) => {
          const dr = p.userProfile;
          const isBpjsActive = dr?.kd_dokter_bpjs;

          if (filterPractitioner === "bpjs" && isBpjsActive === null) {
            return false;
          }

          if (filterPractitioner === "non-bpjs" && isBpjsActive !== null) {
            return false;
          }

          if (filterPolyclinic === "sakit" && !poli_sakit) {
            return false;
          }

          if (filterPolyclinic === "sehat" && poli_sakit === true) {
            return false;
          }

          return true;
        })
        .map((p) => {
          const userProfile = p.userProfile;
          const kodeBpjs = userProfile?.kd_dokter_bpjs;
          const labelProfileName = userProfile
            ? getPractitionerName(userProfile)
            : "";

          return {
            ...p,
            label: `${polyclinic_name} ${is_default ? "Default" : ""} - ${
              labelProfileName || p.username
            }${kodeBpjs ? ` (${kodeBpjs})` : ""}`,
            value: p.id,
            clinic_id,
            polyclinic_id: id,
            polyclinic_name,
            userProfile,
          };
        });

      return options.length > 0 ? { label: polyclinic_name, options } : [];
    },
  );

  return {
    list,
    isLoading: isLoading || isFetching,
  };
};

export default useGroupedPractitionerList;

export const usePractitionerList = (isBpjs?: boolean, isActive?: boolean) => {
  const { data, isLoading, isFetching, refetch } =
    useGetPractitionerInPolyQuery({
      isActive,
      isBpjs,
    });
  const list = data?.reduce((acc, curr) => {
    const {
      clinic_id,
      id,
      polyclinic_name,
      userAccounts: practitioners,
    } = curr;
    const sl = acc.slice();
    const l = sl.length;
    practitioners.forEach((p, idx) => {
      const dr = p.userProfile;
      const drName = dr ? getPractitionerName(dr) : p.username;
      sl.push({
        no: l + idx + 1,
        value: p.id,
        label: drName,
        clinic_id,
        polyclinic_id: id,
        polyclinic_name,
        ...p,
      });
    });
    return sl;
  }, [] as (APractitionerOpt & { no: number })[]);
  return {
    list,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

export const useUserAccountPractitionerList = ({
  isActive,
  page = 1,
  take = 10,
}: {
  isActive?: boolean;
  page?: number;
  take?: number;
}) => {
  const { data, isLoading, isFetching } = useGetAccountsQuery({
    isDoctor: true,
    isActive,
    page,
    take,
  });

  const formatData = data?.data as [] as AnAccountWithProfile[];
  const numberAddition = (page - 1) * take;

  const sanitizedData = formatData?.map((d, idx) => {
    const { userProfile } = d;
    const drName = userProfile ? getPractitionerName(userProfile) : d.username;
    return {
      ...d,
      label: drName,
      value: d.id,
      no: idx + 1 + numberAddition,
    };
  }) as [] as (APractitionerOpt & {
    no: number;
    is_admin?: boolean;
    polyclinic: APolyclinicWithId;
  })[];

  const meta = data?.meta as BackendMetaPagination;

  return { data: sanitizedData, isLoading, isFetching, meta };
};
