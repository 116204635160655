import { useEffect } from "react";
import { clinicStoreAPI, useGetClinicQuery } from "store/clinicStoreAPI";
import { AClinicWithLogo } from "types/api/clinic";

const useClinicData = () => {
  const {
    data: clinicData,
    isLoading: clinicLoading,
    isFetching: clinicFetching,
  } = useGetClinicQuery();

  const [
    triggerGetLogo,
    { currentData: logoData, isLoading: logoLoading, isFetching: logoFetching },
  ] = clinicStoreAPI.endpoints.getClinicLogo.useLazyQuery();

  useEffect(() => {
    triggerGetLogo();
  }, [triggerGetLogo]);

  const combinedData = {
    ...clinicData,
    logo: logoData,
  } as AClinicWithLogo;

  const loadingData = clinicLoading || clinicFetching;

  const loadingLogo = logoLoading || logoFetching;

  return { data: combinedData, loadingData, loadingLogo, triggerGetLogo };
};

export default useClinicData;
