import { useState } from "react";
import { registerLocale } from "react-datepicker";
import { id } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

import { COLORS } from "assets/theme";
import {
  CalendarIcon,
  ClearIcon,
  Container,
  DatePickerContainer,
  GlobalStyle,
  StyledDatePicker,
} from "components/RangedDatepicker/style";

/**
 * RangedDatePickerProps interface for RangedDatePicker component.
 *
 * @interface
 * @property {string} [label] - The label of the date picker.
 * @property {string} [labelColor] - The color of the label.
 * @property {string} [className] - The CSS class to apply to the date picker.
 * @property {boolean} [fullWidth] - Whether the date picker should take up the full width of its container.
 * @property {function} onDateChange - Function to handle date changes. Adjust this function to set changes to redux or local state.
 * @property {Date|null} [startDate] - The start date. Only pass this prop when using redux state.
 * @property {Date|null} [endDate] - The end date. Only pass this prop when using redux state.
 */

registerLocale("id", id);

export interface RangedDatePickerProps {
  label?: string;
  labelColor?: string;
  className?: string;
  fullWidth?: boolean;
  onDateChange: (startDate: Date | null, endDate: Date | null) => void;
  startDate?: Date | null;
  endDate?: Date | null;
}

const RangedDatePicker = ({
  label,
  labelColor,
  className,
  fullWidth = false,
  onDateChange,
  startDate: initialStartDate = null,
  endDate: initialEndDate = null,
}: RangedDatePickerProps) => {
  const id = "datepicker-" + Math.random().toString(36).substring(2, 15);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    initialStartDate,
    initialEndDate,
  ]);
  const [startDate, endDate] = dateRange;

  const onChange = (dates: [Date | null, Date | null]) => {
    setDateRange(dates);
    onDateChange(...dates);
  };

  const clearDates = () => {
    setDateRange([null, null]);
    onDateChange(null, null);
  };

  return (
    <>
      <GlobalStyle />
      <Container className={className}>
        {label && (
          <label
            htmlFor={id}
            style={{ color: labelColor, marginBottom: "8px" }}
          >
            {label}
          </label>
        )}
        <DatePickerContainer>
          <StyledDatePicker
            id={id}
            placeholderText="Pilih Tanggal"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            isClearable={true}
            showIcon={false}
            clearButtonClassName="clear-button"
            dateFormat={"dd/MM/yyyy"}
          />
          {!startDate && !endDate && (
            <CalendarIcon size={20} color={COLORS.neutral_chromium} />
          )}
          {(startDate || endDate) && (
            <ClearIcon
              size={20}
              color={COLORS.neutral_chromium}
              onClick={clearDates}
            />
          )}
        </DatePickerContainer>
      </Container>
    </>
  );
};

export default RangedDatePicker;
