import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import {
  APaginationEntity,
  BasicSelectOpt,
  PaginationQueryParams,
} from "types";
import { ICD_10_CODE } from "types/api/diagnosis_icd";
import { convertToEncodedURL } from "assets/usefulFunctions";

export const diagnosisAPI = createApi({
  reducerPath: "diagnosisStoreAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/diagnoses`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["diagnosis"],
  endpoints: (builder) => {
    return {
      getDiagnosis: builder.query<
        APaginationEntity<BasicSelectOpt<string>[]>,
        {
          page: number;
          take: number;
          kode?: string;
        }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }

          return {
            method: "GET",
            url: params,
          };
        },
        providesTags: ["diagnosis"],
        transformResponse: (data: APaginationEntity<ICD_10_CODE[]>) => {
          const entities = data.entities.slice();
          return {
            entities: entities.map((v) => {
              return {
                value: v.code,
                label: `${v.code} - ${v.description}`,
              };
            }),
            meta: data.meta,
          };
        },
      }),
      getDiagnosisByCode: builder.query<
        BasicSelectOpt<string>,
        { kode: string }
      >({
        query: ({ kode }) => {
          return {
            url: kode,
            method: "GET",
          };
        },
        transformResponse: (v: ICD_10_CODE) => {
          return {
            ...v,
            value: v.code,
            label: `${v.code} (${v.root_code}) - ${v.description}`,
          };
        },
        providesTags: (_, __, { kode }) => [
          "diagnosis",
          { type: "diagnosis", id: kode },
        ],
      }),

      getDiagnosisList: builder.query<
        APaginationEntity<BasicSelectOpt<any>[]>,
        {
          kode?: string;
        } & PaginationQueryParams
      >({
        query: (args) => {
          const { page = 1, take = 10, kode } = args;

          return {
            url: "/",
            method: "GET",
            params: {
              page,
              take,
              kode: kode,
            },
          };
        },
        providesTags: ["diagnosis"],
        transformResponse: (res: APaginationEntity<ICD_10_CODE[]>) => {
          const entities = res.entities.slice();
          return {
            entities: entities.map((v) => {
              return {
                ...v,
                value: v.code,
                label: `${v.code} (${v.root_code}) - ${v.description}`,
              };
            }),
            meta: res.meta,
          };
        },
      }),
    };
  },
});

export const {
  useGetDiagnosisQuery,
  useGetDiagnosisByCodeQuery,
  useGetDiagnosisListQuery,
  util: { resetApiState: resetDiagnosisAPI },
} = diagnosisAPI;
