import styled, { css } from "styled-components";
import { COLORS } from "assets/theme";

interface BaseTextProps {
  className?: string;
  styleSize?: "10" | "12" | "14" | "16";
  bold?: boolean;
}

const styleSizes = {
  10: css`
    font-size: 10px;
  `,
  12: css`
    font-size: 12px;
  `,
  14: css`
    font-size: 14px;
  `,
  16: css`
    font-size: 16px;
  `,
};

export const BaseText = styled.p<BaseTextProps>`
  margin: 0;
  color: ${COLORS.text_black};
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};

  ${({ styleSize = "16" }) => styleSizes[styleSize]}
`;
