import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { format, parseISO } from "date-fns";
import toast from "react-hot-toast";

import { APatientMinWithId } from "types/api/pasien";
import { TIcare } from "types/api/bpjs";
import { usePostIcareMutation } from "store/bpjsStoreAPI/bpjsIcareAPI";
import { usePractitionerList } from "hooks/practitioner/usePractitionerList";

import { Container } from "./style";
import { BaseHeaderLayout, BaseLayout } from "components/base/Container";
import { BaseHeaderText } from "components/base/Text";
import {
  BaseRadioGroupInput,
  BaseSelectInput,
  BaseTextInput,
} from "components/base/Input";
import CustomToast from "components/CustomToast";
import { BaseButton } from "components/base/Button";
import BpjsCheckInput from "./components/BpjsCheckInput";
import SearchPatient from "./components/SearchPatient";

const Icare = () => {
  const [selectedPatient, setSelectedPatient] =
    useState<APatientMinWithId | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientBpjsActive, setPatientBpjsActive] = useState(false);

  const { list: practitionerList, isLoading: practitionerListLoading } =
    usePractitionerList(true);

  const icareForm = useForm<TIcare>();

  const [postICare] = usePostIcareMutation();

  const handleSubmit = () => {
    if (selectedPatient?.bpjs_no && icareForm.watch("doctor_id")) {
      const icareRedirect = window.open("", "_blank");
      icareRedirect?.document.write("Loading iCare...");

      postICare({ noKartu: selectedPatient.bpjs_no })
        .unwrap()
        .then((res) => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil"
              description="Data Riwayat Pelayanan Didapatkan. Redirecting..."
            />
          ));
          if (res && icareRedirect) {
            icareRedirect.location.href = res.url;
          }
        })
        .catch((err) => {
          const metaData = err?.data?.message?.metaData;
          const statusCode = metaData?.code;
          const reason = metaData?.message;
          const formattedMessage = reason ? `${reason}` : "";

          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText={`Gagal ${statusCode}`}
              description={`Terjadi kesalahan.${
                formattedMessage ? ` ${formattedMessage}` : ""
              }`}
            />
          ));
          icareRedirect?.close();
        });
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      const {
        full_name,
        nik,
        bpjs_no,
        encounter,
        sex,
        birth_place,
        birth_date,
      } = selectedPatient;

      let formattedBirthDate;
      if (birth_date) {
        const parsedDate = parseISO(birth_date);
        formattedBirthDate = format(parsedDate, "yyyy-MM-dd");
      }

      const currentValues = icareForm.getValues();

      icareForm.reset({
        full_name,
        nik,
        bpjs_no,
        encounter,
        sex,
        birth_place,
        birth_date: formattedBirthDate,
        doctor_id: currentValues.doctor_id,
      });
    }
  }, [icareForm, selectedPatient]);

  const doctorId = icareForm.watch("doctor_id");

  return (
    <BaseLayout>
      <BaseHeaderLayout center>
        <BaseHeaderText styleSize="24">iCare</BaseHeaderText>
      </BaseHeaderLayout>

      <form onSubmit={icareForm.handleSubmit(handleSubmit)}>
        <Container>
          <SearchPatient setSelectedPatient={setSelectedPatient} />

          <Controller
            name="nik"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                disabled
                required
                label="NIK"
                placeholder="NIK"
                type="text"
                value={value}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    onChange(e.target.value);
                  }
                }}
                maxLength={16}
                pattern="^[0-9]{16}$"
                title="NIK harus 16 digit angka"
              />
            )}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <BpjsCheckInput
              formData={icareForm}
              setPatientBpjsActive={setPatientBpjsActive}
            />
          </div>

          <Controller
            name="encounter"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                disabled
                label="No. Rekmed"
                placeholder="No. Rekmed"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="full_name"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                disabled
                required
                label="Nama"
                placeholder="Nama"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="sex"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseRadioGroupInput
                disabled
                id="jenis-kelamin-radio"
                legend="Jenis Kelamin"
                required
                options={[
                  {
                    label: "Laki-laki",
                    value: "male",
                  },
                  {
                    label: "Perempuan",
                    value: "female",
                  },
                ]}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                orientation="row"
                spacingOptions="24px"
              />
            )}
          />

          <Controller
            name="birth_place"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                disabled
                required
                label="Tempat Lahir"
                placeholder="Tempat Lahir"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            name="birth_date"
            control={icareForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                disabled
                required
                label="Tanggal Lahir"
                placeholder="Tanggal Lahir"
                type="date"
                value={value}
                onChange={onChange}
              />
            )}
          />

          <div className="span-full">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "4fr 1fr",
                gap: "10px",
              }}
            >
              <Controller
                name="doctor_id"
                control={icareForm.control}
                render={({ field: { value = "", onChange } }) => (
                  <BaseSelectInput
                    required
                    label="Dokter"
                    placeholder="Dokter"
                    options={practitionerList}
                    isLoading={practitionerListLoading}
                    onChange={(e) => onChange(e?.value)}
                  />
                )}
              />
              <BaseButton
                type="submit"
                disabled={!selectedPatient?.bpjs_no || !doctorId}
                styleType="alt"
                styleSize="regularSlim"
                style={{ alignSelf: "end" }}
              >
                iCare
              </BaseButton>
            </div>
          </div>
        </Container>
      </form>
    </BaseLayout>
  );
};

export default Icare;
