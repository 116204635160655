import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { add } from "date-fns";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import toast from "react-hot-toast";

import CookieMonster from "config/CookieMonster";
import { combinedRoutes } from "config/nav";
import { SignupRequest } from "types/api/signup";
import { useSignupMutation } from "store/signUpStoreAPI";

import { COLORS } from "assets/theme";
import { isBackendErrorItem } from "assets/usefulFunctions";
import CustomToast from "components/CustomToast";
import {
  FormInput,
  LGActionText,
  LGCheckBoxInput,
  LGDaftarText,
  LGEyeBtn,
  LGGroup,
  LGInputTextLabel,
  LGRegisterForm,
  LGSubmitButton,
  LGTOSLabel,
} from "Pages/LoginPage/components";

type Props = {
  setOpenTOS: React.Dispatch<React.SetStateAction<boolean>>;
};

const RegisterForm = ({ setOpenTOS }: Props) => {
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [signup, { isLoading }] = useSignupMutation();

  const registerForm = useForm<SignupRequest>({
    defaultValues: {
      clinic_name: "",
      username: "",
      email: "",
      password: "",
      is_practitioner: false,
    },
  });

  const handleSubmit = (formData: SignupRequest) => {
    if (formData.password !== confirmPassword) {
      setConfirmPasswordError("Password tidak sama, mohon ulangi");
      return;
    }

    signup(formData)
      .unwrap()
      .then(({ data }) => {
        CookieMonster.saveCookies(
          {
            access_token: data.access_token,
            username: data.username,
            roles: data.roles,
            id: data.id,
            is_bpjs_configured: data.is_bpjs_configured,
            is_satu_sehat_configured: data.is_satu_sehat_configured,
          },
          { expires: add(new Date(), { days: 1 }) },
        );
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText={`Selamat datang, ${data.username}!`}
              description={`Sebelum memulai, mohon lengkapi data untuk dokter pertama`}
              status="success"
            />
          ),
          {
            position: "bottom-right",
          },
        );
        navigate(combinedRoutes.initial_add_doctor_page);
      })
      .catch((e) => {
        let message = "Gagal Registrasi Akun!";
        if (isBackendErrorItem(e)) {
          message = Array.isArray(e.data.message)
            ? e.data.message.join(",")
            : e.data.message ?? message;
        }
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText={`Gagal!`}
              description={message}
              status="error"
            />
          ),
          {
            position: "bottom-right",
          },
        );
      });
  };

  const setCustomValidityOnCondition = (
    element: HTMLInputElement,
    condition: boolean,
    message: string,
  ) => {
    element.setCustomValidity(condition ? message : "");
  };

  const onInvalidTOSCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomValidityOnCondition(
      e.currentTarget,
      e.currentTarget.validity.valueMissing,
      "Mohon setujui TOS terlebih dahulu",
    );
  };

  return (
    <>
      <LGRegisterForm onSubmit={registerForm.handleSubmit(handleSubmit)}>
        <Controller
          name={"clinic_name"}
          control={registerForm.control}
          render={({ field: { onChange } }) => (
            <FormInput
              type="text"
              label="Nama Klinik"
              placeholder="Input nama klinik anda"
              autoComplete="off"
              onChange={onChange}
              disabled={isLoading}
              required
              className="show-star"
            />
          )}
        />
        <Controller
          name={"username"}
          control={registerForm.control}
          render={({ field: { onChange } }) => (
            <FormInput
              type="text"
              label="Username Klinik"
              placeholder="Input username untuk klinik anda"
              autoComplete="off"
              onChange={onChange}
              disabled={isLoading}
              maxLength={20}
              pattern="[^\s]{3,20}"
              title="Jumlah karakter 3-20 dan tanpa whitespace"
              required
            />
          )}
        />
        <Controller
          name={"email"}
          control={registerForm.control}
          render={({ field: { onChange } }) => (
            <FormInput
              type="email"
              label="Email"
              placeholder="Email untuk korespondensi"
              autoComplete="off"
              onChange={onChange}
              disabled={isLoading}
              required
            />
          )}
        />

        <Controller
          name="password"
          control={registerForm.control}
          render={({ field: { onChange } }) => (
            <FormInput
              type={showPwd ? "text" : "password"}
              label="Password"
              placeholder="Password"
              pattern="^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$"
              autoComplete="new-password"
              minLength={8}
              title="Password minimal 8 karakter (mengandung huruf besar, huruf kecil dan angka)"
              onChange={onChange}
              disabled={isLoading}
              required
              elementAddonRight={
                <LGEyeBtn
                  onMouseDown={() => {
                    setShowPwd(true);
                  }}
                  onMouseUp={() => setShowPwd(false)}
                >
                  {showPwd ? (
                    <BsEye color={COLORS.text_hint} />
                  ) : (
                    <BsEyeSlash color={COLORS.text_hint} />
                  )}
                </LGEyeBtn>
              }
            />
          )}
        />

        <div style={{ flexBasis: "100%" }}>
          <FormInput
            type={showPwd ? "text" : "password"}
            label="Konfirmasi Password"
            placeholder="Ulangi Password"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError("");
            }}
            disabled={isLoading}
            required
            elementAddonRight={
              <LGEyeBtn
                onMouseDown={() => {
                  setShowPwd(true);
                }}
                onMouseUp={() => setShowPwd(false)}
              >
                {showPwd ? (
                  <BsEye color={COLORS.text_hint} />
                ) : (
                  <BsEyeSlash color={COLORS.text_hint} />
                )}
              </LGEyeBtn>
            }
          />
          {confirmPasswordError && (
            <span style={{ color: "red" }}>{confirmPasswordError}</span>
          )}
        </div>

        <LGGroup
          style={{
            flexBasis: "100%",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <LGCheckBoxInput
            disabled={isLoading}
            required
            type="checkbox"
            id="TOS_check"
            name="TOS_check"
            onInvalid={onInvalidTOSCheckbox}
          />
          <LGInputTextLabel style={{ margin: 0 }} htmlFor="TOS_check">
            Saya menyetujui semua{" "}
          </LGInputTextLabel>
          <LGTOSLabel
            onClick={(e) => {
              e.stopPropagation();
              setOpenTOS(true);
            }}
          >
            Terms of Use
          </LGTOSLabel>
        </LGGroup>
        <LGGroup
          style={{
            flexBasis: "100%",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Controller
            name={"is_practitioner"}
            control={registerForm.control}
            render={({ field }) => (
              <LGCheckBoxInput
                disabled={isLoading}
                type="checkbox"
                id="isDoctor_check"
                name="isDoctor_check"
                onChange={field.onChange}
              />
            )}
          />
          <LGInputTextLabel style={{ margin: 0 }} htmlFor="isDoctor_check">
            Saya adalah dokter dari klinik ini
          </LGInputTextLabel>
        </LGGroup>
        <LGSubmitButton disabled={isLoading} type="submit">
          {isLoading ? "Mohon Menunggu..." : "Daftar"}
        </LGSubmitButton>
        <LGGroup style={{ flexBasis: "100%" }}>
          <LGActionText>
            Anda sudah memiliki akun?{" "}
            <LGDaftarText
              className={isLoading ? "disabled" : ""}
              to={combinedRoutes.login_page}
            >
              Masuk
            </LGDaftarText>
          </LGActionText>
        </LGGroup>
      </LGRegisterForm>
    </>
  );
};

export default RegisterForm;
