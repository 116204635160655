import styled from "styled-components";
import { COLORS } from "assets/theme";
import PureInputSelect from "components/PureInputSelect/PureInputSelect";

export const BaseSelectInput = styled(PureInputSelect)`
  &.full-width {
    width: 100%;
  }
  & label {
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & div[class$="-control"] {
    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }
` as typeof PureInputSelect;
