import { COLORS } from "assets/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StatsTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  color: ${COLORS.dark_grey_1};

  &.secondary {
    font-size: 14px;
  }
`;

export const Pricing = styled.p`
  color: ${COLORS.black_1};
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

export const PatientCount = styled.p`
  color: ${COLORS.black_1};
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;
