import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { COLORS, extraLarge, medium, small } from "assets/theme";
import { background_min_png } from "assets/background";
import { BasicText } from "components/base";
import InputText from "components/InputText/InputText";

export const LGBackground = styled.div`
  /* background-color: #7493be; */
  background-image: url(${background_min_png});
  background-size: cover;
  background-attachment: fixed;
  background-clip: content-box;
  background-repeat: repeat;
  background-position: 25% top;
  /* background-color: wheat; */
  min-height: 100vh;
  width: 100%;
  margin-top: 0;
`;

export const LGContainerAligner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  min-height: 100vh;
  padding: 24px 0;

  max-width: 50%;
  margin-left: 50%;
  ${medium} {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const LGContainer = styled.div`
  padding: 24px;
  border: 1px solid black;
  border-radius: 8px;
  width: 80%;
  /* width: max(); */
  max-width: 450px;
  /* ${extraLarge} {
    max-width: 360px;
  } */
  background-color: rgba(74, 92, 118, 0.75);
  border: 0.5px solid #7493be;
  /* justify-content: flex-end; */

  ${small} {
    padding: 12px;
  }
`;

export const LGLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
`;

export const LGLoginForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
`;

export const LGRegisterForm = styled(LGLoginForm)``;

export const LGGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LGHeaderText = styled.h1`
  color: ${COLORS.light_blue_1};
  font-weight: 700;
  font-size: 1.6rem;
`;

export const FormInput = styled(InputText)`
  flex-basis: 100%;
  margin-bottom: 14px;
  &.s {
    flex-basis: 48%;
  }
  /* ${medium} {
    &.s {
      flex-basis: 100%;
    }
  } */
  & label {
    color: white;
    margin-bottom: 8px;
  }

  &.show-star .required-star {
    display: inline;
  }

  & .wrapper {
    border: 0.5px solid #7493be;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;

    :focus-within {
      outline: none;
      background: rgba(142, 181, 238, 0.5);
    }
    background: rgba(142, 181, 238, 0.2);
  }
  & input {
    color: ${COLORS.light_blue_1};
    ::placeholder {
      color: rgba(227, 241, 255, 0.5); // light blue rgba
    }
    background: transparent;
    :focus-visible {
      outline: none;
      /* opacity: 0.5; */
      /* background: rgba(142, 181, 238, 0.5); */
    }
    /* :invalid {
      background-color: red;
    } */
    /* 
    background: rgba(142, 181, 238, 0.2); */
    /* border-radius: 4px; */
    border: none;

    padding: 10px 12px;
    /* padding: 6px; */
    box-sizing: border-box;

    :disabled {
      background: #8eb5ee22;
    }
  }
`;

export const LGEyeBtn = styled.span`
  border: none;
  background: transparent;
`;

export const LGInputText = styled.input`
  color: ${COLORS.light_blue_1};
  ::placeholder {
    color: rgba(227, 241, 255, 0.5); // light blue rgba
  }
  :focus-visible {
    outline: none;
    background: rgba(142, 181, 238, 0.5);
  }
  background: rgba(142, 181, 238, 0.2);
  border: 0.5px solid #7493be;
  border-radius: 4px;
  padding: 10px 12px;
  /* padding: 6px; */
  box-sizing: border-box;

  :disabled {
    background: #8eb5ee22;
  }
`;

export const LGInputTextLabel = styled.label`
  color: white;
  margin-bottom: 8px;
`;

export const LGCheckBoxInput = styled.input.attrs({ type: "checkbox" })`
  margin: 2px;
  line-height: normal;
  accent-color: ${COLORS.green};
`;

const textdisabled = css`
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;
export const LGTOSLabel = styled.span`
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
  color: ${COLORS.green_btn};
  font-weight: 700;
  ${textdisabled}
`;

export const LGForgetText = styled(Link)`
  margin: 0;
  text-decoration: underline;
  color: white;
  ${textdisabled}
`;

export const LGSubmitButton = styled.button`
  background: linear-gradient(135deg, #02ca8e 0%, #00cbd8 100%);
  /* flex: 1; */
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: none;
  padding: 10px 43px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  align-self: center;
  /* margin-left: auto;
  margin-right: auto; */
`;

export const LGActionText = styled(BasicText)`
  color: white;
  display: inline;
  text-align: center;
  margin-bottom: 8px;
`;

export const LGDaftarText = styled(Link)`
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 700;
  color: #4cedb3;
  ${textdisabled}
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: rgba(74, 92, 118, 0.7);
  border: 1px solid #7493be;
  border-radius: 4px;
  padding: 12px;

  span {
    color: white;
  }

  .accent {
    color: #44d6cd;
    font-weight: 600;
  }

  ${small} {
    font-size: 14px;
    padding: 4px;
  }
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;

  ${small} {
    gap: 16px;
  }
`;

export const InfoTitleBar = styled.div`
  width: 4px;
  align-self: stretch;
  background: linear-gradient(135deg, #02ca8e 0%, #00cbd8 100%);
  border-radius: 4px;
`;

export const InfoDescription = styled.span`
  color: white;
  font-size: 16px;
  font-weight: 500;
`;
