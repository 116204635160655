import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { combinedRoutes } from "config/nav";
import useAmIAdmin from "hooks/admin/useAmIAdmin";
import useAmIPractitioner from "hooks/practitioner/useAmIPractitioner";
import useSubscriptionCheck from "hooks/practitioner/useSubscriptionCheck";
import useToggle from "hooks/useToggle";

import { SubsModal } from "./style";
import { BasicActionBtn } from "components/base";

const SubsReminder = () => {
  const navigate = useNavigate();
  const isAdmin = useAmIAdmin();
  const isPractitioner = useAmIPractitioner();
  const { isSubscriptionActive, remainingDays, isLoading } =
    useSubscriptionCheck();

  const { value: showModal, toggle: toggleModal } = useToggle(false);

  useEffect(() => {
    const subsReminderStorageItem = localStorage.getItem(
      "hasShownSubsReminder",
    );
    const subsReminderStorage = subsReminderStorageItem
      ? JSON.parse(subsReminderStorageItem)
      : null;
    const isExpired =
      subsReminderStorage && new Date().getTime() > subsReminderStorage.expiry;

    if (
      !isLoading &&
      isPractitioner &&
      remainingDays <= 7 &&
      (!subsReminderStorage || isExpired)
    ) {
      toggleModal();
      const showModal = {
        value: true,
        expiry: new Date().getTime() + 24 * 60 * 60 * 1000, // refresh reminder after 24 hours
      };
      localStorage.setItem("hasShownSubsReminder", JSON.stringify(showModal));
    }
  }, [remainingDays, toggleModal, isPractitioner, isLoading]);

  return (
    <>
      <SubsModal
        headerText={
          isSubscriptionActive ? "Langganan" : "Langganan Anda Tidak Aktif"
        }
        isShown={showModal}
        toggle={toggleModal}
      >
        <div className="children-container">
          {isSubscriptionActive ? (
            <p>
              Langganan akun anda akan segera berakhir dalam{" "}
              <span>{remainingDays} Hari</span>. Perpanjang akun anda untuk
              menikmati semua fitur yang tersedia di REKMED.
            </p>
          ) : (
            <p>
              Langganan akun anda <span>tidak aktif / telah berakhir.</span>{" "}
              Perpanjang akun anda untuk menikmati semua fitur yang tersedia di
              REKMED.
            </p>
          )}

          {isAdmin && isPractitioner ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <BasicActionBtn
                className="info slim"
                type="button"
                onClick={() => toggleModal(false)}
              >
                Perpanjang Nanti
              </BasicActionBtn>
              <BasicActionBtn
                className="slim"
                onClick={() => navigate(combinedRoutes.langganan)}
              >
                Perpanjang Sekarang
              </BasicActionBtn>
            </div>
          ) : (
            <span>
              Silahkan hubungi admin klinik untuk memperpanjang langganan anda.
            </span>
          )}
        </div>
      </SubsModal>
    </>
  );
};

export default SubsReminder;
