import React from "react";
import { Column } from "react-table";

import { BaseCheckboxInput } from "components/base/Input";
import { BaseTable } from "components/base/Table";
import { useGetProlanisParticipantQuery } from "store/bpjsStoreAPI/bpjsProlanisAPI";
import { TPesertaProlanis } from "types/api/bpjs";
import { differenceInYears, format } from "date-fns";

type Props = {
  selectedPesertaProlanis: any[];
  setSelectedPesertaProlanis: React.Dispatch<React.SetStateAction<any[]>>;
};

const TableAddPeserta = ({
  selectedPesertaProlanis,
  setSelectedPesertaProlanis,
}: Props) => {
  const { currentData: pesertaProlanis } = useGetProlanisParticipantQuery();

  const handleCheckboxChange = (row: any, checked: boolean) => {
    if (checked) {
      setSelectedPesertaProlanis((prevSelected) => [...prevSelected, row]);
    } else {
      setSelectedPesertaProlanis((prevSelected) =>
        prevSelected.filter((selectedRow) => selectedRow.id !== row.id),
      );
    }
  };

  console.log(selectedPesertaProlanis);

  const columns: Column<TPesertaProlanis>[] = [
    {
      Header: "No.",
      accessor: (_, i) => i + 1,
      width: 40,
    },
    {
      accessor: "no_kartu",
      Header: "No. Kartu",
    },
    {
      accessor: "name",
      Header: "Nama Peserta",
    },
    {
      accessor: "sex",
      Header: "Jenis Kelamin",
      Cell: ({ value }) => {
        return <span>{value === "male" ? "Laki-laki" : "Perempuan"}</span>;
      },
    },
    {
      accessor: "type",
      Header: "Jenis Peserta",
    },
    {
      accessor: "birth_date",
      Header: "Tanggal Lahir",
      Cell: ({ value }) => {
        return <span>{format(new Date(value), "dd/mm/yyyy")}</span>;
      },
    },
    {
      Header: "Usia",
      // @ts-ignore
      Cell: ({ row: { original: o } }) => {
        const age = differenceInYears(new Date(), new Date(o.birth_date));
        return <span>{age}</span>;
      },
    },
    {
      accessor: "id",
      Header: "Aksi",
      Cell: ({ row }) => {
        const isChecked = selectedPesertaProlanis.some(
          (selectedRow) => selectedRow.id === row.original.id,
        );

        return (
          <BaseCheckboxInput
            checked={isChecked}
            onChange={(e) =>
              handleCheckboxChange(row.original, e.target.checked)
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        data={pesertaProlanis || []}
        isHideNumbering
        showPagination={false}
        isLoading={false}
      />
    </>
  );
};

export default TableAddPeserta;
