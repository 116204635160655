import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { parse } from "date-fns";

import { APatientMinWithId } from "types/api/pasien";
import { TPesertaBpjsPureBase } from "types/api/bpjs";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { BaseTextInput } from "components/base/Input";
import { BaseButton } from "components/base/Button";
import { BaseText } from "components/base/Text";
import CustomToast from "components/CustomToast";

import { usePostProlanisParticipantMutation } from "store/bpjsStoreAPI/bpjsProlanisAPI";

import SearchPatient from "../SearchPatient";
import BpjsCheckInput from "../BpjsCheckInput";
import { Container } from "./style";

type Props = {
  eduId?: string;
  toggleModal: (value?: boolean | undefined) => void;
};

const AddPesertaProlanisForm = ({ eduId, toggleModal }: Props) => {
  const [selectedPatient, setSelectedPatient] =
    useState<APatientMinWithId | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientBpjsActive, setPatientBpjsActive] = useState(false);
  const [patientBpjsData, setPatientBpjsData] =
    useState<TPesertaBpjsPureBase | null>(null);
  const { formatRupiah } = useFormatRupiah();

  const PesertaProlanis = useForm<any>();

  const [createPesertaProlanis] = usePostProlanisParticipantMutation();

  const handleSubmit = () => {
    if (!patientBpjsData) return;

    createPesertaProlanis({
      active_date: parse(
        patientBpjsData?.tglMulaiAktif,
        "dd-mm-yyyy",
        new Date(),
      ).toISOString(),
      no_kartu: patientBpjsData?.noKartu,
      name: patientBpjsData?.nama,
      sex: patientBpjsData?.sex === "P" ? "female" : "male",
      birth_date: parse(
        patientBpjsData?.tglLahir,
        "dd-mm-yyyy",
        new Date(),
      ).toISOString(),
      class_type: patientBpjsData?.jnsKelas.nama,
      status: patientBpjsData?.ketAktif === "AKTIF" ? true : false,
      type: patientBpjsData?.jnsPeserta.nama,
      expired_date: parse(
        patientBpjsData?.tglAkhirBerlaku,
        "dd-mm-yyyy",
        new Date(),
      ).toISOString(),
    })
      .unwrap()
      .then((res) => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Berhasil"
            description="Peserta prolanis berhasil ditambahkan"
          />
        ));
      })
      .catch((err) => {
        console.log(err);
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description="Peserta prolanis gagal ditambahkan"
          />
        ));
      });

    toggleModal(false);
  };

  useEffect(() => {
    if (selectedPatient) {
      const { bpjs_no } = selectedPatient;
      PesertaProlanis.setValue("bpjs_no", bpjs_no);
    }
  }, [selectedPatient, PesertaProlanis]);

  return (
    <>
      <form onSubmit={PesertaProlanis.handleSubmit(handleSubmit)}>
        <Container>
          <SearchPatient setSelectedPatient={setSelectedPatient} />
          <div>
            <BpjsCheckInput
              formData={PesertaProlanis}
              setPatientBpjsActive={setPatientBpjsActive}
              setPatientBpjsData={setPatientBpjsData}
            />
          </div>
          <BaseTextInput
            disabled
            label="Nama"
            placeholder="Nama Peserta"
            type="text"
            defaultValue={patientBpjsData?.nama}
          />
          <BaseTextInput
            disabled
            label="Jenis Kelamin"
            placeholder="Jenis Kelamin"
            type="text"
            defaultValue={patientBpjsData?.sex}
          />
          <BaseTextInput
            disabled
            label="Tanggal Lahir"
            placeholder="Tanggal Lahir"
            type="text"
            defaultValue={patientBpjsData?.tglLahir}
          />
          <BaseTextInput
            disabled
            label="Jenis Kelas"
            placeholder="Jenis Kelas"
            type="text"
            defaultValue={patientBpjsData?.jnsKelas?.nama}
          />
          <BaseTextInput
            disabled
            label="Status Peserta"
            placeholder="Status Peserta"
            type="text"
            defaultValue={patientBpjsData?.ketAktif}
          />
          <BaseTextInput
            disabled
            label="Jenis Peserta"
            placeholder="Jenis Peserta"
            type="text"
            defaultValue={patientBpjsData?.jnsPeserta?.nama}
          />
          <BaseTextInput
            disabled
            label="Tanggal Mulai Aktif"
            placeholder="Tanggal Mulai Aktif"
            type="text"
            defaultValue={patientBpjsData?.tglMulaiAktif}
          />
          <BaseTextInput
            disabled
            label="Tanggal Akhir Berlaku"
            placeholder="Tanggal Akhir Berlaku"
            type="text"
            defaultValue={patientBpjsData?.tglAkhirBerlaku}
          />
          <BaseText>
            Tunggakan:{" "}
            {patientBpjsData?.tunggakan
              ? formatRupiah(patientBpjsData?.tunggakan)
              : "Rp. 0"}
          </BaseText>

          <div
            className="span-full"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <BaseButton styleType="regular" type="submit">
              Simpan
            </BaseButton>
          </div>
        </Container>
      </form>
    </>
  );
};

export default AddPesertaProlanisForm;
