import styled from "styled-components";
import { COLORS } from "assets/theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StatsLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: ${COLORS.dark_grey_1};
`;

export const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  background: linear-gradient(
    120deg,
    ${COLORS.gradient_blue_chart_1} 0%,
    ${COLORS.gradient_blue_chart_2} 100%
  );
`;

export const ScrollableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;
