import React, { useMemo } from "react";
import { Column } from "react-table";

import { ReportDoctorItem, ReportDoctorList } from "types/api/report";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportDoctorList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const tableData = data?.entities ?? [];

  const columns: Column<ReportDoctorItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Username",
        accessor: (row) => row.username || "-",
      },
      {
        Header: "NIK",
        accessor: (row) => row.nik || "-",
      },
      {
        Header: "STR",
        accessor: (row) => row.str_no || "-",
      },
      {
        Header: "Nama Dokter",
        accessor: (row) => row.first_name || "-",
      },
      {
        Header: "Poli",
        accessor: (row) => row.polyclinic_name || "-",
      },
      {
        Header: "Kode Dokter",
        accessor: (row) => row.kd_dokter_bpjs || "-",
      },
      {
        Header: "Status BPJS",
        accessor: (row) => (row.kd_dokter_bpjs ? "✅" : "❌"),
      },
      {
        Header: "No. Telepon",
        accessor: (row) => row.phone_number || "-",
      },
      {
        Header: "Alamat",
        accessor: (row) => row.address || "-",
      },
    ];
  }, [page, take]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData?.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta?.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta?.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
