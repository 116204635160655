import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportType } from "types/report";

interface ReportState {
  reportType: ReportType | undefined;
}

const initialState: ReportState = {
  reportType: undefined,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportType: (state, action: PayloadAction<ReportType | undefined>) => {
      state.reportType = action.payload;
    },
    resetReport: () => initialState,
  },
});

export const { setReportType, resetReport } = reportSlice.actions;

export default reportSlice.reducer;
