// import TimeDisplay from "../TimeDisplay/TimeDisplay";
// import { StyledNavbar } from "./Navbar.style";
// import styled from "styled-components";
// import { MdLogout, MdMenu, MdNotificationsNone } from "react-icons/md";
// import { COLORS } from "../../assets/theme";
// import { useMediaQuery } from "../../hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavbarItemProps, NavbarItemWithoutKiddo } from "types/navbar";
import styled, { css } from "styled-components";
import { logo_putih } from "assets/logos";
import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import { IoMdNotificationsOutline } from "react-icons/io";
import CustomDropdown from "components/CustomDropdown";
import { useLogout } from "hooks/auth";
import { MdAccountBox, MdChevronLeft } from "react-icons/md";
import { BsList } from "react-icons/bs";
import "bootstrap/js/dist/collapse";
import useMyProperName from "hooks/practitioner/useMyProperName";
import { CustomDropdownItemProps } from "types/components";
import CookieMonster from "config/CookieMonster";
import { Role } from "types/roles";
import { combinedRoutes } from "config/nav";
import useIntegrationConfig from "hooks/utils/useIntegrationConfig";
// import { toggleSidebarOpen } from "../../redux/sidebarStore";
// import { useAppDispatch, useAppSelector } from "../../hooks";
type Props = {
  backgroundColor?: string;
  logo?: string;
  navitems: NavbarItemProps[];
};

// const StyledIconWrapper = styled.div`
//   display: flex;
//   gap: 12px;
//   margin: 12px;
// `;

// const StyledImageNavbar = styled.img`
//   object-fit: contain;
//   width: 56px;
//   margin-left: 16px;
// `;

const Nav = styled.nav`
  background: ${COLORS.green_linear_gradient};
  .navbar-toggler {
    &:focus {
      box-shadow: none;
    }
  }
  // /* padding-top: 50px; */
  // /* 120 + 50 of sticky innernav */
  // height: 70px;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 999;
`;

const InnerContainer = styled.div`
  background: ${COLORS.green_linear_gradient};
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // height: 150px;
  // margin-bottom: 80px;
  // position: fixed;
  // width: 100%;
  // z-index: 98;
  // /* position: sticky; */
  // padding-top: 2px;
  // padding-bottom: 2px;
  .navbar-collapse {
    max-height: max-content;
  }
`;
/* position: ${({ isScrolling }) => (isScrolling ? "fixed" : "relative")};
  top: ${({ isScrolling }) => (isScrolling ? 0 : "auto")};
  width: "100%";
  */

const LogoContainer = styled(Link)`
  // flex-basis: 150px;
  // padding: 0 0 0 16px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
`;

const NavListContainer = styled.ul`
  // list-style-type: none;
  // display: flex;
  // justify-content: center;
  // justify-content: between;
  // margin: 0 !important;

  &.collapse.show {
    margin: 0 !important;
    border: 1px solid red;

    li {
      visibility: visible;
      display: block !important;
      width: 50vw;
      border: 1px solid red;
    }
  }
`;

const NavListItem = styled.li`
  padding: 5px 10px;
  // margin-bottom: 5px;
  visibility: invisible;
  // min-width: 116px;
`;

const linkCSS = css`
  text-decoration: none;
  color: white;
  cursor: pointer;
  &.active {
    font-weight: 600;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }
  :hover:not(.disabled) {
    text-decoration: underline;
  }
`;

const Navlink = styled(Link)`
  ${linkCSS}
`;

const NavLinkAsChild = styled(Link)`
  ${linkCSS}
  color: ${COLORS.text_black};
  :hover:not(.disabled) {
    text-decoration: none;
  }
`;

// const NavlinkContainer = styled.ul`
//   ${linkCSS}

// `

const ActionContainer = styled.div`
  width: 134px;
  display: flex;
  padding: 16px 0;
  gap: 5px;
  align-items: center;
  justify-content: end;
  /* & > button {
    padding: 5px;
    background: transparent;
  } */
  /* align-items: center; */
`;

const ActionButton = styled(BasicActionBtn)`
  padding: 5px;
  background: transparent;
`;

const Dropdown = styled(CustomDropdown)`
  & .content {
    border-radius: 4px;
  }
  & .item {
    :hover {
      color: black;
      background: ${COLORS.mint_green};
    }
    &.no-action {
      pointer-events: none;
      font-weight: 600;
    }
  }
`;

const NavListDropdown = styled(CustomDropdown)`
  ${linkCSS}
  & .content {
    top: 150%;
    border-radius: 4px;
  }
  & .item {
    padding: 0;
    :first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    :last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    > * {
      display: block;
      padding: 8px 12px;
      width: 100%;
    }

    :hover {
      color: black;
      background: ${COLORS.mint_green};
    }
  }
`;

const DropdownChevron = styled(MdChevronLeft)`
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
  margin-top: 2px;
  vertical-align: middle;
  &.open {
    transform: rotate(-270deg);
  }
`;

const Container = styled.div`
  height: 0;
  padding-bottom: 130px;
  background: ${COLORS.green_linear_gradient};
`;

/**
 * @todo put this predicate somewhere suitable
 * will filter a navbar item from unwanted roles
 * @param k
 */
const navbarfilterPredicate = (k: NavbarItemWithoutKiddo): boolean => {
  if (k.availableToRoles && k.availableToRoles.length) {
    const { availableToRoles } = k;
    const roles = CookieMonster.loadCookie("roles") as unknown as
      | Role[]
      | undefined;
    if (roles && roles.length) {
      return availableToRoles.some((role) => {
        return roles.some((r) => {
          return role === r;
        });
      });
    }
    return false;
  }
  return true;
};

const Navbar: React.FunctionComponent<Props> = ({ navitems }) => {
  const logout = useLogout();
  const navigate = useNavigate();
  const routes = combinedRoutes;
  const role = CookieMonster.loadCookie("roles") as unknown as Role;
  const { name, str_no } = useMyProperName();
  const { bpjsConfigured } = useIntegrationConfig();
  const [filteredNavItems, setFilteredNavItems] = useState(navitems);

  useEffect(() => {
    if (!bpjsConfigured) {
      setFilteredNavItems(navitems.filter((item) => item.title !== "BPJS"));
    } else {
      setFilteredNavItems(navitems);
    }
  }, [bpjsConfigured, navitems]);

  // const username = CookieMonster.loadCookie("username");
  // const isPractitioner = useAmIPractitioner();
  // useLogin
  // const [isScrolling, setIsScrolling] = React.useState(false);

  // const containerRef = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   const container = containerRef.current;
  //   const offset = container?.offsetTop ?? 0;
  //   let listener = function (this: Window) {
  //     setIsScrolling(() => {
  //       return this.scrollY >= offset;
  //     });
  //   };
  //   window.addEventListener("scroll", listener);

  //   return () => {
  //     window.removeEventListener("scroll", listener);
  //   };
  // }, [containerRef.current]);
  // const isGreaterThan1024 = useMediaQuery("(min-width: 1024px)");
  // const sidebarOpen = useAppSelector(
  //   ({ sidebarStore }) => sidebarStore.sidebarOpen,
  // );
  // const dispatch = useAppDispatch();
  // const toggleExpandOpen = () => {
  //   dispatch(toggleSidebarOpen(true));
  // };

  const items: CustomDropdownItemProps[] = [
    {
      name: "Pengaturan Akun",
      onClick: () => navigate(routes.pengaturan_akun),
    },
    ...(role?.includes("admin")
      ? [
          {
            name: "Langganan",
            onClick: () => navigate(routes.langganan),
          },
        ]
      : []),
    { name: "Keluar", onClick: () => logout() },
  ];

  if (str_no)
    items.unshift({
      name: `STR: ${str_no}`,
      hasNoAction: true,
    });

  items.unshift({
    name,
    hasNoAction: true,
  });

  useEffect(() => {
    const navItems = document.querySelectorAll("a");
    navItems.forEach((navItem) => {
      navItem.addEventListener("click", () => {
        const navbarCollapse = document.querySelector(".navbar-collapse.show");
        if (navbarCollapse) {
          navbarCollapse.classList.remove("show");
        }
      });
    });
  }, []);

  return (
    <Container>
      <Nav
        className="navbar navbar-expand-md fixed-top border border-0"
        id="main-navbar"
      >
        <InnerContainer className="container-fluid">
          <div className="left-side order-md-1">
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <BsList size={35} color="white" />
            </button>
            <LogoContainer
              className="navbar-brand"
              to="/"
              style={{ margin: "0" }}
            >
              <img src={logo_putih} width="134px" alt="Rekmed" />
            </LogoContainer>
          </div>
          <ActionContainer className="order-md-3">
            {/* Temporary Disabled */}
            {/* <ActionButton>
              <IoMdNotificationsOutline size={21} />
            </ActionButton> */}
            <Dropdown
              width={100}
              backgroundColor="transparent"
              contentColor="white"
              direction="right"
              name="Account"
              items={items}
            >
              {(isOpen, setIsOpen) => {
                return (
                  <ActionButton onClick={() => setIsOpen(!isOpen)}>
                    <MdAccountBox size={24} />
                  </ActionButton>
                );
              }}
            </Dropdown>
          </ActionContainer>
          <div
            className="collapse navbar-collapse order-md-2 justify-content-center pb-4 pb-md-0"
            id="navbarSupportedContent"
          >
            <NavListContainer className="navbar-nav p-0 d-flex gap-md-1 gap-lg-3">
              {filteredNavItems.filter(navbarfilterPredicate).map((i) => {
                return <NavItemComponent key={i.to} {...i} />;
              })}
            </NavListContainer>
          </div>
        </InnerContainer>
      </Nav>
    </Container>
  );
};

export default Navbar;

const NavItemComponent: React.FC<NavbarItemProps> = ({
  to,
  title,
  isDisabled,
  kiddos,
}) => {
  const { pathname } = useLocation();
  const isActive = pathname.indexOf(to) === 0;

  return (
    <NavListItem className="nav-item d-flex justify-content-center">
      {kiddos?.length ? (
        <NavListDropdown
          className="object-fit-contain"
          width={100}
          backgroundColor="transparent"
          contentColor="white"
          direction="right"
          name={title}
          items={kiddos.filter(navbarfilterPredicate).map(({ title, to }) => {
            const isActive = pathname.indexOf(to) === 0;
            return {
              name: (
                <>
                  <NavLinkAsChild
                    className={[isDisabled && "disabled", isActive && "active"]
                      .filter((v) => !!v)
                      .join(" ")}
                    aria-disabled={!!isDisabled}
                    // isActive={to === pathname}
                    to={to}
                    // isDisabled={isDisabled}
                  >
                    {title}
                  </NavLinkAsChild>
                </>
              ),
              onClick: (e) => e.stopPropagation(),
            };
          })}
        >
          {(isOpen, toggle) => (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => toggle(!isOpen)}
            >
              {title}
              <DropdownChevron className={isOpen ? "open" : ""} size={16} />
            </div>
          )}
        </NavListDropdown>
      ) : (
        <Navlink
          className={[isDisabled && "disabled", isActive && "active"]
            .filter((v) => !!v)
            .join(" ")}
          aria-disabled={!!isDisabled}
          // isActive={to === pathname}
          to={to}
          // isDisabled={isDisabled}
        >
          {title}
        </Navlink>
      )}
    </NavListItem>
  );
};
