import styled from "styled-components";
import { COLORS, xSmall, small, medium, large } from "assets/theme";
import Modal from "components/Modal/Modal";

export const BasicText = styled.p`
  color: ${COLORS.text_black};
  margin: 0;
`;

export const BasicHeader = styled.h1`
  font-weight: 700;
  background: ${COLORS.green_linear_gradient};
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin: 0;
  font-size: 32px;
`;

export const BasicActionBtn = styled.button`
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border: 0;
  border-radius: 6px;
  color: white;
  background: ${COLORS.green_linear_gradient};
  :hover {
    opacity: 0.7;
  }
  :disabled {
    pointer-events: none;
    background: ${COLORS.text_gray};
  }
  &.danger {
    background: ${COLORS.red_linear_gradient};
  }
  &.info {
    background: ${COLORS.silver_lining};
    color: ${COLORS.text_black};
  }
  &.compact {
    height: 30px;
    padding: 4px 8px;
  }
  &.slim {
    padding: 10px 10px;
  }
  &.ghost {
    height: 38px;
    width: max-content;
    background-color: ${COLORS.white};
    background: transparent;
    border: 2px solid ${COLORS.green};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    color: ${COLORS.green};
    cursor: pointer;
    white-space: nowrap;

    :disabled {
      pointer-events: none;
      background: ${COLORS.text_gray};
      color: white;
      border: none;
    }

    &:hover {
      background: ${COLORS.green_linear_gradient};
      border: 0;
      color: ${COLORS.white};
    }

    ${small} {
      width: 100%;
    }

    &.pill {
      height: 32px;
      width: 50px;
      border-radius: 24px;
    }

    &.active {
      background: ${COLORS.green_linear_gradient};
      border: 0;
      color: ${COLORS.white};
    }
  }
`;

export const BasicOutlineActionBtn = styled(BasicActionBtn)`
  padding: 8px 40px;
  background: ${COLORS.white};
  border: 2px solid ${COLORS.green};
  border-radius: 6px;
  font-weight: 700;
  color: ${COLORS.green};
  cursor: pointer;

  &:hover {
    background: ${COLORS.green_linear_gradient};
    color: ${COLORS.white};
    opacity: 1;
  }
`;
