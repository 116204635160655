import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { AnObat } from "types/api/obat";
import { BackendDataShape } from "types";
// import { APolyclinicWithId } from "types/api/clinic";
// import { APractitionerWithId } from "types/api/practitioner";

export const obatStoreAPI = createApi({
  reducerPath: "obatStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/obat`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["obat"],
  endpoints: (builder) => {
    return {
      getObat: builder.query<Array<AnObat & { no: number }>, {}>({
        query: () => {
          return {
            url: "",
            method: "GET",
          };
        },
        providesTags: ["obat"],
        transformResponse: (data: BackendDataShape<AnObat[]>) => {
          return data.data.map((d, idx) => ({ ...d, no: idx + 1 }));
        },
      }),
    };
  },
});

export const {
  useGetObatQuery,
  util: { resetApiState: resetObatStoreAPI },
} = obatStoreAPI;
