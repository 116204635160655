import React, { lazy } from "react";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { NavbarItemProps } from "types/navbar";
import { combinedRoutes } from "config/nav";

import AuthHOC from "components/auth";
import BasicLayout from "components/Layout/BasicLayout";
import CustomLoader from "components/Loader/CustomLoader";

import NoMatch from "Pages/NoMatch";
import DummyElement from "Pages/Dummy";
import LoginPage from "Pages/LoginPage/LoginPage";
import RegisterPage from "Pages/LoginPage/RegisterPage";
import AddDoctorPage from "Pages/LoginPage/AddDoctorPage";
import ForgotPasswordPage from "Pages/LoginPage/ForgotPasswordPage";
import TermsPage from "Pages/LoginPage/TermsPage";
import SettingsAkun from "Pages/Settings/SettingsAkun";
import SettingsKlinik from "Pages/Settings/SettingsKlinik";
import Langganan from "Pages/Langganan/Langganan";
import Report from "Pages/Report/Report";
import Dashboard from "Pages/Dashboard/Dashboard";
import QueuePage from "Pages/Queue/QueuePage";
import VerificationPage from "Pages/Verification/VerificationPage";
import ResearchAddress from "Pages/Research/Address";
import KelompokBersama from "Pages/Bpjs/KelompokBersama/KelompokBersama";
import Icare from "Pages/Bpjs/Icare/Icare";
import Prolanis from "Pages/Bpjs/Prolanis/Prolanis";
import DetailKelompokBersama from "Pages/Bpjs/KelompokBersama/DetailKelompokBersama";

const AntrianDetailKasirPage = lazy(
  () => import("Pages/Admin/AntrianDetailKasirPage"),
);
const AntrianDetailObatpage = lazy(
  () => import("Pages/Apoteker/AntrianDetailObatPage"),
);
const PeriksaPage = lazy(() => import("Pages/Admin/PeriksaPage"));
const ManagementAccount = lazy(
  () => import("Pages/Management/ManagementAccount"),
);
const ManagementPasien = lazy(
  () => import("Pages/Management/ManagementPasien"),
);
const ManagementDokter = lazy(
  () => import("Pages/Management/ManagementDokter"),
);
const ManagementPoli = lazy(() => import("Pages/Management/ManagementPoli"));
const ManagementTindakan = lazy(
  () => import("Pages/Management/ManagementTindakan"),
);
const ManagementObat = lazy(() => import("Pages/Management/ManagementObat"));

const ManagementTemplate = lazy(
  () => import("Pages/Management/ManagementTemplate"),
);
const ManagementRekamMedis = lazy(
  () => import("Pages/Management/ManagementRekamMedis"),
);

const routes = combinedRoutes;
// const nurseRoutes = new NurseRoute();
// const doctorRoutes = new DoctorRoute();

// const nurseNavItems: NavbarItem[] = [
//   {
//     title: "Dashboard",
//     to: nurseRoutes.dashboard,
//     isDisabled: true,
//   },
//   {
//     title: "Pasien",
//     to: nurseRoutes.encounterpage,
//   },
//   {
//     title: "Pemeriksaan",
//     to: nurseRoutes.pemeriksaan,
//   },
//   {
//     title: "Laporan",
//     to: nurseRoutes.report,
//     isDisabled: true,
//   },
// ];

// @ts-ignore
const combinedNavItems: NavbarItemProps[] = [
  {
    title: "Dashboard",
    to: routes.dashboard,
    availableToRoles: ["admin"],
  },
  {
    title: "Antrian",
    to: routes.antrian,
    kiddos: [
      {
        title: "Antrian Pasien",
        to: routes.antrian_pasien,
      },
      {
        title: "Antrian Farmasi",
        to: routes.antrian_farmasi,
      },
      {
        title: "Antrian Kasir",
        to: routes.antrian_kasir,
      },
    ],
  },
  {
    title: "Pemeriksaan",
    to: routes.pemeriksaan,
  },
  // {
  //   title: "Jadwal",
  //   to: routes.schedule,
  //   isDisabled: true,
  // },
  {
    title: "Manajemen",
    to: routes.management,
    kiddos: [
      {
        title: "Manajemen Akun",
        to: routes.management_account,
        availableToRoles: ["admin"],
      },
      {
        title: "Manajemen Pasien",
        to: routes.management_patient,
      },
      {
        title: "Manajemen Poli",
        to: routes.management_poli,
        availableToRoles: ["admin"],
      },
      {
        title: "Manajemen Dokter",
        to: routes.management_doctor,
        availableToRoles: ["admin"],
      },
      {
        title: "Manajemen Farmasi",
        to: routes.management_farmasi,
      },
      {
        title: "Manajemen Tindakan",
        to: routes.management_tindakan,
      },
      {
        title: "Manajemen SOAP",
        to: routes.management_template,
      },
      {
        title: "Manajemen Rekam Medis",
        to: routes.management_rekam_medis,
      },
    ],
  },
  {
    title: "Klinik",
    to: routes.klinik,
    availableToRoles: ["admin"],
  },
  {
    title: "BPJS",
    to: routes.bpjs,
    kiddos: [
      {
        title: "Kelompok Bersama",
        to: routes.bpjs_kelompok_bersama,
      },
      {
        title: "Prolanis",
        to: routes.bpjs_prolanis,
      },
      {
        title: "iCare",
        to: routes.bpjs_icare,
      },
    ],
  },
  {
    title: "Laporan",
    to: routes.report,
    availableToRoles: ["admin"],
  },
];

// const doctorNavItems: NavbarItem[] = [
//   {
//     title: "Dashboard",
//     to: doctorRoutes.dashboard,
//     isDisabled: true,
//   },
//   {
//     title: "Pasien",
//     to: doctorRoutes.patientPage,
//   },
//   {
//     title: "Jadwal",
//     to: doctorRoutes.schedule,
//     isDisabled: true,
//   },
//   {
//     title: "SOAP",
//     to: doctorRoutes.soappage,
//   },
//   {
//     title: "Laporan",
//     to: doctorRoutes.report,
//     isDisabled: true,
//   },
// ];

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<DummyElement />} /> */}
          <Route path={routes.login_page} element={<LoginPage />} />
          <Route path={routes.registration_page} element={<RegisterPage />} />
          <Route path={routes.forgot_password_page}>
            <Route index element={<ForgotPasswordPage step="reset" />} />
            <Route
              path={"reset"}
              element={<ForgotPasswordPage step="reset" />}
            />
            <Route
              path={"verify-otp"}
              element={<ForgotPasswordPage step="verify-otp" />}
            />
            <Route
              path={"change-password"}
              element={<ForgotPasswordPage step="change-password" />}
            />
          </Route>
          <Route
            /**
             * is this supposed to be guarded?
             */
            path={routes.initial_add_doctor_page}
            element={
              <AuthHOC allowedRoles={["admin"]}>
                <AddDoctorPage />
              </AuthHOC>
            }
          />
          <Route path={routes.terms_page} element={<TermsPage />} />
          <Route
            path="/"
            element={
              <AuthHOC>
                <React.Suspense fallback={<CustomLoader />}>
                  <BasicLayout navitems={combinedNavItems}>
                    <Outlet />
                  </BasicLayout>
                </React.Suspense>
              </AuthHOC>
            }
          >
            <Route
              index
              element={<Navigate to={routes.login_page} replace />}
            />
            <Route
              path={routes.dashboard}
              element={
                <AuthHOC allowedRoles={["admin"]}>
                  <Dashboard />
                </AuthHOC>
              }
            />
            <Route
              path={routes.antrian}
              element={<Navigate to={routes.antrian_pasien} />}
            />
            <Route
              path={routes.antrian_pasien}
              element={<QueuePage queueCategory="pasien" />}
            />
            <Route path={routes.antrian_kasir}>
              <Route index element={<QueuePage queueCategory="kasir" />} />
              <Route path={":enId"} element={<AntrianDetailKasirPage />} />
            </Route>
            <Route path={routes.antrian_farmasi}>
              <Route index element={<QueuePage queueCategory="obat" />} />
              <Route path=":enId" element={<AntrianDetailObatpage />} />
            </Route>
            <Route path={routes.pemeriksaan} element={<PeriksaPage />}>
              <Route path={":enId"} element={<PeriksaPage />} />
            </Route>
            <Route path={routes.management}>
              <Route
                path={routes.management_account}
                element={
                  <AuthHOC allowedRoles={["admin"]}>
                    <ManagementAccount />
                  </AuthHOC>
                }
              />
              <Route
                path={routes.management_doctor}
                element={
                  <AuthHOC allowedRoles={["admin"]}>
                    <ManagementDokter />
                  </AuthHOC>
                }
              />
              <Route
                path={routes.management_poli}
                element={
                  <AuthHOC allowedRoles={["admin"]}>
                    <ManagementPoli />
                  </AuthHOC>
                }
              />
              <Route
                path={routes.management_tindakan}
                element={<ManagementTindakan />}
              />
              <Route
                path={routes.management_farmasi}
                element={<ManagementObat />}
              />
              <Route
                path={routes.management_template}
                element={<ManagementTemplate />}
              />
              <Route
                path={routes.management_rekam_medis}
                element={<ManagementRekamMedis />}
              />
            </Route>
            <Route
              path={routes.management_patient}
              element={<ManagementPasien />}
            />
            {/* <Route
              path={routes.schedule}
              element={
                <DummyElement text="Disini akan dibangun halaman penjadwalan dokter" />
              }
            /> */}
            <Route path={routes.bpjs}>
              <Route path={routes.bpjs_kelompok_bersama}>
                <Route index element={<KelompokBersama />} />
                <Route path={":id"} element={<DetailKelompokBersama />} />
              </Route>
              <Route path={routes.bpjs_prolanis} element={<Prolanis />} />
              <Route path={routes.bpjs_icare} element={<Icare />} />
            </Route>
            <Route
              path={routes.klinik}
              element={
                <AuthHOC allowedRoles={["admin"]}>
                  <SettingsKlinik />
                </AuthHOC>
              }
            />
            <Route
              path={routes.report}
              element={
                <AuthHOC allowedRoles={["admin"]}>
                  <Report />
                </AuthHOC>
              }
            />
            <Route path={routes.pengaturan_akun} element={<SettingsAkun />} />
            <Route
              path={routes.langganan}
              element={
                <AuthHOC allowedRoles={["admin"]}>
                  <Langganan />
                </AuthHOC>
              }
            />
          </Route>
          <Route
            path={routes.verifikasi_email}
            element={<VerificationPage />}
          />
          {/* <Route
            path={nurseRoutes.base}
            element={
              <BasicLayout navitems={nurseNavItems}>
                <Outlet />
              </BasicLayout>
            }
          >
            <Route
              path={nurseRoutes.dashboard}
              element={<DummyElement text="Dashboard Ners" />}
            />
            <Route path={nurseRoutes.encounterpage} element={<AntrianPage />} />
            <Route path={nurseRoutes.pemeriksaan} element={<PeriksaPage />} />
          </Route>
          <Route
            path={doctorRoutes.base}
            element={
              <BasicLayout navitems={doctorNavItems}>
                <Outlet />
              </BasicLayout>
            }
          >
            <Route
              path={doctorRoutes.dashboard}
              element={<DummyElement text="Dashboard Dokter" />}
            />
            <Route path={doctorRoutes.soappage} element={<PeriksaPage />} />
          </Route> */}
          <Route path="*" element={<NoMatch />} />

          {/* Research */}
          <Route path="/research/address" element={<ResearchAddress />} />
        </Routes>
      </BrowserRouter>
      <Toaster position={"bottom-right"} />
    </>
  );
}

export default App;
