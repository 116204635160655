import styled from "styled-components";
import { small, medium } from "assets/theme";

import Modal from "components/Modal/Modal";
import ModalRefined from "components/ModalRefined/ModalRefined";

type Size = "size-auto" | "size-lg" | "size-md" | "size-sm" | "size-xs";

interface BaseModalProps {
  className?: Size;
}

// export const BaseModal = styled(Modal)<BaseModalProps>`
//   &.size-auto {
//     & .children-container {
//       min-width: max-content;
//     }
//   }

//   &.size-lg {
//     & .children-container {
//       min-width: 900px;
//     }
//   }

//   &.size-md {
//     & .children-container {
//       min-width: 660px;
//     }
//   }

//   &.size-sm {
//     & .children-container {
//       min-width: 500px;
//     }
//   }

//   &.size-xs {
//     & .children-container {
//       min-width: 300px;
//     }
//   }

//   ${medium} {
//     &.size-lg .children-container {
//       min-width: 700px;
//     }

//     &.size-md .children-container {
//       min-width: 600px;
//     }
//   }

//   ${small} {
//     &.size-auto .children-container,
//     &.size-lg .children-container,
//     &.size-md .children-container,
//     &.size-sm .children-container,
//     &.size-xs .children-container {
//       min-width: 100%;
//     }
//   }
// `;

export const BaseModalRefined = styled(ModalRefined)``;
