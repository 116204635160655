/**
 * @description Defined BPJS endpoints with the sub endpoint /kelompok.
 * @description Tagged with ["Bpjs-Kelompok", "Bpjs-Kelompok-Peserta"] for caching and other purposes.
 */

import { baseApi } from "store/baseStoreAPI";
import { BackendDataShape } from "types";
import {
  TClubBase,
  TKelompokBersamaBase,
  TKelompokBersamaPesertaRequest,
  TKelompokBersamaRequest,
  TPesertaBpjsBase,
} from "types/api/bpjs";

const subEndpoint = "/bpjs/kelompok";

export const bpjsKelompokApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getKelompokCode: builder.query<any, any>({
      query: () => {
        return {
          url: `${subEndpoint}/kelompok-code`,
          method: "GET",
          params: {},
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    getKegiatanCode: builder.query<any, any>({
      query: () => {
        return {
          url: `${subEndpoint}/kegiatan-code`,
          method: "GET",
          params: {},
        };
      },
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    getKelompokClub: builder.query<
      any,
      {
        KelompokCode: string; // 01 : Diabetes Melitus, 02 : Hipertensi
      }
    >({
      query: ({ KelompokCode }) => {
        return {
          url: `${subEndpoint}/club/${KelompokCode}`,
          method: "GET",
        };
      },
      transformResponse: (res: { count: number; list: TClubBase[] }) => {
        return res?.list;
      },
    }),

    getKelompokKegiatan: builder.query<
      TKelompokBersamaBase[],
      {
        month: string;
      }
    >({
      query: ({ month }) => {
        return {
          url: `${subEndpoint}/kegiatan/${month}`,
          method: "GET",
        };
      },
      providesTags: ["Bpjs-Kelompok"],
      transformResponse: (res: {
        count: number;
        list: TKelompokBersamaBase[];
      }) => {
        return res?.list;
      },
    }),

    getKelompokPeserta: builder.query<
      TPesertaBpjsBase[],
      {
        eduId: string;
      }
    >({
      query: ({ eduId }) => {
        return {
          url: `${subEndpoint}/peserta/${eduId}`,
          method: "GET",
        };
      },
      providesTags: (_, __, { eduId }) => [
        { type: "Bpjs-Kelompok-Peserta", eduId },
      ],
      transformResponse: (res: { count: number; list: TPesertaBpjsBase[] }) => {
        return res?.list;
      },
    }),

    postKelompokKegiatan: builder.mutation<any, TKelompokBersamaRequest>({
      query: (object) => {
        return {
          url: `${subEndpoint}/kegiatan`,
          method: "POST",
          body: object,
        };
      },
      invalidatesTags: ["Bpjs-Kelompok"],
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    patchKelompokKegiatan: builder.mutation<any, TKelompokBersamaRequest>({
      query: () => {
        return {
          url: `${subEndpoint}/kegiatan`,
          method: "PATCH",
          params: {},
        };
      },
      invalidatesTags: ["Bpjs-Kelompok"],
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    patchKelompokPeserta: builder.mutation<any, TKelompokBersamaPesertaRequest>(
      {
        query: ({ eduId, noKartu }) => {
          return {
            url: `${subEndpoint}/peserta`,
            method: "PATCH",
            body: { eduId, noKartu },
            responseHandler: async (response) => {
              if (response.ok) {
                return {
                  statusCode: response.status,
                };
              } else {
                throw new Error(response.statusText);
              }
            },
          };
        },
        invalidatesTags: (_, __, { eduId }) => [
          { type: "Bpjs-Kelompok-Peserta", eduId },
        ],
      },
    ),

    deleteKelompokKegiatan: builder.mutation<
      any,
      {
        eduId: string;
      }
    >({
      query: ({ eduId }) => {
        return {
          url: `${subEndpoint}/kegiatan/${eduId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Bpjs-Kelompok"],
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    deleteKelompokPeserta: builder.mutation<
      any,
      TKelompokBersamaPesertaRequest
    >({
      query: ({ eduId, noKartu }) => {
        return {
          url: `${subEndpoint}/peserta/${eduId}/${noKartu}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_, __, { eduId }) => [
        { type: "Bpjs-Kelompok-Peserta", eduId },
      ],
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetKelompokCodeQuery,
  useGetKegiatanCodeQuery,
  useGetKelompokClubQuery,
  useGetKelompokKegiatanQuery,
  useGetKelompokPesertaQuery,
  usePostKelompokKegiatanMutation,
  usePatchKelompokKegiatanMutation,
  usePatchKelompokPesertaMutation,
  useDeleteKelompokKegiatanMutation,
  useDeleteKelompokPesertaMutation,
  util: { resetApiState: resetBpjsKelompokApiState },
} = bpjsKelompokApi;
