import styled from "styled-components";
import { xSmall } from "assets/theme";

export const InfoModalLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 10px;

  ${xSmall} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;
