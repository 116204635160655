import CookieMonster from "config/CookieMonster";
import { Role } from "types/roles";

const useAmIAdmin = (): boolean => {
  const roles = CookieMonster.loadCookie("roles") as Role[] | undefined;
  if (roles && roles.includes("admin")) {
    return true;
  }
  return false;
};

export default useAmIAdmin;
