import { baseApi } from "store/baseStoreAPI";

const subEndpoint = "/forgot-passwords";

export const forgotPasswordApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    requestForgotPasswordOtp: builder.mutation<any, { username: string }>({
      query: ({ username }) => {
        return {
          url: `${subEndpoint}/${username}`,
          method: "POST",
        };
      },
    }),

    verifyForgotPasswordOtp: builder.query<
      any,
      {
        username: string;
        otp: string;
      }
    >({
      query: ({ username, otp }) => {
        return {
          url: `${subEndpoint}/verify`,
          method: "GET",
          params: {
            username,
            otp,
          },
        };
      },
    }),
  }),
});

export const {
  useRequestForgotPasswordOtpMutation,
  useVerifyForgotPasswordOtpQuery,
  util: { resetApiState: resetForgotPasswordApiState },
} = forgotPasswordApi;
