import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";

import { APatientMinWithId } from "types/api/pasien";
import { useGetPatientByNameAndNikQuery } from "store/patientStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import { BaseSelectInput } from "components/base/Input";

type Props = {
  setSelectedPatient: React.Dispatch<
    React.SetStateAction<APatientMinWithId | null>
  >;
};

const SearchPatient = ({ setSelectedPatient }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebounce(searchValue, 500);

  const validToSearch = searchValue.length > 0;

  const {
    currentData: searchedPatientList,
    isLoading: loadingSearchedPatient,
    isFetching: fetchingSearchedPatient,
  } = useGetPatientByNameAndNikQuery(
    {
      all: debouncedSearch,
    },
    {
      skip: !validToSearch,
    },
  );

  const isLoadingSearchedPatient =
    loadingSearchedPatient || fetchingSearchedPatient;

  const options = searchedPatientList?.data.map((patient) => ({
    value: patient?.id,
    label: `${patient?.full_name} ${
      patient?.nik ? ` - (${patient?.nik})` : ""
    }`,
  }));

  return (
    <BaseSelectInput<any>
      className="w-full"
      label="Cari Pasien"
      placeholder="Cari pasien"
      name="search"
      options={options}
      isClearable
      isSearchable
      onInputChange={(value) => setSearchValue(value)}
      onChange={(selectedOption) => {
        const patient = searchedPatientList?.data.find(
          (patient) => patient.id === selectedOption.value,
        );
        setSelectedPatient(patient as APatientMinWithId);
      }}
      isLoading={isLoadingSearchedPatient}
      loadingMessage={() => "Mencari pasien..."}
      noOptionsMessage={
        validToSearch
          ? () => "Tidak ada pasien dengan nama atau NIK tersebut"
          : () => "Masukkan nama atau NIK pasien"
      }
      menuPlacement="auto"
      icon={<BiSearch />}
    />
  );
};

export default SearchPatient;
