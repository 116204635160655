import { format, parse } from "date-fns";
import { Controller, UseFormReturn } from "react-hook-form";

import { APatientBase } from "types/api/pasien";
import { TPesertaBpjsPureBase } from "types/api/bpjs";
import { AQueueBase } from "types/api/queue";
import { bpjsStoreApi } from "store/bpjsAPI";

import { BaseButton } from "components/base/Button";
import { BaseTextInput } from "components/base/Input";
import { BaseInfoText } from "components/base/Text";
import toast from "react-hot-toast";
import CustomToast from "components/CustomToast";

type Props = {
  formData: UseFormReturn<APatientBase & AQueueBase>;
  setSelectedBpjsPatient: (data: TPesertaBpjsPureBase | null) => void;
};

const BpjsCheckInput = ({ formData, setSelectedBpjsPatient }: Props) => {
  const bpjs_no = formData.watch("bpjs_no");

  const [
    triggerCheckBpjs,
    {
      data: bpjsData,
      isLoading: isBpjsStatusLoading,
      isFetching: isBpjsStatusFetching,
    },
  ] = bpjsStoreApi.endpoints.getPatientBpjsStatus.useLazyQuery();

  const isLoading = isBpjsStatusLoading || isBpjsStatusFetching;

  const handleCheckBpjsStatus = () => {
    triggerCheckBpjs({
      bpjs_no: formData.getValues("bpjs_no"),
    })
      .unwrap()
      .then((res) => {
        const bpjsData = res;
        if (bpjsData !== undefined) {
          setSelectedBpjsPatient(bpjsData);
          formData.setValue("full_name", bpjsData?.nama);
          formData.setValue("nik", bpjsData?.noKTP);
          formData.setValue(
            "phone_number",
            bpjsData?.noHP ? bpjsData?.noHP : "",
          );
          formData.setValue("sex", bpjsData?.sex === "L" ? "male" : "female");
          const date = parse(bpjsData?.tglLahir, "dd-MM-yyyy", new Date());
          const formattedDate = format(date, "yyyy-MM-dd");
          formData.setValue("birth_date", formattedDate);
        }
      })
      .catch((err) => {
        console.error(err);
        const status = err?.status || 500;
        const message = err?.data?.message || "Terjadi kesalahan";
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description={`Pengecekan no. BPJS Gagal. ${status}: ${message}`}
          />
        ));
      });
  };

  const isRequired = formData.watch("is_bpjs") === true;

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "flex-end",
        }}
      >
        <Controller
          name="bpjs_no"
          control={formData.control}
          render={({ field: { value = "", onChange } }) => (
            <BaseTextInput
              className="w-full"
              required={isRequired}
              label="No. BPJS"
              placeholder="No. BPJS"
              type="text"
              value={value}
              onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                  onChange(e.target.value);
                }
              }}
              maxLength={13}
              pattern="^[0-9]{13}$"
              title="No. BPJS harus 13 digit angka"
            />
          )}
        />
        <BaseButton
          type="button"
          styleSize="regularSlim"
          onClick={handleCheckBpjsStatus}
          disabled={isLoading || !bpjs_no || bpjs_no?.length < 13}
        >
          Cek Status
        </BaseButton>
      </div>
      {bpjsData !== undefined ? (
        bpjsData?.aktif ? (
          <BaseInfoText>BPJS Aktif</BaseInfoText>
        ) : (
          <BaseInfoText styleType="danger">BPJS Tidak Aktif</BaseInfoText>
        )
      ) : null}
    </>
  );
};

export default BpjsCheckInput;
