import styled from "styled-components";

import { COLORS, medium, small, xSmall } from "assets/theme";

export const Main = styled.main`
  background-color: white;
  width: 90%;
  margin: auto;
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  padding: 16px 32px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 24px;
  }
`;

export const StatsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;

  ${xSmall} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const StatsTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${COLORS.black_1};
`;

export const Layout = styled.div`
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(3, auto);
`;

export const LayoutItem = styled.div`
  height: auto;
  width: auto;
  display: grid;
  gap: 16px;

  &.section-1 {
    grid-template-columns: repeat(4, 1fr);
    ${medium} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${small} {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.section-2 {
    grid-template-columns: repeat(2, 1fr);
    ${medium} {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &.section-3 {
    grid-template-columns: repeat(2, 1fr);
    ${medium} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export const ItemContainer = styled.div`
  background: ${COLORS.grey_2};
  display: flex;
  border-radius: 6px;
  padding: 16px;
`;
