import styled from "styled-components";
import { COLORS } from "assets/theme";
import InputText from "components/InputText/InputText";

export const BaseTextInput = styled(InputText)`
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & .wrapper {
    border-radius: 6px;
    border: 1px solid ${COLORS.neutral_gray_gull};
    background-color: ${COLORS.neutral_white};

    svg {
      position: relative;
      z-index: -10;
      color: ${COLORS.neutral_chromium};
    }

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }
  &.w-full {
    width: 100%;
    flex-grow: 1;
  }

  & input {
    border-radius: 6px;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: ${COLORS.neutral_white};

    -moz-appearance: textfield;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .right-addon,
  .left-addon {
    label {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        border-left: 0.5px solid ${COLORS.neutral_gray_gull};
        height: 30px;
        width: 1;
        display: block;
      }
    }

    select {
      border: none;
      height: 30px;

      &:focus {
        outline: none;
      }
    }
  }

  .disabled {
    input {
      background-color: ${COLORS.silver_lining};
    }
  }
`;
