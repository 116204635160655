import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import toast from "react-hot-toast";

import CookieMonster from "config/CookieMonster";
import { combinedRoutes } from "config/nav";
import { SignupRequestDoctor } from "types/api/signup";
import { clinicStoreAPI } from "store/clinicStoreAPI";
import { useSignupDoctorMutation } from "store/signUpStoreAPI";
import { usePractitionerList } from "hooks/practitioner/usePractitionerList";
import useProgressiveImg from "hooks/utils/useProgressiveImg";
import useNullishValues from "hooks/utils/useNullishValues";
import useAmIPractitioner from "hooks/practitioner/useAmIPractitioner";

import { logo } from "assets";
import { COLORS } from "assets/theme";
import { background_min_png, background_png } from "assets/background";
import { isBackendErrorItem } from "assets/usefulFunctions";
import CustomToast from "components/CustomToast";
import CustomLoader from "components/Loader/CustomLoader";
import {
  LGBackground,
  LGContainerAligner,
  LGContainer,
  LGLogoContainer,
  LGSubmitButton,
  FormInput,
  LGEyeBtn,
  LGRegisterForm,
} from "Pages/LoginPage/components";

const AddDoctorPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nullify } = useNullishValues();
  const isPractitioner = useAmIPractitioner();

  const [showPwd, setShowPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const [img] = useProgressiveImg({
    placeholderSrc: background_min_png,
    src: background_png,
  });

  const registerForm = useForm<SignupRequestDoctor>({
    defaultValues: {
      prefix: "",
      suffix: "",
      fullname: "",
      email: "",
      nik: "",
      str_no: "",
      polyclinic_name: "",
      username: "",
      password: "",
    },
  });

  const { list: listPractitioner } = usePractitionerList();
  const [registerDoctor, { isLoading }] = useSignupDoctorMutation();

  const handleSubmit = (formData: SignupRequestDoctor) => {
    if (formData.password !== confirmPassword) {
      setConfirmPasswordError("Password tidak sama, mohon ulangi");
      return;
    }

    const sanitizedData = nullify(formData);
    registerDoctor(sanitizedData)
      .unwrap()
      .then(() => {
        dispatch(clinicStoreAPI.util.invalidateTags(["practitioner"]));
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText={`Data dokter tersimpan.`}
              description={`Sekarang anda dapat menerima pasien.`}
              status="success"
            />
          ),
          {
            id: "add_doctor_success",
            position: "bottom-right",
          },
        );
        navigate(combinedRoutes.antrian_pasien);
      })
      .catch((e) => {
        let message = "Gagal Registrasi Dokter!";
        if (isBackendErrorItem(e)) {
          message = Array.isArray(e.data.message)
            ? e.data.message.join(",")
            : e.data.message ?? message;
        }
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText={`Gagal!`}
              description={message}
              status="error"
            />
          ),
          {
            id: "add_doctor_failed",
            position: "bottom-right",
          },
        );
      });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (
      listPractitioner &&
      listPractitioner.length > 0 &&
      listPractitioner[0].profile_id
    ) {
      navigate(combinedRoutes.antrian_pasien);
    }
  }, [listPractitioner, navigate]);

  if (!listPractitioner) {
    return <CustomLoader />;
  }

  return (
    <LGBackground
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <LGContainerAligner>
        <LGContainer>
          <LGLogoContainer>
            <img
              width={150}
              style={{ margin: "0 auto" }}
              alt="Rekmed"
              src={logo}
            />
          </LGLogoContainer>
          <LGRegisterForm onSubmit={registerForm.handleSubmit(handleSubmit)}>
            <Controller
              name="prefix"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  className="s"
                  style={{ marginRight: "auto" }}
                  type="text"
                  label="Prefix"
                  placeholder="dr, drg, Dr dr,..."
                  onChange={onChange}
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              name="suffix"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  className="s"
                  type="text"
                  label="Suffix"
                  placeholder="S.Si, M.M., MARS,..."
                  onChange={onChange}
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              name="fullname"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  ref={inputRef}
                  type="text"
                  label="Nama Dokter"
                  placeholder="Nama Dokter"
                  onChange={onChange}
                  disabled={isLoading}
                  required
                />
              )}
            />

            <Controller
              name="email"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type="email"
                  label="Email Dokter"
                  placeholder="Email Dokter"
                  onChange={onChange}
                  disabled={isLoading || isPractitioner}
                  containerStyle={{
                    display: isPractitioner ? "none" : undefined,
                  }}
                  defaultValue={
                    isPractitioner
                      ? CookieMonster.loadCookie("username") ?? ""
                      : ""
                  }
                  required
                />
              )}
            />

            <Controller
              name="nik"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type="text"
                  label="NIK Dokter"
                  placeholder="NIK Dokter"
                  onChange={onChange}
                  maxLength={16}
                  pattern="^\d{16}$"
                  title="NIK harus 16 digit angka"
                  disabled={isLoading}
                  required
                />
              )}
            />

            <Controller
              name="str_no"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type="text"
                  label="No. STR"
                  placeholder="No. STR"
                  onChange={onChange}
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              name="polyclinic_name"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type="text"
                  label="Poli/Ruangan"
                  placeholder="Poli Umum, Poli Gigi dan Mulut, dst"
                  onChange={onChange}
                  disabled={isLoading}
                />
              )}
            />

            <Controller
              name="username"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type="text"
                  label="Username Dokter"
                  placeholder="Username Dokter"
                  autoComplete="new-username"
                  maxLength={20}
                  pattern="[^\s]{3,20}"
                  title="Jumlah karakter 3-20 dan tanpa whitespace"
                  onChange={onChange}
                  disabled={isLoading || isPractitioner}
                  containerStyle={{
                    display: isPractitioner ? "none" : undefined,
                  }}
                  required
                />
              )}
            />

            <Controller
              name="password"
              control={registerForm.control}
              render={({ field: { onChange } }) => (
                <FormInput
                  type={showPwd ? "text" : "password"}
                  label="Password"
                  placeholder="Password"
                  pattern="^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$"
                  autoComplete="new-password"
                  minLength={8}
                  title="Password minimal 8 karakter (mengandung huruf besar, huruf kecil dan angka)"
                  onChange={onChange}
                  disabled={isLoading || isPractitioner}
                  required={!isPractitioner}
                  containerStyle={{
                    display: isPractitioner ? "none" : undefined,
                  }}
                  elementAddonRight={
                    <LGEyeBtn
                      onMouseDown={() => {
                        setShowPwd(true);
                      }}
                      onMouseUp={() => setShowPwd(false)}
                    >
                      {showPwd ? (
                        <BsEye color={COLORS.text_hint} />
                      ) : (
                        <BsEyeSlash color={COLORS.text_hint} />
                      )}
                    </LGEyeBtn>
                  }
                />
              )}
            />

            <div style={{ flexBasis: "100%" }}>
              <FormInput
                type={showPwd ? "text" : "password"}
                label="Konfirmasi Password"
                placeholder="Ulangi Password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError("");
                }}
                disabled={isLoading || isPractitioner}
                required={!isPractitioner}
                containerStyle={{
                  display: isPractitioner ? "none" : undefined,
                }}
                elementAddonRight={
                  <LGEyeBtn
                    onMouseDown={() => {
                      setShowPwd(true);
                    }}
                    onMouseUp={() => setShowPwd(false)}
                  >
                    {showPwd ? (
                      <BsEye color={COLORS.text_hint} />
                    ) : (
                      <BsEyeSlash color={COLORS.text_hint} />
                    )}
                  </LGEyeBtn>
                }
              />
              {confirmPasswordError && (
                <span style={{ color: "red" }}>{confirmPasswordError}</span>
              )}
            </div>

            <LGSubmitButton disabled={isLoading} type="submit">
              {isLoading ? "Mohon Menunggu..." : "Simpan"}
            </LGSubmitButton>
          </LGRegisterForm>
        </LGContainer>
      </LGContainerAligner>
    </LGBackground>
  );
};

export default AddDoctorPage;
