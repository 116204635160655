// export class ROUTES {
//   static base = "/" as const;
//   static nurse_base = `${this.base}/nurse`;
//   static doctor_base = `${this.base}/doctor`;
// }

export class BaseRoute {
  constructor(public base: string) {}

  get login_page() {
    return `${this.base}/login`;
  }

  get registration_page() {
    return `${this.base}/register`;
  }

  get forgot_password_page() {
    return `${this.base}/forgot-password`;
  }

  get forgot_password_page_reset() {
    return `${this.base}/forgot-password/reset`;
  }

  get forgot_password_page_confirm_otp() {
    return `${this.base}/forgot-password/confirm-otp`;
  }

  get forgot_password_page_change_password() {
    return `${this.base}/forgot-password/change-password`;
  }

  get terms_page() {
    return `${this.base}/terms`;
  }

  get initial_add_doctor_page() {
    return `${this.base}/dokter_pertama`;
  }

  get antrian() {
    return `${this.base}/antrian`;
  }

  /**
   * antrian
   */
  get antrian_pasien() {
    return `${this.antrian}/kunjungan`;
  }

  get antrian_farmasi() {
    return `${this.antrian}/farmasi`;
  }

  get antrian_kasir() {
    return `${this.antrian}/kasir`;
  }

  get dashboard() {
    return `${this.base}/dashboard`;
  }

  get schedule() {
    return `${this.base}/jadwal`;
  }

  get pemeriksaan() {
    return `${this.base}/pemeriksaan`;
  }

  get management_patient() {
    return `${this.management}/pasien`;
  }

  get management() {
    return `${this.base}/manajemen`;
  }

  get management_account() {
    return `${this.management}/akun`;
  }

  get management_tindakan() {
    return `${this.management}/tindakan`;
  }
  get management_farmasi() {
    return `${this.management}/farmasi`;
  }

  get management_poli() {
    return `${this.management}/poli`;
  }

  get management_doctor() {
    return `${this.management}/dokter`;
  }

  get management_template() {
    return `${this.management}/template`;
  }

  get management_rekam_medis() {
    return `${this.management}/rekam-medis`;
  }

  get report() {
    return `${this.base}/report`;
  }

  get klinik() {
    return `${this.base}/klinik`;
  }

  get pengaturan_akun() {
    return `${this.base}/pengaturan/akun`;
  }

  get langganan() {
    return `${this.base}/pengaturan/langganan`;
  }

  get verifikasi_email() {
    return `${this.base}/verify-email`;
  }

  get bpjs() {
    return `${this.base}/bpjs`;
  }

  get bpjs_kelompok_bersama() {
    return `${this.base}/bpjs/kelompok-bersama`;
  }

  get bpjs_prolanis() {
    return `${this.base}/bpjs/prolanis`;
  }

  get bpjs_icare() {
    return `${this.base}/bpjs/icare`;
  }
}

export const combinedRoutes = new BaseRoute("");

export class NurseRoute extends BaseRoute {
  constructor() {
    super("/nurse");
  }

  get pemeriksaan() {
    return `${this.base}/pemeriksaan`;
  }
}

export class DoctorRoute extends BaseRoute {
  constructor() {
    super("/dokter");
  }

  /**
   * akan menampilkan jadwal semua dokter
   */
  get schedule() {
    return `${this.base}/jadwal`;
  }

  get soappage() {
    return `${this.base}/soap`;
  }
}
