import styled from "styled-components";
import { COLORS, large, medium, small, xSmall } from "assets/theme";
import { BasicActionBtn } from "components/base";
import InputText from "components/InputText/InputText";

export const Main = styled.main`
  background-color: white;
  height: 100%;
  width: 90%;
  margin: auto;
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  padding: 24px 64px;

  ${large} {
    padding: 16px 16px;
  }

  ${small} {
    padding: 8px 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 24px;
  }

  ${small} {
    align-items: stretch;
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  & > :nth-child(2) {
    margin-left: auto;

    ${xSmall} {
      width: 100%;
      align-items: stretch;
    }
  }
`;

export const ActionBtn = styled(BasicActionBtn)`
  padding: 8px 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.alt {
    background: ${COLORS.asphalt};
  }

  &.download {
    white-space: nowrap;
    background: ${COLORS.green_2};
  }

  &[disabled] {
    background: ${COLORS.neutral_gray_gull};
  }

  & > svg {
    margin-right: 4px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  gap: 16px;

  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    ${xSmall} {
      flex-direction: column;
    }
  }

  .table-filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    ${xSmall} {
      align-items: stretch;
      flex-direction: column;
    }
  }

  .sub-table-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    ${xSmall} {
      align-items: stretch;
      flex-direction: column;
    }
  }

  // & > :nth-child(1) {
  //   align-items: center;

  //   ${xSmall} {
  //     align-items: stretch;
  //   }
  // }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${medium} {
    flex-direction: column;
    gap: 24px;
  }
`;

export const InfoContainer = styled.div`
  background: #e3f1ff;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 2px solid #cee6ff;
  border-radius: 6px;

  p,
  svg {
    margin: 0;
    color: ${COLORS.yale_blue};
  }

  ${small} {
    padding: 8px;
    font-size: 12px;
  }
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${small} {
    width: 16px;
    height: 16px;
  }
`;

export const SearchInput = styled(InputText)`
  & .wrapper {
    border-radius: 6px;
    border: 1px solid ${COLORS.neutral_gray_gull};
    background-color: ${COLORS.neutral_white};

    svg {
      position: relative;
      z-index: -10;
      color: ${COLORS.neutral_chromium};
    }

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }

  & input {
    border-radius: 6px;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 16px;
  }
`;

export const RecapContainer = styled.div`
  width: fit-content;
  background: ${COLORS.grey_2};
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 24px;
  padding: 4px 12px;
  border: 2px solid ${COLORS.neutral_gray_gull};
  border-radius: 6px;
  flex-wrap: nowrap;

  ${small} {
    padding: 8px;
    font-size: 12px;
    gap: 8px;
    align-items: start;
  }

  ${xSmall} {
    justify-content: center;
    width: 100%;
  }
`;

export const RecapItem = styled.p`
  margin: 0;
  color: ${COLORS.text_gray};
  white-space: nowrap;

  span {
    font-weight: 600;
    color: ${COLORS.green};
  }
`;
