import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import {
  OdontogramRequest,
  OdontogramResponse,
  TeethCondition,
  TeethElement,
} from "types/api/odontogram";
import { BackendDataShape } from "types";

export const odontogramStoreAPI = createApi({
  reducerPath: "odontogramStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: UsedAPI,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["odontogram", "teethElements", "teethConditions"],
  endpoints: (builder) => {
    return {
      getTeethElements: builder.query<{ label: string; value: number }[], void>(
        {
          query: () => ({
            url: "/teeth-elements",
            method: "GET",
          }),
          providesTags: ["teethElements"],
          transformResponse: (response: BackendDataShape<TeethElement[]>) => {
            return response.data.map((item) => ({
              value: item.id,
              label: item.number.toString(),
            }));
          },
        },
      ),

      getTeethConditions: builder.query<
        { label: string; value: number; shortLabel: string }[],
        void
      >({
        query: () => ({
          url: "/teeth-conditions",
          method: "GET",
        }),
        providesTags: ["teethConditions"],
        transformResponse: (response: BackendDataShape<TeethCondition[]>) => {
          return response.data.map((item) => ({
            value: item.id,
            label: `${item.name} (${item.code})`,
            shortLabel: item.code,
          }));
        },
      }),

      createOdontogram: builder.mutation<
        BackendDataShape<{ id: number }>,
        OdontogramRequest & { queue_id: string }
      >({
        query: ({ queue_id, ...obj }) => ({
          url: `/queue/${queue_id}/odontogram`,
          method: "POST",
          body: { ...obj },
        }),
        invalidatesTags: ["odontogram"],
      }),

      getOdontogram: builder.query<
        BackendDataShape<OdontogramResponse>,
        string
      >({
        query: (id) => ({
          url: `/queue/${id}/odontogram`,
          method: "GET",
        }),
        providesTags: ["odontogram"],
      }),
    };
  },
});

export const {
  useGetTeethConditionsQuery,
  useGetTeethElementsQuery,
  useCreateOdontogramMutation,
  useGetOdontogramQuery,
  util: { resetApiState: resetOdontogramStoreAPIState },
} = odontogramStoreAPI;
