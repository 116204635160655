import { FormInput } from "Pages/Langganan/components";
import { InfoModalLayout } from "Pages/Langganan/components/InfoModal/style";
import { UserAccount } from "types/api/user";

type Props = {
  data?: UserAccount;
};

const InfoModal = ({ data }: Props) => {
  return (
    <>
      <InfoModalLayout>
        <FormInput
          label="Username"
          placeholder="-"
          disabled
          value={data?.username}
        />
        <FormInput label="Email" placeholder="-" disabled value={data?.email} />
        <FormInput
          label="NIK"
          placeholder="-"
          disabled
          value={data?.userProfile?.nik}
        />
        <FormInput
          label="No. STR"
          placeholder="-"
          disabled
          value={data?.userProfile?.str_no}
        />
        <FormInput
          label="Nama Dokter"
          placeholder="-"
          disabled
          value={data?.userProfile?.first_name}
        />
        <FormInput
          label="Poli"
          placeholder="-"
          disabled
          value={data?.polyclinic_name}
        />
      </InfoModalLayout>
    </>
  );
};

export default InfoModal;
