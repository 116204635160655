import React, { useMemo } from "react";
import { Column } from "react-table";
import { format } from "date-fns";

import { ReportPatientItem, ReportPatientList } from "types/api/report";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportPatientList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const tableData = data?.entities ?? [];

  const columns: Column<ReportPatientItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "No. Rekam Medis",
        accessor: (row) => row.encounter || "-",
      },
      {
        Header: "ID SatuSehat",
        accessor: (row) => row.satu_sehat_id || "-",
      },
      {
        Header: "NIK",
        accessor: (row) => row.nik || "-",
      },
      {
        Header: "No. BPJS",
        accessor: (row) => row.bpjs_no || "-",
      },
      {
        Header: "Nama Pasien",
        accessor: (row) => row.full_name || "-",
      },
      {
        Header: "Tanggal Lahir",
        accessor: "birth_date",
        Cell: ({ value }) => (
          <>{value ? format(new Date(value), "dd MMM yyyy") : "-"}</>
        ),
      },
      {
        Header: "Jenis Kelamin",
        accessor: "sex",
        Cell: ({ value }) => (
          <>{value === "male" ? "Laki-laki" : "Perempuan"}</>
        ),
      },
      {
        Header: "Alamat",
        accessor: (row) => row.address || "-",
      },
    ];
  }, [page, take]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
