import { useCallback } from "react";
import _ from "lodash";

const useNullishValues = () => {
  const nullifyValues = useCallback((obj: any): any => {
    return _.transform(obj, (result, value, key) => {
      if (_.isNil(value) || value === "") {
        result[key] = null;
      } else if (_.isObject(value)) {
        result[key] = nullifyValues(value);
      } else {
        result[key] = value;
      }
    });
  }, []);

  return { nullify: nullifyValues };
};

export default useNullishValues;
