import { useState } from "react";
import { BiSearch } from "react-icons/bi";

import { BaseHeaderLayout, BaseLayout } from "components/base/Container";
import { BaseHeaderText } from "components/base/Text";
import { BaseTextInput } from "components/base/Input";
import AddKelompokBersamaForm from "./components/AddKelompokBersamaForm/AddKelompokBersamaForm";
import TableKelompokBersama from "./components/TableKelompokBersama/TableKelompokBersama";

const KelompokBersama = () => {
  const [search, setSearch] = useState("");

  return (
    <>
      <BaseLayout
        height="auto"
        style={{
          marginBottom: "16px",
        }}
      >
        <BaseHeaderLayout center>
          <BaseHeaderText styleSize="24">Kelompok Bersama</BaseHeaderText>
        </BaseHeaderLayout>
        <AddKelompokBersamaForm />
      </BaseLayout>
      <BaseLayout height="auto">
        <BaseHeaderLayout>
          <BaseHeaderText styleSize="24">
            Daftar Kelompok Bersama
          </BaseHeaderText>
          <BaseTextInput
            placeholder="Cari..."
            elementAddonRight={<BiSearch />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </BaseHeaderLayout>

        <TableKelompokBersama search={search} />
      </BaseLayout>
    </>
  );
};

export default KelompokBersama;
