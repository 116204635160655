import { useGetWilayaByCodeQuery } from "store/wilayahStoreAPI";

const useFullWilayahOptByCode = (code: string) => {
  const level0Code = code.slice(0, 2) || "";
  const level1Code = code.slice(0, 4) || "";
  const level2Code = code.slice(0, 6) || "";
  const level3Code = code.slice(0, 10) || "";

  const { currentData: level0 } = useGetWilayaByCodeQuery(level0Code, {
    skip: level0Code === "" || code.length > 10,
  });
  const { currentData: level1 } = useGetWilayaByCodeQuery(level1Code, {
    skip: level1Code === "" || code.length > 10,
  });
  const { currentData: level2 } = useGetWilayaByCodeQuery(level2Code, {
    skip: level2Code === "" || code.length > 10,
  });
  const { currentData: level3 } = useGetWilayaByCodeQuery(level3Code, {
    skip: level3Code === "" || code.length > 10,
  });

  if (level0?.data && level1?.data && level2?.data && level3?.data) {
    return {
      0: level0?.data,
      1: level1?.data,
      2: level2?.data,
      3: level3?.data,
    };
  } else {
    return undefined;
  }
};

export default useFullWilayahOptByCode;
