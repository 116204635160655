import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  & > div.span-full {
    grid-column: 1 / -1;
  }
`;
