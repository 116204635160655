import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface sidebarState {
  sidebarExpand: boolean;
  sidebarOpen: boolean;
  quickboardExpand: boolean;
}

const initialState: sidebarState = {
  sidebarExpand: false,
  sidebarOpen: false,
  quickboardExpand: false,
};

export const sidebarSlice = createSlice({
  name: "SIDEBAR_SLICE",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) {
        state.sidebarExpand = action.payload;
      } else {
        state.sidebarExpand = !state.sidebarExpand;
      }
    },
    toggleSidebarOpen: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) {
        state.sidebarOpen = action.payload;
      } else {
        state.sidebarOpen = false;
      }
    },
    toggleQuickboard: (state, action: PayloadAction<boolean | undefined>) => {
      if (action.payload) {
        state.quickboardExpand = action.payload;
      } else {
        state.quickboardExpand = !state.quickboardExpand;
      }
    },
  },
});

export default sidebarSlice.reducer;
export const { toggleQuickboard, toggleSidebar, toggleSidebarOpen } =
  sidebarSlice.actions;
