import { extraLarge, large, medium, small } from "assets/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-flow: auto;
  gap: 16px;

  ${extraLarge} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${medium} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${small} {
    grid-template-columns: 1fr;
  }

  .span-full {
    grid-column: 1 / -1;
  }
`;
