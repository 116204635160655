import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import { BiSearch } from "react-icons/bi";
import toast from "react-hot-toast";

import {
  PatientTypeEnum,
  BpjsTypeEnum,
  QueuePemeriksaanTypeEnum,
  QueueTypeEnum,
  PemeriksaanKehamilanEnum,
} from "types/queue";
import { APatientBase, APatientMinWithId } from "types/api/pasien";
import { AQueueBase } from "types/api/queue";
import { APractitionerOpt } from "types/api/practitioner";
import { TPesertaBpjsPureBase } from "types/api/bpjs";
import {
  useCreatePatientMutation,
  usePatchPatientMutation,
} from "store/patientStoreAPI";
import { useCreateQueueMutation } from "store/encounterStoreAPI";
import { useChangePatientSatusehatConsentMutation } from "store/satuSehatStoreAPI";
import useIntegrationConfig from "hooks/utils/useIntegrationConfig";
import useNullishValues from "hooks/utils/useNullishValues";

import { Container, SubContainer } from "./style";
import {
  BaseRadioGroupInput,
  BaseSelectInput,
  // BaseSwitchInput,
  BaseTextInput,
} from "components/base/Input";
import CustomToast from "components/CustomToast";
import { BaseText } from "components/base/Text";
import { BaseButton } from "components/base/Button";
import IntegrationInfo from "./components/IntegrationInfo";
import BpjsCheckInput from "./components/BpjsCheckInput";
import PoliklinikSelect from "./components/PoliklinikSelect";
import SatusehatConsentChanger from "./components/SatusehatConsentChanger";
import JenisAntrianChanger from "./components/JenisAntrianChanger";
import TipePasienChanger from "./components/TipePasienChanger";
import KunjunganKehamilanSelect from "./components/KunjunganKehamilanSelect";
import BpjsJenisAntrianChanger from "./components/BpjsJenisAntrianChanger";
import SearchPatientInput from "./components/SearchPatientInput";
import TipePemeriksaanSelect from "./components/TipePemeriksaanSelect";
import NikBayiInput from "./components/NikBayiInput";
import BpjsJenisPoliSakitChanger from "./components/BpjsJenisPoliSakitChanger";
import useDebounce from "hooks/utils/useDebounce";
import {
  useGetWilayaByCodeQuery,
  useGetWilayahQuery,
} from "store/wilayahStoreAPI";
import useFullWilayahOptByCode from "hooks/utils/useFullWilayahOptByCode";

type Props = {
  toggle: (v?: boolean | undefined) => void;
};

const AddQueueForm = ({ toggle }: Props) => {
  const { satusehatConfigured, bpjsConfigured } = useIntegrationConfig();
  const { nullify } = useNullishValues();

  const [jenisAntrian, setJenisAntrian] = useState<QueueTypeEnum>(
    QueueTypeEnum.UMUM,
  );
  const [jenisPoliSakit, setJenisPoliSakit] = useState<"sakit" | "sehat">(
    "sakit",
  );
  const [selectedPatient, setSelectedPatient] = useState<
    APatientMinWithId | undefined
  >(undefined);
  const [selectedBpjsPatient, setSelectedBpjsPatient] =
    useState<TPesertaBpjsPureBase | null>(null);
  const [selectedPolyclinic, setSelectedPolyclinic] =
    useState<APractitionerOpt | null>(null);

  const [wilayahKeyword, setWilayahKeyword] = useState<string>("");
  const wilayahKeywordDebounce = useDebounce(wilayahKeyword, 500);

  const queueForm = useForm<APatientBase & AQueueBase>({
    defaultValues: {
      is_bpjs: false,
      type: PatientTypeEnum.WNI,
      bpjs_type: BpjsTypeEnum.BARU,
      pemeriksaan_type: QueuePemeriksaanTypeEnum.UMUM,
      kunjungan_type: PemeriksaanKehamilanEnum.K1_MURNI,
      is_opt_in: false,
      kunj_sakit: true,
      birth_order: 0,
      address_match_with_ktp: false,
    },
  });

  const [createPatient] = useCreatePatientMutation();
  const [editPatient] = usePatchPatientMutation();
  const [createQueue, { isLoading: isCreateQueueLoading }] =
    useCreateQueueMutation();
  const [changePatientConsent] = useChangePatientSatusehatConsentMutation();
  const {
    currentData: searchedWilayahData,
    isLoading: searchWilayahLoading,
    isFetching: searchWilayahFetching,
  } = useGetWilayahQuery({ name: wilayahKeywordDebounce, take: 500 });

  const currentWilayah = useFullWilayahOptByCode(
    selectedPatient?.address ?? "",
  );

  const currentWilayahOptions = currentWilayah
    ? [
        {
          value: currentWilayah[3]?.code,
          label: `${currentWilayah[0]?.name}, ${currentWilayah[1]?.name}, ${currentWilayah[2]?.name}, ${currentWilayah[3]?.name}`,
        },
      ]
    : [];

  const searchedWilayahDataOptions =
    searchedWilayahData?.data?.map((wilayah) => {
      return {
        value: wilayah[3].code,
        label: `${wilayah[0].name}, ${wilayah[1].name}, ${wilayah[2].name}, ${wilayah[3].name}`,
      };
    }) ?? [];

  const wilayahOptions =
    wilayahKeyword.length >= 3
      ? searchedWilayahDataOptions
      : selectedPatient?.address === queueForm.watch("address")
      ? currentWilayahOptions
      : [];

  const isLoadingWilayah = searchWilayahLoading || searchWilayahFetching;

  const tipePasien = queueForm.watch("type");
  const tipePemeriksaan = queueForm.watch("pemeriksaan_type");
  // const tipeBpjs = queueForm.watch("bpjs_type");
  // const tipeKunjunganKehamilan = queueForm.watch("kunjungan_type");

  const handleChangeJenisAntrian = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value === QueueTypeEnum.BPJS) {
      queueForm.setValue("type", PatientTypeEnum.WNI);
      queueForm.setValue("pemeriksaan_type", QueuePemeriksaanTypeEnum.UMUM);
    } else {
      queueForm.setValue("bpjs_type", BpjsTypeEnum.BARU);
    }
    setJenisAntrian(event.target.value as QueueTypeEnum);
    queueForm.setValue("is_bpjs", event.target.value === QueueTypeEnum.BPJS);
  };

  const isBpjs = jenisAntrian === QueueTypeEnum.BPJS;

  const patientFactory = (formData: APatientBase): Promise<{ id: number }> => {
    return new Promise((resolve, reject) => {
      if (selectedPatient) {
        const updatedPatientData: any = {
          ...formData,
          id: selectedPatient.id,
          is_queue: true,
          is_bpjs: isBpjs,
        };

        editPatient(updatedPatientData)
          .unwrap()
          .then(() => {
            resolve({ id: 0 });
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        createPatient(formData)
          .unwrap()
          .then((res) => {
            resolve({ id: res.data.id });
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  const handleSubmit = (formData: any) => {
    const sanitizedPatientData: APatientBase = nullify(formData);
    const sanitizedQueueData: AQueueBase = nullify(formData);

    if (jenisAntrian !== QueueTypeEnum.BPJS) {
      sanitizedQueueData.is_bpjs = false;
      delete sanitizedQueueData.bpjs_type;
      // delete sanitizedQueueData.kunj_sakit;
    }
    if (tipePemeriksaan !== QueuePemeriksaanTypeEnum.KEHAMILAN) {
      delete sanitizedQueueData.kunjungan_type;
    }
    if (tipePasien !== PatientTypeEnum.BABY) {
      delete sanitizedPatientData.mothers_nik;
      delete sanitizedQueueData.birth_order;
    }
    if (tipePasien === PatientTypeEnum.BABY) {
      delete sanitizedPatientData.phone_number;
      delete sanitizedPatientData.email;
    }
    if (tipePasien !== PatientTypeEnum.WNI) {
      delete sanitizedPatientData.address_match_with_ktp;
    }
    if (tipePasien !== PatientTypeEnum.WNA) {
      delete sanitizedPatientData.passport_no;
    }
    if (tipePasien === PatientTypeEnum.WITHOUT_NIK) {
      delete sanitizedPatientData.nik;
      delete sanitizedPatientData.mothers_nik;
    }

    // Create/Update patient
    patientFactory(sanitizedPatientData)
      .then((patient) => {
        const patientId =
          patient?.id !== undefined && patient?.id !== 0
            ? patient?.id
            : selectedPatient?.id;

        // SatuSehat consent change
        if (satusehatConfigured && sanitizedPatientData.nik) {
          changePatientConsent({
            nik: sanitizedPatientData.nik,
            is_opt_in: sanitizedPatientData.is_opt_in ?? true,
          });
        } else if (!satusehatConfigured) {
          sanitizedPatientData.is_opt_in = false;
        }

        // Create queue
        if (patientId && selectedPolyclinic) {
          const finalQueueData: AQueueBase = {
            polyclinic_id: selectedPolyclinic?.polyclinic_id,
            doctor_id: selectedPolyclinic?.id,
            patient_id: patientId,
            is_bpjs: isBpjs,
            kunj_sakit: jenisPoliSakit === "sakit" ? true : false,
            bpjs_type: sanitizedQueueData?.bpjs_type,
            pemeriksaan_type: sanitizedQueueData?.pemeriksaan_type,
            kunjungan_type: sanitizedQueueData?.kunjungan_type,
            birth_order: sanitizedQueueData?.birth_order,
          };

          createQueue({
            ...finalQueueData,
          })
            .unwrap()
            .then(() => {
              toast.custom((t) => (
                <CustomToast
                  t={t}
                  status="success"
                  headerText="Berhasil"
                  description="Antrian berhasil ditambahkan"
                />
              ));
              toggle(false);
            })
            .catch((err) => {
              let message: string;
              let code: string;
              let formattedMessage: string;

              if (isBpjs) {
                message = err?.data?.message?.metaData?.message;
                code = err?.data?.message?.metaData?.code;
                formattedMessage = message ? `(${code}: ${message})` : "";
              } else {
                message = err?.data?.message;
                code = err?.data?.statusCode;
                formattedMessage = message ? `(${code}: ${message})` : "";
              }

              toast.custom((t) => (
                <CustomToast
                  t={t}
                  status="error"
                  headerText="Gagal"
                  description={`Antrian gagal ditambahkan. ${formattedMessage}`}
                />
              ));
            });
        }
      })
      .catch((err) => {
        let message = err?.data?.message;
        let code = err?.data?.statusCode;
        let formattedMessage = message ? `(${code}: ${message})` : "";

        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description={`Antrian gagal ditambahkan. ${formattedMessage}`}
          />
        ));
      });
  };

  useEffect(() => {
    if (tipePasien === PatientTypeEnum.BABY) {
      queueForm.setValue("pemeriksaan_type", QueuePemeriksaanTypeEnum.UMUM);
    }
  }, [queueForm, tipePasien]);

  useEffect(() => {
    if (tipePasien === PatientTypeEnum.BABY) {
      queueForm.reset({
        ...queueForm.getValues(),
        nik: selectedPatient?.nik,
        mothers_nik: selectedPatient?.mothers_nik,
      });
    }
  }, [queueForm, selectedPatient, tipePasien]);

  useEffect(() => {
    if (!satusehatConfigured) {
      queueForm.setValue("is_opt_in", false);
    }
    if (!bpjsConfigured) {
      setJenisAntrian(QueueTypeEnum.UMUM);
      queueForm.setValue("is_bpjs", false);
    }
  }, [queueForm, satusehatConfigured, bpjsConfigured]);

  return (
    <form onSubmit={queueForm.handleSubmit(handleSubmit)}>
      <IntegrationInfo />

      <Container>
        <SubContainer className="col-3">
          <JenisAntrianChanger
            formData={queueForm}
            value={jenisAntrian}
            onChange={handleChangeJenisAntrian}
            bpjsConfigured={bpjsConfigured}
          />
        </SubContainer>
        <SubContainer
          className={`
          col-3
          ${jenisAntrian === QueueTypeEnum.UMUM ? "" : "hidden"}  
        `}
        >
          <Controller
            name="type"
            control={queueForm.control}
            render={({ field: { value, onChange } }) => (
              <TipePasienChanger
                value={value}
                onChange={onChange}
                selectedPatient={selectedPatient}
              />
            )}
          />
        </SubContainer>
        <SubContainer
          className={`
          col-3
          ${jenisAntrian === QueueTypeEnum.BPJS ? "" : "hidden"}
        `}
        >
          <Controller
            name="bpjs_type"
            control={queueForm.control}
            render={({ field: { value, onChange } }) => (
              <BpjsJenisAntrianChanger value={value} onChange={onChange} />
            )}
          />
        </SubContainer>

        <SubContainer className="col-3">
          <Controller
            name="pemeriksaan_type"
            control={queueForm.control}
            render={({ field: { value, onChange } }) => (
              <TipePemeriksaanSelect
                formData={queueForm}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-3">
          {tipePemeriksaan === QueuePemeriksaanTypeEnum.KEHAMILAN && (
            <Controller
              name="kunjungan_type"
              control={queueForm.control}
              render={({ field: { value, onChange } }) => (
                <KunjunganKehamilanSelect value={value} onChange={onChange} />
              )}
            />
          )}
          {jenisAntrian === QueueTypeEnum.BPJS && (
            <BpjsJenisPoliSakitChanger
              formData={queueForm}
              value={jenisPoliSakit}
              onChange={(e) =>
                setJenisPoliSakit(e.target.value as "sakit" | "sehat")
              }
            />
          )}
        </SubContainer>

        <SubContainer className="col-3">
          <SearchPatientInput
            formData={queueForm}
            selectedPatient={selectedPatient}
            setSelectedPatient={setSelectedPatient}
          />
        </SubContainer>
        <SubContainer className="col-3">
          {tipePasien === PatientTypeEnum.WNI && (
            <Controller
              name="nik"
              control={queueForm.control}
              render={({ field: { value = "", onChange } }) => (
                <BaseTextInput
                  required
                  label="NIK"
                  placeholder="NIK"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    if (/^[0-9]*$/.test(e.target.value)) {
                      onChange(e.target.value);
                    }
                  }}
                  maxLength={16}
                  pattern="^[0-9]{16}$"
                  title="NIK harus 16 digit angka"
                />
              )}
            />
          )}
          {tipePasien === PatientTypeEnum.WNA && (
            <Controller
              name="passport_no"
              control={queueForm.control}
              render={({ field: { value = "", onChange } }) => (
                <BaseTextInput
                  required
                  label="No. Paspor/KITAS"
                  placeholder="No. Paspor/KITAS"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  pattern="[A-Za-z0-9]*"
                  title="Hanya boleh huruf dan angka"
                />
              )}
            />
          )}
          {tipePasien === PatientTypeEnum.BABY && (
            <NikBayiInput formData={queueForm} />
          )}
        </SubContainer>

        <SubContainer className="col-3">
          <BpjsCheckInput
            formData={queueForm}
            setSelectedBpjsPatient={setSelectedBpjsPatient}
          />
        </SubContainer>
        <SubContainer className="col-3">
          <Controller
            name="encounter"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                // required
                label="No. Rekmed"
                placeholder="No. Rekmed"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </SubContainer>

        <SubContainer className="col-3">
          <Controller
            name="full_name"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required
                label="Nama"
                placeholder="Nama"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-3">
          <Controller
            name="sex"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseRadioGroupInput
                id="jenis-kelamin-radio"
                legend="Jenis Kelamin"
                required
                options={[
                  {
                    label: "Laki-laki",
                    value: "male",
                  },
                  {
                    label: "Perempuan",
                    value: "female",
                  },
                ]}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                disabled={
                  tipePemeriksaan === QueuePemeriksaanTypeEnum.KEHAMILAN
                }
                orientation="row"
                spacingOptions="24px"
              />
            )}
          />
        </SubContainer>

        <SubContainer className="col-3">
          <Controller
            name="birth_place"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                // required
                label="Tempat Lahir"
                placeholder="Tempat Lahir"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-3">
          <Controller
            name="birth_date"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required
                label="Tanggal Lahir"
                placeholder="Tanggal Lahir"
                type="date"
                value={value}
                onChange={onChange}
                max={format(new Date(), "yyyy-MM-dd")}
              />
            )}
          />
        </SubContainer>

        {tipePasien === PatientTypeEnum.BABY && (
          <>
            <SubContainer className="col-3">
              <Controller
                name="birth_order"
                control={queueForm.control}
                render={({ field: { value = "", onChange } }) => (
                  <BaseTextInput
                    required
                    label="Urutan Kelahiran"
                    placeholder="Urutan Kelahiran"
                    type="number"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value ? parseInt(e.target.value) : 0);
                    }}
                  />
                )}
              />
            </SubContainer>
            <SubContainer
              className="col-3"
              style={{
                display: "flex",
                height: "100%",
                alignItems: "flex-end",
              }}
            >
              <BaseText>
                Urutan Kelahiran digunakan untuk bayi kembar, jika bayi bukan
                kembar, dapat diisi dengan angka 0
              </BaseText>
            </SubContainer>
          </>
        )}

        <SubContainer className="col-6">
          <Controller
            name="address"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <>
                {/* <BaseTextInput
                  // required
                  label={
                    tipePasien === PatientTypeEnum.WNA
                      ? "Alamat di Indonesia"
                      : "Alamat Domisili"
                  }
                  placeholder="Cari alamat berdasarkan Kota/Kabupaten, Kecamatan, Kelurahan"
                  type="text"
                  value={value}
                  onChange={onChange}
                  minLength={3}
                  disabled
                  title="Alamat minimal 3 karakter"
                  elementAddonRight={<BiSearch />}
                /> */}
                <BaseSelectInput<any>
                  required={satusehatConfigured || bpjsConfigured}
                  label={
                    tipePasien === PatientTypeEnum.WNA
                      ? "Alamat di Indonesia"
                      : "Alamat Domisili"
                  }
                  placeholder="Cari alamat berdasarkan Kecamatan / Kelurahan"
                  value={wilayahOptions.find(
                    (wilayah) => value === wilayah?.value,
                  )}
                  onChange={(e) => onChange(e.value)}
                  onInputChange={(e) => setWilayahKeyword(e)}
                  noOptionsMessage={() => {
                    return wilayahKeyword === ""
                      ? "Masukkan Kecamatan / Kelurahan"
                      : "Data tidak ditemukan";
                  }}
                  isLoading={isLoadingWilayah}
                  options={wilayahOptions}
                />
              </>
            )}
          />
        </SubContainer>

        <SubContainer className="col-2">
          <Controller
            name="rt"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required={satusehatConfigured || bpjsConfigured}
                label="RT"
                placeholder="No. RT"
                type="text"
                value={value}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    onChange(e.target.value);
                  }
                }}
                pattern="^[0-9]*$"
                maxLength={3}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-2">
          <Controller
            name="rw"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required={satusehatConfigured || bpjsConfigured}
                label="RW"
                placeholder="No. RW"
                type="text"
                value={value}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    onChange(e.target.value);
                  }
                }}
                pattern="^[0-9]*$"
                maxLength={3}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-2">
          <Controller
            name="postal_code"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required={satusehatConfigured || bpjsConfigured}
                label="Kode POS"
                placeholder="Kode POS"
                type="text"
                value={value}
                onChange={(e) => {
                  if (/^[0-9]*$/.test(e.target.value)) {
                    onChange(e.target.value);
                  }
                }}
                maxLength={5}
                pattern="^[0-9]{5}$"
                title="Kode POS harus 5 digit angka"
              />
            )}
          />
        </SubContainer>

        <SubContainer className="col-6">
          <Controller
            name="additional_address"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                // required
                label="Alamat (Keterangan Tambahan)"
                placeholder="Informasi tambahan seperti Nama Jalan, Nomor Rumah/Gedung/Apartment/dll"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
          {/* {tipePasien === PatientTypeEnum.WNI && (
            <Controller
              name="address_match_with_ktp"
              control={queueForm.control}
              render={({ field: { value = false, onChange } }) => (
                <BaseSwitchInput
                  valueLabel="Alamat Domisili sama dengan alamat KTP"
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          )} */}
        </SubContainer>

        {tipePasien !== PatientTypeEnum.BABY && (
          <>
            <SubContainer className="col-3">
              <Controller
                name="phone_number"
                control={queueForm.control}
                render={({ field: { value = "", onChange } }) => (
                  <BaseTextInput
                    // required
                    label="No. HP"
                    placeholder="No. HP"
                    type="tel"
                    value={value}
                    onChange={onChange}
                    pattern="^[+]?(\d{10,13})$"
                    title="No HP harus 10-13 digit tanpa karakter khusus"
                  />
                )}
              />
            </SubContainer>
            <SubContainer className="col-3">
              <Controller
                name="email"
                control={queueForm.control}
                render={({ field: { value = "", onChange } }) => (
                  <BaseTextInput
                    // required
                    label="Email"
                    placeholder="Email"
                    type="email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </SubContainer>
          </>
        )}

        <SubContainer className="col-3">
          <Controller
            name="reference_name"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required={bpjsConfigured || satusehatConfigured}
                label="Nama Penjamin"
                placeholder="Nama Penjamin"
                type="text"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </SubContainer>
        <SubContainer className="col-3">
          <Controller
            name="reference_no"
            control={queueForm.control}
            render={({ field: { value = "", onChange } }) => (
              <BaseTextInput
                required={bpjsConfigured || satusehatConfigured}
                label="No. HP Penjamin"
                placeholder="No. HP Penjamin"
                type="tel"
                value={value}
                onChange={onChange}
                pattern="^[+]?(\d{10,13})$"
                title="No HP harus 10-13 digit tanpa karakter khusus"
              />
            )}
          />
        </SubContainer>

        <SubContainer className="col-6">
          <PoliklinikSelect
            jenisAntrian={jenisAntrian}
            jenisPoliSakit={jenisPoliSakit}
            bpjsConfigured={bpjsConfigured}
            selectedPolyclinic={selectedPolyclinic}
            setSelectedPolyclinic={setSelectedPolyclinic}
          />
        </SubContainer>

        {satusehatConfigured && (
          <SubContainer className="col-3 gap-none">
            <Controller
              name="is_opt_in"
              control={queueForm.control}
              render={({ field: { value = false, onChange } }) => (
                <SatusehatConsentChanger
                  value={value}
                  onChange={onChange}
                  satusehatConfigured={satusehatConfigured}
                />
              )}
            />
          </SubContainer>
        )}

        {/* Feature pending */}
        {/* {jenisAntrian === "bpjs" && (
          <SubContainer className="col-3">
            <BaseTextInput
              required
              label="No. Rujukan BPJS"
              placeholder="No. Rujukan BPJS"
              type="text"
              onChange={(e) => {}}
              elementAddonRight={<BiSearch />}
            />
          </SubContainer>
        )} */}

        <SubContainer className="col-6">
          <div
            style={{
              paddingTop: "24px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <BaseButton
              type="button"
              styleType="info"
              onClick={() => toggle(false)}
            >
              Batalkan
            </BaseButton>

            <BaseButton
              type="submit"
              // onClick={(e) => {
              //   e.preventDefault();
              //   console.log(queueForm.getValues());
              //   // toggle(false);
              // }}
              disabled={isCreateQueueLoading || !selectedPolyclinic}
            >
              Tambah Antrian
            </BaseButton>
          </div>
        </SubContainer>
      </Container>
    </form>
  );
};

export default AddQueueForm;
