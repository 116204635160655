import { createSlice } from "@reduxjs/toolkit";

/**
 * Currently this redux slice is unused
 */

const initialState = {
  selectedKdppk: "",
};

const soapInspectionSlice = createSlice({
  name: "soap_inspection_slice",
  initialState,
  reducers: {
    setSelectedKdppk: (state, action) => {
      state.selectedKdppk = action.payload;
    },
    clearSelectedKdppk: (state) => {
      state.selectedKdppk = "";
    },
    resetState: () => initialState,
  },
});

export default soapInspectionSlice.reducer;
export const { setSelectedKdppk, clearSelectedKdppk, resetState } =
  soapInspectionSlice.actions;
