import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { APatientMinWithId } from "types/api/pasien";
import { TPesertaBpjsPureBase } from "types/api/bpjs";
import { usePatchKelompokPesertaMutation } from "store/bpjsStoreAPI/bpjsKelompokAPI";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { Container } from "./style";
import { BaseRadioGroupInput, BaseTextInput } from "components/base/Input";
import { BaseButton } from "components/base/Button";
import { BaseText } from "components/base/Text";
import CustomToast from "components/CustomToast";
import TableAddPeserta from "./components/TableAddPeserta";
import SearchPatient from "./components/SearchPatient";
import BpjsCheckInput from "./components/BpjsCheckInput";

type Props = {
  eduId?: string;
  toggleModal: (value?: boolean | undefined) => void;
};

const AddPesertaForm = ({ eduId, toggleModal }: Props) => {
  const [addType, setAddType] = useState<"cari" | "prolanis">("cari");
  const [selectedPatient, setSelectedPatient] =
    useState<APatientMinWithId | null>(null);
  const [selectedPesertaProlanis, setSelectedPesertaProlanis] = useState<any[]>(
    [],
  );
  const [patientBpjsActive, setPatientBpjsActive] = useState(false);
  const [patientBpjsData, setPatientBpjsData] =
    useState<TPesertaBpjsPureBase | null>(null);
  const { formatRupiah } = useFormatRupiah();

  const PesertaForm = useForm<any>();

  const [patchKelompokPeserta] = usePatchKelompokPesertaMutation();

  const handleSubmit = () => {
    if (!eduId) return;

    if (addType === "cari" && patientBpjsData?.noKartu) {
      patchKelompokPeserta({
        eduId: eduId,
        noKartu: patientBpjsData?.noKartu,
      })
        .unwrap()
        .then((res) => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil"
              description="Peserta berhasil ditambahkan ke Kelompok Bersama"
            />
          ));
        })
        .catch((err) => {
          console.log(err);
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal"
              description="Peserta gagal ditambahkan ke Kelompok Bersama"
            />
          ));
        });
    }

    if (addType === "prolanis" && selectedPesertaProlanis.length > 0) {
      const promises = selectedPesertaProlanis.map((peserta) => {
        return patchKelompokPeserta({
          eduId: eduId,
          noKartu: peserta.no_kartu,
        }).unwrap();
      });

      Promise.allSettled(promises).then((results) => {
        let countSuccess = 0;
        let countFail = 0;

        results.forEach((result) => {
          if (result.status === "fulfilled") {
            countSuccess++;
          } else {
            countFail++;
          }
        });

        if (countSuccess > 0) {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil"
              description={`${countSuccess} peserta berhasil ditambahkan ke Kelompok Bersama, terdapat ${countFail} gagal ditambahkan`}
            />
          ));
        } else {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal"
              description="Peserta gagal ditambahkan ke Kelompok Bersama"
            />
          ));
        }
      });
    }

    toggleModal(false);
  };

  useEffect(() => {
    if (selectedPatient) {
      const { bpjs_no } = selectedPatient;
      PesertaForm.setValue("bpjs_no", bpjs_no);
    }
  }, [selectedPatient, PesertaForm]);

  const isOnProlanis = addType === "prolanis";

  return (
    <>
      <form onSubmit={PesertaForm.handleSubmit(handleSubmit)}>
        <Container>
          <div className="span-full">
            <BaseRadioGroupInput
              required={!isOnProlanis}
              id="jenis-kegiatan-radiogroup"
              legend="Jenis Kegiatan"
              options={[
                { label: "Cari Peserta", value: "cari" },
                { label: "Peserta Prolanis", value: "prolanis" },
              ]}
              spacingOptions="12px"
              value={addType}
              onChange={(e) =>
                setAddType(e.target.value as "cari" | "prolanis")
              }
            />
          </div>

          {addType === "cari" ? (
            <>
              <SearchPatient setSelectedPatient={setSelectedPatient} />
              <div>
                <BpjsCheckInput
                  formData={PesertaForm}
                  setPatientBpjsActive={setPatientBpjsActive}
                  setPatientBpjsData={setPatientBpjsData}
                />
              </div>
              <BaseTextInput
                disabled
                label="Nama"
                placeholder="Nama Peserta"
                type="text"
                defaultValue={patientBpjsData?.nama}
              />
              <BaseTextInput
                disabled
                label="Jenis Kelamin"
                placeholder="Jenis Kelamin"
                type="text"
                defaultValue={patientBpjsData?.sex}
              />
              <BaseTextInput
                disabled
                label="Tanggal Lahir"
                placeholder="Tanggal Lahir"
                type="text"
                defaultValue={patientBpjsData?.tglLahir}
              />
              <BaseTextInput
                disabled
                label="Jenis Kelas"
                placeholder="Jenis Kelas"
                type="text"
                defaultValue={patientBpjsData?.jnsKelas?.nama}
              />
              <BaseTextInput
                disabled
                label="Status Peserta"
                placeholder="Status Peserta"
                type="text"
                defaultValue={patientBpjsData?.ketAktif}
              />
              <BaseTextInput
                disabled
                label="Jenis Peserta"
                placeholder="Jenis Peserta"
                type="text"
                defaultValue={patientBpjsData?.jnsPeserta?.nama}
              />
              <BaseTextInput
                disabled
                label="Tanggal Mulai Aktif"
                placeholder="Tanggal Mulai Aktif"
                type="text"
                defaultValue={patientBpjsData?.tglMulaiAktif}
              />
              <BaseTextInput
                disabled
                label="Tanggal Akhir Berlaku"
                placeholder="Tanggal Akhir Berlaku"
                type="text"
                defaultValue={patientBpjsData?.tglAkhirBerlaku}
              />
              <BaseText>
                Tunggakan:{" "}
                {patientBpjsData?.tunggakan
                  ? formatRupiah(patientBpjsData?.tunggakan)
                  : "Rp. 0"}
              </BaseText>
            </>
          ) : (
            <div className="span-full">
              <TableAddPeserta
                selectedPesertaProlanis={selectedPesertaProlanis}
                setSelectedPesertaProlanis={setSelectedPesertaProlanis}
              />
            </div>
          )}

          <div
            className="span-full"
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <BaseButton styleType="regular" type="submit">
              Simpan
            </BaseButton>
          </div>
        </Container>
      </form>
    </>
  );
};

export default AddPesertaForm;
