import { MdChevronLeft } from "react-icons/md";
import styled, { css } from "styled-components";

import { COLORS, medium, small } from "assets/theme";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import InputText from "components/InputText/InputText";
import { BasicActionBtn } from "components/base";
import InputTextArea from "components/InputText/InputTextArea";
import CustomDropdown from "components/CustomDropdown";

// export const PRDiv = styled(PRForm).withConfig({})

const baseContainerStyle = css`
  margin-bottom: 15px;
  /* :not(:first-child) {
    margin-top: 10px;
  } */
  background-color: ${COLORS.silver_lining};
  border-radius: 6px;
  padding: 16px 24px;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 5px;

  font-size: 12px;
  & > * {
    margin-bottom: 10px;
  }
`;

const inputTextCSS = css`
  & > label {
    margin-bottom: 5px;
    font-weight: normal;
    color: ${COLORS.text_black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & .wrapper {
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    border-radius: 6px;
  }
  & input,
  textarea {
    padding: 10px;
    border: none;
    &:focus {
      outline: 2px solid ${COLORS.green};
      outline-offset: -1.5px;
    }
    :disabled {
      background: ${COLORS.silver_lining};
    }
    font-size: 1em;
    /* ::placeholder {
    } */
  }
`;

export const Main = styled.main`
  width: 90%;
  margin: 0 auto;
  position: relative;
  top: -60px;
  background-color: white;

  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 5px 20px rgba(85, 85, 85, 0.15);
  /* max-height: 75vh; */

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PRForm = styled.form`
  ${baseContainerStyle}
`;

export const PRDiv = styled.div`
  ${baseContainerStyle}
`;

export const PRVitalSignDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 10px;
  margin-bottom: 5px;

  ${medium} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const PRRekamMedisContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  & > .rekmed-select {
    flex-basis: 200px;
  }
`;

export const PRRekamMedisSelect = styled(PureInputSelect)`
  & [class*="-container"] {
    margin: 0;
    outline: none;
  }

  &.rekmed-select [class*="-container"] {
    width: 300px;
  }

  ${small} {
    &.rekmed-select [class*="-container"] {
      width: 100%;
    }
  }

  & [class$="-control"] {
    border-color: ${COLORS.neutral_gray_gull};
    border-width: 0.5;
    border-style: solid;
    border-radius: 6px;
    box-shadow: none;
    &:focus-within {
      outline: 2px solid ${COLORS.green} !important;
      outline-offset: -2px;
      // box-shadow: 0 0 0 2px ${COLORS.green};
    }

    &:hover {
      border-color: ${COLORS.neutral_gray_gull};
    }
  }

  & [class$="-multiValue"] {
    background: ${COLORS.teal};
    color: white;
    box-shadow: none;
  }

  &.disabled {
    & [class$="-multiValue"] > div[class$="-MultiValueRemove"] {
      display: none;
      /* background: ${COLORS.teal};
    color: white; */
    }
  }
  ${inputTextCSS}
` as typeof PureInputSelect;

export const PRNormalBtn = styled(BasicActionBtn)`
  transition: background 0.2s ease-in;
  &.inactive {
    background: ${COLORS.text_gray};
  }
`;

// const disabledInput = css``;

export const PRFormInput = styled(InputText)`
  ${inputTextCSS}
  overflow-y: hidden;
  & > label {
    /* font-weight: normal;
    color: ${COLORS.text_black};
    margin-bottom: 5px; */
  }
`;

export const PRTDInput = styled(PRFormInput)`
  flex: 1;
  /* max- */
`;

export const PRFormTextArea = styled(InputTextArea)`
  ${inputTextCSS}
  & textarea {
    resize: vertical;

    &:focus {
      outline: none;
    }
  }

  .wrapper {
    border-radius: 6px;

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -1.5px;
    }
  }
`;
// style={{ display: "flex", alignItems: "center", gap: "10px" }}
export const PRRadioGroup = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-basis: 100%;
  &.s {
    flex-basis: 48%;
  }
  margin: 0;
  border: 0;
  padding: 4px 10px;

  accent-color: ${COLORS.green};
`;

export const PRRadioLabel = styled.label``;

export const PRRadio = styled.input``;

export const AntrianSearchInput = styled(InputText)<{ $hasNoLabel?: boolean }>`
  margin-right: 5px;
  min-width: 500px;

  ${medium} {
    min-width: unset;
    width: 100%;
  }
  :focus-visible {
    outline: none;
  }
  & .wrapper {
    background: ${COLORS.silver_lining};
    border-radius: 6px;
    height: ${({ $hasNoLabel }) => $hasNoLabel && "100%"};
    svg {
      margin-left: 5px;
    }

    &:focus-within {
      background: ${COLORS.white};
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }
  & input {
    ${medium} {
      min-width: unset !important;
    }
    background: ${COLORS.silver_lining};
    border: none;
    padding-left: 0;
    :focus-visible {
      outline: none;
      background: ${COLORS.white};
    }
  }
`;

export const PRUnduhDropdown = styled(CustomDropdown)`
  & .content {
    /* top: 150%; */
    border-radius: 6px;
  }
  & .item {
    padding: 0;
    :first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    :last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    > * {
      display: block;
      padding: 8px 12px;
      width: 100%;
    }

    :hover {
      color: black;
      background: ${COLORS.mint_green};
    }
  }
`;

export const DropdownChevron = styled(MdChevronLeft)`
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
  margin-top: 2px;
  vertical-align: middle;
  &.open {
    transform: rotate(-270deg);
  }
`;

export const PRUnduhItem = styled.span`
  font-size: 0.75rem;
  &.no-action {
    color: gray;
  }
`;

export const InfoTextContainer = styled.span`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

export const BackButton = styled(BasicActionBtn)`
  background: transparent;
  border: 0;
  border-radius: 6px;

  padding: 5px;
  color: black;

  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background 0.2s ease-in-out;
  :hover {
    background: ${COLORS.mint_green};
  }
`;

export const ActionButton = styled(BasicActionBtn)`
  background: ${COLORS.asphalt};
  padding: 10px;
  text-align: center;
  width: 120px;
`;

export const FormInputTable = styled(InputText)`
  flex-basis: 100%;
  margin-bottom: 10px;
  /* & > * {
    padding: 0 4px;
  } */
  &.s {
    flex-basis: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & .wrapper {
    background-color: white;
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    border-radius: 4px;
    &.disabled {
      background: ${COLORS.silver_lining};
    }
  }
  & input {
    padding: 4px;
    min-height: initial;
    font-size: inherit;
    border: none;
    ::placeholder {
      font-size: inherit;
    }
    :focus-visible {
      outline: 1.5px solid ${COLORS.green};
    }
    :disabled {
      background: ${COLORS.silver_lining};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  & input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const KasirFormInput = styled(InputText)``;

export const HeaderForm = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 42px auto 42px;
  height: 30px;

  h1 {
    text-align: center;
    font-size: 1.3rem;
  }

  svg {
    font-size: 30px;
    color: ${COLORS.green};
  }
`;

export const StyledForm = styled(PRForm)`
  &.is-collapsed {
    .wrapper-contents {
      grid-template-rows: 0fr;
      overflow: hidden;
    }

    gap: 0;

    * {
      margin-bottom: 0;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    transition: transform 0.3s ease-in-out;
  }

  * {
    transition: margin-bottom 0.3s ease-in-out;
    transition-delay: 0.3s;
  }

  .wrapper-contents {
    display: grid;
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.3s ease-in-out;
  }

  .expandable {
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-height: 0;
    padding: 0 2px;
    width: 100%;
    overflow: hidden;

    .table-odontogram {
      min-width: 460px;
    }
  }
`;

export const InfoText = styled.p`
  margin: 0px;
  font-size: 12px;
  color: ${COLORS.text_hint};
  font-style: italic;
`;

export const AltActionBtn = styled(BasicActionBtn)`
  padding: 8px 10px;
  whitespace: nowrap;
  background: ${COLORS.green_linear_gradient};
  color: transparent;
  background-clip: text;

  transition: background 0.2s ease-in;
  &.inactive {
    background: ${COLORS.text_gray};
  }

  svg {
    color: ${COLORS.green};
  }
`;

export const DoubleInputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  & > * {
    flex: 1 !important;
  }

  & > p {
    align-self: flex-end;
    margin-bottom: 17px;
    width: 100% !important;
  }

  ${small} {
    flex-direction: column;
    & > * {
      flex: 1;
    }

    & > p {
      align-self: flex-start;
    }
  }
`;
