import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Occlusi,
  OdontogramDetailsRequest,
  Palatum,
  TorusMandibularis,
  TorusPlatinus,
} from "types/api/odontogram";

export type OdontogramFormState = {
  id: string;
  isCleftLip: string;
  isMouthCornerWound: string;
  isAphthousStomatitis: string;
  isCoatedTongue: string;
  otherWound: string;
  occlusi: Occlusi;
  "luka-lainnya": string;
  torusPlatinus: TorusPlatinus;
  torusMandibularis: TorusMandibularis;
  palatum: Palatum;
  diastema: string;
  anomalyTeeth: string;
  otherInformation: string;
  totalDecayTeeth: number;
  totalFillingTeeth: number;
  totalMissingTeeth: number;
  odontogramDetails: (OdontogramDetailsRequest & {
    teethNumber: string;
    conditionName: string;
  })[];
};

const initialState = {
  queue_id: "",
  odontogram_data: {},
};

const OdontogramFormSlice = createSlice({
  name: "odontogram_form_slice",
  initialState,
  reducers: {
    setQueueId: (state, action: PayloadAction<string>) => {
      state.queue_id = action.payload;
    },
    setOdontogramData: (
      state,
      action: PayloadAction<Partial<OdontogramFormState>>,
    ) => {
      state.odontogram_data = action.payload;
    },
    resetOdontogramData: (state) => {
      state.odontogram_data = initialState.odontogram_data;
    },
  },
});

export default OdontogramFormSlice.reducer;
export const { setQueueId, setOdontogramData, resetOdontogramData } =
  OdontogramFormSlice.actions;
