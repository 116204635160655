import styled from "styled-components";

import { COLORS, large, medium, small } from "assets/theme";
import InputText from "components/InputText/InputText";

export const Main = styled.main`
  width: 90%;
  margin: auto;
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Form = styled.form`
  &.hidden {
    display: none;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;

  p:first-child {
    margin-bottom: 0;
    color: ${COLORS.text_black};
    min-width: 200px;
  }

  p:last-child,
  span:last-child {
    margin-bottom: 0;
    font-weight: bold;
  }
`;

export const WideItem = styled(Item)`
  p:first-child {
    min-width: 150px;
  }

  ${small} {
    p:first-child {
      min-width: 100px;
    }
  }
`;

export const FormInput = styled(InputText)`
  flex-basis: 100%;
  margin-bottom: 10px;
  &.s,
  &.m {
    flex-basis: 48%;
  }
  ${large} {
    &.m {
      flex-basis: 100%;
    }
  }
  ${medium} {
    &.s {
      flex-basis: 100%;
    }
  }

  &.alone {
    max-width: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & .wrapper {
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    border-radius: 6px;
  }
  & input {
    border: none;
    ::placeholder {
      font-size: 1rem;
    }
    :focus-visible {
      outline: 2px solid ${COLORS.green};
    }
    :disabled {
      background: ${COLORS.silver_lining};
    }
  }

  .right-addon {
    label {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        border-left: 0.5px solid ${COLORS.neutral_gray_gull};
        height: 30px;
        width: 1;
        display: block;
      }
    }

    select {
      border: none;
      height: 36px;

      &:focus {
        outline: none;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;

  button {
    margin: inherit 0;
    flex-basis: 220px;
  }

  &.active {
    justify-content: center;
    button {
      display: none;
    }
  }

  h1 {
    font-size: 24px;
    min-width: 230px;
  }

  &.wide {
    display: grid;
    grid-template-columns: 220px 1fr 220px;
    align-items: center;

    ${medium} {
      display: flex;
      justify-content: between;
      flex-wrap: wrap;

      span {
        display: none;
      }
    }

    h1 {
      text-align: center;
    }
  }
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 27px 50px;

  ${medium} {
    padding: 35px;
  }

  ${small} {
    padding: 20px;
  }

  .item-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;

  ${small} {
    justify-content: center;

    button {
      width: 100%;
    }
  }
`;

export const InfoText = styled.p`
  font-size: 12px;
  color: ${COLORS.text_hint};
  font-style: italic;
  margin: 0;
`;

export const AccountDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const EmailDisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ClinicDataWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: start;
  flex-wrap: wrap;
  margin-top: 25px;

  & img {
    object-fit: cover;
    object-position: center;
    height: 200px;
    width: 200px;
    border-radius: 8px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  & form {
    flex-grow: 1;
    min-width: 300px;
  }
`;

export const EditImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 200px;

  & p {
    font-size: 12px;
    color: ${COLORS.text_gray};
  }

  & .input-file {
    display: none;
  }
`;

export const UploadLogoButton = styled.button`
  width: fit-content;
  padding: 10px 20px;
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.green};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-weight: 700;
  color: ${COLORS.green};
  cursor: pointer;

  &:hover {
    background: ${COLORS.green_linear_gradient};
    border: 2px solid ${COLORS.green};
    color: ${COLORS.white};
  }
`;

export const PreviewTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    font-size: 12px;
    color: ${COLORS.text_gray};
    margin: 0;
  }

  svg {
    cursor: pointer;
    flex-shrink: 0;
  }
`;
