/**
 * @description Defined BPJS endpoints with the sub endpoint /participant-prolanis.
 * @description Tagged with "Bpjs-Prolanis" for caching and other purposes.
 */

import { baseApi } from "store/baseStoreAPI";
import { BackendDataShape } from "types";
import { TPesertaProlanis } from "types/api/bpjs";

const subEndpoint = "/participant-prolanis";

export const bpjsProlanisApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProlanisParticipant: builder.query<TPesertaProlanis[], void>({
      query: () => {
        return {
          url: `${subEndpoint}`,
          method: "GET",
        };
      },
      providesTags: ["Bpjs-Prolanis"],
      transformResponse: (res: BackendDataShape<TPesertaProlanis[]>) => {
        return res?.data;
      },
    }),

    postProlanisParticipant: builder.mutation<
      any,
      {
        no_kartu: string;
        name: string;
        sex: "male" | "female";
        birth_date: string;
        class_type: string;
        status: boolean;
        type: string;
        active_date: string;
        expired_date: string;
      }
    >({
      query: ({ ...args }) => {
        return {
          url: `${subEndpoint}`,
          method: "POST",
          body: {
            ...args,
          },
        };
      },
      invalidatesTags: ["Bpjs-Prolanis"],
      transformResponse: (res: BackendDataShape<any>) => {
        return res?.data;
      },
    }),

    deleteProlanisParticipant: builder.mutation<
      any,
      {
        id: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `${subEndpoint}/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Bpjs-Prolanis"],
    }),
  }),
});

export const {
  useGetProlanisParticipantQuery,
  usePostProlanisParticipantMutation,
  useDeleteProlanisParticipantMutation,
} = bpjsProlanisApi;
