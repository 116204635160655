import { useState } from "react";
import { Column } from "react-table";
import { differenceInYears, format } from "date-fns";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";

import { TPesertaProlanis } from "types/api/bpjs";
import useDebounce from "hooks/utils/useDebounce";
import {
  useDeleteProlanisParticipantMutation,
  useGetProlanisParticipantQuery,
} from "store/bpjsStoreAPI/bpjsProlanisAPI";

import { COLORS } from "assets/theme";
import { BaseTable } from "components/base/Table";
import { BaseActionButton, BaseButton } from "components/base/Button";
import { BaseModalRefined } from "components/base/Modal";
import { useModal } from "components/ModalRefined/ModalRefined";
import CustomToast from "components/CustomToast";

type Props = {
  search?: string;
};

const TablePeserta = ({ search }: Props) => {
  const { currentData: pesertaProlanis } = useGetProlanisParticipantQuery();
  const [deletePesertaProlanis] = useDeleteProlanisParticipantMutation();
  const [selectedPeserta, setSelectedPatient] = useState<TPesertaProlanis>();
  const { isOpen, toggleModal } = useModal(false);
  const searchDebounce = useDebounce(search, 500);

  const filteredPesertaProlanis =
    pesertaProlanis?.filter((peserta) => {
      if (!searchDebounce) return true;
      return (
        peserta.no_kartu
          ?.toLowerCase()
          .includes(searchDebounce.toLowerCase()) ||
        peserta.name?.toLowerCase().includes(searchDebounce.toLowerCase()) ||
        peserta.type?.toLowerCase().includes(searchDebounce.toLowerCase())
      );
    }) || [];

  const handleDelete = (id: string) => {
    deletePesertaProlanis({
      id,
    })
      .then((res) => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Berhasil"
            description="Peserta prolanis berhasil dihapus"
          />
        ));
      })
      .catch((err) => {
        console.log(err);
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description="Peserta prolanis gagal dihapus"
          />
        ));
      });

    toggleModal(false);
    setSelectedPatient(undefined);
  };

  const columns: Column<TPesertaProlanis>[] = [
    {
      Header: "No.",
      accessor: (_, i) => i + 1,
      width: 40,
    },
    {
      accessor: "no_kartu",
      Header: "No. Kartu",
    },
    {
      accessor: "name",
      Header: "Nama Peserta",
    },
    {
      accessor: "sex",
      Header: "Jenis Kelamin",
      Cell: ({ value }) => {
        return <span>{value === "male" ? "Laki-laki" : "Perempuan"}</span>;
      },
    },
    {
      accessor: "type",
      Header: "Jenis Peserta",
    },
    {
      accessor: "birth_date",
      Header: "Tanggal Lahir",
      Cell: ({ value }) => {
        return <span>{format(new Date(value), "dd/mm/yyyy")}</span>;
      },
    },
    {
      Header: "Usia",
      // @ts-ignore
      Cell: ({ row: { original: o } }) => {
        const age = differenceInYears(new Date(), new Date(o.birth_date));
        return <span>{age}</span>;
      },
    },
    {
      Header: "Aksi",
      // @ts-ignore
      Cell: ({ row: { original: o } }) => {
        const handleDelete = () => {
          setSelectedPatient(o);
          toggleModal(true);
        };

        return (
          <BaseActionButton
            title="Hapus Peserta Prolanis"
            onClick={handleDelete}
          >
            <MdDeleteForever size={18} color={COLORS.magenta} />
          </BaseActionButton>
        );
      },
    },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        data={filteredPesertaProlanis}
        isHideNumbering
        showPagination={false}
        isLoading={false}
      />
      <BaseModalRefined
        isOpen={isOpen}
        header="Hapus Peserta Prolanis"
        onClose={toggleModal}
      >
        <div>
          <p
            style={{
              margin: "12px 0",
              textAlign: "center",
            }}
          >
            Apakah anda yakin ingin menghapus peserta {selectedPeserta?.name}?
          </p>
          <div
            style={{
              paddingTop: "24px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <BaseButton
              type="button"
              styleType="info"
              onClick={() => toggleModal(false)}
            >
              Batalkan
            </BaseButton>

            <BaseButton
              type="button"
              styleType="danger"
              onClick={() =>
                handleDelete(selectedPeserta?.id?.toString() ?? "")
              }
            >
              Hapus
            </BaseButton>
          </div>
        </div>
      </BaseModalRefined>
    </>
  );
};

export default TablePeserta;
