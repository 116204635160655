import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import toast from "react-hot-toast";

import { TClubBase, TCodeBase, TKelompokBersamaRequest } from "types/api/bpjs";
import {
  useGetKegiatanCodeQuery,
  useGetKelompokClubQuery,
  useGetKelompokCodeQuery,
  usePostKelompokKegiatanMutation,
} from "store/bpjsStoreAPI/bpjsKelompokAPI";

import { BaseSelectInput, BaseTextInput } from "components/base/Input";
import { BaseButton } from "components/base/Button";
import CustomToast from "components/CustomToast";
import { Container } from "./style";

const defaultFormValues: TKelompokBersamaRequest = {
  clubId: 0,
  tglPelayanan: "",
  kdKegiatan: "",
  kdKelompok: "",
  materi: "",
  pembicara: "",
  lokasi: "",
  keterangan: "",
  biaya: NaN,
};

const AddKelompokBersamaForm = () => {
  const KBform = useForm<TKelompokBersamaRequest>({
    defaultValues: defaultFormValues,
  });

  const { data: kelompokCode, isLoading: kelompokCodeLoading } =
    useGetKelompokCodeQuery({});
  const { data: kelompokClub, isLoading: kelompokClubLoading } =
    useGetKelompokClubQuery(
      {
        KelompokCode: KBform.watch("kdKelompok"),
        // 01 : Diabetes Melitus, 02 : Hipertensi
      },
      {
        skip: !KBform.watch("kdKelompok"),
      },
    );
  const { data: kelompokKegiatanCode, isLoading: kelompokKegiatanCodeLoading } =
    useGetKegiatanCodeQuery({});

  const kelompokCodeOptions = kelompokCode?.map((item: TCodeBase) => ({
    label: item?.nmProgram,
    value: item?.kdProgram,
  }));

  const kelompokClubOptions = kelompokClub?.map((item: TClubBase) => ({
    label: item?.nama,
    value: item?.clubId,
  }));

  const kelompokKegiatanCodeOptions = kelompokKegiatanCode?.map(
    (item: TCodeBase) => ({
      label: item?.nmProgram,
      value: item?.kdProgram,
    }),
  );

  const [postKegiatan] = usePostKelompokKegiatanMutation();

  const handleSubmit = (data: TKelompokBersamaRequest) => {
    postKegiatan(data)
      .unwrap()
      .then((res) => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Berhasil"
            description="Kelompok berhasil ditambahkan"
          />
        ));
        KBform.reset({
          ...defaultFormValues,
        });
      })
      .catch((err) => {
        console.log(err);
        const status = err?.status;
        const reason = err?.data?.message?.response
          ?.map((error: any) => `${error.field}: ${error.message}`)
          .join(", ");
        const message =
          `Kelompok gagal ditambahkan. status code: ${status}.` +
          (reason ? ` ${reason}` : "");
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description={message}
          />
        ));
      });
  };

  const watchKdKelompok = KBform.watch("kdKelompok");

  return (
    <form onSubmit={KBform.handleSubmit(handleSubmit)}>
      <Container>
        <Controller
          name="tglPelayanan"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Tanggal Pelaksanaan"
              placeholder="Tanggal Pelaksanaan"
              type="date"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="kdKelompok"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectInput<any>
              required
              label="Jenis Kelompok"
              placeholder="Jenis Kelompok"
              options={kelompokCodeOptions || []}
              value={
                kelompokCodeOptions?.find(
                  (item: any) => item?.value === value,
                ) || null
              }
              onChange={(e) => {
                onChange(e?.value);
              }}
              isLoading={kelompokCodeLoading}
            />
          )}
        />
        <Controller
          name="clubId"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectInput<any>
              required
              label="Club Prolanis"
              placeholder={
                !watchKdKelompok
                  ? "Pilih jenis kelompok terlebih dahulu"
                  : "Club Prolanis"
              }
              options={kelompokClubOptions || []}
              value={
                kelompokClubOptions?.find(
                  (item: any) => item?.value === value,
                ) || null
              }
              onChange={(e) => {
                onChange(e?.value);
              }}
              isLoading={kelompokClubLoading}
              isDisabled={!watchKdKelompok}
              noOptionsMessage={() => {
                return isEmpty(kelompokCodeOptions)
                  ? "Pilih jenis kelompok terlebih dahulu"
                  : "Tidak ada data Club Prolanis";
              }}
            />
          )}
        />
        <Controller
          name="kdKegiatan"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseSelectInput<any>
              required
              label="Jenis kegiatan"
              placeholder="Jenis kegiatan"
              options={kelompokKegiatanCodeOptions || []}
              value={
                kelompokKegiatanCodeOptions?.find(
                  (item: any) => item?.value === value,
                ) || null
              }
              onChange={(e) => {
                onChange(e?.value);
              }}
              isLoading={kelompokKegiatanCodeLoading}
            />
          )}
        />
        <Controller
          name="materi"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Materi"
              placeholder="Materi"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="pembicara"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Pembicara"
              placeholder="Pembicara"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="lokasi"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Lokasi"
              placeholder="Lokasi"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="biaya"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Biaya (IDR)"
              placeholder="Biaya (IDR)"
              type="number"
              value={value}
              onChange={(e) => {
                onChange(parseInt(e.target.value));
              }}
            />
          )}
        />
        <Controller
          name="keterangan"
          control={KBform.control}
          render={({ field: { value, onChange } }) => (
            <BaseTextInput
              required
              label="Keterangan"
              placeholder="Keterangan"
              type="text"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <div
          className="span-full"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <BaseButton type="submit" styleType="regular" styleSize="md">
            Tambah Kelompok
          </BaseButton>
        </div>
      </Container>
    </form>
  );
};

export default AddKelompokBersamaForm;
