import { useGetPolyclinicQuery } from "store/clinicStoreAPI";
import { BasicSelectOpt } from "types";
// import { APolyclinicWithId } from "types/api/clinic";

export interface PolyclinicOpt extends BasicSelectOpt<number> {}

type Props = {
  page: number;
  take: number;
  skip?: boolean;
  allowAll?: boolean;
};

const usePolyclinicOptions = ({
  page = 1,
  take = 10,
  skip = false,
  allowAll,
}: Props) => {
  const { data, isLoading, isFetching } = useGetPolyclinicQuery(
    {
      page,
      take,
    },
    { skip },
  );

  let list =
    data?.data?.map<PolyclinicOpt>((obj) => {
      return {
        ...obj,
        value: obj.id,
        label: obj.is_default
          ? `${obj.polyclinic_name} (Default)`
          : obj.code
          ? `${obj.code} - ${obj.polyclinic_name}`
          : `${obj.polyclinic_name}`,
      };
    }) ?? [];

  if (allowAll) {
    list = [{ value: -1, label: "Semua Poli" }, ...list];
  }

  return {
    list,
    isLoading: isLoading || isFetching,
  };
};

export default usePolyclinicOptions;
