import React, { useMemo } from "react";
import { Column } from "react-table";

import { ReportDrugsItem, ReportDrugsList } from "types/api/report";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportDrugsList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const tableData = data?.entities ?? [];

  const columns: Column<ReportDrugsItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Nama Merk",
        accessor: "merk",
      },
      {
        Header: "Nama Generik",
        accessor: "generic_name",
      },
      {
        Header: "Pabrikan",
        accessor: "manufacturer",
      },
      {
        Header: "Satuan",
        accessor: "unit",
      },
      {
        Header: "Harga Beli Satuan",
        accessor: "purchase_price",
      },
      {
        Header: "Harga Jual Satuan",
        accessor: "retail_price",
      },
      {
        Header: "Jumlah Stok",
        accessor: "stock",
      },
    ];
  }, [page, take]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
