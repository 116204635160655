import React, { InputHTMLAttributes, ChangeEventHandler } from "react";
import styled from "styled-components";

type SwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  valueLabel?: string;
  trackColor?: string;
};

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SwitchContainer = styled.label`
  display: inline-block;
  position: relative;
  width: 30px;
  height: 20px;
`;

const Slider = styled.span<{ checked: boolean; trackColor?: string }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.checked ? props.trackColor || "#2196F3" : "#ccc"};
  transition: 0.2s;
  border-radius: 30px;

  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 3px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
    transform: ${(props) =>
      props.checked ? "translateX(11px)" : "translateX(0)"};
  }
`;

const HiddenCheckbox = styled.input`
  opacity: 0;
  width: 0;
`;

const Switch: React.FC<SwitchProps> = ({
  onChange,
  checked,
  valueLabel,
  trackColor,
  ...props
}) => {
  return (
    <Container>
      <SwitchContainer>
        <HiddenCheckbox
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <Slider checked={checked} trackColor={trackColor} />
      </SwitchContainer>
      {valueLabel && <span>{valueLabel}</span>}
    </Container>
  );
};

export default Switch;
