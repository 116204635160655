import styled, { createGlobalStyle } from "styled-components";
import DatePicker from "react-datepicker";
import { IoMdCalendar } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { RangedDatePickerProps } from "components/RangedDatepicker/RangedDatepicker";
import { COLORS } from "assets/theme";

export const GlobalStyle = createGlobalStyle`
  .clear-button {
    display: none !important;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const DatePickerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)<
  Pick<RangedDatePickerProps, "fullWidth">
>`
  min-width: ${(props) => (props.fullWidth ? "0" : "210px")};
  width: 100%;
  padding: 6px;
  border: 1px solid ${COLORS.neutral_gray_gull};
  border-radius: 6px;
  font-size: 16px;

  &:focus-within {
    outline: 2px solid ${COLORS.green};
    outline-offset: -2px;
  }
`;

export const CalendarIcon = styled(IoMdCalendar)`
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: none;
`;

export const ClearIcon = styled(IoCloseOutline)`
  position: absolute;
  right: 6px;
  top: 10px;
  cursor: pointer;
`;
