import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LuInfo } from "react-icons/lu";

import { useAppSelector } from "hooks";
import { resetReport } from "store/reportSlice";

import { BasicHeader } from "components/base";
import {
  Container,
  HeaderContainer,
  IconContainer,
  InfoContainer,
  Main,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import ReportTable from "Pages/Report/components/ReportTables/ReportTable";

const InfoPrompt = () => {
  return (
    <InfoContainer>
      <IconContainer>
        <LuInfo size={24} />
      </IconContainer>
      <p>
        Silahkan pilih <strong>jenis laporan</strong> yang ingin Anda tinjau
        untuk mendapatkan informasi yang tepat sesuai kebutuhan Anda.
      </p>
    </InfoContainer>
  );
};

const Report = () => {
  const dispatch = useDispatch();
  const { reportType } = useAppSelector((state) => state.reportSlice);

  useEffect(() => {
    return () => {
      dispatch(resetReport());
    };
  }, [dispatch]);

  return (
    <Main>
      <HeaderContainer>
        <BasicHeader>Laporan</BasicHeader>
      </HeaderContainer>

      <Container>
        {reportType ? (
          <ReportTable />
        ) : (
          <>
            <InfoPrompt />
            <div className="filter-container">
              <ReportTypeSelect />
            </div>
          </>
        )}
      </Container>
    </Main>
  );
};

export default Report;
