import styled from "styled-components";
import { COLORS, large, medium, small, xSmall } from "assets/theme";
import { BasicActionBtn, BasicText } from "components/base";
import InputText from "components/InputText/InputText";
import Modal from "components/Modal/Modal";

export const Main = styled.main`
  background-color: white;
  height: 100%;
  width: 90%;
  margin: auto;
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  padding: 16px 32px;

  ${medium} {
    padding: 16px 16px;
  }

  ${small} {
    padding: 8px 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 24px;
  }
`;

export const ActionContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(6, 1fr);

  ${small} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${xSmall} {
    gap: 8px 0;
    grid-template-columns: repeat(1, 1fr);
  }

  &.filter-section {
    grid-template-columns: repeat(4, 1fr);

    ${small} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${xSmall} {
      gap: 8px 0;
      grid-template-columns: repeat(1, 1fr);
    }
`;

export const SearchInput = styled(InputText)`
  grid-column: 1 / 3;

  ${small} {
    grid-column: 1;
  }

  & .wrapper {
    border-radius: 6px;
    border: 1px solid ${COLORS.neutral_gray_gull};
    background-color: ${COLORS.neutral_white};

    svg {
      position: relative;
      z-index: -10;
      color: ${COLORS.neutral_chromium};
    }

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }

  & input {
    border-radius: 6px;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: ${COLORS.neutral_white};
  }
`;

export const ActionButton = styled(BasicActionBtn)`
  padding: 8px 16px;
  white-space: nowrap;

  &.add-antrian {
    background: ${COLORS.asphalt};
    grid-column: 6 / -1;

    ${small} {
      grid-column: 3;
    }

    ${xSmall} {
      grid-column: 1;
    }
  }

  &.cancel {
    background: ${COLORS.neutral_gray_gull};
    color: black;
  }

  &.delete {
    background: ${COLORS.red_linear_gradient};
  }
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const TabBtn = styled(BasicActionBtn)`
  background: white;
  padding: 10px;
  color: ${COLORS.text_gray};
  width: 100%;
  border: 0.5px solid ${COLORS.neutral_gray_gull};
  cursor: pointer;
  height: 100%;
  font-weight: 600;

  & span {
    background: ${COLORS.green};
  }

  filter: saturate(0.9);

  &.tab-berjalan {
    background: ${COLORS.green_linear_gradient};
    color: ${COLORS.white};

    & span {
      background: ${COLORS.white};
      color: ${COLORS.asphalt} !important;
    }

    &.active {
      filter: saturate(1);
      border: 0.5px solid ${COLORS.green};

      & span {
        color: ${COLORS.green} !important;
      }
    }
  }

  &.tab-selesai {
    background: ${COLORS.white};
    color: ${COLORS.text_gray};

    & span {
      background: ${COLORS.text_gray};
      color: ${COLORS.white} !important;
    }

    &.active {
      filter: saturate(1);
      color: ${COLORS.asphalt};
      border: 0.5px solid ${COLORS.green};

      & span {
        background: ${COLORS.green} !important;
      }
    }
  }

  &:hover {
    filter: brightness(0.9);
    border: 0.5px solid ${COLORS.green};
  }

  &:active {
    filter: brightness(1.1);
  }
`;

export const CounterTag = styled.span`
  border-radius: 3px;
  padding: 2px 4px;
`;

export const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 1px;
  overflow-y: auto;
  flex-grow: 1;
`;

export const EmptyQueueText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 100%;
`;

export const DeleteModal = styled(Modal)`
  & .children-container {
    min-width: 35vw;
    text-align: center;

    ${large} {
      min-width: 50vw;
    }

    span {
      white-space: nowrap;
    }
  }

  .action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    button {
      width: 135px;
      margin: 7px 7px;

      ${small} {
        width: 100%;
      }
    }
  }
`;

export const AddEncounterModal = styled(Modal)`
  max-width: 100%;
  & .children-container {
    min-width: 60vw;
    position: relative;
  }
  ${large} {
    & .children-container {
      min-width: 70vw;
    }
  }
  ${medium} {
    & .children-container {
      min-width: 80vw;
    }
  }
  ${small} {
    & .children-container {
      min-width: 90vw;
    }
  }
`;
