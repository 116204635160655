import styled, { css } from "styled-components";
import { COLORS } from "assets/theme";

interface BaseHeaderTextProps {
  className?: string;
  styleSize?: "12" | "18" | "24" | "32";
  stylesColor?: "regular" | "black" | "danger" | "info" | "alt";
}

const styleSizes = {
  12: css`
    font-size: 12px;
  `,
  18: css`
    font-size: 18px;
  `,
  24: css`
    font-size: 24px;
  `,
  32: css`
    font-size: 32px;
  `,
};

const styleColors = {
  regular: css`
    background: ${COLORS.green_linear_gradient};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  `,
  black: css`
    color: ${COLORS.text_black};
  `,
  danger: css`
    background: ${COLORS.red_linear_gradient};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  `,
  info: css`
    color: ${COLORS.text_hint};
  `,
  alt: css`
    background: ${COLORS.grey_linear_gradient};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  `,
};

export const BaseHeaderText = styled.h1<BaseHeaderTextProps>`
  margin: 0;
  font-weight: 700;

  ${({ styleSize = "32" }) => styleSizes[styleSize]}
  ${({ stylesColor = "regular" }) => styleColors[stylesColor]}
`;
