import { baseApi } from "store/baseStoreAPI";
import { BackendDataShape } from "types";
import { OneLevelWilayah, WilayahResponse } from "types/api/wilayah";

const wilayahApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWilayah: builder.query<
      WilayahResponse,
      {
        take?: number;
        name: string;
      }
    >({
      query: ({ take = 10, name }) => ({
        url: `/wilayah`,
        method: "GET",
        params: {
          take,
          name,
        },
      }),
    }),

    getWilayaByCode: builder.query<BackendDataShape<OneLevelWilayah>, string>({
      query: (code) => ({
        url: `/wilayah/${code}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetWilayahQuery,
  useGetWilayaByCodeQuery,
  util: { resetApiState: resetWilayahStoreApi },
} = wilayahApi;
