import { LuUsers, LuArrowUp, LuArrowDown } from "react-icons/lu";

import { useGetAnalyticsPatientStatsQuery } from "store/analyticsStoreAPI";

import { COLORS } from "assets/theme";
import {
  CardContainer,
  CardIcon,
  ChangesCount,
  Container,
  HeaderContainer,
  PatientCount,
} from "Pages/Dashboard/components/PatientAmountTracker/style";

type CardProps = {
  color: string;
  className: string;
  patientCount: number;
  monthsAgo: number;
  changesValue: number;
};

const Card = ({
  color,
  className,
  patientCount,
  monthsAgo,
  changesValue,
}: CardProps) => {
  return (
    <CardContainer>
      <HeaderContainer>
        <p>
          Jumlah Pasien <br /> (<strong>{monthsAgo} Bulan</strong> Terakhir)
        </p>
        <CardIcon className={className}>
          <LuUsers color={color} size={24} />
        </CardIcon>
      </HeaderContainer>
      <PatientCount>{patientCount}</PatientCount>
      <ChangesCount>
        <p>
          <span
            className={
              changesValue > 0 ? "plus" : changesValue < 0 ? "minus" : ""
            }
          >
            {changesValue > 0 ? (
              <LuArrowUp />
            ) : changesValue < 0 ? (
              <LuArrowDown />
            ) : null}{" "}
            {Math.abs(changesValue)}%
          </span>
          {monthsAgo} Bulan Sebelumnya
        </p>
      </ChangesCount>
    </CardContainer>
  );
};

const PatientAmountTracker = () => {
  const queryStats1 = useGetAnalyticsPatientStatsQuery({ monthRange: "1" });
  const queryStats2 = useGetAnalyticsPatientStatsQuery({ monthRange: "3" });
  const queryStats3 = useGetAnalyticsPatientStatsQuery({ monthRange: "6" });

  const data = [queryStats1.data, queryStats2.data, queryStats3.data];

  return (
    <Container>
      <Card
        color={COLORS.strong_yellow}
        className="yellow"
        patientCount={
          data[0]?.total_patient ? parseInt(data[0]?.total_patient) : 0
        }
        monthsAgo={1}
        changesValue={
          data[0]?.percentage_difference
            ? parseInt(data[0]?.percentage_difference)
            : 0
        }
      />
      <Card
        color={COLORS.strong_pink}
        className="pink"
        patientCount={
          data[1]?.total_patient ? parseInt(data[1]?.total_patient) : 0
        }
        monthsAgo={3}
        changesValue={
          data[1]?.percentage_difference
            ? parseInt(data[1]?.percentage_difference)
            : 0
        }
      />
      <Card
        color={COLORS.strong_blue}
        className="blue"
        patientCount={
          data[2]?.total_patient ? parseInt(data[2]?.total_patient) : 0
        }
        monthsAgo={6}
        changesValue={
          data[2]?.percentage_difference
            ? parseInt(data[2]?.percentage_difference)
            : 0
        }
      />
    </Container>
  );
};

export default PatientAmountTracker;
