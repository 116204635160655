import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { PatientSexType } from "types/report";
import { useGetPatientReportQuery } from "store/reportStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TablePasien/TableTemplate";

const SexTypeOpt = [
  {
    value: "all",
    label: "Semua Jenis Kelamin",
  },
  {
    value: "male",
    label: "Laki-laki",
  },
  {
    value: "female",
    label: "Perempuan",
  },
];

const TablePasien = () => {
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [sex, setSex] = useState<PatientSexType>("all");

  const { data: reportData } = useGetPatientReportQuery({
    page: page,
    take: take,
    sex: sex === "all" ? undefined : sex === "male" ? "male" : "female",
    query: debouncedSearchValue || undefined,
  });

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
      </div>
      {
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Jumlah Pasien <span>{data?.meta?.itemCount ?? "-"}</span>
              </RecapItem>
            </RecapContainer>
            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              <PureInputSelect
                label=""
                placeholder="Jenis Kelamin"
                options={SexTypeOpt}
                value={SexTypeOpt.find((option) => option.value === sex)}
                onChange={(e) => {
                  if (e) {
                    const newValue = e.value as PatientSexType;
                    setSex(newValue);
                  }
                }}
              />
              {_.isEmpty(data?.entities) ? null : <DownloadReport sex={sex} />}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      }
    </>
  );
};

export default TablePasien;
