import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { BackendDataShape } from "types";
import { SatusehatConsent } from "types/api/satusehat_credential";

export const satuSehatStoreAPI = createApi({
  reducerPath: "satuSehatStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/satu-sehat`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => {
    return {
      getPatientConcentSatuSehat: builder.mutation<
        BackendDataShape<boolean>,
        { nik: string }
      >({
        query: ({ nik }) => {
          return {
            url: `consent/patient/${nik}`,
            method: "GET",
          };
        },
      }),

      getPatientSatusehatConsent: builder.query<
        BackendDataShape<SatusehatConsent>,
        { nik: string }
      >({
        query: ({ nik }) => {
          return {
            url: `consent/patient/${nik}`,
            method: "GET",
          };
        },
      }),

      changePatientSatusehatConsent: builder.mutation<
        BackendDataShape<SatusehatConsent>,
        {
          nik: string;
          is_opt_in: boolean;
        }
      >({
        query: ({ nik, is_opt_in }) => {
          return {
            url: `consent/patient/${nik}`,
            method: "PATCH",
            body: {
              is_opt_in,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetPatientConcentSatuSehatMutation,
  useGetPatientSatusehatConsentQuery,
  useChangePatientSatusehatConsentMutation,
  util: { resetApiState: resetSatuSehatStoreAPI },
} = satuSehatStoreAPI;
