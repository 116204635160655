import {
  CustomPaginationWrapper,
  StyledButtonNextAndPreviousWrapper,
  StyledPageDescription,
  StyledPaginationButton,
  StyledPaginationInput,
  StyledPaginationSelect,
} from "components/BasicTable/CustomPaginationTable/CustomPagination.style";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type Props = {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageOptions: number[];
  pageIndex: number;
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
};
export const defaultPaginationSize: number[] = [10, 20, 30, 40, 50];
const DefaultPaginationTable = (props: Props) => {
  const {
    pageSize,
    setPageSize,
    pageCount,
    pageIndex,
    pageOptions,
    gotoPage,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
  } = props;
  return (
    <CustomPaginationWrapper>
      <div>
        <span style={{ marginRight: 4 }}>Tampilkan&nbsp;</span>
        <StyledPaginationSelect
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {defaultPaginationSize.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize} Baris
            </option>
          ))}
        </StyledPaginationSelect>
        &nbsp;&nbsp;
        <StyledPageDescription>
          Halaman&nbsp;
          {pageIndex + 1} dari {pageOptions?.length}
          &nbsp;
        </StyledPageDescription>
      </div>
      <StyledButtonNextAndPreviousWrapper>
        &nbsp;
        <StyledPaginationButton
          title="Previous"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <MdChevronLeft size={16} />
        </StyledPaginationButton>
        &nbsp;
        <span>
          <StyledPaginationInput
            type="number"
            value={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
        &nbsp;
        <StyledPaginationButton
          title="Next"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <MdChevronRight size={16} />
        </StyledPaginationButton>
        &nbsp; &nbsp;
      </StyledButtonNextAndPreviousWrapper>
    </CustomPaginationWrapper>
  );
};

export default DefaultPaginationTable;
