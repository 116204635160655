import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import toast from "react-hot-toast";

import { ChangePwdAccountRequest } from "types/api/account";
import { useChangePasswordMutation } from "store/accountsStoreAPI";

import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import CustomToast from "components/CustomToast";
import { ActionWrapper, Form, FormInput } from "Pages/Settings/components";
import { LGEyeBtn } from "Pages/LoginPage/components";
import { Col } from "reactstrap";

type Props = {
  closeActiveCard: () => void;
};

type PasswordFormData = ChangePwdAccountRequest;

const SettingPasswordForm = ({ closeActiveCard }: Props) => {
  const [showPwd, setShowPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [changePwd, { isLoading, isSuccess }] = useChangePasswordMutation();

  const settingForm = useForm<PasswordFormData>({
    defaultValues: {
      password: "",
      new_password: "",
    },
  });

  const handleSubmit = (formData: PasswordFormData) => {
    if (formData.new_password !== confirmPassword) {
      setConfirmPasswordError("Password tidak sama, mohon ulangi");
      return;
    }

    changePwd(formData)
      .unwrap()
      .then(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Berhasil"
            description={`Berhasil merubah password`}
            status="success"
          />
        ));
      })
      .catch((e) => {
        let message = "Gagal mengubah password, mohon coba lagi nanti";
        if (e?.status === 401) {
          message = "Password Lama Tidak Sesuai";
        }
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description={message}
            status="error"
          />
        ));
      });
  };

  useEffect(() => {
    if (isSuccess) {
      settingForm.reset();
      closeActiveCard();
    }
  }, [settingForm, isSuccess, closeActiveCard]);

  return (
    <Form onSubmit={settingForm.handleSubmit(handleSubmit)}>
      <Col>
        <Controller
          name="password"
          control={settingForm.control}
          render={({ field }) => (
            <FormInput
              type={showPwd ? "text" : "password"}
              label="Password Lama"
              placeholder="Masukkan Password Lama"
              autoComplete="off"
              onChange={field.onChange}
              required
              elementAddonRight={
                <LGEyeBtn
                  onMouseDown={() => {
                    setShowPwd(true);
                  }}
                  onMouseUp={() => setShowPwd(false)}
                >
                  {showPwd ? (
                    <BsEye color={COLORS.text_hint} />
                  ) : (
                    <BsEyeSlash color={COLORS.text_hint} />
                  )}
                </LGEyeBtn>
              }
            />
          )}
        />

        <Controller
          name="new_password"
          control={settingForm.control}
          render={({ field }) => (
            <FormInput
              type={showPwd ? "text" : "password"}
              label="Password Baru"
              placeholder="Password Baru"
              autoComplete="off"
              onChange={field.onChange}
              pattern="^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$"
              minLength={8}
              title="Password minimal 8 karakter (mengandung huruf besar, huruf kecil dan angka)"
              required
              elementAddonRight={
                <LGEyeBtn
                  onMouseDown={() => {
                    setShowPwd(true);
                  }}
                  onMouseUp={() => setShowPwd(false)}
                >
                  {showPwd ? (
                    <BsEye color={COLORS.text_hint} />
                  ) : (
                    <BsEyeSlash color={COLORS.text_hint} />
                  )}
                </LGEyeBtn>
              }
            />
          )}
        />

        <FormInput
          type={showPwd ? "text" : "password"}
          label="Konfirmasi ulang Password Baru"
          placeholder="Konfirmasi ulang Password Baru"
          autoComplete="off"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setConfirmPasswordError("");
          }}
          required
          elementAddonRight={
            <LGEyeBtn
              onMouseDown={() => {
                setShowPwd(true);
              }}
              onMouseUp={() => setShowPwd(false)}
            >
              {showPwd ? (
                <BsEye color={COLORS.text_hint} />
              ) : (
                <BsEyeSlash color={COLORS.text_hint} />
              )}
            </LGEyeBtn>
          }
        />
        {confirmPasswordError && (
          <span style={{ color: "red" }}>{confirmPasswordError}</span>
        )}
      </Col>

      <ActionWrapper>
        <BasicActionBtn
          type="button"
          onClick={closeActiveCard}
          style={{
            background: COLORS.neutral_gray_gull,
            color: COLORS.black_1,
          }}
        >
          Batalkan
        </BasicActionBtn>
        <BasicActionBtn type="submit" disabled={isLoading}>
          Simpan
        </BasicActionBtn>
      </ActionWrapper>
    </Form>
  );
};

export default SettingPasswordForm;
