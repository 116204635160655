import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import { APatientBase, APatientMinWithId } from "types/api/pasien";
import CookieMonster from "config/CookieMonster";
import {
  BackendDataPageShape,
  BackendDataShape,
  BackendMetaPagination,
  PaginationQueryParams,
} from "types";
import { convertToEncodedURL } from "assets/usefulFunctions";

export const patientStoreAPI = createApi({
  reducerPath: "patientStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/patients`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["patients"],
  endpoints: (builder) => {
    return {
      createPatient: builder.mutation<
        BackendDataShape<{ id: number }>,
        APatientBase
      >({
        query: (obj) => {
          return {
            url: "",
            method: "POST",
            body: obj,
          };
        },
        invalidatesTags: ["patients"],
      }),

      patchPatient: builder.mutation<
        BackendDataShape<{
          id: number;
        }>,
        APatientBase & {
          id: number;
          is_queue?: boolean;
          is_bpjs?: boolean;
        }
      >({
        query: ({ id, is_queue, is_bpjs, ...obj }) => {
          return {
            url: "" + id,
            method: "PATCH",
            body: obj,
            params: {
              is_queue: is_queue,
              is_bpjs: is_bpjs,
            },
          };
        },
        invalidatesTags: (_, __, { id }) => [
          "patients",
          { type: "patients", id },
        ],
      }),

      patchPatientConcentSatuSehat: builder.mutation<
        BackendDataShape<object>,
        { id: number; is_opt_in?: boolean }
      >({
        query: ({ id, is_opt_in }) => {
          return {
            url: "" + id,
            method: "PATCH",
            body: {
              is_opt_in: is_opt_in,
            },
          };
        },
        invalidatesTags: (_, __, { id }) => [
          "patients",
          { type: "patients", id },
        ],
      }),

      getPatient: builder.query<
        {
          data: (APatientMinWithId & { no: number })[];
          meta: BackendMetaPagination;
        },
        {
          nik?: string;
          bpjs?: string;
          /**
           * rme number (misnomer)
           */
          encounter?: string;
          query?: string;
          all?: string;
        } & PaginationQueryParams
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }

          return {
            url: params,
            method: "GET",
          };
        },
        providesTags: ["patients"],
        transformResponse: (res: BackendDataPageShape<APatientMinWithId[]>) => {
          const { offset } = res.data.meta;
          return {
            data: res.data.entities.map((k, idx) => ({
              ...k,
              no: offset + idx + 1,
            })),
            meta: res.data.meta,
          };
        },
      }),

      getPatientByField: builder.query<
        APatientMinWithId,
        {
          /**
           * nik, bpjs_no, rekmed_no
           */
          field: string;
          value: string;
        }
      >({
        query: ({ field, value }) => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }

          return {
            url: `/${field}/${value}`,
            method: "GET",
          };
        },
        providesTags: (_, __, { field, value }) => [
          { type: "patients", id: `${field}-${value}` },
        ],
        transformResponse: (res: BackendDataShape<APatientMinWithId>) => {
          return res.data;
        },
      }),

      getPatientById: builder.query<APatientMinWithId, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/${id}`,
            method: "GET",
          };
        },
        providesTags: (_, __, { id }) => ["patients", { type: "patients", id }],
        transformResponse: (res: BackendDataShape<APatientMinWithId>) => {
          return res.data;
        },
      }),

      getPatientByNameAndNik: builder.query<
        {
          data: (APatientMinWithId & { no: number })[];
          meta: BackendMetaPagination;
        },
        {
          page?: number;
          take?: number;
          all?: string;
        }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }

          return {
            url: `/search${params}`,
            method: "GET",
          };
        },
        transformResponse: (res: BackendDataPageShape<APatientMinWithId[]>) => {
          const { offset } = res.data.meta;
          return {
            data: res.data.entities.map((k, idx) => ({
              ...k,
              no: offset + idx + 1,
            })),
            meta: res.data.meta,
          };
        },
        providesTags: ["patients"],
      }),
    };
  },
});

export const {
  useGetPatientQuery,
  useGetPatientByFieldQuery,
  useGetPatientByIdQuery,
  usePatchPatientMutation,
  usePatchPatientConcentSatuSehatMutation,
  useCreatePatientMutation,
  useGetPatientByNameAndNikQuery,
  util: { resetApiState: resetPatientStoreAPI },
} = patientStoreAPI;
