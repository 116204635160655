import styled from "styled-components";
import { large, small, xSmall } from "assets/theme";

type BaseLayoutHeightType = "100%" | "auto";

interface BaseLayoutProps {
  height?: BaseLayoutHeightType;
}

interface BaseHeaderLayoutProps {
  center?: boolean;
}

export const BaseLayout = styled.div<BaseLayoutProps>`
  background-color: white;
  height: ${(props) => props.height || "100%"};
  width: 90%;
  margin: auto;
  position: relative;
  top: -60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  padding: 24px 64px;
  overflow: auto;

  ${large} {
    padding: 16px 16px;
  }

  ${small} {
    padding: 16px 16px;
  }
`;

export const BaseHeaderLayout = styled.div<BaseHeaderLayoutProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.center && "justify-content: center;"}

  h1 {
    font-size: 24px;
  }

  ${small} {
    align-items: stretch;
    width: 100%;
    flex-direction: column;
    gap: 16px;

    h1 {
      text-align: left;
    }
  }

  & > :nth-child(2) {
    margin-left: auto;

    ${xSmall} {
      width: 100%;
      align-items: stretch;
    }
  }
`;
