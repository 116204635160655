import { BaseRadioGroupInput } from "components/base/Input";
import { APatientMinWithId } from "types/api/pasien";
import { PatientTypeEnum } from "types/queue";

type Props = {
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedPatient: APatientMinWithId | undefined;
};

const TipePasienChanger = ({ value, onChange, selectedPatient }: Props) => {
  const tipePasienOptions = [
    { label: "WNI", value: PatientTypeEnum.WNI },
    { label: "WNA", value: PatientTypeEnum.WNA },
    { label: "Bayi", value: PatientTypeEnum.BABY },
    { label: "Tanpa NIK", value: PatientTypeEnum.WITHOUT_NIK },
  ];

  return (
    <BaseRadioGroupInput
      id="tipe-pasien-radio"
      legend="Tipe Pasien"
      required
      options={tipePasienOptions}
      disabled={selectedPatient !== undefined}
      value={value}
      onChange={onChange}
      orientation="row"
      spacingOptions="24px"
    />
  );
};

export default TipePasienChanger;
