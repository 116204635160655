import CookieMonster from "config/CookieMonster";
import { useGetAccountByIdQuery } from "store/accountsStoreAPI";

const useSubscriptionCheck = () => {
  const id = CookieMonster.loadCookie("id");
  const { data: d, isLoading } = useGetAccountByIdQuery(id);

  const remainingDays = d?.expired_date
    ? Math.ceil(
        (new Date(d.expired_date).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24),
      )
    : 0;

  const isSubscriptionActive =
    d?.expired_date && remainingDays > 0 ? true : false;

  return { isSubscriptionActive, remainingDays, isLoading };
};

export default useSubscriptionCheck;
