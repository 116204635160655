import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import toast from "react-hot-toast";

import { useChangePasswordMutation } from "store/userAccountStoreAPI";

import { COLORS } from "assets/theme";
import CustomToast from "components/CustomToast";
import {
  FormInput,
  InfoDescription,
  InfoTitle,
  InfoTitleBar,
  LGEyeBtn,
} from "Pages/LoginPage/components";
import {
  Container,
  ActionButton,
  FormContainer,
} from "Pages/LoginPage/components/ForgotPasswordForm/style";

type RequestChangePassword = {
  new_password: string;
};

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("temporary_access_token");
  const [showPwd, setShowPwd] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [changePassword, { isLoading, isSuccess, isError }] =
    useChangePasswordMutation();

  const resetForm = useForm<RequestChangePassword>({
    defaultValues: {
      new_password: "",
    },
  });
  const { control } = resetForm;

  const handleSubmit = (formData: RequestChangePassword) => {
    if (formData.new_password !== confirmPassword) {
      setConfirmPasswordError("Password tidak sama, mohon ulangi");
      return;
    }

    if (token === null) return;

    changePassword({
      new_password: formData.new_password,
    })
      .unwrap()
      .then(() => {
        localStorage.removeItem("temporary_access_token");
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 1000);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Berhasil"
          description="Password berhasil diubah."
          status="success"
        />
      ));
    } else if (isError) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Gagal"
          description="Password gagal diubah."
          status="error"
        />
      ));
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    return () => {
      if (token && location.pathname !== "/forgot-password/change-password") {
        localStorage.removeItem("temporary_access_token");
        navigate("/login", { replace: true });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location]);

  return (
    <Container>
      <InfoTitle>
        <InfoTitleBar />
        <InfoDescription>Silahkan masukkan kata sandi baru</InfoDescription>
      </InfoTitle>

      <FormContainer onSubmit={resetForm.handleSubmit(handleSubmit)}>
        <Controller
          name="new_password"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormInput
              type={showPwd ? "text" : "password"}
              label="Password Baru"
              placeholder="Password Baru"
              autoComplete="off"
              value={value}
              onChange={onChange}
              pattern="^(?=.*([A-Z]){1,})(?=.*[0-9]{1,})(?=.*[a-z]{1,}).{8,}$"
              minLength={8}
              title="Password minimal 8 karakter (mengandung huruf besar, huruf kecil dan angka)"
              required
              elementAddonRight={
                <LGEyeBtn
                  onMouseDown={() => {
                    setShowPwd(true);
                  }}
                  onMouseUp={() => setShowPwd(false)}
                >
                  {showPwd ? (
                    <BsEye color={COLORS.text_hint} />
                  ) : (
                    <BsEyeSlash color={COLORS.text_hint} />
                  )}
                </LGEyeBtn>
              }
            />
          )}
        />

        <FormInput
          type={showPwd ? "text" : "password"}
          label="Konfirmasi ulang Password Baru"
          placeholder="Konfirmasi ulang Password Baru"
          autoComplete="off"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setConfirmPasswordError("");
          }}
          required
          elementAddonRight={
            <LGEyeBtn
              onMouseDown={() => {
                setShowPwd(true);
              }}
              onMouseUp={() => setShowPwd(false)}
            >
              {showPwd ? (
                <BsEye color={COLORS.text_hint} />
              ) : (
                <BsEyeSlash color={COLORS.text_hint} />
              )}
            </LGEyeBtn>
          }
        />
        {confirmPasswordError && (
          <span style={{ color: "red" }}>{confirmPasswordError}</span>
        )}

        <ActionButton disabled={isLoading} type="submit">
          Simpan
        </ActionButton>
      </FormContainer>
    </Container>
  );
};

export default ChangePasswordForm;
