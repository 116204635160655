import React from "react";
import { BaseRadioGroupInput } from "components/base/Input";
import { BaseInfoText } from "components/base/Text";

type Props = {
  value?: boolean;
  onChange: (event: any) => void;
  satusehatConfigured: boolean;
};

const SatusehatConsentChanger = ({
  value,
  onChange,
  satusehatConfigured,
}: Props) => {
  const consentOptions = [
    {
      label: "Setuju",
      value: "true",
    },
    {
      label: "Menolak",
      value: "false",
    },
  ];

  return (
    <>
      <BaseRadioGroupInput
        id="satu-sehat-consent-radio"
        legend="Persetujuan SatuSehat"
        required={satusehatConfigured}
        // disabled={!satusehatConfigured}
        options={consentOptions}
        value={value ? "true" : "false"}
        onChange={(e) => {
          onChange(e.target.value === "true" ? true : false);
        }}
        orientation="row"
        spacingOptions="24px"
      />
      {value ? (
        <BaseInfoText>
          Pasien <b>setuju</b> Data Rekam Medisnya dikirim ke SatuSehat
        </BaseInfoText>
      ) : (
        <BaseInfoText styleType="alt">
          Pasien <b>tidak setuju</b> Data Rekam Medisnya dikirim ke SatuSehat
        </BaseInfoText>
      )}
    </>
  );
};

export default SatusehatConsentChanger;
