import styled, { css } from "styled-components";
import { COLORS, large, small } from "../../../assets/theme";
import Modal from "../../../components/Modal/Modal";
import InputText from "../../../components/InputText/InputText";
import PureInputSelect from "../../../components/PureInputSelect/PureInputSelect";
import InputTextArea from "../../../components/InputText/InputTextArea";
import { DropdownIndicatorProps, components } from "react-select";
import { AiOutlineSearch } from "react-icons/ai";
import { BasicActionBtn, BasicHeader } from "../../../components/base";
import BasicTable from "../../../components/BasicTable/BasicTable";

export const Main = styled.main`
  width: 90%;
  min-height: 80vh;
  margin: 0 auto;
  position: relative;
  top: -60px;
  background-color: white;

  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 5px 20px rgba(85, 85, 85, 0.15);
  /* max-height: 75vh; */

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Chip = styled.span`
  background: ${COLORS.teal};
  align-self: flex-start;
  color: white;
  padding: 4px 6px;
  border-radius: 8px;
`;

export const MgmtModal = styled(Modal)`
  /* height: 80vh; */
  /* max-height: 90vh; */
`;

export const MgmtForm = styled.form`
  min-width: min(60vw, 500px);
  /* width: 60vw; */

  /* overflow-y: auto; */
  flex: 1;
  padding: 2px;
  display: flex;
  flex-direction: row;
  column-gap: 4%;
  align-content: flex-start;
  flex-wrap: wrap;
  z-index: 2;
  gap: 10px;
`;

export const RadioGroup = styled.fieldset`
  flex-basis: 100%;
  &.s {
    flex-basis: 48%;
  }
  margin: 0;
  border: 0;
  padding: 4px 0;

  display: flex;
  align-items: center;
  gap: 10px;
  accent-color: ${COLORS.green};
`;

export const RadioSpacer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const RadioLabel = styled.label``;

export const Radio = styled.input`
  margin-right: 4px;
`;

export const FormInput = styled(InputText)`
  flex-basis: 100%;
  margin-bottom: 10px;
  &.s {
    flex-basis: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  & .wrapper {
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    border-radius: 6px;
  }
  & input {
    border: none;
    ::placeholder {
      font-size: 1rem;
    }
    :focus-visible {
      outline: 2px solid ${COLORS.green};
    }
    :disabled {
      background: ${COLORS.silver_lining};
    }
  }
  &.no-margin {
    margin-bottom: 0;
  }
`;

export const SelectIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <AiOutlineSearch fontSize={12} />
    </components.DropdownIndicator>
  );
};

export const StyledSelectIndicator = styled(SelectIndicator)`
  padding: 0 8px;
`;

const inputTextCSS = css`
  & > label {
    margin-bottom: 5px;
    font-weight: normal;
    color: ${COLORS.text_black};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & input,
  textarea {
    padding: 10px;
    border: 0.5px solid ${COLORS.neutral_gray_gull};
    :disabled {
      background: ${COLORS.silver_lining};
    }
    font-size: 1em;
    /* ::placeholder {
    } */
  }
`;

const selectStyles = css`
  flex-basis: 100%;
  margin-bottom: 10px;
  & div[class$="-placeholder"] {
    font-size: 1rem;
  }
  /* & div[class$="-control"] {
    border-color: black;
  } */
  &.s {
    flex-basis: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & .required-star {
    color: red;
  }
  ${inputTextCSS}
`;

export const FormInputSelect = styled(PureInputSelect)`
  ${selectStyles}

  & [class$="-control"] {
    border-color: ${COLORS.neutral_gray_gull};
    &:focus-within {
      outline: 1px solid ${COLORS.green} !important;
      outline-offset: -2px;
    }

    &:hover {
      border-color: ${COLORS.green};
      outline-color: ${COLORS.green};
    }
  }
` as typeof PureInputSelect;

export const FormTextArea = styled(InputTextArea)`
  ${inputTextCSS}
  flex-basis: 100%;
  // margin-bottom: 10px;
  &.s {
    flex-basis: 48%;
  }
  & label {
    color: ${COLORS.text_black};
    margin-bottom: 5px;
  }
  & textarea {
    resize: vertical;
    font-family: Barlow;
    padding: 8px;
    ::placeholder {
      font-size: 1rem;
    }
    :disabled {
      border: 0.5px solid ${COLORS.neutral_gray_gull};
      background: ${COLORS.silver_lining};
    }

    &:focus-within {
      outline: 2px solid ${COLORS.green} !important;
      outline-offset: -2px;
    }
  }
`;

export const StyledMain = styled(Main)`
  box-sizing: border-box;
  padding: 24px 64px;
  top: -60px;
  height: calc(100vh - (60px + 90px));
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${large} {
    padding: 24px 50px;
  }

  ${small} {
    padding: 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  ${small} {
    flex-grow: 1;
    align-items: start;
    flex-direction: column;
    gap: 8px;
  }
`;

export const SearchInput = styled(InputText)`
  & .wrapper {
    border-radius: 6px;
    border: 1px solid ${COLORS.neutral_gray_gull};
    background-color: ${COLORS.neutral_white};

    svg {
      position: relative;
      z-index: -10;
      color: ${COLORS.neutral_chromium};
    }

    &:focus-within {
      outline: 2px solid ${COLORS.green};
      outline-offset: -2px;
    }
  }

  & input {
    border-radius: 6px;
    padding: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: ${COLORS.neutral_white};
  }
`;

export const AddBtn = styled(BasicActionBtn)`
  background: ${COLORS.asphalt};
  min-width: 170px;
  height: 100%;
  flex-grow: 1;
  padding: 8px 16px;

  &:hover {
    opacity: 1;
    background: ${COLORS.midnight};
  }
`;

export const StockBtn = styled(BasicActionBtn)`
  font-size: 12px;
  background: ${COLORS.asphalt};
  border: 0.5px solid ${COLORS.asphalt};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;
  &:hover {
    opacity: 1;
    background: ${COLORS.midnight};
  }
`;

export const menuBtncss = css`
  background: ${COLORS.mint_green};
  padding: 8px;
  border: 0.5px solid ${COLORS.teal};
`;

export const MenuBtn = styled(BasicActionBtn)`
  ${menuBtncss}
`;

export const StyledTable = styled(BasicTable)`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 0.5px solid ${COLORS.neutral_gray_gull};
  border-radius: 6px;

  & thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: ${COLORS.white};
  }

  & tbody tr {
    :nth-child(odd) {
      background-color: ${COLORS.light_blue_1};
    }
  }
` as typeof BasicTable;

export const StyledBasicHeader = styled(BasicHeader)`
  font-size: 24px;
`;

export const ActionBtn = styled(BasicActionBtn)`
  padding: 8px 10px;
  background: transparent;
  outline: none;

  &.asphalt {
    background: ${COLORS.asphalt};
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  ${small} {
    width: 100%;
    flex-direction: column;
  }
`;

export const StyledInputSelect = styled(PureInputSelect)`
  width: 100%;

  & [class*="-container"] {
    margin: 0;
    outline: none;
  }

  & [class$="-control"] {
    border-color: ${COLORS.neutral_gray_gull};
    border-width: 0.5;
    border-style: solid;
    border-radius: 6px;
    box-shadow: none;
    &:focus-within {
      outline: 2px solid ${COLORS.green} !important;
      outline-offset: -2px;
      // box-shadow: 0 0 0 2px ${COLORS.green};
    }

    &:hover {
      border-color: ${COLORS.neutral_gray_gull};
    }
  }

  & [class$="-multiValue"] {
    background: ${COLORS.teal};
    color: white;
    box-shadow: none;
  }

  &.disabled {
    & [class$="-multiValue"] > div[class$="-MultiValueRemove"] {
      display: none;
      /* background: ${COLORS.teal};
    color: white; */
    }
  }
  ${inputTextCSS}
` as typeof PureInputSelect;

export const ElipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
