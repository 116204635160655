import { FC, useMemo } from "react";
import { motion } from "framer-motion";
import toast, { Toast } from "react-hot-toast";
import { COLORS } from "assets/theme";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { easing } from "assets/transitionProps";

type toastStatus = "success" | "error";

type Props = {
  t: Toast;
  status: toastStatus;
  headerText: string;
  description: string;
};

const StyledToast = styled(motion.div)`
  background-color: ${COLORS.neutral_gray_gull};
  &.success {
    background-color: ${COLORS.green_1};
  }
  &.error {
    background-color: ${COLORS.red_1};
  }
  border-radius: 4px;
  color: white;
  min-width: 300px;
  position: relative;
  display: flex;
  gap: 2px;
  z-index: 9999;
  flex-direction: column;
  padding: 1rem 1.25rem 1rem 1rem;
`;

const CustomToast: FC<Props> = (props) => {
  const {
    t,
    status,
    headerText = "Example Header!",
    description = "Example Description!",
  } = props;

  const className = useMemo(() => {
    switch (status) {
      case "error":
        return "error";
      case "success":
        return "success";
      default:
        return "";
    }
  }, [status]);

  return (
    <StyledToast
      className={className}
      initial={{ opacity: 0, x: 80 }}
      animate={{
        opacity: t.visible ? 1 : 0,
        y: 0,
        x: 0,
        transition: { duration: 0.6, ease: easing },
      }}
      exit={{
        opacity: 0,
        y: 10,
      }}
    >
      <MdClose
        title="closeIcon"
        style={{
          position: "absolute",
          right: 16,
          cursor: "pointer",
        }}
        color={COLORS.white}
        strokeWidth={0}
        size={16}
        onClick={() => toast.dismiss(t.id)}
      />
      <span style={{ fontWeight: 600, fontSize: "1.2rem" }}>{headerText}</span>
      <span style={{ fontSize: "1rem" }}>{description}</span>
    </StyledToast>
  );
  //   return (
  //     <StyledToast status={status}>
  //       <MdClose
  //         title="closeIcon"
  //         style={{
  //           position: "absolute",
  //           right: 16,
  //           cursor: "pointer",
  //         }}
  //         color={COLORS.white}
  //         strokeWidth={0}
  //         size={16}
  //         onClick={() => toast.dismiss(t.id)}
  //       />
  //       <span style={{ fontWeight: 600, fontSize: "12px" }}>{headerText}</span>
  //       <span style={{ fontSize: "10px" }}>{description}</span>
  //     </StyledToast>
  //   );
};

export default CustomToast;
