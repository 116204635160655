import styled, { css } from "styled-components";
import { COLORS } from "assets/theme";

type StyleType = "regular" | "danger" | "alt" | "dark" | "info";
type StyleShape = "regular" | "pill" | "sharp";
type StyleSize = "regular" | "regularSlim" | "compact" | "xs" | "sm" | "md";

interface BaseButtonProps {
  className?: string;
  styleType?: StyleType;
  styleShape?: StyleShape;
  styleSize?: StyleSize;
}

const styleTypes = {
  regular: css`
    background: ${COLORS.green_linear_gradient};
  `,
  danger: css`
    background: ${COLORS.red_linear_gradient};
  `,
  alt: css`
    background: ${COLORS.blue_linear_gradient};
  `,
  dark: css`
    background: ${COLORS.asphalt};
  `,
  info: css`
    background: ${COLORS.silver_lining};
    color: ${COLORS.text_black};
  `,
};

const styleShapes = {
  regular: css`
    border-radius: 6px;
  `,
  pill: css`
    border-radius: 24px;
  `,
  sharp: css`
    border-radius: 0;
  `,
};

const styleSizes = {
  compact: css`
    padding: 2px 6px;
  `,
  xs: css`
    padding: 6px 10px;
  `,
  sm: css`
    padding: 8px 20px;
  `,
  md: css`
    padding: 10px 30px;
  `,
  regularSlim: css`
    padding: 8px 36px;
  `,
  regular: css`
    padding: 10px 38px;
  `,
};

export const BaseButton = styled.button<BaseButtonProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 38px;
  border: 0;
  border-radius: 6px;
  color: ${COLORS.white};
  background: ${COLORS.green_linear_gradient};
  transition: 0.2s;
  white-space: nowrap;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(1.1);
  }

  &:disabled {
    pointer-events: none;
    background: ${COLORS.text_gray};
    color: ${COLORS.white};
  }

  &.h-full {
    height: 100%;
  }

  &.w-full {
    width: 100%;
  }

  &.color-flat {
    background: ${COLORS.green_5};

    ${({ styleType }) =>
      styleType === "danger" &&
      css`
        background: ${COLORS.red};
      `}

    ${({ styleType }) =>
      styleType === "info" &&
      css`
        background: ${COLORS.silver_lining};
      `}
  }

  &.outline {
    box-sizing: border-box;
    background-color: ${COLORS.white};
    background: transparent;
    color: ${COLORS.green_5};
    box-shadow: inset 0 0 0 2px ${COLORS.green_5};

    ${({ styleType }) =>
      styleType === "danger" &&
      css`
        color: ${COLORS.red};
        box-shadow: inset 0 0 0 2px ${COLORS.red};
      `}

    ${({ styleType }) =>
      styleType === "info" &&
      css`
        color: ${COLORS.text_black};
        box-shadow: inset 0 0 0 2px ${COLORS.silver_lining};
      `}

  &:disabled {
      pointer-events: none;
      background: ${COLORS.text_gray};
      color: white;
      box-shadow: none;
    }

    &:hover {
      background: ${COLORS.green_5};
      color: ${COLORS.white};

      ${({ styleType }) =>
        styleType === "danger" &&
        css`
          background: ${COLORS.red};
          color: ${COLORS.white};
        `}

      ${({ styleType }) =>
        styleType === "info" &&
        css`
          background: ${COLORS.silver_lining};
          color: ${COLORS.text_black};
        `}
    }

    &.active {
      background: ${COLORS.green_5};
      color: ${COLORS.white};

      ${({ styleType }) =>
        styleType === "danger" &&
        css`
          background: ${COLORS.red};
          color: ${COLORS.white};
        `}

      ${({ styleType }) =>
        styleType === "info" &&
        css`
          background: ${COLORS.silver_lining};
          color: ${COLORS.text_black};
        `}
    }
  }

  ${({ styleType = "regular" }) => styleTypes[styleType]}
  ${({ styleShape = "regular" }) => styleShapes[styleShape]}
  ${({ styleSize = "regular" }) => styleSizes[styleSize]}
`;

export const BaseActionButton = styled.button`
  box-sizing: border-box;
  border: none;
  padding: 8px 10px;
  background: transparent;
  outline: none;

  &.asphalt {
    background: ${COLORS.asphalt};
  }

  &:hover {
    opacity: 0.8;
  }
`;
