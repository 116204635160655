import { PemeriksaanKehamilanEnum } from "types/queue";

import { BaseSelectInput } from "components/base/Input";

type Props = {
  value?: string;
  onChange: (selectedOption: any) => void;
};

const KunjunganKehamilanSelect = ({ value, onChange }: Props) => {
  const tipeKunjunganOptions = [
    { label: "Kunjungan K1 Akses", value: PemeriksaanKehamilanEnum.K1_AKSES },
    { label: "Kunjungan K1 Murni", value: PemeriksaanKehamilanEnum.K1_MURNI },
    { label: "Kunjungan Lanjutan", value: PemeriksaanKehamilanEnum.LANJUTAN },
  ];

  return (
    <BaseSelectInput<any>
      required
      label="Kunjungan"
      placeholder="Kunjungan Kehamilan"
      options={tipeKunjunganOptions}
      onChange={(selectedOption) => {
        onChange(selectedOption.value);
      }}
      value={tipeKunjunganOptions.find((option) => option.value === value)}
    />
  );
};

export default KunjunganKehamilanSelect;
