import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import toast from "react-hot-toast";

import CookieMonster from "config/CookieMonster";
import { combinedRoutes } from "config/nav";
import { LoginObj } from "types/auth";
import { useLogin } from "hooks/auth";
import CustomToast from "components/CustomToast";
import { isDev } from "assets/usefulFunctions";
import { COLORS } from "assets/theme";

import {
  FormInput,
  LGActionText,
  LGCheckBoxInput,
  LGDaftarText,
  LGEyeBtn,
  LGForgetText,
  LGGroup,
  LGInputTextLabel,
  LGLoginForm,
  LGSubmitButton,
} from "Pages/LoginPage/components";

type LoginFormData = LoginObj;

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const login = useLogin();

  const from = location.state?.from?.pathname ?? combinedRoutes.antrian_pasien;

  const loginForm = useForm<LoginFormData>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleSubmit = async (formData: LoginFormData) => {
    setIsLoading(true);
    login(formData)
      .then((res) => {
        const username = res?.data?.data?.username ?? "pengguna";
        const roles = CookieMonster.loadCookie("roles");
        if (isDev()) console.log(roles);
        navigate(from);
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText="Selamat datang!"
              description={`Selamat datang, ${username}!`}
              status="success"
            />
          ),
          {
            id: "welcome",
            position: "bottom-right",
          },
        );
      })
      .catch((e) => {
        let message = "Unknown Error";
        if (e instanceof Error) {
          message = e.message;
        }
        toast.custom(
          (t) => (
            <CustomToast
              t={t}
              headerText="Gagal Login!"
              description={message}
              status="error"
            />
          ),
          {
            position: "bottom-right",
          },
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      loginForm.reset();
    };
  }, [loginForm]);

  return (
    <LGLoginForm onSubmit={loginForm.handleSubmit(handleSubmit)}>
      <Controller
        name="username"
        control={loginForm.control}
        render={({ field }) => (
          <FormInput
            type="text"
            label="Username"
            placeholder="Username"
            autoComplete="username"
            maxLength={20}
            value={field.value}
            onChange={field.onChange}
            disabled={isLoading}
            required
          />
        )}
      />
      <Controller
        name="password"
        control={loginForm.control}
        render={({ field }) => (
          <FormInput
            type={showPwd ? "text" : "password"}
            label="Password"
            placeholder="Password"
            autoComplete="current-password"
            value={field.value}
            onChange={field.onChange}
            disabled={isLoading}
            required
            elementAddonRight={
              <LGEyeBtn
                onMouseDown={() => {
                  setShowPwd(true);
                }}
                onMouseUp={() => setShowPwd(false)}
              >
                {showPwd ? (
                  <BsEye color={COLORS.text_hint} />
                ) : (
                  <BsEyeSlash color={COLORS.text_hint} />
                )}
              </LGEyeBtn>
            }
          />
        )}
      />
      <div
        style={{
          display: "flex",
          flexBasis: "100%",
          justifyContent: "space-between",
          marginBottom: "14px",
        }}
      >
        <LGGroup style={{ flexDirection: "row-reverse", alignItems: "center" }}>
          <LGInputTextLabel style={{ margin: 0 }} htmlFor="ingat">
            Ingat Saya
          </LGInputTextLabel>
          <LGCheckBoxInput
            disabled={isLoading}
            type="checkbox"
            id="ingat"
            name="ingat"
          />
        </LGGroup>
        <span>
          <LGForgetText
            className={isLoading ? "disabled" : ""}
            to={combinedRoutes.forgot_password_page_reset}
          >
            Lupa Password?
          </LGForgetText>
        </span>
      </div>
      <LGSubmitButton disabled={isLoading} type="submit">
        {isLoading ? "Mohon Menunggu..." : "Masuk"}
      </LGSubmitButton>
      <LGGroup style={{ flexBasis: "100%" }}>
        <LGActionText>
          Belum Memiliki akun?{" "}
          <LGDaftarText
            className={isLoading ? "disabled" : ""}
            to={combinedRoutes.registration_page}
          >
            Daftar
          </LGDaftarText>
        </LGActionText>
      </LGGroup>
    </LGLoginForm>
  );
};

export default LoginForm;
