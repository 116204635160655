import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const rippleAnimation = keyframes`
  to {
    transform: scale(3);
    opacity: 0;
  }
`;

const RippleContainer = styled.div<{ borderRadius?: string }>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: fit-content;
  border-radius: ${(props) => props.borderRadius};
`;

interface RippleEffectProps extends React.HTMLProps<HTMLDivElement> {
  color?: string;
}

const RippleEffect = styled.span<{
  top: number;
  left: number;
  diameter: number;
  color: string;
}>`
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  pointer-events: none;
  animation: ${rippleAnimation} 0.6s linear;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  width: ${(props) => `${props.diameter}px`};
  height: ${(props) => `${props.diameter}px`};
  background: ${(props) => props.color};
  z-index: 1; // Set a higher z-index value to bring the ripple effect to the front
  overflow: hidden;
`;

const RippleWrapper: React.FC<RippleEffectProps> = ({ color, children }) => {
  const [ripples, setRipples] = useState<JSX.Element[]>([]);
  const [borderRadius, setBorderRadius] = useState<string>("");

  React.useEffect(() => {
    const childElement = document.querySelector(".ripple-wrapper-child");

    if (childElement) {
      const computedStyles = window.getComputedStyle(childElement);
      const computedBorderRadius =
        computedStyles.getPropertyValue("border-radius");
      setBorderRadius(computedBorderRadius);
    }
  }, []);
  const handleRipple = (event: React.MouseEvent<HTMLDivElement>) => {
    const container = event.currentTarget;
    if (container) {
      const rect = container.getBoundingClientRect();
      const diameter = Math.max(rect.width, rect.height);
      const posX = event.clientX - rect.left - diameter / 2;
      const posY = event.clientY - rect.top - diameter / 2;
      const newRipple = (
        <RippleEffect
          key={Date.now()}
          top={posY}
          left={posX}
          diameter={diameter}
          color={color || "rgba(255,255,255,0.2)"}
        />
      );

      setRipples((prevRipples) => [...prevRipples, newRipple]);

      setTimeout(() => {
        setRipples((prevRipples) =>
          prevRipples.filter((ripple) => ripple !== newRipple),
        );
      }, 600);
    }
  };

  return (
    <RippleContainer borderRadius={borderRadius} onClick={handleRipple}>
      {ripples}
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement, {
          className: "ripple-wrapper-child",
        }),
      )}
    </RippleContainer>
  );
};

export default RippleWrapper;
