import { COLORS } from "assets/theme";
import { BaseButton } from "components/base/Button";
import styled from "styled-components";

export const TabButton = styled(BaseButton)`
  min-width: 80px;
  height: 38px;
  padding: 0 16px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.neutral_gray_gull};
  color: ${COLORS.text_gray};

  &.active {
    background: ${COLORS.green_linear_gradient};
    color: ${COLORS.white};
  }
`;
