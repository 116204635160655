/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { BiInfoCircle } from "react-icons/bi";
import { Column } from "react-table";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import toast from "react-hot-toast";

import { OrderDetail, OrderDetailRequest } from "types/api/payment";
import { UserAccount } from "types/api/user";
import { VoucherDetail } from "types/api/voucher";
import { usePostPaymentTokenMutation } from "store/paymentStoreAPI";
import { useGetProductsQuery } from "store/productStoreAPI";
import { usePractitionerList } from "hooks/practitioner/usePractitionerList";
import useFormatRupiah from "hooks/utils/useFormatRupiah";
import useToggle from "hooks/useToggle";
import useCustomPaginationController from "hooks/table/useCustomPaginationController";

import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import Modal from "components/Modal/Modal";
import CustomToast from "components/CustomToast";
import {
  Card,
  CardContent,
  HeaderContainer,
  Main,
  SubsDescription,
  Header,
  SubsChildContainer,
  SubsPricing,
  StyledTable,
  FormInput,
  Layout,
  ModalContainer,
  ActionBtn,
  GreenText,
  RedText,
  ElipsisText,
} from "Pages/Langganan/components";
import InfoModal from "Pages/Langganan/components/InfoModal/InfoModal";
import CouponModal from "Pages/Langganan/components/CouponModal/CouponModal";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

declare global {
  interface Window {
    snap: {
      pay: (token: string, options?: any) => void;
    };
  }
}

const Langganan = () => {
  const [price, setPrice] = useState(300000);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [activeCoupon, setActiveCoupon] = useState<VoucherDetail>(
    {} as VoucherDetail,
  );
  const { value: showCouponModal, toggle: toggleCouponModal } =
    useToggle(false);

  const { formatRupiah } = useFormatRupiah();
  const { page, take, setLimit, setPage } = useCustomPaginationController();
  const {
    list: practitionerList,
    isLoading: isPractitionerListLoading,
    refetch: refetchPractitionerList,
  } = usePractitionerList();
  const { data: productData } = useGetProductsQuery();

  const [postPayment, { isLoading: isPostPaymentLoading }] =
    usePostPaymentTokenMutation();

  const paymentForm = useForm<OrderDetail>({
    defaultValues: {
      id: "1",
      voucher_code: "",
      item_details: [],
    },
  });

  const rawPractitionerList: unknown[] = useMemo(() => {
    const arr = practitionerList ?? [];
    return arr?.slice(take * (page - 1), take * page);
  }, [take, page, practitionerList]);

  const doctorList = (rawPractitionerList as UserAccount[]) ?? [];
  const watchedItems = paymentForm.watch("item_details");

  const columns: Column<UserAccount>[] = useMemo(() => {
    return [
      {
        accessor: "username",
        Header: "Nama Akun",
        Cell: ({ row: { original: data } }) => {
          const { value: showInfoModal, toggle: toggleInfoModal } =
            useToggle(false);

          return (
            <>
              <ModalContainer
                headerText="Lihat Data Akun"
                isShown={showInfoModal}
                toggle={toggleInfoModal}
              >
                <InfoModal data={data} />
              </ModalContainer>

              <Layout className="table-username-container">
                <ElipsisText title={data.username}>{data.username}</ElipsisText>
                <span
                  className="info-icon"
                  onClick={() => toggleInfoModal(true)}
                >
                  <BiInfoCircle color={COLORS.baltic} size={16} />
                </span>
              </Layout>
            </>
          );
        },
      },
      {
        accessor: "email",
        Header: "Email",
        Cell: ({ value }) => {
          return <ElipsisText title={value}>{value}</ElipsisText>;
        },
      },
      {
        accessor: "expired_date",
        Header: "Masa Akfif",
        maxWidth: 60,
        Cell: ({ value }) => {
          return (
            <>
              {value
                ? format(new Date(value), "d MMM yyyy", {
                    locale: id,
                  })
                : "inactive"}
            </>
          );
        },
      },
      {
        accessor: "id",
        Header: "Perbarui",
        Cell: ({
          row: {
            original: { id, username },
          },
        }: {
          row: { original: any };
        }) => {
          const [monthCount, setMonthCount] = useState(0);
          const [isChecked, setIsChecked] = useState(false);

          useEffect(() => {
            if (isChecked && monthCount > 0) {
              const existingItems = paymentForm.getValues("item_details");
              const existingItemIndex = existingItems.findIndex(
                (item) => item.user_id === id,
              );

              if (existingItemIndex !== -1) {
                existingItems[existingItemIndex].quantity = monthCount;
              } else {
                existingItems.push({
                  user_id: id,
                  quantity: monthCount,
                  username: username,
                });
              }

              paymentForm.setValue("item_details", existingItems);
            } else if (!isChecked || monthCount < 1) {
              paymentForm.setValue(
                "item_details",
                paymentForm
                  .getValues("item_details")
                  .filter((item) => item.user_id !== id),
              );
            }
          }, [monthCount, isChecked, id, username]);

          return (
            <Layout className="table-action-container">
              <div>
                <input
                  id={`${id}-${username}`}
                  type="checkbox"
                  disabled={monthCount < 1}
                  style={{
                    accentColor: COLORS.green,
                  }}
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      setIsChecked(true);
                    } else if (e.target.checked === false) {
                      setIsChecked(false);
                      paymentForm.setValue(
                        "item_details",
                        paymentForm
                          .getValues("item_details")
                          .filter((item) => item.user_id !== id),
                      );
                    }
                  }}
                />
              </div>
              <FormInput
                className="compact"
                type="number"
                placeholder="0"
                min="0"
                max="99"
                step={1}
                elementAddonRight={<span>Bulan</span>}
                onChange={(e) => {
                  if (e.target.value === "" || e.target.value === "0") {
                    paymentForm.setValue(
                      "item_details",
                      paymentForm
                        .getValues("item_details")
                        .filter((item) => item.user_id !== id),
                    );
                  }
                  setMonthCount(Number(e.target.value));
                }}
              />
            </Layout>
          );
        },
      },
    ];
  }, []);

  const resetOrderState = () => {
    paymentForm.reset();
    setTotalPrice(0);
    setDiscountAmount(0);
    setActiveCoupon({} as VoucherDetail);
    refetchPractitionerList();
  };

  const handleSubmit = async (formData: OrderDetail) => {
    const requestData = formData as OrderDetailRequest;

    try {
      const res = await postPayment(requestData).unwrap();
      const newToken = res?.token;
      const orderId = res?.order_id;
      const isTransactionFree = !newToken && orderId && totalPrice === 0;

      /**
       * Checks if the transaction is free. If true, shows toast notification,
       * resets the order state, and returns early from the function (skipping Midtrans Snap).
       */
      if (isTransactionFree) {
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="success"
            headerText="Subscription Berhasil"
            description="Kupon menghemat semua tagihan."
          />
        ));

        resetOrderState();
        return;
      }

      /**
       * Checks if newToken is available. If true, initiate Midtrans Snap.
       */
      if (newToken) {
        window.snap.pay(newToken, {
          onSuccess: function (result: any) {
            resetOrderState();
            console.log("Success");
            console.log(result);

            toast.custom((t) => (
              <CustomToast
                t={t}
                status="success"
                headerText="Subscription Berhasil"
                description={message}
              />
            ));

            let message = `Transaksi ${result?.status}`;
            toast.custom((t) => (
              <CustomToast
                t={t}
                status="success"
                headerText="Pembayaran Berhasil"
                description={message}
              />
            ));

            // setSearchParams({
            //   merchant_id: String(result.merchant_id),
            //   order_id: String(result.order_id),
            //   status_code: "200",
            //   transaction_status: "success",
            // });
          },
          onPending: function (result: any) {
            resetOrderState();
            console.log("Pending");
            console.log(result);

            // setSearchParams({
            //   merchant_id: String(result.merchant_id),
            //   order_id: String(result.order_id),
            //   status_code: "201",
            //   transaction_status: "pending",
            // });
          },
          onError: function (result: any) {
            resetOrderState();
            console.log("Error");
            console.log(result);

            let message = `Transaksi ${result?.status}`;
            toast.custom((t) => (
              <CustomToast
                t={t}
                status="error"
                headerText="Pembayaran Gagal"
                description={message}
              />
            ));

            // setSearchParams({
            //   merchant_id: String(result.merchant_id),
            //   order_id: String(result.order_id),
            //   status_code: "202",
            //   transaction_status: "deny",
            // });
          },
          onClose: function () {
            resetOrderState();
            console.log(
              "Customer closed the popup without finishing the payment",
            );
          },
        });
      } else {
        console.error("Failed to fetch transaction token");
        let message = "Gagal menerima token pembayaran";
        toast.custom((t) => (
          <CustomToast
            t={t}
            status="error"
            headerText="Gagal"
            description={message}
          />
        ));
      }
    } catch (err) {
      console.error("Error processing payment:", err);
      let message = "Terjadi kesalahan Midtrans";
      toast.custom((t) => (
        <CustomToast
          t={t}
          status="error"
          headerText="Gagal"
          description={message}
        />
      ));
    }
  };

  const getTotalPrice = useCallback(() => {
    return paymentForm
      .getValues("item_details")
      .reduce((total, { quantity }) => total + quantity * price, 0);
  }, [paymentForm, price]);

  const getDiscountAmount = useCallback(() => {
    const baseTotalPrice = getTotalPrice();
    if (!activeCoupon) {
      return 0;
    }

    switch (activeCoupon.discount_type) {
      case "AMOUNT":
        return activeCoupon.discount >= baseTotalPrice
          ? baseTotalPrice
          : activeCoupon.discount;
      case "PERCENTAGE":
        const percentageDiscount =
          (baseTotalPrice * activeCoupon.discount) / 100;
        return percentageDiscount >= baseTotalPrice
          ? baseTotalPrice
          : percentageDiscount;
      case "MONTHLY":
        const monthlyDiscount = activeCoupon.discount * price;
        return monthlyDiscount >= baseTotalPrice
          ? baseTotalPrice
          : monthlyDiscount;
      default:
        return 0;
    }
  }, [activeCoupon, getTotalPrice, price]);

  const getPriceAfterDiscount = useCallback(() => {
    const baseTotalPrice = getTotalPrice();
    if (!activeCoupon.voucher_code) {
      return baseTotalPrice;
    }

    switch (activeCoupon.discount_type) {
      case "AMOUNT":
        return activeCoupon.discount >= baseTotalPrice
          ? 0
          : baseTotalPrice - activeCoupon.discount;
      case "PERCENTAGE":
        const percentageDiscount =
          (baseTotalPrice * activeCoupon.discount) / 100;
        return percentageDiscount >= baseTotalPrice
          ? 0
          : baseTotalPrice - percentageDiscount;
      case "MONTHLY":
        const monthlyDiscount = activeCoupon.discount * price;
        return monthlyDiscount >= baseTotalPrice
          ? 0
          : baseTotalPrice - monthlyDiscount;
      default:
        return baseTotalPrice;
    }
  }, [activeCoupon, getTotalPrice, price]);

  useEffect(() => {
    if (productData && productData?.length > 0) {
      paymentForm.setValue("id", productData[0].id);
      setPrice(productData[0].price);
    }
  }, [productData, paymentForm]);

  useEffect(() => {
    if (activeCoupon?.voucher_code) {
      paymentForm.setValue("voucher_code", activeCoupon?.voucher_code);
      const discount = getDiscountAmount();
      setDiscountAmount(discount);
      const finalPrice = getPriceAfterDiscount();
      setTotalPrice(finalPrice);
    } else {
      const finalPrice = getTotalPrice();
      setTotalPrice(finalPrice);
    }
  }, [
    watchedItems,
    activeCoupon,
    getTotalPrice,
    getDiscountAmount,
    getPriceAfterDiscount,
  ]);

  useEffect(() => {
    const snapSrcUrl =
      process.env.REACT_APP_MIDTRANS_SNAP_URL ??
      "https://app.sandbox.midtrans.com/snap/snap.js";
    const myMidtransClientKey =
      process.env.REACT_APP_MIDTRANS_CLIENT_KEY ??
      "SB-Mid-client-CcmP4mhw6JQ8MKlA";

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Modal
        headerText="Penerapan Kupon"
        toggle={toggleCouponModal}
        isShown={showCouponModal}
      >
        <CouponModal
          toggle={toggleCouponModal}
          activeCoupon={activeCoupon}
          setActiveCoupon={setActiveCoupon}
        />
      </Modal>

      <Main>
        <Card>
          <CardContent>
            <HeaderContainer>
              <Header>Manajemen Langganan</Header>
            </HeaderContainer>

            <SubsDescription>
              Optimalkan pengelolaan akun dokter Anda dengan layanan manajemen
              langganan. Kelola akun dokter mana pun dengan mudah, perpanjang
              masa aktif dan lakukan pembayaran dengan cepat dan aman.
            </SubsDescription>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <HeaderContainer>
              <Header>Perpanjang Langganan</Header>
            </HeaderContainer>

            <StyledTable
              columns={columns}
              data={doctorList}
              isHideNumbering
              isLoading={isPractitionerListLoading}
              noDataText="Belum ada langganan"
              showPagination={false}
            />

            <CustomPaginationTable
              hasilText={`Memperlihatkan ${doctorList?.length} dari ${
                practitionerList?.length ?? 0
              } Akun`}
              setLimit={setLimit}
              page={page}
              setPage={setPage}
              take={take}
              meta={{
                itemCount: practitionerList?.length ?? 0,
                page,
                offset: 0,
                pageCount: Math.ceil((practitionerList?.length ?? 0) / take),
              }}
            />
          </CardContent>
        </Card>

        <Card>
          <CardContent className="gap-20">
            <HeaderContainer>
              <Header>Biaya Langganan</Header>
            </HeaderContainer>

            <SubsChildContainer className="space-between direction-column">
              <SubsPricing className="total-pricing">
                <SubsDescription className="bold color-green">
                  Total
                </SubsDescription>
                <SubsDescription className="bold color-green">
                  {totalPrice ? formatRupiah(totalPrice) : "Rp 0"}
                </SubsDescription>
              </SubsPricing>

              {watchedItems?.length > 0 ? (
                <SubsPricing>
                  <SubsDescription className="bold">
                    Langganan Rekmed
                  </SubsDescription>
                  <SubsDescription className="bold">
                    {totalPrice ? formatRupiah(totalPrice) : "Rp 0"}
                  </SubsDescription>
                </SubsPricing>
              ) : null}
              {paymentForm.getValues("item_details")?.map((item, index) => {
                return (
                  <SubsPricing key={index}>
                    <SubsDescription>
                      {item.username}{" "}
                      <GreenText>x {item.quantity} Bulan</GreenText>
                    </SubsDescription>
                    <SubsDescription>
                      {formatRupiah(item.quantity * 300000)}
                    </SubsDescription>
                  </SubsPricing>
                );
              })}

              {activeCoupon?.voucher_code && watchedItems?.length > 0 && (
                <>
                  <SubsPricing>
                    <SubsDescription className="bold">Kupon</SubsDescription>
                    <SubsDescription className="bold">
                      {activeCoupon?.voucher_code}
                      <ActionBtn
                        className="link"
                        style={{ paddingLeft: "4px" }}
                        type="button"
                        onClick={() => {
                          toggleCouponModal(true);
                        }}
                      >
                        Ubah
                      </ActionBtn>
                    </SubsDescription>
                  </SubsPricing>

                  <SubsPricing>
                    <SubsDescription>
                      <RedText>Diskon</RedText>
                    </SubsDescription>
                    <SubsDescription>
                      <RedText>- {formatRupiah(discountAmount)}</RedText>
                    </SubsDescription>
                  </SubsPricing>
                </>
              )}
            </SubsChildContainer>

            {!activeCoupon?.voucher_code && watchedItems?.length > 0 && (
              <ActionBtn
                className="ghost"
                type="button"
                onClick={() => {
                  toggleCouponModal(true);
                }}
              >
                Tambahkan Kupon
              </ActionBtn>
            )}

            <BasicActionBtn
              onClick={paymentForm.handleSubmit(handleSubmit)}
              disabled={!watchedItems?.length || isPostPaymentLoading}
            >
              {isPostPaymentLoading
                ? "Processing..."
                : !watchedItems?.length
                ? "Lengkapi data langganan"
                : "Proses Langganan"}
            </BasicActionBtn>
          </CardContent>
        </Card>
      </Main>
    </>
  );
};

export default Langganan;
