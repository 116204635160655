import { DataMeta } from "types/api";
import { BasicSelectOpt, Nullable, RequireSome } from "types";
import { APolyclinicWithId } from "types/api/clinic";
import { APatientMinWithId } from "types/api/pasien";
import { AnAccountWithId, AnAccountWithProfile } from "types/api/practitioner";
import { RecipeStatusEnum } from "types/api/transactions";

export interface AnEncounterBase {
  // "id": 4,
  polyclinic_id: number;
  doctor_id: number;
  patient_id: number;

  queue_time: unknown;
  /**
   * supposedly nomor antrian
   */
  queue: number;
  schedule_time: unknown;
  actual_time: unknown;
  status: QueueStatusEnum;
  is_bpjs: boolean;

  // "created_date": null,
  // "modified_date": null,
  // "created_by": null,
  // "modified_by": null,
}

export type AnEncounterMin = RequireSome<
  AnEncounterBase,
  "polyclinic_id" | "doctor_id" | "patient_id"
>;

export type AnEncounterWithId = AnEncounterBase & { id: number } & DataMeta & {
    patient: APatientMinWithId;
    practitioner: AnAccountWithProfile;

    polyclinic: APolyclinicWithId;

    initialInspection: Nullable<{ id: number }>;
    medicalRecord: Nullable<{
      id: number;
      medrecTrx: {
        id: number;
        recipe_status: RecipeStatusEnum | null;
      };
    }>;
    pemeriksaan_type: "KEHAMILAN" | "UMUM" | string;
    antenatal: Nullable<{ id: number }>;
  };

export type EncounterOpt = AnEncounterBase &
  BasicSelectOpt<number> & {
    pemeriksaan_type: "KEHAMILAN" | "UMUM" | string;
  };

export enum QueueStatusEnum {
  QUEUEING = "QUEUEING",
  INSPECT = "INSPECT",
  FINISH = "FINISH",
}
