import { useState, useEffect } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useGetAnalyticsPatientTrendQuery } from "store/analyticsStoreAPI";

import { COLORS } from "assets/theme";
import { StatsHeader, StatsTitle } from "Pages/Dashboard/components";
import MonthBtnGroup from "Pages/Dashboard/components/MonthBtnGroup/MonthBtnGroup";
import {
  Container,
  StatsFilter,
} from "Pages/Dashboard/components/PatientAmountChart/style";

const PatientAmountChart = () => {
  const [filterMonth, setFilterMonth] = useState(1);
  const [chartHeight, setChartHeight] = useState(
    window.innerWidth >= 1024 && window.innerWidth <= 1366 ? "100%" : 150,
  );

  const { data: patientTrendData, isLoading: isLoadingPatientTrend } =
    useGetAnalyticsPatientTrendQuery({
      monthRange: filterMonth.toString(),
    });

  const processedData = patientTrendData?.map((item) => ({
    ...item,
    total_patient: Number(item.total_patient),
  }));

  const handleButtonClick = (value: number) => {
    setFilterMonth(value);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && window.innerWidth <= 1366) {
        setChartHeight("100%");
      } else {
        setChartHeight(150);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Container>
        <StatsHeader>
          <StatsTitle>Tren Jumlah Pasien</StatsTitle>
          <StatsFilter>
            <MonthBtnGroup onButtonClick={handleButtonClick} />
          </StatsFilter>
        </StatsHeader>
        {patientTrendData &&
          patientTrendData?.length > 0 &&
          !isLoadingPatientTrend && (
            <ResponsiveContainer width="100%" height={chartHeight}>
              <AreaChart width={300} height={100} data={processedData}>
                <defs>
                  <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="0%"
                      stopColor={COLORS.gradient_pink_chart_1}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={COLORS.gradient_pink_chart_1}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  strokeDasharray="0"
                  strokeWidth={0.5}
                  horizontal={true}
                  vertical={false}
                />
                <XAxis
                  dataKey="month"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  padding={{ left: 20, right: 20 }}
                />
                <YAxis
                  dataKey="total_patient"
                  width={50}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12 }}
                  label={{
                    value: "Dalam satuan Ratus",
                    fontSize: 12,
                    angle: -90,
                    position: "insideLeft",
                    dy: 50,
                    dx: 0,
                  }}
                />
                <Tooltip />
                <Area
                  dataKey="total_patient"
                  type="linear"
                  dot={{
                    stroke: "white",
                    strokeWidth: 2,
                    fill: `${COLORS.gradient_pink_chart_1}`,
                    r: 6,
                  }}
                  stroke={COLORS.gradient_pink_chart_1}
                  fill="url(#colorUv3)"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}

        {!patientTrendData && <div>No data</div>}
      </Container>
    </>
  );
};

export default PatientAmountChart;
