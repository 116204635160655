import { useCallback, useState } from "react";
import { AiOutlineIdcard } from "react-icons/ai";
import { PiSpeakerHighBold } from "react-icons/pi";
import { LiaFileMedicalAltSolid } from "react-icons/lia";
import { BsThreeDotsVertical } from "react-icons/bs";

import { CustomDropdownItemProps } from "types/components";
import { AnEncounterWithId } from "types/api/encounter";
import { useGetProfileByIdQuery } from "store/userProfileStoreAPI";

import { COLORS } from "assets/theme";
import { logo_bpjs } from "assets/logos";
import { bpjsWordmark } from "assets/icons";
import { getPractitionerName } from "assets/usefulFunctions/practitioner";
import { BasicActionBtn, BasicText } from "components/base";
import {
  ActionButton,
  ActionContainer,
  ActionDropdown,
  Chip,
  ChipContainer,
  Container,
  InfoTextContainer,
  Main,
} from "Pages/Queue/components/QueueItem/style";

type Props = {
  onClick?: (encounterId: number) => void;
  actions?: CustomDropdownItemProps[];
  className?: string;
  id: number;
  queue: AnEncounterWithId;
  showCallBtn?: boolean;
  actionTitle?: string;
  actionText?: string;
};

const QueueItem = ({
  id: enId,
  className,
  actions,
  actionText = "Proses",
  onClick,
  queue: queueData,
  showCallBtn = true,
  actionTitle = `Periksa ${queueData.patient.full_name}`,
}: Props) => {
  const patient = queueData.patient;
  const practitioner = queueData.practitioner;
  const { polyclinic_name } = queueData.polyclinic ?? {};

  const [isSpeaking, setIsSpeaking] = useState(false);

  const { data: practitionerData } = useGetProfileByIdQuery(
    { id: practitioner.profile_id ?? -1 },
    {
      skip: !practitioner?.profile_id,
    },
  );

  const practitionerName = practitionerData
    ? getPractitionerName(practitionerData, true)
    : practitioner.username;

  const speak = useCallback((text: string) => {
    if (!queueData.queue) return;

    setIsSpeaking(true);
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "id-ID";
    utterance.onend = () => setIsSpeaking(false);
    window.speechSynthesis.speak(utterance);
  }, []);

  return (
    <Container className={className}>
      <Main>
        <BasicText
          style={{
            fontWeight: 600,
            fontSize: "1.1rem",
          }}
        >
          {patient?.full_name}{" "}
        </BasicText>

        <InfoTextContainer>
          <AiOutlineIdcard color={COLORS.green} />
          <BasicText
            style={{
              justifySelf: "start",
              fontSize: "0.8rem",
            }}
          >
            {patient?.nik ?? "-"}
          </BasicText>
          <LiaFileMedicalAltSolid color={COLORS.green} />
          <BasicText
            style={{
              justifySelf: "start",
              fontSize: "0.8rem",
            }}
          >
            {patient?.encounter ?? "-"}
          </BasicText>
          <img src={bpjsWordmark} alt="" width="20px" />
          <BasicText
            style={{
              justifySelf: "start",
              fontSize: "0.8rem",
            }}
          >
            {patient?.bpjs_no ?? "-"}
          </BasicText>
        </InfoTextContainer>
        <ChipContainer>
          {enId && <Chip>ID - {enId}</Chip>}
          <Chip>{`${polyclinic_name ?? "-"} - ${
            practitionerName ?? "-"
          }`}</Chip>
        </ChipContainer>
      </Main>

      <ActionContainer>
        {queueData?.is_bpjs && (
          <div style={{ width: "40px" }}>
            <img src={logo_bpjs} width="40px" alt="BPJS" />
          </div>
        )}

        {showCallBtn && (
          <ActionButton
            className="inverse"
            title={`Panggil antrian ke ${queueData.queue ?? "-"}`}
            onClick={() => speak(`Antrian: ${queueData.queue}` ?? "-")}
            disabled={isSpeaking}
            style={{
              cursor: isSpeaking ? "not-allowed" : "pointer",
              background: isSpeaking ? COLORS.light_grey_1 : COLORS.white,
            }}
          >
            <PiSpeakerHighBold size={16} />{" "}
            {`Antrian: ${queueData.queue}` ?? "-"}
          </ActionButton>
        )}
        <ActionButton
          onClick={() => {
            onClick && onClick(enId);
          }}
          title={actionTitle}
        >
          {actionText}
        </ActionButton>
        {!!actions?.length && (
          <ActionDropdown
            className="action-dropdown"
            items={actions.map(({ name, onClick, hasNoAction }, idx) => ({
              name: (
                <div
                  className={hasNoAction ? "no-action" : ""}
                  key={idx}
                  title={hasNoAction ? "Pasien Sedang Diperiksa" : ""}
                >
                  {name}
                </div>
              ),
              onClick,
              hasNoAction,
            }))}
            name="action-antrian"
            direction="right"
          >
            {(isOpen, setIsOpen) => (
              <>
                <BasicActionBtn
                  className="three-dots-btn"
                  type="button"
                  onClick={(e) => {
                    setIsOpen(!isOpen);
                    e.stopPropagation();
                  }}
                >
                  <BsThreeDotsVertical />
                </BasicActionBtn>
              </>
            )}
          </ActionDropdown>
        )}
      </ActionContainer>
    </Container>
  );
};

export default QueueItem;
