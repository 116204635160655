import { baseApi } from "store/baseStoreAPI";

const subEndpoint = "/user-accounts";

export const userAccountApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVerifyEmail: builder.query({
      query: () => {
        return {
          url: `${subEndpoint}/verify-email`,
          method: "GET",
        };
      },
    }),

    verifyToken: builder.query<any, { token?: string }>({
      query: ({ token }) => {
        return {
          url: `${subEndpoint}/verify-email/verify`,
          method: "GET",
          params: {
            token,
          },
        };
      },
    }),

    changePassword: builder.mutation<
      any,
      {
        new_password: string;
      }
    >({
      query: ({ new_password }) => {
        return {
          url: `${subEndpoint}/change/password`,
          method: "PATCH",
          body: {
            new_password,
          },
        };
      },
    }),
  }),
});

export const {
  useGetVerifyEmailQuery,
  useVerifyTokenQuery,
  useChangePasswordMutation,
  util: { resetApiState: resetUserAccountApiState },
} = userAccountApi;
