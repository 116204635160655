import React, { useMemo } from "react";
import { Column } from "react-table";

import { ReportPolyclinicItem, ReportPolyclinicList } from "types/api/report";

import { StyledTable } from "Pages/Report/components/ReportTableVariant/style";
import CustomPaginationTable from "components/BasicTable/CustomPaginationTable/CustomPaginationTable";

type Props = {
  data?: ReportPolyclinicList;
  page: number;
  take: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTake: React.Dispatch<React.SetStateAction<number>>;
};

const TableTemplate = ({ data, page, take, setPage, setTake }: Props) => {
  const tableData = data?.entities ?? [];

  const columns: Column<ReportPolyclinicItem>[] = useMemo(() => {
    return [
      {
        Header: "No.",
        id: "row_number",
        accessor: (_, i) => (page - 1) * take + i + 1,
        maxWidth: 40,
      },
      {
        Header: "Kode Poli",
        accessor: (row) => row.code || "-",
      },
      {
        Header: "Nama Poli",
        accessor: (row) => row.polyclinic_name || "-",
      },
      {
        Header: "Jumlah Dokter",
        accessor: (row) => row.docter_count || "-",
      },
      {
        Header: "Keterangan",
        accessor: (row) => row.poli_sakit || "-",
      },
    ];
  }, [page, take]);

  return (
    <>
      <StyledTable
        columns={columns}
        data={tableData}
        isHideNumbering
        showPagination={false}
        noDataText="No Data"
      />

      <CustomPaginationTable
        hasilText={`Memperlihatkan ${tableData?.length} dari ${
          data?.meta?.itemCount ?? 0
        } Data`}
        setLimit={setTake}
        page={page}
        setPage={setPage}
        take={take}
        meta={{
          itemCount: data?.meta?.itemCount ?? 0,
          page,
          offset: 0,
          pageCount: data?.meta?.pageCount ?? 0,
        }}
      />
    </>
  );
};

export default TableTemplate;
