import useProgressiveImg from "hooks/utils/useProgressiveImg";

import { logo } from "assets";
import { background_min_png, background_png } from "assets/background";
import {
  LGBackground,
  LGContainer,
  LGContainerAligner,
  LGLogoContainer,
} from "Pages/LoginPage/components";
import ResetPasswordForm from "Pages/LoginPage/components/ForgotPasswordForm/ResetPasswordForm";
import VerifyOtpForm from "Pages/LoginPage/components/ForgotPasswordForm/VerifyOtpForm";
import ChangePasswordForm from "Pages/LoginPage/components/ForgotPasswordForm/ChangePasswordForm";

type ResetStep = "reset" | "verify-otp" | "change-password";

type Props = {
  step: ResetStep;
};

const ForgotPasswordPage = ({ step }: Props) => {
  const [img] = useProgressiveImg({
    placeholderSrc: background_min_png,
    src: background_png,
  });

  return (
    <LGBackground
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <LGContainerAligner>
        <LGContainer>
          <LGLogoContainer>
            <img
              width={150}
              style={{ margin: "0 auto" }}
              alt="Rekmed"
              src={logo}
            />
          </LGLogoContainer>
          {step === "reset" && <ResetPasswordForm />}
          {step === "verify-otp" && <VerifyOtpForm />}
          {step === "change-password" && <ChangePasswordForm />}
        </LGContainer>
      </LGContainerAligner>
    </LGBackground>
  );
};

export default ForgotPasswordPage;
