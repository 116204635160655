import React from "react";

/**
 * this hook will debounce (aka delay setting the value) as long as the value is changing
 * @param value value to be dounced
 * @param delay time to debounce, default to 1000 ms
 * @returns debounced value
 */
const useDebounce = <D>(value: D, delay = 1000) => {
  const [debouncedVal, setDebounceVal] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedVal;
};

export default useDebounce;
