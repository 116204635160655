import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import toast from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";

import { TKelompokBersamaBase } from "types/api/bpjs";
import {
  useDeleteKelompokKegiatanMutation,
  useGetKelompokKegiatanQuery,
} from "store/bpjsStoreAPI/bpjsKelompokAPI";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import { COLORS } from "assets/theme";
import CustomToast from "components/CustomToast";
import { useModal } from "components/ModalRefined/ModalRefined";
import { BaseActionButton, BaseButton } from "components/base/Button";
import { BaseModalRefined } from "components/base/Modal";
import { BaseTable } from "components/base/Table";

type Props = {
  search?: string;
};

const columns: Column<TKelompokBersamaBase>[] = [
  {
    Header: "No.",
    accessor: (_, i) => i + 1,
    width: 40,
  },
  {
    accessor: "tglPelayanan",
    Header: "Tgl Pelaksanaan",
  },
  {
    accessor: "kelompok",
    Header: "Jenis Kelompok",
    Cell: ({ value }) => {
      const val = value ? `${value.kode} - ${value.nama}` : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "clubProl",
    Header: "Club Prolanis",
    Cell: ({ value }) => {
      const val = value ? `${value.clubId} - ${value.nama}` : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "kegiatan",
    Header: "Jenis Kegiatan",
    Cell: ({ value }) => {
      const val = value ? `${value.kode} - ${value.nama}` : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "materi",
    Header: "Materi",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "pembicara",
    Header: "Pembicara",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "lokasi",
    Header: "Lokasi",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "biaya",
    Header: "Biaya",
    Cell: ({ value }) => {
      const { formatRupiah } = useFormatRupiah();
      const val = value ? formatRupiah(value) : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "keterangan",
    Header: "Keterangan",
    Cell: ({ value }) => {
      const val = value ? value : "-";
      return <span>{val}</span>;
    },
  },
  {
    accessor: "eduId",
    Header: "Aksi",
    Cell: ({ row: { original: data } }) => {
      const navigate = useNavigate();
      const eduId = data.eduId;
      const kelompokName = data?.kelompok?.nama;

      const [deleteKelompok] = useDeleteKelompokKegiatanMutation();

      const { isOpen, toggleModal } = useModal();

      const handleDelete = () => {
        deleteKelompok({
          eduId: eduId,
        })
          .unwrap()
          .then((res) => {
            toast.custom((t) => (
              <CustomToast
                t={t}
                status="success"
                headerText="Berhasil"
                description="Kelompok berhasil dihapus"
              />
            ));
          })
          .catch((err) => {
            console.error(err);
            toast.custom((t) => (
              <CustomToast
                t={t}
                status="error"
                headerText="Gagal"
                description="Kelompok gagal dihapus"
              />
            ));
          });
      };

      return (
        <>
          <BaseModalRefined
            isOpen={isOpen}
            onClose={toggleModal}
            header="Konfirmasi"
            headerColor="alt"
            size="sm"
          >
            <div>
              <p
                style={{
                  margin: "12px 0",
                  textAlign: "center",
                }}
              >
                Apakah anda yakin ingin menghapus data kelompok {kelompokName}?
              </p>
              <div
                style={{
                  paddingTop: "24px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <BaseButton
                  type="button"
                  styleType="info"
                  onClick={() => toggleModal(false)}
                >
                  Batalkan
                </BaseButton>

                <BaseButton
                  type="button"
                  styleType="danger"
                  onClick={handleDelete}
                >
                  Hapus
                </BaseButton>
              </div>
            </div>
          </BaseModalRefined>

          <div className="flex justify-center">
            <BaseActionButton
              title="Lihat Detail Kelompok"
              onClick={() => navigate(`/bpjs/kelompok-bersama/${eduId}`)}
            >
              <AiOutlineSearch size={18} color={COLORS.teal} />
            </BaseActionButton>
            <BaseActionButton
              title="Hapus Kelompok"
              onClick={() => {
                toggleModal(true);
              }}
            >
              <MdDeleteForever size={18} color={COLORS.magenta} />
            </BaseActionButton>
          </div>
        </>
      );
    },
  },
];

const TableKelompokBersama = ({ search }: Props) => {
  const { data: kelompokKegiatan, isLoading: isLoadingKelompokKegiatan } =
    useGetKelompokKegiatanQuery({
      month: format(new Date().setDate(1), "dd-MM-yyyy"),
    });

  const filteredKelompokKegiatan =
    kelompokKegiatan?.filter((kelompok) => {
      if (!search) return true;
      return (
        kelompok.kelompok?.nama?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.kegiatan?.nama?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.materi?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.pembicara?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.lokasi?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.keterangan?.toLowerCase().includes(search.toLowerCase()) ||
        kelompok.biaya
          ?.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        kelompok.clubProl?.nama?.toLowerCase().includes(search.toLowerCase())
      );
    }) || [];

  return (
    <>
      <BaseTable
        columns={columns}
        data={filteredKelompokKegiatan}
        isHideNumbering
        showPagination={false}
        isLoading={isLoadingKelompokKegiatan}
      />
    </>
  );
};

export default TableKelompokBersama;
