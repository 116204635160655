import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { BiX } from "react-icons/bi";

import { small } from "assets/theme";
import { BaseHeaderText } from "components/base/Text";
import Portal from "components/Portal/Portal";

interface ModalProps {
  onClose: () => void; // pass the toggle handler from the useModal hook
  isOpen: boolean; // pass the isOpen state from the useModal hook
  children?: React.ReactNode;
  header?: string;
  headerSize?: "12" | "18" | "24" | "32";
  headerColor?: "regular" | "black" | "danger" | "info" | "alt";
  headerDivider?: boolean;
  padding?: "sm" | "md";
  size?: "sm" | "md" | "lg" | "xl" | "2xl" | "fit";
  onBackdropClose?: boolean;
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
`;

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
`;

const ModalContainer = styled.div<{
  size?: ModalProps["size"];
}>`
  background: white;
  border-radius: 8px;
  width: ${({ size }) => {
    switch (size) {
      case "sm":
        return "400px";
      case "md":
        return "500px";
      case "lg":
        return "600px";
      case "xl":
        return "700px";
      case "2xl":
        return "800px";
      case "fit":
        return "fit-content";
      default:
        return "600px";
    }
  }};
  height: min-content;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9001;

  *::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0 0 8px 0;
  }

  *::-webkit-scrollbar-thumb {
    background-color: c4cde0;
    border: 3px solid white;
  }

  ${small} {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }
`;

const ModalHeader = styled.div<{
  headerDivider?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ headerDivider }) =>
    headerDivider ? "0.5px solid #eef1f8;" : "none"};

  .modal-header-text {
    margin: 12px 0;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 12px;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 0.6;
    }
  }
`;

const ModalBody = styled.div<{
  padding?: ModalProps["padding"];
}>`
  flex: 1 1 auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding: ${({ padding }) => {
    switch (padding) {
      case "sm":
        return "0 20px 20px 20px";
      case "md":
        return "0 30px 30px 30px";
      default:
        return "0 20px 20px 20px";
    }
  }};
`;

const ModalRefined: React.FC<ModalProps> = ({
  onClose,
  isOpen,
  children,
  header,
  headerSize,
  headerColor,
  headerDivider,
  padding,
  size,
  onBackdropClose = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <ModalWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ModalContainer size={size}>
              <ModalHeader headerDivider={headerDivider || false}>
                <BaseHeaderText
                  className="modal-header-text"
                  styleSize={headerSize || "24"}
                  stylesColor={headerColor || "regular"}
                >
                  {header || "Modal Header"}
                </BaseHeaderText>
                <button className="close-button" onClick={() => onClose()}>
                  <BiX size={24} />
                </button>
              </ModalHeader>
              <ModalBody padding={padding || "sm"}>{children}</ModalBody>
            </ModalContainer>
            <Backdrop
              onClick={() => {
                if (onBackdropClose) {
                  onClose();
                }
              }}
            ></Backdrop>
          </ModalWrapper>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default ModalRefined;

export function useModal(initialIsOpen: boolean = false) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const toggleModal = useCallback((value?: boolean) => {
    if (value !== undefined) {
      setIsOpen(value);
    } else {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  }, []);

  return { isOpen, toggleModal };
}
