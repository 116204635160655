import React, { PropsWithChildren } from "react";
import { Socket, io } from "socket.io-client";
import { ServerToClentEvents, WSQueueUpdateFn } from "types/socket";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "hooks";
import CookieMonster from "config/CookieMonster";
import { encounterStoreAPI } from "store/encounterStoreAPI";
import { combinedRoutes } from "config/nav";
import { toast } from "react-hot-toast";
import CustomToast from "components/CustomToast";
import { QueueStatusEnum } from "types/api/encounter";
import { RecipeStatusEnum } from "types/api/transactions";

type Props = {
  activeOnRoute: string;
};

const QueueUpdateHOC: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  activeOnRoute,
}) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  React.useEffect(() => {
    const jwt = CookieMonster.loadCookie("access_token");
    // const user_id = CookieMonster.loadCookie("username")
    const socket: Socket<ServerToClentEvents> = io(
      process.env.REACT_APP_ENDPOINT ?? "",
      {
        autoConnect: false,

        extraHeaders: {
          authorization: `Bearer ${jwt}`,
        },
      },
    );
    // console.log({ pathname, activeOnRoute });

    const fn: WSQueueUpdateFn = function (obj) {
      // console.log("Received", { pathname, activeOnRoute });
      // console.log("Received", obj);
      // // @ts-ignore
      // console.log({ socket: this });
      let id = "";
      let headerText = "";
      let description = "";
      let shouldSendToast = false;

      switch (pathname) {
        case combinedRoutes.antrian_pasien:
          switch (obj.queue_status) {
            case QueueStatusEnum.QUEUEING:
              id = "new_patient";
              headerText = "Pasien Baru";
              description = `${
                obj.patient_name
              } berkunjung ke ${obj.practitioner_name.replace("null", "")}`;
              shouldSendToast = true;
              break;
            case null:
              id = "patient_removed";
              headerText = "Pasien Batal Berkunjung";
              description = `${
                obj.patient_name
              } batal berkunjung ke ${obj.practitioner_name.replace(
                "null",
                "",
              )}`;
              shouldSendToast = true;
              break;
            default:
              shouldSendToast = false;
              break;
          }

          break;

        case combinedRoutes.antrian_farmasi:
          if (obj.queue_status === QueueStatusEnum.FINISH) {
            switch (obj.recipe_status) {
              case RecipeStatusEnum.DONE:
              case RecipeStatusEnum.QUEUE:
                shouldSendToast = false;
                break;
              case RecipeStatusEnum.WIP:
                id = "patient_paid";
                headerText = `Pasien telah membayar obat`;
                description = `Pasien ${obj.patient_name} telah melakukan pembayar. Lanjutkan dengan penyerahan obat`;
                shouldSendToast = true;
                break;
              default:
                id = "patient_have_examined";
                headerText = `Pasien telah selesai diperiksa`;
                description = `Pasien ${obj.patient_name} telah diperiksa. Obat-obatan dapat segera disiapkan.`;
                shouldSendToast = true;
                break;
            }
            // shouldSendToast &&
            //   toast.custom((t) => {
            //     return (
            //       <CustomToast
            //         t={t}
            //         status="success"
            //         headerText={headerText}
            //         description={description}
            //       />
            //     );
            //   }, {

            //   });
          } else shouldSendToast = false;
          break;

        case combinedRoutes.antrian_kasir:
          if (obj.queue_status === QueueStatusEnum.FINISH) {
            switch (obj.recipe_status) {
              case RecipeStatusEnum.QUEUE:
                id = "patient_can_pay";
                headerText = `Pasien dapat membayar tagihan`;
                description = `Pasien ${obj.patient_name} dapat segera melakukan pembayaran.`;
                shouldSendToast = true;
                break;
              case RecipeStatusEnum.DONE:
              case RecipeStatusEnum.WIP:
              default:
                shouldSendToast = false;
                break;
            }
          } else shouldSendToast = false;
          break;

        default:
          shouldSendToast = false;
          break;
      }
      // encounterStoreAPI
      shouldSendToast &&
        toast.custom(
          (t) => {
            return (
              <CustomToast
                t={t}
                status="success"
                headerText={headerText}
                description={description}
              />
            );
          },
          {
            id,
          },
        );
      dispatch(
        encounterStoreAPI.util.invalidateTags([
          "queue",
          { type: "queue", id: obj.queue_id },
          { type: "medrec", id: obj.queue_id },
          { type: "vital", id: obj.queue_id },
          { type: "transactions", id: obj.queue_id },
          { type: "medrec-procedures", id: obj.queue_id },
          { type: "medrec-recipes", id: obj.queue_id },
        ]),
      );
    };

    socket.on("queue_update", fn);

    if (jwt && pathname.includes(activeOnRoute)) {
      socket.connect();
      // console.log("socket connected", socket.id, activeOnRoute, pathname);
    } else {
      socket.disconnect();
      socket.removeListener("queue_update", fn);
    }

    return () => {
      socket.disconnect();
      socket.removeListener("queue_update", fn);
      // console.log("socket disconnected", socket.id, activeOnRoute, pathname);
    };
  }, [pathname, activeOnRoute]);
  return <>{children}</>;
};

export default QueueUpdateHOC;
