import { useState, useEffect, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Buffer } from "buffer";
import toast from "react-hot-toast";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { ABpjsCredentialBase } from "types/api/bpjs_credential";
import {
  useCreateBpjsCredentialMutation,
  useGetBpjsCredentialQuery,
  useUpdateBpjsCredentialMutation,
} from "store/clinicStoreAPI";
import { useGetBpjsPatientStatusMutation } from "store/bpjsAPI";
import useCookieAccess, { ParsedCookieObject } from "hooks/cookie";

import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import CustomToast from "components/CustomToast";
import {
  ActionWrapper,
  Form,
  FormInput,
  InfoText,
} from "Pages/Settings/components";
import { LGEyeBtn } from "Pages/LoginPage/components";
import { Col, Row } from "reactstrap";

type Props = {
  activeState: () => void;
};

type ABPJSFormData = ABpjsCredentialBase & {
  usernamePCare: string;
  passwordPCare: string;
};

const SettingBpjsForm = ({ activeState }: Props) => {
  const [showValue, setShowValue] = useState(false);
  const { saveCookie } = useCookieAccess();

  const {
    data: bpjsCredential,
    isLoading: isQueryBpjsLoading,
    isSuccess: isQueryBpjsSuccess,
  } = useGetBpjsCredentialQuery();
  const { const_id, cons_secret, authorization_header, user_key } =
    bpjsCredential?.data ?? {};
  const isBpjsIntegrationExist = bpjsCredential?.data;

  const [createBpjsCredential, { isLoading: isCreateBpjsLoading }] =
    useCreateBpjsCredentialMutation();
  const [updateBpjsCredential, { isLoading: isUpdateBpjsLoading }] =
    useUpdateBpjsCredentialMutation();
  const [testBpjsConnection, { isLoading: isTestConnectionLoading }] =
    useGetBpjsPatientStatusMutation();

  const isLoading = isCreateBpjsLoading || isUpdateBpjsLoading;

  const bpjsForm = useForm<ABPJSFormData>({
    defaultValues: {
      const_id: const_id ?? "",
      cons_secret: cons_secret ?? "",
      authorization_header: authorization_header ?? "",
      user_key: user_key ?? "",
      usernamePCare: "",
      passwordPCare: "",
    },
  });

  const handleResetForm = useCallback(() => {
    bpjsForm.reset({
      const_id,
      cons_secret,
      authorization_header,
      user_key,
    });
  }, [bpjsForm, const_id, cons_secret, authorization_header, user_key]);

  useEffect(() => {
    if (isQueryBpjsSuccess) {
      handleResetForm();
    }
  }, [isQueryBpjsSuccess, handleResetForm]);

  const handleSubmit = (formData: ABPJSFormData) => {
    const encryptedAuthHeader = Buffer.from(
      `${formData.usernamePCare}:${formData.passwordPCare}:095`,
    ).toString("base64");

    if (isBpjsIntegrationExist) {
      updateBpjsCredential({
        const_id: formData.const_id,
        cons_secret: formData.cons_secret,
        authorization_header: encryptedAuthHeader,
        user_key: formData.user_key,
      })
        .unwrap()
        .then(() => {
          saveCookie("is_bpjs_configured", { is_bpjs_configured: true });
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil!"
              description="Berhasil Integrasi BPJS Credential"
            />
          ));
        })
        .catch((e) => {
          let message =
            e.message ??
            "Gagal Mengubah Integrasi BPJS Credential atau Unauthorized";
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal!"
              description={message}
            />
          ));
        });
    } else {
      createBpjsCredential({
        const_id: formData.const_id,
        cons_secret: formData.cons_secret,
        authorization_header: encryptedAuthHeader,
        user_key: formData.user_key,
      })
        .unwrap()
        .then(() => {
          saveCookie("is_bpjs_configured", { is_bpjs_configured: true });
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil!"
              description="Berhasil Mengubah Integrasi BPJS Credential"
            />
          ));
        })
        .catch((e) => {
          let message =
            e.message ??
            "Gagal Melakukan Integrasi BPJS Credential atau Unauthorized";
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="error"
              headerText="Gagal!"
              description={message}
            />
          ));
        });
    }
  };

  const handleCheckBpjsConnection = () => {
    /**
     * @description If BPJS integration exist, it will return success even with any patient id.
     */
    if (isBpjsIntegrationExist) {
      testBpjsConnection("0000000000000")
        .unwrap()
        .then(() => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              status="success"
              headerText="Berhasil!"
              description="Tes Koneksi BPJS berhasil"
            />
          ));
        })
        .catch(() => {
          toast.custom((t) => (
            <CustomToast
              t={t}
              headerText="Gagal"
              description="Tes Koneksi BPJS gagal"
              status="error"
            />
          ));
        });
    }
  };

  return (
    <Form onSubmit={bpjsForm.handleSubmit(handleSubmit)}>
      {!isQueryBpjsLoading ? (
        <>
          <Col>
            <div className="input w-100">
              <Controller
                name="const_id"
                control={bpjsForm.control}
                render={({ field }) => (
                  <FormInput
                    name="bpjs-cons-id"
                    id="bpjs-cons-id"
                    autoComplete="new-password"
                    type="number"
                    label="Cons ID"
                    placeholder="Cons ID"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    required
                  />
                )}
              />
              <Controller
                name="cons_secret"
                control={bpjsForm.control}
                render={({ field }) => (
                  <FormInput
                    name="bpjs-cons-secret"
                    id="bpjs-cons-secret"
                    autoComplete="new-password"
                    type={showValue ? "text" : "password"}
                    label="Cons Secret"
                    placeholder="Cons Secret"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    elementAddonRight={
                      <LGEyeBtn
                        onMouseDown={() => {
                          setShowValue(true);
                        }}
                        onMouseUp={() => setShowValue(false)}
                      >
                        {showValue ? (
                          <BsEye color={COLORS.text_hint} />
                        ) : (
                          <BsEyeSlash color={COLORS.text_hint} />
                        )}
                      </LGEyeBtn>
                    }
                    required
                  />
                )}
              />
              <Row>
                <Col xs="12" md="6">
                  <Controller
                    name="usernamePCare"
                    control={bpjsForm.control}
                    render={({ field }) => (
                      <FormInput
                        name="bpjs-pcare-username"
                        id="bpjs-pcare-username"
                        autoComplete="new-password"
                        type={showValue ? "text" : "password"}
                        label="Username"
                        placeholder="Username akun PCare BPJS"
                        onChange={field.onChange}
                        required
                        elementAddonRight={
                          <LGEyeBtn
                            onMouseDown={() => {
                              setShowValue(true);
                            }}
                            onMouseUp={() => setShowValue(false)}
                          >
                            {showValue ? (
                              <BsEye color={COLORS.text_hint} />
                            ) : (
                              <BsEyeSlash color={COLORS.text_hint} />
                            )}
                          </LGEyeBtn>
                        }
                      />
                    )}
                  />
                </Col>
                <Col xs="12" md="6">
                  <Controller
                    name="passwordPCare"
                    control={bpjsForm.control}
                    render={({ field }) => (
                      <FormInput
                        name="bpjs-pcare-password"
                        id="bpjs-pcare-password"
                        autoComplete="new-password"
                        type={showValue ? "text" : "password"}
                        label="Password"
                        placeholder="Password akun PCare BPJS"
                        onChange={field.onChange}
                        required
                        elementAddonRight={
                          <LGEyeBtn
                            onMouseDown={() => {
                              setShowValue(true);
                            }}
                            onMouseUp={() => setShowValue(false)}
                          >
                            {showValue ? (
                              <BsEye color={COLORS.text_hint} />
                            ) : (
                              <BsEyeSlash color={COLORS.text_hint} />
                            )}
                          </LGEyeBtn>
                        }
                      />
                    )}
                  />
                </Col>
                <InfoText
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  Data Username dan Password anda tidak akan disimpan di
                  database kami. Data Username dan Password hanya akan
                  dikonversi sebagai Authorization Header untuk kebutuhan
                  integrasi.
                </InfoText>
              </Row>
              <Controller
                name="authorization_header"
                control={bpjsForm.control}
                render={({ field }) => (
                  <FormInput
                    name="bpjs-auth-header"
                    id="bpjs-auth-header"
                    autoComplete="new-password"
                    label="Authorization Header"
                    placeholder="Username dan Password PCare BPJS akan dikonversi menjadi Authorization Header"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    disabled
                  />
                )}
              />
              <Controller
                name="user_key"
                control={bpjsForm.control}
                render={({ field }) => (
                  <FormInput
                    name="bpjs-user-key"
                    id="bpjs-user-key"
                    autoComplete="new-password"
                    type="text"
                    label="User Key"
                    placeholder="User Key"
                    onChange={field.onChange}
                    defaultValue={field.value}
                    required
                  />
                )}
              />
            </div>
          </Col>

          <ActionWrapper>
            {isBpjsIntegrationExist ? (
              <BasicActionBtn
                onClick={handleCheckBpjsConnection}
                type="button"
                disabled={isTestConnectionLoading}
                style={{
                  background: "white",
                  color: `${COLORS.green_4}`,
                  outline: `2px solid ${COLORS.green_4}`,
                  outlineOffset: "-2px",
                  marginRight: "auto",
                }}
              >
                {isTestConnectionLoading ? "Testing..." : "Test Koneksi"}
              </BasicActionBtn>
            ) : null}

            <BasicActionBtn
              type="button"
              onClick={activeState}
              style={{
                background: COLORS.neutral_gray_gull,
                color: COLORS.black_1,
              }}
            >
              Batal
            </BasicActionBtn>

            <BasicActionBtn type="submit" disabled={isLoading}>
              {isLoading ? "Menyimpan..." : "Simpan"}
            </BasicActionBtn>
          </ActionWrapper>

          {isBpjsIntegrationExist && (
            <InfoText
              style={{
                paddingTop: "10px",
              }}
            >
              *Klinik telah terintegrasi BPJS
            </InfoText>
          )}
        </>
      ) : (
        <p
          style={{
            textAlign: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          Checking Integration...
        </p>
      )}
    </Form>
  );
};

export default SettingBpjsForm;
