export interface FontSizes {
  sm: number;
  md: number;
  lg: number;
}

export interface ChartColor {
  red: string;
  lightRed: string;
  green: string;
  lightGreen: string;
  blue_1: string;
  purple_1: string;
  yellow: string;
  green_1: string;
}

export interface BarColor {
  vivid_sky_blue: string;
  blue_munsell: string;
  curious_blue: string;
  bahama_blue: string;
  persian_green: string;
  atoll: string;
}

export const widthBreakpoints = [480, 768, 992, 1024, 1280, 1366, 1920];
export const mq = widthBreakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const extraExtraLarge = mq[6];

export const extraLarge = `@media screen and (max-width:${widthBreakpoints[5]}px)`;

export const large = `@media screen and (max-width:${widthBreakpoints[4]}px)`;

export const medium = `@media screen and (max-width:${widthBreakpoints[3]}px)`;

export const small = `@media screen and (max-width:${widthBreakpoints[1]}px)`;

export const xSmall = `@media screen and (max-width:${widthBreakpoints[0]}px)`;

export function genKeyframes(keyname: string) {
  return `@keyframes ${keyname}`;
}

export interface BasicTheme {
  barheight: string;
  sidebarBackgroundColor: string;
  sidebarSelected: string;
  backgroundColor: string;
  cardHeaderColor: string;
  primaryColorLightest: string;
  primaryColorDarkest: string;
  darkGray: string;
  dangerColor: string;
  codeColor: string;
  fontSizes: FontSizes;
  blue: string;
  secondaryBlue: string;
  white: string;
  black: string;
  secondaryBlack: string;
  gray: string;
  lightGray: string;
  chart: ChartColor;
  bar: BarColor;
  loginBackground: string;
  inputLogin: string;
  black_1: string;
}

export const themeA: BasicTheme = {
  barheight: "4rem",
  sidebarBackgroundColor: "#22232B",
  sidebarSelected: "#353844",
  backgroundColor: "#EEF1F8",
  cardHeaderColor: "#20A8D8",
  primaryColorDarkest: "#A21A91",
  primaryColorLightest: "#F1B0E9",
  codeColor: "#d63384",
  fontSizes: {
    sm: 10,
    md: 15,
    lg: 20,
  },
  // based on desgyn system
  blue: "#376EB5",
  secondaryBlue: "#3699FF",
  white: "#FFFFFF",
  black: "#22232B",
  dangerColor: "#F05454",
  secondaryBlack: "#303139",
  gray: "#A5AEC2",
  lightGray: "#C4CDE0",
  chart: {
    red: "#FC5A5A",
    lightRed: "#FFE5E5",
    green: "#20C997",
    lightGreen: "#DCFFF5",
    blue_1: "#3699FF",
    purple_1: "#7B61FF",
    yellow: "#FDAA09",
    green_1: "#20C997",
  },
  loginBackground: "#073572",
  inputLogin: "#254a81",
  black_1: "#1C1C24",
  darkGray: "#687484",
  bar: {
    vivid_sky_blue: "#01CEEB",
    blue_munsell: "#0A95A8",
    curious_blue: "#1486DC",
    bahama_blue: "#0964A9",
    persian_green: "#009687",
    atoll: "#0C7368",
  },
};

export const COLORS = {
  white: "#FFFFFF",
  gradient_vertical_green_pln:
    "linear-gradient(180deg, #035871 0%, #00A2B9 100%)",
  gradient_horizontal_green_pln:
    "linear-gradient(90deg, #035871 0%, #00A2B9 100%)",

  green_linear_gradient: "linear-gradient(120deg, #02AC8E 0%, #00CBD8 100%)",
  red_linear_gradient: "linear-gradient(135deg, #971717 0%, #FC5A5A 100%)",
  blue_linear_gradient: "linear-gradient(135deg, #00B4DB 0%, #1565C3 100%)",
  grey_linear_gradient: "linear-gradient(135deg, #3D4555 0%, #729AB5 100%)",

  light_grey_1: "#EEF1F8",
  text_white: "#FFFFFF",
  text_black: "#13131A",
  text_gray: "#687484",
  text_hint: "#A5AEC2",
  neutral_chromium: "#A5AEC2",
  neutral_gray_gull: "#C4CDE0",
  neutral_white: "#FFFFFF",
  grey_1: "#EEF1F8",
  grey_2: "#F7FAFF",
  grey_3: "#515255",

  dark_grey_1: "#8997A9",
  pln_yellow_1: "#F7E82E",
  lightGray_2: "#C4CDE0",
  light_grey_3: "#F8F8F8",
  gray_3: "#DDDDDD",
  black_1: "#1C1C24",
  black_2: "#13131A",
  black_3: "#000000",

  light_blue_1: "#E3F1FF",
  light_blue_2: "#CEE6FF",
  blue_1: "#3699FF",
  baltic: "#1486DC",
  yale_blue: "#376EB5",

  green: "#02ac8e",
  mint_green: "#dcfff5",

  magenta: "#DB2777",
  peach: "#FFE5E5",

  green_1: "#20C997",
  green_2: "#169E50",
  green_3: "#CCDD1E",
  green_4: "#009687",
  green_5: "#02beb9",

  green_btn: "#4CEDB3",

  light_green: "#00A2B9",
  green_1_pln: "#035871",
  green_2_pln: "#00A2B9",

  darkerGreen: "#1E8263",

  yellow: "#FDAA09",
  yellowHoney: "#FDBF46",

  red: "#FC5A5A",
  red_1: "#F05454",

  darkerRed: "#9A383A",

  teal: "#009687",

  secondaryBlue: "#3699FF",
  purple_1: "#7B61FF",
  purple_2: "#9C1AB1",

  brown_1: "#B89A8E",
  brown_2: "#7A5547",

  asphalt: "#34495E",
  midnight: "#2C3E50",
  card_background: "#34495E",
  dark_blue: "#34495E",
  card_title_background: "#2C3E50",

  silver_lining: "#EEF1F8",

  gradient_blue_chart_1: "#00b4db",
  gradient_blue_chart_2: "#1565c3",
  gradient_green_chart_1: "#00cbd8",
  gradient_green_chart_2: "#02ac8e",
  gradient_pink_chart_1: "#CC36AB",

  subtle_yellow: "#fff6d9",
  subtle_pink: "#fae1f5",
  subtle_blue: "#daedfe",

  strong_yellow: "#FEB400",
  strong_pink: "#CC36AB",
  strong_blue: "#007BF5",
} as const;

export const FONTSIZES = {
  sm: 10,
  base: 12,
  md: 15,
  lg: 20,
} as const;
