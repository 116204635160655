import { getPractitionerName } from "assets/usefulFunctions/practitioner";
import { useGetPractitionerInPolyQuery } from "store/clinicStoreAPI";
import { BasicSelectOpt } from "types";

export interface PractitionerOpt extends BasicSelectOpt<number> {}

type Props = {
  isActive?: boolean;
  isBpjs?: boolean;
  allowAll?: boolean;
};

const usePractitionerOptions = ({ isActive, isBpjs, allowAll }: Props) => {
  const { data, isLoading, isFetching } = useGetPractitionerInPolyQuery({
    isActive,
    isBpjs,
  });

  let list = (data ?? []).flatMap<PractitionerOpt>((obj) => {
    return obj.userAccounts.map((userAccount) => {
      const practitioner = userAccount?.userProfile;
      const practitionerName = practitioner
        ? getPractitionerName(practitioner)
        : "";
      return {
        value: userAccount.id,
        label: practitionerName,
        detail: userAccount,
      };
    });
  });

  if (allowAll) {
    list = [
      {
        value: -1,
        label: "Semua Dokter",
        detail: null,
      },
      ...list,
    ];
  }

  return {
    list,
    isLoading: isLoading || isFetching,
  };
};

export default usePractitionerOptions;
