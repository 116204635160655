import styled from "styled-components";
import Modal from "components/Modal/Modal";
import { small } from "assets/theme";

export const SubsModal = styled(Modal)`
  & .children-container {
    min-width: 400px;
    min-height: 20vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    p {
      margin-top: 20px;
    }
  }

  ${small} {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
    align-items: flex-start;
    text-align: left;
  }

  span {
    font-weight: bold;
  }
`;
