import { format } from "date-fns";
import { LuDownload } from "react-icons/lu";

import { PatientType, TransactionReportType } from "types/report";
import { reportApi } from "store/reportStoreAPI";
import { useAppSelector } from "hooks";

import { ActionBtn } from "Pages/Report/components";

type Props = {
  patientType?: PatientType;
  page?: number;
  take?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  searchValue?: string;
  sex?: string;
  polyclinicId?: string;
  practitionerId?: string;
  patientId?: string;
  trxType?: TransactionReportType;
};

const DownloadReport = ({
  patientType = "all",
  page = 1,
  take = 10,
  startDate = null,
  endDate = null,
  searchValue = "",
  sex = "",
  polyclinicId = "",
  practitionerId = "",
  patientId = "",
  trxType,
}: Props) => {
  const { reportType } = useAppSelector((state) => state.reportSlice);
  const today = new Date().toISOString();

  const [exportTransaksi] =
    reportApi.endpoints.getPatientTransaksiExport.useLazyQuery();
  const [exportKunjungan] =
    reportApi.endpoints.getPatientKunjunganExport.useLazyQuery();
  const [exportObat] = reportApi.endpoints.getDrugsExport.useLazyQuery();
  const [exportPasienList] =
    reportApi.endpoints.getPatientExport.useLazyQuery();
  const [exportDoctorList] = reportApi.endpoints.getDoctorExport.useLazyQuery();
  const [exportPoliklinikList] =
    reportApi.endpoints.getPolyclinicExport.useLazyQuery();
  const [exportProcedureList] =
    reportApi.endpoints.getProcedureExport.useLazyQuery();
  const [exportPharmacyTransaction] =
    reportApi.endpoints.getPharmacyTransactionExport.useLazyQuery();
  const [exportTransactionByPoly] =
    reportApi.endpoints.getTransactionByPolyExport.useLazyQuery();
  const [exportTransactionByDoctor] =
    reportApi.endpoints.getTransactionByDoctorExport.useLazyQuery();
  const [exportProcedureTransactionByPatient] =
    reportApi.endpoints.getProcedureTransactionByPatientExport.useLazyQuery();
  const [exportPharmacyTransactionByPatient] =
    reportApi.endpoints.getPharmacyTransactionByPatientExport.useLazyQuery();

  const handleDownload = () => {
    const params = {
      page: page,
      take: take,
      startDate: startDate?.toISOString() || today,
      endDate: endDate?.toISOString() || today,
      isBpjs:
        patientType === "bpjs"
          ? true
          : patientType === "umum"
          ? false
          : undefined,
      query: searchValue || undefined,
    };

    const downloadFile = (objectUrl: string, reportType: string) => {
      if (!objectUrl) {
        console.error(`Failed to download ${reportType}`);
        return;
      }

      const link = document.createElement("a");
      link.href = objectUrl;

      let filename = `${reportType}`;

      if (startDate && endDate) {
        const formattedStartDate = format(startDate, "dd/MMM/yyyy");
        const formattedEndDate = format(endDate, "dd/MMM/yyyy");
        filename = `${reportType} ${formattedStartDate} - ${formattedEndDate}`;
      }

      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    switch (reportType) {
      case "report-kunjungan":
        exportKunjungan(params)
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-transaksi":
        exportTransaksi(params)
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-obat":
        exportObat()
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-pasien-list":
        exportPasienList({
          sex: sex === "all" ? undefined : sex,
        })
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-dokter-list":
        exportDoctorList({
          polyclinicId: polyclinicId === "all" ? undefined : polyclinicId,
        })
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-poli-list":
        exportPoliklinikList()
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-tindakan-list":
        exportProcedureList()
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-transaksi-farmasi":
        exportPharmacyTransaction({
          startDate: params.startDate,
          endDate: params.endDate,
        })
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-transaksi-by-poli":
        exportTransactionByPoly({
          startDate: params.startDate,
          endDate: params.endDate,
          polyclinicId: polyclinicId,
        })
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-transaksi-by-dokter":
        exportTransactionByDoctor({
          startDate: params.startDate,
          endDate: params.endDate,
          practitionerId: practitionerId,
        })
          .unwrap()
          .then((objectUrl) => downloadFile(objectUrl, reportType))
          .catch((err) => console.log(err));
        break;
      case "report-transaksi-by-pasien":
        if (trxType === "report-trx-procedure") {
          exportProcedureTransactionByPatient({
            startDate: params.startDate,
            endDate: params.endDate,
            patientId: patientId,
            practitionerId: practitionerId || undefined,
          })
            .unwrap()
            .then((objectUrl) => downloadFile(objectUrl, reportType))
            .catch((err) => console.log(err));
        } else if (trxType === "report-trx-pharmacy") {
          exportPharmacyTransactionByPatient({
            startDate: params.startDate,
            endDate: params.endDate,
            patientId: patientId,
          })
            .unwrap()
            .then((objectUrl) => downloadFile(objectUrl, reportType))
            .catch((err) => console.log(err));
        }
        break;
      default:
        break;
    }
  };

  return (
    <ActionBtn className="download" onClick={handleDownload}>
      <LuDownload />
      Download CSV
    </ActionBtn>
  );
};

export default DownloadReport;
