import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { useGetTransactionByDoctorReportQuery } from "store/reportStoreAPI";
import useDebounce from "hooks/utils/useDebounce";
import useFormatRupiah from "hooks/utils/useFormatRupiah";
import { useUserAccountPractitionerList } from "hooks/practitioner/usePractitionerList";

import RangedDatePicker from "components/RangedDatepicker/RangedDatepicker";
import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableTransaksiByDokter/TableTemplate";

const TableTransaksiByDokter = () => {
  const today = new Date().toISOString();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [takePractitioner, setTakePractitioner] = useState<number>(50);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [practitionerId, setPractitionerId] = useState<string>("");
  const { formatRupiah } = useFormatRupiah();

  const { data: practitionerData } = useUserAccountPractitionerList({
    page: 1,
    take: takePractitioner,
  });

  const practitionerOpt = [
    ...(practitionerData?.map((v) => {
      return {
        value: v?.id?.toString(),
        label: `${v?.label}`,
      };
    }) || []),
  ];

  const { data: reportData } = useGetTransactionByDoctorReportQuery(
    {
      page: page,
      take: take,
      query: debouncedSearchValue || undefined,
      startDate: startDate?.toISOString() || today,
      endDate: endDate?.toISOString() || today,
      practitionerId: practitionerId || "1",
    },
    {
      skip: !startDate || !endDate || !practitionerId,
    },
  );

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
        <PureInputSelect
          label=""
          placeholder="Pilih Dokter"
          options={practitionerOpt}
          value={practitionerOpt?.find(
            (option) => option.value === practitionerId,
          )}
          onChange={(e) => {
            if (e) {
              const newValue = e.value as string;
              setPractitionerId(newValue);
            }
          }}
          onMenuScrollToBottom={() => {
            if (practitionerOpt && practitionerOpt.length >= takePractitioner) {
              setTakePractitioner(takePractitioner + 50);
            }
          }}
        />
        <RangedDatePicker label="" fullWidth onDateChange={handleDateChange} />
      </div>
      {startDate && endDate && practitionerId && (
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Total Transaksi{" "}
                <span>{formatRupiah(data?.total_earning || 0)}</span>
              </RecapItem>
            </RecapContainer>
            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              {_.isEmpty(data?.entities) ? null : (
                <DownloadReport
                  startDate={startDate}
                  endDate={endDate}
                  practitionerId={practitionerId}
                />
              )}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      )}
    </>
  );
};

export default TableTransaksiByDokter;
