/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { VoucherDetail } from "types/api/voucher";
import { voucherStoreApi } from "store/voucherStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import { BasicActionBtn } from "components/base";
import { FormInput, InputHelper } from "Pages/Langganan/components";
import { CouponModalLayout } from "Pages/Langganan/components/CouponModal/style";

type Props = {
  toggle: (v?: boolean | undefined) => void;
  activeCoupon: VoucherDetail;
  setActiveCoupon: (v?: any) => void;
};

const CouponModal = ({ toggle, activeCoupon, setActiveCoupon }: Props) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const [useCoupon, setUseCoupon] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const debounceCheck = useDebounce(voucherCode, 1000);

  const [
    triggerCheckVoucher,
    {
      data: voucherData,
      isLoading: isVoucherLoading,
      isSuccess: isVoucherSuccess,
    },
  ] = voucherStoreApi.endpoints.checkVoucher.useLazyQuery();

  const handleCheckVoucher = (voucherCode: string) => {
    triggerCheckVoucher({ code: voucherCode })
      .unwrap()
      .finally(() => {
        setDisplayInfo(true);
      });
  };

  const handleApply = () => {
    if (!voucherCode && activeCoupon?.voucher_code) {
      setActiveCoupon({} as VoucherDetail);
      toggle(false);
    } else {
      setUseCoupon(true);
      toggle(false);
    }
  };

  useEffect(() => {
    if (voucherCode === "") {
      setDisplayInfo(false);
    }
  }, [voucherCode]);

  useEffect(() => {
    if (debounceCheck) {
      handleCheckVoucher(debounceCheck);
    }
  }, [debounceCheck]);

  useEffect(() => {
    if (voucherData && isVoucherSuccess) {
      setActiveCoupon(useCoupon ? voucherData : {});
    }
  }, [voucherData, useCoupon, isVoucherSuccess]);

  return (
    <CouponModalLayout>
      <FormInput
        label="Kode Voucher"
        placeholder="Masukkan Kode Voucher disini"
        type="text"
        disabled={isVoucherLoading}
        onChange={(e) => setVoucherCode(e.target.value)}
        elementAddonHelper={
          displayInfo && (
            <InputHelper
              className={voucherData && isVoucherSuccess ? "" : "danger"}
            >
              {voucherData && !isVoucherLoading && isVoucherSuccess ? (
                `${voucherData?.name}`
              ) : (
                <>
                  "Kode voucher yang anda masukkan salah/tidak valid, tolong cek
                  kembali kode voucher dan coba lagi."
                </>
              )}
            </InputHelper>
          )
        }
      />

      <div
        style={{
          flexBasis: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BasicActionBtn
          className="info"
          type="button"
          onClick={() => toggle(false)}
        >
          Batalkan
        </BasicActionBtn>
        <BasicActionBtn
          type="button"
          disabled={isVoucherLoading}
          onClick={handleApply}
        >
          Terapkan
        </BasicActionBtn>
      </div>
    </CouponModalLayout>
  );
};

export default CouponModal;
