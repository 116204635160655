import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { PatientType } from "types/report";
import { useGetPatientKunjunganReportQuery } from "store/reportStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import RangedDatePicker from "components/RangedDatepicker/RangedDatepicker";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import ReportPatientTypeSelect from "Pages/Report/components/ReportPatientTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableKunjungan/TableTemplate";

const TableKunjungan = () => {
  const today = new Date().toISOString();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [patientType, setPatientType] = useState<PatientType>("all");
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const { data: reportData } = useGetPatientKunjunganReportQuery(
    {
      page: page,
      take: take,
      startDate: startDate?.toISOString() || today,
      endDate: endDate?.toISOString() || today,
      isBpjs:
        patientType === "bpjs"
          ? true
          : patientType === "umum"
          ? false
          : undefined,
      query: debouncedSearchValue || undefined,
    },
    {
      skip: !startDate || !endDate,
    },
  );

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
        <RangedDatePicker label="" fullWidth onDateChange={handleDateChange} />
      </div>
      {startDate && endDate && (
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Jumlah Pasien{" "}
                <span>{data?.dataJumlah?.jumlah_pasien ?? "-"}</span>
              </RecapItem>
              <RecapItem>
                Pasien BPJS{" "}
                <span>{data?.dataJumlah?.jumlah_pasien_bpjs ?? "-"}</span>
              </RecapItem>
              <RecapItem>
                Pasien Umum{" "}
                <span>{data?.dataJumlah?.jumlah_pasien_umum ?? "-"}</span>
              </RecapItem>
            </RecapContainer>

            <div className="sub-table-filter-container">
              <ReportPatientTypeSelect
                state={patientType}
                onChange={(newPatientType) => {
                  setPatientType(newPatientType);
                }}
              />
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              {_.isEmpty(data?.dataKunjungan?.entities) ? null : (
                <DownloadReport
                  patientType={patientType}
                  page={page}
                  take={take}
                  startDate={startDate}
                  endDate={endDate}
                  searchValue={searchValue}
                />
              )}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data?.dataKunjungan}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      )}
    </>
  );
};

export default TableKunjungan;
