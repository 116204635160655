import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import CookieMonster from "config/CookieMonster";
import { BackendDataShape } from "types";
import { convertToEncodedURL } from "assets/usefulFunctions";
import { AnEncounterWithId } from "types/api/encounter";
import { AVitalBase, AVitalWithId } from "types/api/vital";
import { AMedrecBase, AMedrecWithId } from "types/api/medrec";
import { AFileWithId } from "types/api/files";
import {
  FullSoapCheckWithId,
  FullSoapInspection,
  VitalInspection,
} from "types/inspection";
import { AQueue, AQueueBase } from "types/api/queue";
import { TBundleRMEForm, TResponseMedrec } from "types/api/bundle_rme";

export const encounterStoreAPI = createApi({
  reducerPath: "encounterStoreAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/emr`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: [
    "queue",
    "vital",
    "medrec",
    "medrec-procedures",
    "medrec-recipes",
    "transactions",
    "files",
  ],
  endpoints: (builder) => {
    return {
      // createMedrec: builder.mutation<BackendDataShape<undefined>, APatientMin>(
      //   {
      //     query: (obj) => {
      //       return {
      //         url: "/medrec",
      //         method: "POST",
      //         body: obj,
      //       };
      //     },
      //     invalidatesTags: ["medrec"],
      //   },
      // ),

      // patchPatient: builder.mutation<
      //   BackendDataShape<{
      //     id: number;
      //   }>,
      //   APatientMin & { id: number }
      // >({
      //   query: ({ id, ...obj }) => {
      //     return {
      //       url: "" + id,
      //       method: "PATCH",
      //       body: obj,
      //     };
      //   },
      //   invalidatesTags: ["queue"],
      // }),

      createVital: builder.mutation<
        BackendDataShape<{
          /**
           * vital id
           */
          id: number;
        }>,
        AVitalBase
      >({
        query: (body) => {
          return {
            url: `/queue/${body.queue_id}/vital`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          { type: "queue", id: queue_id },
          "queue",
          { type: "vital", id: queue_id },
          "vital",
        ],
      }),

      getVitalByQueueId: builder.query<AVitalWithId[], { queue_id: number }>({
        query: ({ queue_id }) => {
          return {
            url: `/queue/${queue_id}/vital`,
            method: "GET",
          };
        },
        providesTags: (_, __, { queue_id }) => [
          "vital",
          { type: "vital", id: queue_id },
        ],
        transformResponse: (data: BackendDataShape<AVitalWithId[]>, _) => {
          const vitals = data.data;
          return vitals;
        },
      }),

      createMedrec: builder.mutation<
        BackendDataShape<{
          /**
           * vital id
           */
          id: number;
        }>,
        AMedrecBase & {
          queue_id: number;
        }
      >({
        query: (body) => {
          return {
            url: `/queue/${body.queue_id}/medrec`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          { type: "queue", id: queue_id },
          "queue",
          { type: "medrec", id: queue_id },
          "medrec",
        ],
      }),

      getMedrecByQueueId: builder.query<AMedrecWithId, { queue_id: number }>({
        query: ({ queue_id }) => {
          return {
            url: `/queue/${queue_id}/medrec`,
            method: "GET",
          };
        },
        providesTags: (_, __, { queue_id }) => [
          "medrec",
          { type: "medrec", id: queue_id },
        ],
        transformResponse: (res: BackendDataShape<AMedrecWithId>, _) => {
          const medrecs = res.data;
          return medrecs;
        },
      }),

      createQueue: builder.mutation<
        BackendDataShape<{
          /**
           * queue id
           */
          id: number;
        }>,
        AQueueBase
      >({
        query: (body) => {
          return {
            url: "/queue",
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["queue"],
      }),

      getQueue: builder.query<
        AnEncounterWithId[],
        {
          patient_id?: number;
          startTime?: string;
          endTime?: string;
        }
      >({
        query: (obj) => {
          let params = "";
          if (obj) {
            params = convertToEncodedURL(obj);
            params = params.length ? "?" + params : "";
          }

          return {
            url: "/queue" + params,
            method: "GET",
          };
        },
        providesTags: (_, __, { patient_id }) => [
          "queue",
          { type: "queue", id: patient_id },
        ],
        transformResponse: (res: BackendDataShape<AnEncounterWithId[]>) => {
          return res.data.filter((v) => !!v.patient && !!v.practitioner);
        },
      }),

      deleteQueue: builder.mutation<
        BackendDataShape<null | string>,
        { id: number }
      >({
        query: ({ id }) => {
          return {
            url: `/queue/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: (_, __, { id }) => ["queue", { type: "queue", id }],
      }),

      getQueueById: builder.query<
        BackendDataShape<AnEncounterWithId>,
        { id: number }
      >({
        query: ({ id }) => {
          // let params = "";
          // if (obj) {
          //   params = convertToEncodedURL(obj);
          //   params = params.length ? "?" + params : "";
          // }

          return {
            url: `/queue/${id}`,
            method: "GET",
          };
        },
        providesTags: (_, __, { id }) => ["queue", { type: "queue", id }],
      }),

      createFile: builder.mutation<
        BackendDataShape<null>,
        { files: FormData; queue_id: number }
      >({
        query: ({ files, queue_id }) => {
          return {
            url: `/queue/${queue_id}/medrec/upload`,
            method: "POST",
            body: files,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          "files",
          { type: "files", queue_id },
          "medrec",
          { type: "medrec", id: queue_id },
        ],
      }),

      getFiles: builder.query<
        BackendDataShape<AFileWithId[]>,
        { queue_id: number }
      >({
        query: ({ queue_id }) => {
          return {
            url: `/queue/${queue_id}/medrec/files`,
            method: "GET",
          };
        },
        providesTags: (_, __, { queue_id }) => [
          "files",
          { type: "files", queue_id },
        ],
      }),

      /**
       * Below are the updated versions of the queries and mutations.
       * Queries/mutations with the keyword "medrec" is changed to "soap" to avoid confusion.
       */
      createVitalCheck: builder.mutation<
        BackendDataShape<null>,
        {
          queue_id: number;
          body: VitalInspection;
        }
      >({
        query: ({ queue_id, body }) => {
          return {
            url: `/queue/${queue_id}/vital`,
            method: "POST",
            body,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          "vital",
          { type: "vital", queue_id },
        ],
      }),

      updateVitalCheck: builder.mutation<
        BackendDataShape<null>,
        {
          queue_id: number;
          body: VitalInspection;
        }
      >({
        query: ({ queue_id, body }) => {
          return {
            url: `/queue/${queue_id}/vital`,
            method: "PUT",
            body,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          "vital",
          { type: "vital", queue_id },
        ],
      }),

      getVitalCheckByQueueId: builder.query<AVitalWithId, { queue_id: number }>(
        {
          query: ({ queue_id }) => {
            return {
              url: `/queue/${queue_id}/vital`,
              method: "GET",
            };
          },
          providesTags: (_, __, { queue_id }) => [
            "vital",
            { type: "vital", id: queue_id },
          ],
          transformResponse: (res: BackendDataShape<AVitalWithId>) => {
            return res.data;
          },
          keepUnusedDataFor: 0,
        },
      ),

      getSoapCheckByQueueId: builder.query<
        FullSoapCheckWithId & {
          kdppk: string;
          tglEstRujuk: string;
          subSpesialis: string;
          catatan: string;
        },
        { queue_id: number }
      >({
        query: ({ queue_id }) => {
          return {
            url: `/queue/${queue_id}/medrec`,
            method: "GET",
          };
        },
        providesTags: (_, __, { queue_id }) => [
          "medrec",
          { type: "medrec", id: queue_id },
        ],
        transformResponse: (
          res: BackendDataShape<
            FullSoapCheckWithId & {
              kdppk: string;
              tglEstRujuk: string;
              subSpesialis: string;
              catatan: string;
            }
          >,
        ) => {
          return res.data;
        },
        keepUnusedDataFor: 0,
      }),

      createSoapCheck: builder.mutation<
        BackendDataShape<{
          id: number;
        }>,
        {
          data: FullSoapInspection;
          queue_id: string;
        }
      >({
        query: ({ data, queue_id }) => {
          return {
            url: `/queue/${queue_id}/medrec`,
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          { type: "queue", id: queue_id },
          "queue",
          { type: "medrec", id: queue_id },
          "medrec",
        ],
      }),

      updateSoapCheck: builder.mutation<
        BackendDataShape<{
          id: number;
        }>,
        {
          queue_id: string;
          data: FullSoapInspection;
        }
      >({
        query: ({ queue_id, data }) => {
          return {
            url: `/queue/${queue_id}/medrec`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: (_, __, { queue_id }) => [
          { type: "queue", id: queue_id },
          "queue",
          { type: "medrec", id: queue_id },
          "medrec",
        ],
      }),

      getAllMedrec: builder.query<
        TResponseMedrec,
        {
          page?: number;
          take?: number;
          search?: string;
        }
      >({
        query: (args) => {
          return {
            url: `/medrec`,
            method: "GET",
            params: {
              ...args,
            },
          };
        },
        providesTags: ["medrec"],
        transformResponse: (res: BackendDataShape<TResponseMedrec>) => {
          const { entities, meta } = res.data;

          return {
            entities,
            meta,
          };
        },
      }),

      postMedrecBundle: builder.mutation<any, TBundleRMEForm>({
        query: (args) => {
          return {
            url: `/all`,
            method: "POST",
            body: args,
          };
        },
        invalidatesTags: ["medrec"],
      }),
    };
  },
});

export const {
  useCreateQueueMutation,
  useGetQueueByIdQuery,
  useCreateVitalMutation,
  useGetVitalByQueueIdQuery,
  useCreateMedrecMutation,
  useGetMedrecByQueueIdQuery,
  useGetQueueQuery,
  useCreateFileMutation,
  useGetFilesQuery,
  useDeleteQueueMutation,

  useCreateVitalCheckMutation,
  useUpdateVitalCheckMutation,
  useGetVitalCheckByQueueIdQuery,
  useGetSoapCheckByQueueIdQuery,
  useCreateSoapCheckMutation,
  useUpdateSoapCheckMutation,
  useGetAllMedrecQuery,
  usePostMedrecBundleMutation,
  util: { resetApiState: resetEncounterStoreAPI },
} = encounterStoreAPI;
