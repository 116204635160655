import { ChangeEvent, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";

import { clinic_placeholder } from "assets";
import { COLORS } from "assets/theme";

import {
  EditImageWrapper,
  PreviewTextWrapper,
  UploadLogoButton,
} from "Pages/Settings/components";

type Props = {
  currentImage?: string;
  file: File | undefined;
  setFile: (file: File | undefined) => void;
};

const EditClinicLogo = ({ currentImage, file, setFile }: Props) => {
  const [preview, setPreview] = useState<string | undefined>(
    currentImage ?? clinic_placeholder,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const nameFile =
    file && file?.name?.length > 80
      ? file?.name?.slice(0, 80) + "..."
      : file?.name;

  const removeFile = () => {
    setFile(undefined);
    setPreview(currentImage ?? clinic_placeholder);
  };

  const inputFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files?.[0];
    if (newFiles) {
      storeImage(newFiles);
    }
  };

  const storeImage = (image: File) => {
    const allowedMimeTypes = ["image/jpg", "image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024;

    if (image.size > maxSize) {
      alert("Ukuran File terlalu besar, maksimal 2MB");
      return false;
    }
    if (!allowedMimeTypes.includes(image.type)) {
      alert(
        "Jenis file tidak didukung. File yang diperbolehkan: .jpg, .jpeg, .png",
      );
      return false;
    }

    const preview = URL.createObjectURL(image);

    setPreview(preview);
    setFile(image);
  };

  return (
    <EditImageWrapper>
      <img src={preview} alt="logo klinik" />
      {!file && (
        <>
          <UploadLogoButton onClick={() => inputRef.current?.click()}>
            Ganti Logo Klinik
          </UploadLogoButton>
          <p>
            Unggah Logo Klinik dari Perangkat anda. Gambar Logo Klinik harus
            berbentuk kotak, minimal 800 x 800 dan ukuran maksimum 2mb.
          </p>
          <input
            className="input-file"
            type="file"
            ref={inputRef}
            max={1}
            size={2048}
            accept="image/*"
            onChange={inputFileHandler}
          />
        </>
      )}
      {file && (
        <PreviewTextWrapper>
          <a target="_blank" rel="noreferrer" href={preview}>
            {nameFile}
          </a>
          <RxCross2 size={20} color={COLORS.red_1} onClick={removeFile} />
        </PreviewTextWrapper>
      )}
    </EditImageWrapper>
  );
};

export default EditClinicLogo;
