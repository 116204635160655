import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Col } from "reactstrap";
import toast from "react-hot-toast";

import { Accounts } from "types/api/account";
import { useEditAccountNonIdMutation } from "store/accountsStoreAPI";
import useNullishValues from "hooks/utils/useNullishValues";

import { COLORS } from "assets/theme";
import { BasicActionBtn } from "components/base";
import CustomToast from "components/CustomToast";
import { ActionWrapper, Form, FormInput } from "Pages/Settings/components";

type Props = {
  data?: Accounts;
  closeActiveCard: () => void;
};

const SettingAccountForm = ({ data, closeActiveCard }: Props) => {
  const { username, email, phone_number } = data || {};
  const { nullify } = useNullishValues();
  const [updateAccount, { isSuccess, isLoading }] =
    useEditAccountNonIdMutation();

  const settingForm = useForm<Accounts>({
    defaultValues: {
      username: username || undefined,
      email: email || undefined,
      phone_number: phone_number || undefined,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      settingForm.reset();
      closeActiveCard();
    }
  }, [settingForm, isSuccess, closeActiveCard]);

  const handleSubmit = (formData: Accounts) => {
    const sanitizedData = nullify(formData);
    updateAccount({ ...sanitizedData })
      .unwrap()
      .then(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Berhasil"
            description={`Berhasil merubah data akun`}
            status="success"
          />
        ));
      })
      .catch(() => {
        toast.custom((t) => (
          <CustomToast
            t={t}
            headerText="Gagal"
            description={`Gagal merubah data akun`}
            status="error"
          />
        ));
      });
  };

  return (
    <Form onSubmit={settingForm.handleSubmit(handleSubmit)}>
      <Col>
        <Controller
          name="username"
          control={settingForm.control}
          render={({ field }) => (
            <FormInput
              type="text"
              label="Username"
              placeholder="Username"
              autoComplete="off"
              onChange={field.onChange}
              defaultValue={username}
              maxLength={20}
              pattern="[^\s]{3,20}"
              title="Jumlah karakter 3-20 dan tanpa whitespace"
              required
            />
          )}
        />
        <Controller
          name="email"
          control={settingForm.control}
          render={({ field }) => (
            <FormInput
              type="email"
              label="Email"
              placeholder="Email"
              autoComplete="off"
              onChange={field.onChange}
              defaultValue={email}
              required
            />
          )}
        />
        <Controller
          name="phone_number"
          control={settingForm.control}
          render={({ field }) => (
            <FormInput
              type="tel"
              label="No. Telepon"
              placeholder="No. Telepon"
              autoComplete="off"
              onChange={field.onChange}
              defaultValue={phone_number}
              pattern={"[0-9]{10,}"}
              title="No. telepon minimal 10 digit"
            />
          )}
        />
      </Col>

      <ActionWrapper>
        <BasicActionBtn
          type="button"
          onClick={closeActiveCard}
          style={{
            background: COLORS.neutral_gray_gull,
            color: COLORS.black_1,
          }}
        >
          Batalkan
        </BasicActionBtn>
        <BasicActionBtn type="submit" disabled={isLoading}>
          Simpan
        </BasicActionBtn>
      </ActionWrapper>
    </Form>
  );
};

export default SettingAccountForm;
