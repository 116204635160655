import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { TransactionReportType } from "types/report";
import { useGetPatientQuery } from "store/patientStoreAPI";
import {
  useGetPharmacyTransactionByPatientReportQuery,
  useGetProcedureTransactionByPatientReportQuery,
} from "store/reportStoreAPI";
import { useUserAccountPractitionerList } from "hooks/practitioner/usePractitionerList";
import useDebounce from "hooks/utils/useDebounce";
import useFormatRupiah from "hooks/utils/useFormatRupiah";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import RangedDatePicker from "components/RangedDatepicker/RangedDatepicker";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableTransaksiByPasien/TableTemplate";

const TransactionTypeOpt = [
  { value: "report-trx-pharmacy", label: "Farmasi" },
  { value: "report-trx-procedure", label: "Tindakan" },
];

const TableTransaksiByPasien = () => {
  const today = new Date().toISOString();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [takePractitioner, setTakePractitioner] = useState<number>(50);
  const [takePatient, setTakePatient] = useState<number>(50);
  const [searchPatient, setSearchPatient] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { formatRupiah } = useFormatRupiah();
  const [trxType, setTrxType] = useState<TransactionReportType>(
    "report-trx-procedure",
  );
  const [patientId, setPatientId] = useState<string>("");
  const [practitionerId, setPractitionerId] = useState<string | undefined>(
    undefined,
  );

  const { data: practitionerData } = useUserAccountPractitionerList({
    page: 1,
    take: takePractitioner,
  });

  const practitionerOpt = [
    ...(practitionerData?.map((v) => {
      return {
        value: v?.id?.toString(),
        label: `${v?.label}`,
      };
    }) || []),
    {
      value: undefined,
      label: "Semua Dokter",
    },
  ];

  const { data: patientData } = useGetPatientQuery({
    page: 1,
    take: takePatient,
    query: searchPatient,
  });

  const patientOpt = [
    ...(patientData?.data?.map((v) => {
      return {
        value: v?.id?.toString(),
        label: `${v?.full_name}`,
      };
    }) || []),
  ];

  const { data: reportDataProcedure } =
    useGetProcedureTransactionByPatientReportQuery(
      {
        page: page,
        take: take,
        startDate: startDate?.toISOString() || today,
        endDate: endDate?.toISOString() || today,
        query: debouncedSearchValue || undefined,
        patientId: patientId || "1",
        practitionerId: practitionerId || undefined,
      },
      {
        skip:
          !startDate ||
          !endDate ||
          !patientId ||
          trxType !== "report-trx-procedure",
      },
    );

  const { data: reportDataPharmacy } =
    useGetPharmacyTransactionByPatientReportQuery(
      {
        page: page,
        take: take,
        startDate: startDate?.toISOString() || today,
        endDate: endDate?.toISOString() || today,
        query: debouncedSearchValue || undefined,
        patientId: patientId || "1",
      },
      {
        skip:
          !startDate ||
          !endDate ||
          !patientId ||
          trxType !== "report-trx-pharmacy",
      },
    );

  const data = useMemo(() => {
    return trxType === "report-trx-procedure"
      ? reportDataProcedure
      : reportDataPharmacy;
  }, [reportDataProcedure, reportDataPharmacy, trxType]);

  const handleDateChange = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
        <PureInputSelect
          label=""
          placeholder="Pilih Pasien"
          options={patientOpt}
          value={patientOpt.find((option) => option.value === patientId)}
          onChange={(e) => {
            if (e) {
              const newValue = e.value as string;
              setPatientId(newValue);
            }
          }}
          onInputChange={(e) => {
            setSearchPatient(e);
          }}
          onMenuScrollToBottom={() => {
            if (patientOpt && patientOpt.length >= takePatient) {
              setTakePatient(takePatient + 50);
            }
          }}
        />
        <RangedDatePicker label="" fullWidth onDateChange={handleDateChange} />
      </div>
      {startDate && endDate && patientId && (
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Total Transaksi{" "}
                <span>{formatRupiah(data?.total_earning || 0)}</span>
              </RecapItem>
            </RecapContainer>

            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              <PureInputSelect
                label=""
                placeholder="Pilih Tipe Transaksi"
                options={TransactionTypeOpt}
                value={TransactionTypeOpt.find(
                  (option) => option.value === trxType,
                )}
                onChange={(e) => {
                  if (e) {
                    const newValue = e.value as TransactionReportType;
                    setTrxType(newValue);
                  }
                }}
              />
              {trxType === "report-trx-procedure" && (
                <PureInputSelect
                  label=""
                  placeholder="Pilih Dokter"
                  options={practitionerOpt}
                  value={practitionerOpt.find(
                    (option) => option.value === practitionerId,
                  )}
                  onChange={(e) => {
                    if (e) {
                      const newValue = e.value as string;
                      setPractitionerId(newValue);
                    }
                  }}
                  onMenuScrollToBottom={() => {
                    if (
                      practitionerOpt &&
                      practitionerOpt.length >= takePractitioner
                    ) {
                      setTakePractitioner(takePractitioner + 50);
                    }
                  }}
                />
              )}
              {_.isEmpty(data?.entities) ? null : (
                <DownloadReport
                  startDate={startDate}
                  endDate={endDate}
                  patientId={patientId}
                  practitionerId={practitionerId}
                  trxType={trxType}
                />
              )}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
            trxType={trxType}
          />
        </>
      )}
    </>
  );
};

export default TableTransaksiByPasien;
