import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { combinedRoutes } from "config/nav";
import { userAccountApi } from "store/userAccountStoreAPI";
import useCookieAccess from "hooks/cookie";

import CustomToast from "components/CustomToast";
import CustomLoader from "components/Loader/CustomLoader";
import { Container } from "Pages/Verification/components";

const VerificationPage = () => {
  const { loadCookie } = useCookieAccess();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [triggerVerifyToken, { isSuccess, isError }] =
    userAccountApi.endpoints.verifyToken.useLazyQuery();

  let from = location.state?.from?.pathname ?? combinedRoutes.login_page;
  const destination = loadCookie("access_token")
    ? combinedRoutes.pengaturan_akun
    : combinedRoutes.login_page;

  const handleTokenVerification = () => {
    triggerVerifyToken({ token: token ?? "" })
      .unwrap()
      .then(() => {
        navigate(destination, { replace: true });
      })
      .catch(() => {
        navigate(from, { replace: true });
      });
  };

  useEffect(() => {
    if (token) {
      handleTokenVerification();
    } else {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Berhasil"
          description="Verifikasi email berhasil."
          status="success"
        />
      ));
    } else if (isError) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Gagal"
          description="Verifikasi email gagal."
          status="error"
        />
      ));
    }
  }, [isSuccess, isError]);

  return (
    <Container>
      <CustomLoader />
    </Container>
  );
};

export default VerificationPage;
