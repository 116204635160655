import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { forgotPasswordApi } from "store/forgotPasswordStoreAPI";

import CustomToast from "components/CustomToast";
import {
  FormInput,
  InfoDescription,
  InfoTitle,
  InfoTitleBar,
} from "Pages/LoginPage/components";
import {
  Container,
  ActionButton,
  FormContainer,
} from "Pages/LoginPage/components/ForgotPasswordForm/style";

type RequestVerifyOtp = {
  username: string;
  otp: string;
};

const VerifyOtpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get("username");
  const otp_token = searchParams.get("otp");

  const [verifyOtp, { isLoading, isSuccess, isError }] =
    forgotPasswordApi.endpoints.verifyForgotPasswordOtp.useLazyQuery();

  const resetForm = useForm<RequestVerifyOtp>({
    defaultValues: {
      username: username ?? "",
      otp: otp_token ?? "",
    },
  });
  const { control } = resetForm;

  const handleSubmit = (formData: RequestVerifyOtp) => {
    verifyOtp(formData)
      .unwrap()
      .then((res) => {
        const access_token = res?.access_token;
        localStorage.setItem("temporary_access_token", access_token ?? "");

        setTimeout(() => {
          navigate(`/forgot-password/change-password`, {
            replace: true,
          });
        }, 1000);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Berhasil"
          description="OTP berhasil diverifikasi."
          status="success"
        />
      ));
    } else if (isError) {
      toast.custom((t) => (
        <CustomToast
          t={t}
          headerText="Gagal"
          description="Verifikasi OTP gagal."
          status="error"
        />
      ));
    }
  }, [isSuccess, isError]);

  return (
    <Container>
      <InfoTitle>
        <InfoTitleBar />
        <InfoDescription>Silahkan masukkan Kode OTP</InfoDescription>
      </InfoTitle>

      <FormContainer onSubmit={resetForm.handleSubmit(handleSubmit)}>
        <Controller
          name="otp"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormInput
              type="text"
              label="Kode OTP"
              placeholder="Masukkan Kode OTP"
              value={value}
              onChange={onChange}
              required
            />
          )}
        />

        <ActionButton disabled={isLoading} type="submit">
          Selanjutnya
        </ActionButton>
      </FormContainer>
    </Container>
  );
};

export default VerifyOtpForm;
