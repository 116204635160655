import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UsedAPI } from "api";
import { BackendDataShape } from "types";
import CookieMonster from "config/CookieMonster";
import { VoucherCreationDetail, VoucherDetail } from "types/api/voucher";

export const voucherStoreApi = createApi({
  reducerPath: "voucherStoreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${UsedAPI}/voucher`,
    prepareHeaders: (headers) => {
      const token = CookieMonster.loadCookie("access_token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Voucher"],
  endpoints: (builder) => {
    return {
      checkVoucher: builder.query<
        VoucherDetail,
        {
          code: string;
        }
      >({
        query: (args) => {
          const { code } = args;
          return {
            url: `/${code}`,
            method: "GET",
          };
        },
        providesTags: ["Voucher"],
        transformResponse: (res: BackendDataShape<VoucherDetail>) => res?.data,
      }),

      generateVoucher: builder.mutation<
        VoucherCreationDetail,
        {
          discount: number;
          discount_type: "MONTHLY" | "PERCENTAGE";
          expiration_date?: string;
        }
      >({
        query: (args) => {
          const { discount, discount_type, expiration_date } = args;
          return {
            url: "",
            method: "POST",
            body: {
              discount,
              discount_type,
              expiration_date,
            },
          };
        },
        invalidatesTags: ["Voucher"],
        transformResponse: (res: BackendDataShape<VoucherCreationDetail>) =>
          res?.data,
      }),
    };
  },
});

export const {
  useCheckVoucherQuery,
  useGenerateVoucherMutation,
  util: { resetApiState: resetVoucherStoreApi },
} = voucherStoreApi;
