import { useEffect, useMemo, useState } from "react";
import { BiSearch } from "react-icons/bi";
import _ from "lodash";

import { useGetDoctorReportQuery } from "store/reportStoreAPI";
import { useGetPolyclinicQuery } from "store/clinicStoreAPI";
import useDebounce from "hooks/utils/useDebounce";

import PureInputSelect from "components/PureInputSelect/PureInputSelect";
import {
  RecapContainer,
  RecapItem,
  SearchInput,
} from "Pages/Report/components";
import ReportTypeSelect from "Pages/Report/components/ReportTypeSelect";
import DownloadReport from "Pages/Report/components/DownloadReport";
import TableTemplate from "Pages/Report/components/ReportTables/TableDokter/TableTemplate";

const TableDokter = () => {
  const [page, setPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [polyId, setPolyId] = useState<string>("all");

  const { data: polyclinicData } = useGetPolyclinicQuery({
    page: 1,
    take: 100,
  });

  const polyOpt = [
    ...(polyclinicData?.data?.map((v) => {
      return {
        value: v.id.toString(),
        label: `${v.polyclinic_name}`,
      };
    }) || []),
    {
      value: "all",
      label: "Semua Poliklinik",
    },
  ];

  const { data: reportData } = useGetDoctorReportQuery({
    page: page,
    take: take,
    query: debouncedSearchValue || undefined,
    polyclinicId: polyId === "all" ? undefined : polyId,
  });

  const data = useMemo(() => {
    return reportData;
  }, [reportData]);

  useEffect(() => {
    if (searchValue !== "") {
      setPage(1);
    }
  }, [searchValue]);

  return (
    <>
      <div className="filter-container">
        <ReportTypeSelect />
      </div>
      {
        <>
          <div className="table-filter-container">
            <RecapContainer>
              <RecapItem>
                Jumlah Dokter <span>{data?.meta?.itemCount ?? "-"}</span>
              </RecapItem>
            </RecapContainer>
            <div className="sub-table-filter-container">
              <SearchInput
                placeholder="Cari..."
                onChange={(e) => setSearchValue(e.target.value)}
                elementAddonRight={<BiSearch />}
              />
              <PureInputSelect
                label=""
                placeholder="Poliklinik"
                options={polyOpt}
                value={polyOpt.find((option) => option.value === polyId)}
                onChange={(e) => {
                  if (e) {
                    const newValue = e.value as string;
                    setPolyId(newValue);
                  }
                }}
              />
              {_.isEmpty(data?.entities) ? null : (
                <DownloadReport polyclinicId={polyId} />
              )}
            </div>
          </div>
          <TableTemplate
            key={debouncedSearchValue}
            data={data}
            page={page}
            take={take}
            setPage={setPage}
            setTake={setTake}
          />
        </>
      }
    </>
  );
};

export default TableDokter;
